package csaware.policy

import csaware.main.CsawareServices
import csaware.messages.CsawareMessagesObject
import csaware.utilities.markdown.MarkdownRender
import dk.rheasoft.csaware.api.Policy
import dk.rheasoft.csaware.api.PolicyComment
import kafffe.bootstrap.Modal
import kafffe.core.*
import kafffe.messages.MessagesObject.formatDateTime
import kotlinx.browser.window
import kotlinx.datetime.Clock
import kotlinx.datetime.toJSDate
import org.w3c.dom.HTMLDivElement

class PolicyCommentsEditor(private val isSavedPolicy: Boolean, private val policyModel: Model<Policy>) : KafffeComponent() {
    init {
        loadData()
    }

    private fun testComment(id: String, comment: String, repsponseToPolicyId: String? = null): PolicyComment = PolicyComment(
        id,
        "1",
        repsponseToPolicyId,
        comment,
        createdBy = "palfred@rheasoft.dk",
        createdAt = Clock.System.now(),
        updatedBy = "palfred@rheasoft.dk",
        updatedAt = Clock.System.now()
    )

    private val commentsModel: Model<List<PolicyComment>> = Model.of(emptyList())

    private fun topLevelComments(): List<PolicyComment> =
        commentsModel.data.filter { it.responseToCommentId == null }.sortedByDescending { it.updatedAt }

    private fun responseComments(comment: PolicyComment): List<PolicyComment> =
        commentsModel.data.filter { it.responseToCommentId == comment.id }.sortedByDescending { it.updatedAt }

    private fun showDialog(dlg: PolicyCommentDialog) {
        dlg.onSubmitOk = {
            // consider error handling in dialogs
            if (!isSavedPolicy) {
                val comment: PolicyComment = dlg.model.data
                commentsModel.data = commentsModel.data.filter { it.id != comment.id } + comment
                detach()
                loadData()
            } else {
                CsawareServices.policyBackend.storePolicyComment(dlg.model.data) {
                    detach()
                    loadData()
                }
            }
        }
        dlg.attach()
    }

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut =
        div {
            addClass("csa-comments-editor")
            div {
                addClass("h2")
                text(CsawareMessagesObject.get().policy_comment_comment)
                button {
                    addClass("btn btn-secondary float-end")
                    withElement {
                        onclick = {
                            showDialog(PolicyCommentDialog.commentOn(policyModel.data))
                        }
                        title = CsawareMessagesObject.get().policy_comment_add
                    }
                    i { addClass("fas fa-comment") }
                }
            }
            div {
                for (comment in topLevelComments()) {
                    addCommentView(comment)
                }
            }
        }

    private fun KafffeHtml<HTMLDivElement>.addCommentView(comment: PolicyComment): KafffeHtml<HTMLDivElement> {
        return div {
            addClass("csa-comment")
            div {
                addClass("csa-comment-header")
                span {
                    text(comment.updatedAt.toJSDate().formatDateTime())
                }
                text(" ")
                span {
                    text(comment.updatedBy)
                }
                div {
                    addClass("ms-3 btn-group btn-group-sm float-end")
                    button {
                        addClass("btn btn-secondary")
                        i { addClass("fas fa-reply") }
                        withElement {
                            onclick = {
                                showDialog(PolicyCommentDialog.replyTo(comment))
                            }
                            title = CsawareMessagesObject.get().policy_comment_respond
                        }
                    }
                    button {
                        addClass("btn btn-secondary")
                        i { addClass("fas fa-pencil") }
                        withElement {
                            onclick = {
                                showDialog(PolicyCommentDialog.edit(comment))
                            }
                            title = CsawareMessagesObject.get().policy_comment_edit
                        }
                    }
                    button {
                        addClass("btn btn-warning")
                        i { addClass("fas fa-trash") }
                        withElement {
                            onclick = {
                                Modal.confirm(
                                    Model.of(CsawareMessagesObject.get().policy_comment_delete),
                                    Model.of(comment.comment)
                                ) {
                                    if (isSavedPolicy) {
                                        CsawareServices.policyBackend.deleteComment(comment) {
                                            loadData()
                                        }
                                    } else {
                                        commentsModel.data = commentsModel.data.filter { it.id != comment.id }
                                        loadData()
                                    }
                                }
                            }
                            title = CsawareMessagesObject.get().policy_comment_delete
                        }
                    }
                }
            }
            add(MarkdownRender(comment.comment).html)
            for (response in responseComments(comment)) {
                div {
                    i {
                        withStyle { verticalAlign = "top" }
                        addClass("fa-solid fa-reply fa-rotate-180")
                    }
                    addCommentView(response).apply {
                        addClass("mt-2")
                        withStyle { display = "inline-block" }
                    }
                }
            }
        }
    }

    private fun loadData() {
        if (isSavedPolicy) {
            CsawareServices.policyBackend.getCommentsToPolicy(policyModel.data.id) { commentsResult ->
                commentsModel.data = commentsResult.result
                rerenderRecursive()
            }
        } else {
            rerenderRecursive()
        }
    }

    /**
     * Save comments in memory, when policy is newly saved
     */
    fun saveComments(whenDone: () -> Unit) {
        for (comment in topLevelComments()) {
            saveComment(comment)
        }
        window.setTimeout({whenDone()}, 1000)
    }

    private fun saveComment(comment: PolicyComment) {
        CsawareServices.policyBackend.storePolicyComment(comment) {
            for (response in responseComments(comment)) {
                saveComment(response)
            }
        }
    }
}