package dk.rheasoft.csaware.api.socialmedia

import dk.rheasoft.csaware.api.QueryResult
import dk.rheasoft.csaware.utils.JsonUtilSerialization

@kotlinx.serialization.Serializable
data class SocialMediaEntry(
    val observation: SocialMediaObservation,
    val translation : Translation? = null,
    var matches: Set<String> = setOf()
    )  {

    fun getTitle(): String {
        if(translation != null){
            if(!translation.title.isNullOrEmpty())
                return translation.title
        }
        return observation.title
    }

    fun getText(): String {
        if(translation != null){
            if(!translation.text.isNullOrEmpty())
                return translation.text
        }
        return observation.text
    }

    fun toJson() = JsonUtilSerialization.json.encodeToString(serializer(),this)

    companion object {
        fun QueryResult<SocialMediaEntry>.toJson() : String =
            JsonUtilSerialization.json.encodeToString(QueryResult.serializer(serializer()),this)

        fun fromQueryResultJson(json: String) : QueryResult<SocialMediaEntry> =
            JsonUtilSerialization.json.decodeFromString(QueryResult.serializer(serializer()),json)

        fun fromJson(json: String) : SocialMediaEntry =
            JsonUtilSerialization.json.decodeFromString(serializer(), json)

    }
}