package csaware.kpi_statistics

import kafffe.core.Model

class QuestionnaireSection(titleModel: Model<String>) : StatisticSectionView(
    titleModel,
    listOf(),
    Model.of("""
        The latest results of the Cybersecurity Awareness Questionnaire for the organisation are presented here and compared to the average of the ecosystem and the average of all organisations that have filled in the questionnaire. The questionnaire is a self-assessment and intends to map the state of cybersecurity (awareness) of an organisation on 5 dimensions. 

        Dimension 1 explores the capacity to develop and deliver cybersecurity strategy, and to enhance the organisations cybersecurity resilience by improving incident response, cyber defence and critical infrastructure (CI) protection capacities.

        Dimension 2 reviews important elements of a responsible cybersecurity culture such as the understanding of cyber-related risks, the level of trust in services, understanding of privacy issues, collaboration within the organisation to promote cybersecurity awareness, and contacts with relevant stakeholders.

        Dimension 3 reviews the availability, uptake and quality of relevant cybersecurity awareness-raising programmes, formal cybersecurity educational programmes, and professional training programmes

        Dimension 4 examines the organisation’s capacity to design and enact national legislation that directly and indirectly relates to cybersecurity

        Dimension 5 examines the implementation of cybersecurity standards and good practices, the deployment of processes and controls, and the development of technologies and products in order to reduce cybersecurity risks
    """.trimIndent())
) {
    private val questionnaireColumnChart = addChild(QuestionnaireColumnChart(titleModel))
}