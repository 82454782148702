package csaware.systemdepend.config

import csaware.messages.CsawareMessages
import csaware.messages.CsawareMessagesObject.csawareMessageStrategy
import csaware.messages.i18nText
import csaware.systemdepend.SystemDependencyService
import dk.rheasoft.csaware.api.LayoutDirection
import dk.rheasoft.csaware.api.systemdependencies.SystemDependencyConfig
import dk.rheasoft.csaware.api.systemdependencies.SystemDependencyResource
import kafffe.bootstrap.BasicColor
import kafffe.bootstrap.ModalSize
import kafffe.bootstrap.form.FormDialog
import kafffe.bootstrap.form.FormInputGroupDecorator
import kafffe.bootstrap.form.MultipleEditSelect
import kafffe.bootstrap.form.checkbox
import kafffe.core.*
import kafffe.core.modifiers.CssClassModifier
import kafffe.core.modifiers.CssClassModifier.Companion.cssClassModifier
import kafffe.core.modifiers.StyleModifier
import kafffe.core.modifiers.StyleModifier.Companion.styleModifier

class SystemResourceConfigChangeDlg(
    systemResource: SystemDependencyConfig,
    private val graphService: SystemDependencyService
) :
        FormDialog<SystemDependencyConfig>(Model.of("System Dependency Config"), Model.of(cloneData(systemResource))) {

    private val layoutRootModel: Model<List<String>> = model.func(
            { p -> p.data.rootNodeIds.toList() },
            { p, v -> p.data.rootNodeIds = v.toMutableList() }
    )
    private val resourceIds = graphService.model.data.sortedBy(SystemDependencyResource::name).map { it.id }
    private val layoutRoot = object : MultipleEditSelect<String>("layout_roots", layoutRootModel, Model.of(resourceIds)) {
        override fun display(choice: String): String =
                graphService.byId(choice)?.name ?: ""
    }
    private val layoutRootWithLabel = FormInputGroupDecorator(i18nText(CsawareMessages::system_depend_layout_roots), layoutRoot)

    enum class Tab(val label: Model<String>) {
        Layout(i18nText(CsawareMessages::system_depend_layout)),
        Fields(i18nText(CsawareMessages::system_depend_fields)),
        ValueSets(i18nText(CsawareMessages::system_depend_fields_valueset)),
        NodeTypes(i18nText(CsawareMessages::system_depend_node_types)),
        Views(i18nText(CsawareMessages::system_depend_views))
    }

    private var currentTab: Tab = Tab.Layout

    fun selectTab(newTab: Tab) {
        if (newTab != currentTab) {
            // validate -> update model with previous tab data
            processForm(
                    onOk = {
                        currentTab = newTab
                        rerenderRecursive()
                    },
                    onError = {

                    })
        }
    }

    init {
        labelStrategy = csawareMessageStrategy("system_depend_")
        size = ModalSize.large
        modal.modifiersBody.add(StyleModifier {
            overflowY = "auto"
            maxHeight = "80vh"
        })
        modal.modifiersModal.add(StyleModifier {
            maxWidth = "90vw"
            width = "fit-content"
        })
        modal.modifiersContent.add(CssClassModifier("bg-light"))

        addChild(NavTabs())

        val layoutEditor = group {
            cssClassModifier("col-md-6 vgap-3")
            inputNum(SystemDependencyConfig::spacing).apply {
                minimum = 20
                maximum = 200
            }
            addChild(layoutRootWithLabel)
            val horizontalModel = model.func(
                    getData = { it.data.layoutDirection.isHorizontal() },
                    setData = { m, v -> m.data.layoutDirection = if (v) LayoutDirection.horizontal else LayoutDirection.vertical }
            )
            checkbox("direction", horizontalModel, labelStrategy.label("layout_horizontal"))

            checkbox(SystemDependencyConfig::includeSource)
        }
        layoutEditor.styleModifier { display = if (currentTab == Tab.Layout) "block" else "none" }

        val fieldsEditor = FieldsEditor(model.property(SystemDependencyConfig::fields), model, graphService)
        fieldsEditor.styleModifier { display = if (currentTab == Tab.Fields) "block" else "none" }
        addChild(fieldsEditor)

        val valueSetsEditor = ValueSetsEditor(
            model.property(SystemDependencyConfig::valueSets),
            countFunction = ::countFieldsUsingValueSet,
            valueCountFunction = ::countValueUse
        )
        valueSetsEditor.styleModifier { display = if (currentTab == Tab.ValueSets) "block" else "none" }
        addChild(valueSetsEditor)

        val typesEditor = NodeTypesEditor(
            model.property(SystemDependencyConfig::nodeTypes),
            model,
            countFunction = ::countNodeTypes,
        )
        typesEditor.styleModifier { display = if (currentTab == Tab.NodeTypes) "block" else "none" }
        addChild(typesEditor)

        val viewsEditor = GraphViewsEditor(
            model.property(SystemDependencyConfig::views),
            model,
        )
        viewsEditor.styleModifier { display = if (currentTab == Tab.Views) "block" else "none" }
        addChild(viewsEditor)

        cssClassModifier("hgap-3 vgap-3")
        submit().apply {
            color = BasicColor.primary
        }
        cancel().color = BasicColor.secondary

        onSubmitOk = {
            graphService.storeConfig(model.data)
        }
    }

    inner class NavTabs : KafffeComponent() {
        override fun KafffeHtmlBase.kafffeHtml() = ul {
            addClass("nav nav-tabs")
            for (tab in Tab.entries) {
                li {
                    addClass("nav-item")
                    a {
                        addClass("nav-link")
                        if (tab == currentTab) {
                            addClass("active")
                        }
                        text(tab.label.data)
                        element.onclick = {
                            selectTab(tab)
                            it.preventDefault()
                        }
                    }
                }
            }
        }

    }

    /**
     * Count node type uses
     */
    private fun countNodeTypes(): Map<String, Int> =
        graphService.model.data
            .groupingBy { it.x_csaware_node_type }
            .eachCount()

    private fun countFieldsUsingValueSet(): Map<String, Int> =
        model.data.fields.mapNotNull { it.valueSet }.groupingBy { it }.eachCount()

    /**
     * Counts the use of each value in a given value set.
     */
    private fun countValueUse(valueSetName: String): Map<String, Int> {
        val idsOfAllFieldsUsingValueSet = model.data.fields.filter { valueSetName == it.valueSet }.map { it.id }
        val systemDependencyResources = graphService.model.data

        // all values of single value fields using the valueSet
        val values = systemDependencyResources.flatMap { systemDependencyResource ->
            systemDependencyResource.data.filterKeys { fieldId -> fieldId in idsOfAllFieldsUsingValueSet }.values
        }
        // all values of multi value fields using the valueSet
        val valuesFromLists = systemDependencyResources.flatMap { systemDependencyResource ->
            systemDependencyResource.dataLists.filterKeys { fieldId -> fieldId in idsOfAllFieldsUsingValueSet }.values
        }.flatten()
        // include predefined fields, that are not store in data or dataList
        val valuesFromPredef = when (valueSetName) {
            SystemDependencyConfig.infoflowValueSet -> systemDependencyResources.flatMap { it.x_infoflow }
            else -> listOf()
        }

        // combine the results
        return (values + valuesFromLists + valuesFromPredef).groupingBy { it }.eachCount()
    }

}

private fun cloneData(systemResource: SystemDependencyConfig): SystemDependencyConfig =
    SystemDependencyConfig.fromJson(systemResource.toJsonObject())


