package dk.rheasoft.csaware.json.adapter

import kotlinx.datetime.Instant
import kotlinx.datetime.format.DateTimeComponents.Formats.ISO_DATE_TIME_OFFSET
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

object DatrixInstantSerializer : KSerializer<Instant> {
    override val descriptor: SerialDescriptor = Instant.serializer().descriptor

    override fun deserialize(decoder: Decoder): Instant {
        var value = decoder.decodeString()
        if (!value.endsWith("Z")) {
            value += "Z"
        }
        if (value.contains("+00:00")) {
            value = value.replace("+00:00", "")
        }

        return Instant.parse(value, ISO_DATE_TIME_OFFSET)
    }

    override fun serialize(encoder: Encoder, value: Instant) {
        encoder.encodeString(value.toString())
    }
}