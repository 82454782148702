package csaware.messages

import kafffe.core.MessagesStrategy
import kafffe.core.ServiceRegistry
import kafffe.messages.MessagesObject

object CsawareMessagesObject {
    fun register() {
        ServiceRegistry.register("csaware_en", CsawareMessages_en())
        ServiceRegistry.register("csaware_da", CsawareMessages_da())
    }

    val allLanguages: List<CsawareMessages>
        get() = ServiceRegistry.findAll()

    private var cache: CsawareMessages? = null
    private var cachedLanguage: String? = null

    fun get(): CsawareMessages {
        if (cachedLanguage != MessagesObject.currentLanguage) {
            cache = get(MessagesObject.currentLanguage)
            cachedLanguage = MessagesObject.currentLanguage
        }
        return cache!!
    }

    fun get(language: CharSequence): CsawareMessages {
        return allLanguages.find { language.startsWith(it.language) } ?: CsawareMessages_en()
    }

    fun csawareMessageStrategy(prefix: String? = null) = MessagesStrategy(prefix) { get() }
}