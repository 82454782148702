package csaware.user

import csaware.main.CsawareServices
import csaware.main.UserInformation
import csaware.messages.CsawareMessages
import csaware.messages.CsawareMessagesObject.csawareMessageStrategy
import csaware.messages.i18nText
import dk.rheasoft.csaware.api.User
import dk.rheasoft.csaware.api.access.MainFeature
import dk.rheasoft.csaware.api.access.Permission
import dk.rheasoft.csaware.api.access.UserRole
import kafffe.bootstrap.BasicColor
import kafffe.bootstrap.ColWidth
import kafffe.bootstrap.ModalSize
import kafffe.bootstrap.ResponsiveSize
import kafffe.bootstrap.form.FormDialog
import kafffe.bootstrap.form.checkbox
import kafffe.core.Model
import kafffe.core.func
import kafffe.core.modifiers.CssClassModifier
import kafffe.core.modifiers.CssClassModifier.Companion.cssClassModifier
import kafffe.core.modifiers.StyleModifier

class UserEditDlg(user: User, allowDelete: Boolean, val refresh: () ->Unit) : FormDialog<User>(i18nText(CsawareMessages::user_edit), Model.of(user)) {

    init {
        cssClassModifier("hgap-3 vgap-3")
        labelStrategy = csawareMessageStrategy("user_")
        size = ModalSize.large
        modal.modifiersBody.add(StyleModifier {
            overflowY = "auto"
            maxHeight = "75vh"
        })
        modal.modifiersModal.add(StyleModifier {
            maxWidth = "90vw"
            width = "1400px"
        })
        modal.modifiersContent.add(CssClassModifier("bg-light"))

        readonly(User::subject)
        input(User::email)
        readonly(User::fullname)

        row{
            col(ColWidth(ResponsiveSize.md, 5)) {
                cssClassModifier("hgap-3 vgap-3")
                group {
                    label(User::roles.name)
                    for (role in UserRole.entries) {
                        checkbox(
                            role.name,
                            model.func({ um -> um.data[role] }, { um: Model<User>, v: Boolean -> um.data[role] = v }),
                            Model.of(role.namePrettified)
                        ).readOnly = !(role.allowedUserAdministration || UserInformation.hasAccess(MainFeature.CsawareAdministration, Permission.Write ))
                    }
                }
            }
        }
        submit("save", onOk = {
            CsawareServices.alerts.clearAlerts()
            CsawareServices.userBackend.updateUser(model.data) {
                refresh()
            }
            detach()
        }).color = BasicColor.primary
        if (allowDelete) {
            submit("delete", onOk = {
                CsawareServices.alerts.clearAlerts()
                CsawareServices.userBackend.deleteUser(model.data) {
                    refresh()
                }
                detach()
            }).color = BasicColor.danger
        }
        cancel().color = BasicColor.secondary
    }

}