package csaware.threats

import csaware.messages.CsawareMessagesObject
import csaware.messages.threatState
import dk.rheasoft.csaware.api.incident.ThreatState
import kafffe.bootstrap.form.Dropdown
import kafffe.core.Model
import kafffe.core.kafffenv

/**
 * Dropdown for ThreatState, translates back and forth enum value to localized display name
 */
class ThreatStateDropdown(id: String,
                          valueModel: Model<ThreatState>,
                          choicesModel: Model<List<ThreatState>> = choicesModel(valueModel.data)
) : Dropdown<ThreatState>(id, valueModel, choicesModel) {

    override fun display(choice: ThreatState): String = CsawareMessagesObject.get().threatState(choice)
    override fun valueFromString(strValue: String): ThreatState = ThreatState.valueOf(strValue)
    override fun valueToString(value: ThreatState): String = value.toString()

    companion object {
        private fun choices(currentState: ThreatState): List<ThreatState> {
            if (kafffenv.restrictStates as Boolean) {
                return when (currentState) {
                    ThreatState.Active -> listOf(ThreatState.Active, ThreatState.Ignored, ThreatState.Resolved)
                    ThreatState.Ignored -> listOf(ThreatState.Active, ThreatState.Ignored, ThreatState.Resolved)
                    ThreatState.Resolved -> listOf(ThreatState.Active, ThreatState.Ignored, ThreatState.Resolved)
                    ThreatState.HealingAwaitDecision -> listOf(
                        ThreatState.HealingAwaitDecision,
                        ThreatState.HealingDeclined,
                        ThreatState.HealingAccepted
                    )

                    ThreatState.HealingAccepted -> listOf(
                        ThreatState.HealingAccepted,
                        ThreatState.HealingDeclined,
                        ThreatState.HealingAwaitDecision
                    )

                    ThreatState.HealingDeclined -> listOf(
                        ThreatState.Ignored,
                        ThreatState.Resolved,
                        ThreatState.HealingDeclined,
                        ThreatState.HealingAccepted,
                        ThreatState.HealingAwaitDecision
                    )

                    ThreatState.HealingInProgress -> listOf(ThreatState.HealingInProgress)
                    ThreatState.HealingFailed -> listOf(
                        ThreatState.Active,
                        ThreatState.Ignored,
                        ThreatState.Resolved,
                        ThreatState.HealingDeclined,
                        ThreatState.HealingAccepted,
                        ThreatState.HealingFailed
                    )

                    ThreatState.Healed -> listOf(ThreatState.Healed, ThreatState.Resolved, ThreatState.Active)
                    else -> ThreatState.entries
                }
            } else {
                return ThreatState.entries
            }
        }

        fun choicesModel(currentState: ThreatState): Model<List<ThreatState>> = Model.ofGet { choices(currentState) }
    }
}