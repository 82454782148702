package dk.rheasoft.csaware.api.incident

import kotlinx.serialization.Serializable

@Serializable
data class PolicyTemplateContext (
    val id: String,
    val title: String,
    val purpose: String,
)


