@file:Suppress("PropertyName","FunctionName")

package csaware.messages

import csaware.kpi_statistics.KpiDimensions
import dk.rheasoft.csaware.api.AccessLevel
import kafffe.messages.Messages


@OptIn(ExperimentalJsExport::class)
@JsExport
interface CsawareMessages : Messages {


    val translation_automatic: String
    val settings : String

    val environment_name: String

    val nav_overview: String
    val nav_anomalies: String get() = "Anomalies"
    val nav_threats: String
    val nav_threatsClosed: String
    val nav_config: String
    val tab_threats_open: String
    val tab_threats_closed: String
    val threat_group: String
    val threat_state: String
    val threat_state_initator: String
    val threat_state_history: String
    val threat_state_comment: String
    val threat_course_of_action: String
    val threat_subtype: String
    val threat_firstObserved: String
    val threat_endActive: String
    val threat_newState: String
    val threat_selfHealingConfirm: String
    val threat_where: String
    val threat_assignee: String

    val threat_observed_data_objects: String
    val threat_observed_data_id: String
    val threat_observed_data_type: String
    val threat_observed_data_data: String

    val severity: String
    val count: String

    val threat_topTitle: String

    val threat_level_5_name: String
    val threat_level_5_text: String
    val threat_level_4_name: String
    val threat_level_4_text: String
    val threat_level_3_name: String
    val threat_level_3_text: String
    val threat_level_2_name: String
    val threat_level_2_text: String
    val threat_level_1_name: String
    val threat_level_1_text: String
    val threat_level_0_name: String
    val threat_level_0_text: String
    val threat_group_all: String
    val threat_nobody: String

    fun threatLevelName(level: Int?): String {
        return when (level) {
            5 -> threat_level_5_name
            4 -> threat_level_4_name
            3 -> threat_level_3_name
            2 -> threat_level_2_name
            1 -> threat_level_1_name
            0 -> threat_level_0_name
            else -> "-"
        }
    }

    fun threatLevelText(level: Int?): String {
        return when (level) {
            5 -> threat_level_5_text
            4 -> threat_level_4_text
            3 -> threat_level_3_text
            2 -> threat_level_2_text
            1 -> threat_level_1_text
            0 -> threat_level_0_text
            else -> "-"
        }
    }

    val ThreatState_Active: String
    val ThreatState_Resolved: String
    val ThreatState_Ignored: String
    val ThreatState_HealingAwaitDecision: String
    val ThreatState_HealingAccepted: String
    val ThreatState_HealingDeclined: String
    val ThreatState_Healed: String
    val ThreatState_HealingFailed: String
    val ThreatState_HealingInProgress: String
    val ThreatState_Unknown: String

    val user_management: String
    val user_new: String
    val user_edit: String
    val user_subject: String
    val user_email: String
    val user_firstName: String
    val user_lastName: String
    val user_fullname: String
    val user_roles: String
    val user_action_import: String
    val user_action_export: String

    val user_preferences_edit:String
    val user_preferences_uiLanguage:String
    val user_preferences_dataPresentationLanguage: String

    val system_preferences_edit:String
    val system_preferences_accountUiLanguage:String
    val system_preferences_accountDataPresentationLanguage: String

    val informationshare_management: String
    val informationshare_short_description: String
    val informationshare_edit: String
    val informationshare_id: String
    val informationshare_description: String
    val informationshare_state: String
    val informationshare_create: String
    val informationshare_modified: String
    val informationshare_initiator: String
    val informationshare_history: String
    val informationshare_summary: String

    val ShareState_AwaitDecision: String
    val ShareState_Accept: String
    val ShareState_Decline: String
    val ShareState_Unknown: String

    val system_dependencies: String

    val system_depend_id: String
    val system_depend_name: String
    val system_depend_description: String
    val system_depend_connectedFrom: String
    val system_depend_connectedTo: String
    val system_depend_infoflow: String
    val system_depend_category: String
    val system_depend_fields: String
    val system_depend_fields_valueset: String
    val system_depend_fields_valueset_values: String
    val system_depend_node_types: String
    val system_depend_views: String get() = "Views"
    val system_depend_fields_stylepattern: String get() = "Dash Pattern"
    val system_depend_fields_selectedbydefault: String get() = "Selected"
    val system_depend_layout: String
    val system_depend_layoutDirection: String
    val system_depend_layout_horizontal: String
    val system_depend_layout_vertical: String
    val system_depend_layout_roots: String
    val system_depend_layout_user_root: String
    val system_depend_layout_default_root: String
    val system_depend_spacing: String

    val system_depend_action_search: String
    val system_depend_action_new: String
    val system_depend_action_edit: String
    val system_depend_action_delete: String
    val system_depend_action_delete_confirm_title: String
    val system_depend_action_config: String
    val system_depend_action_import: String
    val system_depend_action_export: String
    val system_depend_action_config_import: String
    val system_depend_action_config_export: String
    val system_depend_action_print: String

    val system_depend_action_backup_or_restore: String
    val system_depend_backup_title: String
    val system_depend_backup_create_new: String
    val system_depend_backup_created: String
    val system_depend_backup_comment: String
    val system_depend_backup_nodes_not_in_current: String
    val system_depend_backup_nodes_in_current_not_in_this: String
    val system_depend_backup_nodes_changed: String
    val system_depend_restore_state: String
    val system_depend_restore_operation: String
    val system_depend_restore_operation_remove: String
    val system_depend_restore_operation_restore: String
    val system_depend_restore_revert_all_nodes: String
    val system_depend_restore_revert_configuration: String

    val system_depend_label_all: String

    val system_depend_field_id: String
    val system_depend_field_label: String
    val system_depend_field_type: String
    val system_depend_field_cardinality: String
    val system_depend_field_add: String
    val system_depend_fields_config: String

    val system_depend_field_validation_error_txt: String


    val sign_out: String
    val sign_out_disclaimer: String
    val sign_out_link: String

    val mail_dialog_title: String
    val mail_to: String
    val mail_from: String
    val mail_subject: String
    val mail_body: String
    val mail_send: String

    val socialmedia_reader : String
    val socialmedia_reader_filter_readstate_both : String
    val socialmedia_reader_filter_readstate_not_read : String
    val socialmedia_reader_filter_readstate_read : String
    val socialmedia_reader_read_on_source : String
    val socialmedia_createdAt : String
    val socialmedia_keywords : String
    val socialmedia_text : String
    val socialmedia_account : String
    val socialmedia_id:String

    val socialmedia_system_where : String

    val socialmedia_account_included :String
    val socialmedia_management_title: String
    val socialmedia_account_edit: String
    val socialmedia_account_new: String
    val socialmedia_account_mediaType: String
    val socialmedia_account_collectorState: String
    val socialmedia_account_id: String
    val socialmedia_account_name: String
    val socialmedia_account_displayName: String
    val socialmedia_account_description: String
    val socialmedia_account_lastCollected: String

    val socialmedia_account_twitter_new: String

    val socialmedia_account_include: String
    val socialmedia_account_exclude: String

    val socialmedia_account_action_export: String
    val socialmedia_account_action_import: String
    val socialmedia_account_action_include_page: String
    val socialmedia_account_action_exclude_page: String

    val policy_action_ai_import: String

    val socialmedia_create_threatobservation: String

    val policy : String
    val policy_edit : String
    val policy_title : String
    val policy_purpose : String
    val policy_elements : String
    val policy_need : String
    val policy_roles_responsibilities : String
    val policy_references : String
    val policy_tags : String
    val policy_systemNodeReferences : String
    val policy_new  : String
    val policy_createdAt : String
    val policy_createdBy : String
    val policy_updatedAt : String
    val policy_updatedBy : String

    val policy_state : String
    val policy_state_draft : String
    val policy_state_approved : String
    val policy_state_draft_with_approved : String
    val policy_state_obsolete : String
    val policy_state_unknown : String

    val policy_type_organisation : String
    val policy_type_template : String

    val policy_comment_comment : String
    val policy_comment_add : String
    val policy_comment_edit : String
    val policy_comment_respond : String
    val policy_comment_delete : String

    val policy_approve_comment_add : String

    val policy_history : String

    val policy_action_edit: String
    val policy_action_clone: String
    val policy_action_approve: String
    val policy_action_export: String

    val policy_import_extract_text: String

    val policy_config  : String
    val policy_relation_type : String
    val policy_relation_id : String
    val policy_relation_name : String
    val policy_relation_reversed : String

    val policy_template_action_edit : String

    val markdown_bold            : String
    val markdown_italic          : String
    val markdown_strikethrough   : String
    val markdown_heading         : String
    val markdown_heading_smaller : String
    val markdown_heading_bigger  : String
    val markdown_heading_1x       : String
    val markdown_heading_2x       : String
    val markdown_heading_3x       : String
    val markdown_code            : String
    val markdown_quote           : String
    val markdown_unordered_list  : String
    val markdown_ordered_list    : String
    val markdown_clean_block     : String
    val markdown_link            : String
    val markdown_image           : String
    val markdown_mde_table       : String
    val markdown_horizontal_rule : String
    val markdown_preview         : String
    val markdown_side_by_side    : String
    val markdown_fullscreen      : String
    val markdown_guide           : String
    val markdown_undo            : String
    val markdown_redo            : String

    val anomalies_ignores_saved: String
    val anomalies_incident_saved: String
    val anomaly_details_module: String
    val anomaly_to_be_ignored: String
    val anomaly_incident: String
    val anomaly_new_incident: String
    val anomaly_unsaved_changes_titel: String
    val anomaly_unsaved_changes_message: String

    val eco_system: String get() = "Eco System"

    val accessLevel: String get() = "Access Level"
    val accessLevel_name_Unknown: String get() = "Unknown"
    val accessLevel_name_FrontendLocal: String get() = "Frontend only"
    val accessLevel_name_OrganisationalPrivate: String get() = "Organisation"
    val accessLevel_name_EcosystemGenerated: String get() = "Ecosystem generated"
    val accessLevel_name_OrganisationalSharedWithEcosystem: String get() = "Shared with ecosystem"
    val accessLevel_name_OrganisationalSharedWithAll: String get() = "Shared with all"
    val accessLevel_name_PublicData: String get() = "Public data"

    fun accessLevelName(accessLevel: AccessLevel) : String =
        when (accessLevel) {
            AccessLevel.Unknown                             -> accessLevel_name_Unknown
            AccessLevel.FrontendLocal                       -> accessLevel_name_FrontendLocal
            AccessLevel.OrganisationalPrivate               -> accessLevel_name_OrganisationalPrivate
            AccessLevel.EcosystemGenerated                  -> accessLevel_name_EcosystemGenerated
            AccessLevel.OrganisationalSharedWithEcosystem   -> accessLevel_name_OrganisationalSharedWithEcosystem
            AccessLevel.OrganisationalSharedWithAll         -> accessLevel_name_OrganisationalSharedWithAll
            AccessLevel.PublicData                          -> accessLevel_name_PublicData
        }

    val criticalasset_assign_title: String get() = "Critical Asset ID Assign"

    val nav_kpi: String get() = "Statistic and KPI"

    val kpi_dimension_title_Dynamic_Cybersecurity_Strategy: String
    val kpi_dimension_title_Collaborative_Mindset: String
    val kpi_dimension_title_Organizational_Learning: String
    val kpi_dimension_title_Regulatory_Compliance: String
    val kpi_dimension_title_Standards_and_Technologies: String

    fun kpiDimensionTitle(kpiDimensions: KpiDimensions) : String =
        when (kpiDimensions) {
            KpiDimensions.d1    -> kpi_dimension_title_Dynamic_Cybersecurity_Strategy
            KpiDimensions.d2    -> kpi_dimension_title_Collaborative_Mindset
            KpiDimensions.d3    -> kpi_dimension_title_Organizational_Learning
            KpiDimensions.d4    -> kpi_dimension_title_Regulatory_Compliance
            KpiDimensions.d5    -> kpi_dimension_title_Standards_and_Technologies
        }


    val kpi_number_of_incidents: String
    val kpi_number_of_policies: String
    val kpi_number_of_changes: String
    val kpi_number_of_assets: String
    val kpi_number_of_dependencies:String
    val kpi_number_of_values:String
    val kpi_number_of_logins:String
    val kpi_number_of_distinct_users:String
    val kpi_number_of_anomalies:String
    val kpi_number_of_new_anomalies:String
    val kpi_number_of_dismissed_anomalies:String
    val kpi_number_of_new_incidents:String
    val kpi_number_of_dot_dot_dot:String
    val kpi_number_of_security_appliances:String
    val kpi_number_of_policy_standards:String

    val kpi_time: String
    val kpi_time_of_count:String
    val kpi_logins: String
    val kpi_distinct_users:String
    val kpi_anomaly_count:String
    val kpi_anomaly_timeline:String
    val kpi_anomaly_dismissed:String
    val kpi_anomaly_incident:String
    val kpi_system_graph_security_appliances:String
    val kpi_security_appliances:String
    val kpi_policy_standards:String

    val kpi_policy_count: String
    val kpi_policy_changes: String
    val kpi_incident_total: String

    val kpi_incident_sharing_ecosystem : String
    val kpi_incident_sharing_global: String
    val kpi_sharing_count_public: String

    val kpi_incident_sharing_changes: String

    //d1 descriptions
    val kpi_statistic_description_policy_count: String
    val kpi_statistic_description_policy_changes: String
    val kpi_statistic_description_system_graph_assets_d1: String
    val kpi_statistic_description_system_graph_dependencies_d1: String
    val kpi_statistic_description_system_graph_values_d1: String
    val kpi_statistic_description_system_graph_changes_d1: String

    //d2 description
    val kpi_statistic_description_incident_total: String
    val kpi_statistic_description_incident_sharing_ecosystem: String
    val kpi_statistic_description_incident_sharing_global_d2: String
    val kpi_statistic_description_incident_sharing_changes: String
    val kpi_statistic_description_frontend_logins: String
    val kpi_statistic_description_distinct_users: String
    val kpi_statistic_description_anomaly_count: String
    val kpi_statistic_description_anomaly_timeline: String
    val kpi_statistic_description_anomaly_dismissed: String
    val kpi_statistic_description_anomaly_incidents: String

    val kpi_statistic_description_system_graph_assets_d4: String
    val kpi_statistic_description_system_graph_dependencies_d4: String
    val kpi_statistic_description_system_graph_values_d4:String
    val kpi_statistic_description_system_graph_changes_d4: String
    val kpi_statistic_description_incident_sharing_global_d4: String
    val kpi_statistic_description_system_graph_security_appliances: String
    val kpi_statistic_description_policy_standards: String

    val kpi_system_graph_assets:String
    val kpi_system_graph_dependencies:String
    val kpi_system_graph_values: String
    val kpi_system_graph_changes: String

    val kpi_questionnaire:String
    val kpi_out_of_100:String

    val kpi_d1_section_view_description:String
    val kpi_d2_section_view_description:String
    val kpi_d3_section_view_description:String
    val kpi_d4_section_view_description:String
    val kpi_d5_section_view_description:String



    val validation_error : String
    val save_anyway : String

    val link : String get() = "Link"

    val threat_context_dicussions: String get() = "Ecosystem Discussion"
    val threat_context_threats: String get() = "Other incindents"
    val threat_context_policies: String get() = "Policies"
    val threat_context_socialmedia: String get() = "Socialmedia"

    val required_field_not_filled_error: String

}




