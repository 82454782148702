package dk.rheasoft.csaware.api.config

import dk.rheasoft.csaware.utils.JsonUtilSerialization
import kotlinx.serialization.Serializable
import kotlinx.serialization.encodeToString

/**
 * A key value map of configuration on the system level.
 * Some of these could in the future may be be overridden by config entries on the user level.
 * (May be we could have 3 levels of configuration: system management (only editable by instance manager), system (this, only editable by system admin), and user (editable by user).
 * Some config options will be overriddable on next level, but some may not and will be hidden.
 * Supports JSON serialized objects.
 */
@Serializable
data class SystemLevelConfig(val properties: MutableMap<String, String> = mutableMapOf()): MutableMap<String, String> by properties {
    /**
     * Get the value decoded from JSON
     */
    inline fun <reified T> getTyped(key: String): T? =
        get(key)?.let { JsonUtilSerialization.json.decodeFromString(it) }

    /**
     * Get the value decoded from JSON, with a fallback value, if the key is not found or not deserializable
     */
    inline fun <reified T> getTyped(key: String, fallback: T): T {
        return try {
            getTyped(key) ?: fallback
        } catch (e: Throwable) {
            fallback
        }
    }

    /**
     * Sets the JSON encoded value
     */
    inline fun <reified T> setTyped(key: String, value: T) {
        set(key, JsonUtilSerialization.json.encodeToString(value))
    }

    /**
     * Get a subset configuration where keys are in the given set.
     */
    fun subset(keys: Set<String>) : SystemLevelConfig =
        SystemLevelConfig( properties.filterKeys { it in keys }.toMutableMap() )

    /**
     * Get a subset configuration where keys mathc the givem prefix.
     */
    fun subsetPrefic(prefix: String) : SystemLevelConfig =
        SystemLevelConfig( properties.filterKeys { it.startsWith(prefix)}.toMutableMap())

}