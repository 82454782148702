package csaware.kpi_statistics

import csaware.messages.CsawareMessagesObject
import kafffe.core.Model

enum class KpiDimensions() {
    d1, d2, d3, d4, d5;

    val questionnaireSubTitle: String get() = name.lowercase()

    val title: String get() = CsawareMessagesObject.get().kpiDimensionTitle(this)

    val titleModel: Model<String> get() = Model.of(title)
}