package dk.rheasoft.csaware.api.systemdependencies

import dk.rheasoft.csaware.api.QueryResult
import dk.rheasoft.csaware.utils.JsonUtilSerialization
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.encodeToString

@Serializable
data class SystemDependencyBackup(
    val created: Instant,
    val graph: SystemDependency,
    val config: SystemDependencyConfig,
    val comment: String,
) {
    constructor(graph: SystemDependency, config: SystemDependencyConfig, comment: String) : this(Clock.System.now(), graph, config, comment)

    fun toJsonString(): String =
        JsonUtilSerialization.json.encodeToString(this)

    companion object {
        fun fromJson(json: String): SystemDependencyBackup =
            JsonUtilSerialization.json.decodeFromString(json)

        fun fromQueryResultJson(json: String) : QueryResult<SystemDependencyBackup> =
            JsonUtilSerialization.json.decodeFromString(QueryResult.serializer(SystemDependencyBackup.serializer()),json)

    }
}
