package csaware.policy.config

import csaware.main.CsawareServices
import csaware.messages.CsawareMessages
import csaware.messages.i18nText
import dk.rheasoft.csaware.api.policy.policyRelationTypes
import dk.rheasoft.csaware.api.policy.policyTags
import kafffe.bootstrap.Modal
import kafffe.bootstrap.ModalSize
import kafffe.core.KafffeComponent
import kafffe.core.KafffeHtml
import kafffe.core.KafffeHtmlBase
import kafffe.core.Model
import kafffe.core.modifiers.CssClassModifier
import kafffe.core.modifiers.StyleModifier
import kafffe.core.modifiers.StyleModifier.Companion.styleModifier
import org.w3c.dom.HTMLDivElement

/**
 * Dialog for editing system specific configuration of policy editing. Includes standard tags and relation types
 */
class PolicyConfigDialog : KafffeComponent() {

    val modal  = object : Modal(i18nText(CsawareMessages::policy_config)) {
        override fun KafffeHtml<HTMLDivElement>.createBody() {
            add(this@PolicyConfigDialog.html)
        }
    }

    init {
        modal.size = ModalSize.large
        modal.modifiersBody.add(StyleModifier {
            overflowY = "auto"
            maxHeight = "80vh"
        })
        modal.modifiersModal.add(StyleModifier {
            maxWidth = "90vw"
            width = "80rem"
        })
        modal.modifiersContent.add(CssClassModifier("bg-light"))
    }

    override fun attach() {
        modal.attach()
        attachChildrenRecursive()
    }

    override fun detach() {
        modal.detach()
        super.detachChildrenRecursive()
    }

    enum class Tab(val label: Model<String>) {
        Tags(i18nText(CsawareMessages::policy_tags)),
        RelationTypes(i18nText(CsawareMessages::policy_relation_type)),
    }

    private var currentTab: Tab = Tab.Tags

        private fun selectTab(newTab: Tab) {
            if (newTab != currentTab) {
                currentTab = newTab
                rerenderRecursive()
            }
        }

        override fun KafffeHtmlBase.kafffeHtml() =
            div {
                ul {
                    addClass("nav nav-tabs")
                    for (tab in Tab.entries) {
                        li {
                            addClass("nav-item")
                            a {
                                addClass("nav-link")
                                if (tab == currentTab) {
                                    addClass("active")
                                }
                                text(tab.label.data)
                                element.onclick = {
                                    selectTab(tab)
                                    it.preventDefault()
                                }
                            }
                        }
                    }
                }
                when (currentTab) {
                    Tab.Tags -> add(tagsForm.html)
                    Tab.RelationTypes -> add(relationtypesForm.html)
                }
            }

    private fun systemLevelConfig() = CsawareServices.configrationService.systemLevelConfig.data

    private val tagsForm = addChild(PolicyTagsForm(Model.of(systemLevelConfig().policyTags())))

    private val relationtypesForm = addChild(PolicyRelationTypesForm(Model.of(systemLevelConfig().policyRelationTypes())))


}