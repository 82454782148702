package csaware.messages

import dk.rheasoft.csaware.api.PolicyState
import dk.rheasoft.csaware.api.PolicyType
import dk.rheasoft.csaware.api.ShareState
import dk.rheasoft.csaware.api.incident.ThreatState
import dk.rheasoft.csaware.api.systemdependencies.Cardinality
import dk.rheasoft.csaware.api.systemdependencies.FieldType
import kafffe.core.FunctionalSubModel
import kafffe.core.Model
import kafffe.messages.Messages
import kafffe.messages.messagesModel
import kotlin.reflect.KProperty1

fun i18nText(prop: KProperty1<CsawareMessages, String>): Model<String> {
    val getMessage: (Model<Messages>) -> String = {
        val messages = CsawareMessagesObject.get()
        prop.get(messages)
    }
    return FunctionalSubModel(messagesModel, getData = getMessage)
}
fun CsawareMessages.threatState(state: ThreatState) =
    when (state) {
        ThreatState.Active -> ThreatState_Active
        ThreatState.Resolved -> ThreatState_Resolved
        ThreatState.Ignored -> ThreatState_Ignored
        ThreatState.HealingAwaitDecision -> ThreatState_HealingAwaitDecision
        ThreatState.HealingAccepted -> ThreatState_HealingAccepted
        ThreatState.HealingDeclined -> ThreatState_HealingDeclined
        ThreatState.Healed -> ThreatState_Healed
        ThreatState.HealingFailed -> ThreatState_HealingFailed
        ThreatState.HealingInProgress -> ThreatState_HealingInProgress
        ThreatState.Unknown -> ThreatState_Unknown
    }

fun CsawareMessages.shareState(state: ShareState) =
    when (state) {
        ShareState.PermissionNeeded -> ShareState_AwaitDecision
        ShareState.SharingAccepted -> ShareState_Accept
        ShareState.SharingDenied -> ShareState_Decline
        ShareState.Unknown -> ShareState_Unknown
    }

@Suppress("UnusedReceiverParameter")
fun CsawareMessages.system_depend_field_cardinality_enum(cardinality: Cardinality): String = when (cardinality) {
    Cardinality.One -> "1"
    Cardinality.ZeroOrOne -> "0..1"
    Cardinality.ZeroToMany -> "0..*"
    Cardinality.OneToMany -> "1..*"
}

fun CsawareMessages.policy_state_text(policyState: PolicyState) : String {
    return when (policyState) {
        PolicyState.DRAFT -> policy_state_draft
        PolicyState.APPROVED -> policy_state_approved
        PolicyState.DRAFT_WITH_APPROVED -> policy_state_draft_with_approved
        PolicyState.OBSOLETE -> policy_state_obsolete
        PolicyState.UNKNOWN -> policy_state_unknown
    }
}

fun CsawareMessages.policy_type_text(policyType: PolicyType) : String {
    return when (policyType) {
        PolicyType.ORGANISATION -> policy_type_organisation
        PolicyType.TEMPLATE -> policy_type_template
    }
}
fun CsawareMessages.system_depend_field_validation_error(fieldType: FieldType, value: String): String =
    system_depend_field_validation_error_txt
        .replace("%type%", fieldType.name)
        .replace("%value%", value)
