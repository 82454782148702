package csaware.threats

import csaware.messages.CsawareMessages
import csaware.messages.i18nText
import csaware.systemdepend.SystemDependencyService
import kafffe.bootstrap.navigation.NavSimpleContainer
import kafffe.core.*


/**
 * Shows tabs with open and closed incidents*/
class IncidentsView(
    private val graphService: SystemDependencyService,
) : KafffeComponent(){

    private val openIncidents = ThreatsTableCurrentActive(graphService)
    private val closedIncidents = ThreatsTableRecentClosed(graphService)


    private val tabs = NavSimpleContainer().apply {
        add("Open_incidents", i18nText(CsawareMessages::tab_threats_open), "") { openIncidents}
        add("Closed_incidents", i18nText(CsawareMessages::tab_threats_closed), "") { closedIncidents}
    }

    init {
        addChild(tabs)

    }

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut = div {
        div {
                add(tabs.html)
                tabs.navigateTo(NavigationPath.fromString("container/Open_incidents"))
            }
        }
    }


