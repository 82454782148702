package csaware.utilities

import dk.rheasoft.csaware.api.incident.ThreatGroupWithTranslations
import kafffe.bootstrap.form.Dropdown
import kafffe.core.Model
import kafffe.messages.MessagesObject

/**
 * FormInput Component to select ThreatGroup based on their names, which could be translated
 */
class ThreatGroupDropdown(
    idInput: String,
    valueModel: Model<String>,
    val values: List<ThreatGroupWithTranslations>
) : Dropdown<String>(idInput, valueModel, Model.of(values.map { it.threatGroup.name })) {

    override fun display(choice: String): String {
        val value: ThreatGroupWithTranslations? = values.find { it.threatGroup.name == choice }
        return value?.languageToTranslation?.get(MessagesObject.currentLanguage)?.name ?: choice
    }

    override fun valueFromString(strValue: String): String = strValue

    override fun valueToString(value: String): String = value

}