package csaware.comm

import dk.rheasoft.csaware.EcoSystemWithMembers
import dk.rheasoft.csaware.api.*
import dk.rheasoft.csaware.api.systemdependencies.*
import dk.rheasoft.csaware.utils.JsonUtilSerialization

open class EcoSystemGraphBackend(
    val ecoSystemId: String,
    exceptionHandler: (errorCode: Int, errorText: String) -> Unit
) : CSAwareBackend(exceptionHandler),
    GraphBackend {

    fun getEcoSystems(receiver: (List<EcoSystemWithMembers>) -> Unit) {
        getTxt("/ecosystem/memberOf") {
            val ecoSystems: List<EcoSystemWithMembers> = JsonUtilSerialization.json.decodeFromString(it)
            receiver(ecoSystems)
        }
    }

    //    fun getSystemDependencies(receiver: (SystemDependency) -> Unit) {
    override fun getSystemDependencies(receiver: (List<SystemDependencyResource>) -> Unit) {
        getTxt("/ecosystem/$ecoSystemId/sysdep/all") {
            receiver(SystemDependencyResource.fromListJson(it))
        }
    }

    override fun getSystemDependency(id: String, receiver: (SystemDependencyResource?) -> Unit) {
        getTxt("/ecosystem/$ecoSystemId/sysdep/read/$id") {
            receiver(SystemDependencyResource.fromJson(it))
        }
    }

    override fun storeSystemDependency(resource: SystemDependencyResource, receiver: (SystemDependencyResource?) -> Unit) {
        sendJsonTxt("/ecosystem/$ecoSystemId/sysdep/update", "POST", resource.toJsonString()) {
            receiver(SystemDependencyResource.fromJson(it))
        }
    }

    override fun storeSystemDependencyWithSourceInOther(
        resource: SystemDependencyResource,
        sourceInOtherResourceIds: Set<String>,
        receiver: (SystemDependencyResource?) -> Unit
    ) {
        val resourceWithOther = SystemDependencyResourceWithSourceInOthers(resource, sourceInOtherResourceIds)
        sendJsonTxt("/ecosystem/$ecoSystemId/sysdep/updateWithSourceInOther", "POST", resourceWithOther.toJsonString()) {
            receiver(SystemDependencyResource.fromJson(it))
        }
    }

    override fun deleteSystemDependency(resource: SystemDependencyResource, receiver: (SystemDependencyResource?) -> Unit) {
        sendJsonTxt("/ecosystem/$ecoSystemId/sysdep/delete/${resource.id}", "DELETE", resource.toJsonString()) {
            receiver(resource)
        }
    }

    override fun importSystemDependencies(jsonString: String, replaceCurrent: Boolean, receiver: () -> Unit) {
        sendJsonTxt("/ecosystem/$ecoSystemId/sysdep/import?replace=$replaceCurrent", "POST", jsonString) {
            receiver()
        }
    }

    override fun getSystemDependencyConfig(receiver: (SystemDependencyConfig) -> Unit) {
        getTxt("/ecosystem/$ecoSystemId/sysdep/config") {
            receiver(SystemDependencyConfig.fromJson(it))
        }
    }

    override fun storeSystemDependencyConfig(config: SystemDependencyConfig, receiver: (SystemDependencyConfig) -> Unit) {
        sendJsonTxt("/ecosystem/$ecoSystemId/sysdep/config", "POST", config.toJsonString()) {
            receiver(SystemDependencyConfig.fromJson(it))
        }
    }

    override fun importSystemDependencyConfig(
        configJson: String,
        receiver: (String) -> Unit
    ) {
        sendJsonTxt("/ecosystem/$ecoSystemId/sysdep/config/import", "POST", configJson) {
            val x = it
            receiver(x)
        }
    }

    override fun getBackups(offset: Int, pagesize: Int, receiver: (QueryResult<SystemDependencyBackup>) -> Unit) {
        getTxt("/ecosystem/$ecoSystemId/sysdep/backups?offset=$offset&limit=$pagesize") {
            val x: QueryResult<SystemDependencyBackup> = SystemDependencyBackup.fromQueryResultJson(it)
            receiver(x)
        }
    }

    override fun backup(comment: String, receiver: (SystemDependencyBackup) -> Unit) {
        sendJsonTxt("/ecosystem/$ecoSystemId/sysdep/backup", "POST", comment) {
            val x: SystemDependencyBackup = SystemDependencyBackup.fromJson(it)
            receiver(x)
        }
    }

    override fun restore(request: SystemDependencyRestoreRequest, receiver: (SystemDependencyBackup) -> Unit) {
        sendJsonTxt("/ecosystem/$ecoSystemId/sysdep/restore", "POST", request.toJsonString()) {
            val x: SystemDependencyBackup = SystemDependencyBackup.fromJson(it)
            receiver(x)
        }
    }
}

