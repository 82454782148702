package csaware.policy.config

import csaware.main.CsawareServices
import csaware.messages.CsawareMessages
import csaware.messages.i18nText
import dk.rheasoft.csaware.api.policy.PolicyTags
import dk.rheasoft.csaware.api.policy.setPolicyTags
import dk.rheasoft.csaware.api.policy.subsetPolicyTags
import kafffe.bootstrap.form.BootstrapForm
import kafffe.bootstrap.form.editMultiple
import kafffe.bootstrap.form.listeditor.StringListEditor
import kafffe.core.Label
import kafffe.core.Model
import kafffe.core.modifiers.CssClassModifier.Companion.cssClassModifier
import kafffe.core.property
import org.w3c.dom.events.Event

/**
 * Form for maintenance of tags
 */
class PolicyTagsForm(model: Model<PolicyTags>)  : BootstrapForm<PolicyTags>(model) {

    init {
        cssClassModifier("hgap-3 vgap-3")
        val listModel: Model<List<String>> = Model.ofGetSet(
            setter = { value: List<String> ->
                model.data.tags = value.toMutableList()
            },
            getter = { model.data.tags.toList().sorted() }

        )
        val input = StringListEditor(listModel)
        decorateAndAddComponent(i18nText(CsawareMessages::policy_tags), input)
        submit(i18nText(CsawareMessages::save))
    }

    override fun onSubmit(event: Event) {
        if (isRendered) {
            event.preventDefault()
            event.stopPropagation(); // Should we support actual form submit
            processForm(::onOk)
        }
    }

    private fun onOk() {
        val tagsSubset = CsawareServices.configrationService.systemLevelConfig.data.subsetPolicyTags()
        tagsSubset.setPolicyTags(model.data)
        println(model.data)
        println(tagsSubset)
        CsawareServices.configBackend.storeSystemLevelConfig(tagsSubset) {
            CsawareServices.configrationService.systemLevelConfig.data.setPolicyTags(model.data)
            // detach dialog
            parent?.detach()
        }
    }

}