package dk.rheasoft.csaware.api.access

import kotlinx.serialization.Serializable

/**
 * Main features of the visualisation. Used to control through configuration what features that are available.
 */
@Serializable
enum class MainFeature {
    UserManagement,
    SystemDependencies,
    SystemDependencyConfig,
    SystemDependencyBackup,
    SocialMedia,
    SocialMediaAccount,
    SystemPreferences,
    AutomaticTranslation,
    Threats,
    Anomalies,
    InformationSharing,
    CsawareAdministration,
    Email,
    SocialMediaAI,
    Policy,
    EcoSystem,
    None,
    ;

    companion object {
        val lightMode = setOf(
            UserManagement,
            SystemDependencies,
            SystemDependencyConfig,
            SocialMedia,
            SocialMediaAccount,
            AutomaticTranslation,
            SocialMediaAI,
            Policy,
        )

        val fullMode = entries.toSet()
        
        fun findByName(name: String): MainFeature? = entries.find{it.name.equals(name, ignoreCase = true)}
    }
}