package csaware.kpi_statistics

import dk.rheasoft.csaware.api.statistics.StatisticCounter.CountType
import kafffe.core.KafffeComponent
import kafffe.core.KafffeHtmlBase
import kafffe.core.KafffeHtmlOut
import kafffe.core.modifiers.StyleModifier.Companion.styleModifier

class CounterPeriodsChart(
    val definition: StatisticSectionView.StatisticCounterDef
) : KafffeComponent() {

    private enum class Tabs(val label: String, val nofCount: Int, val type: CountType, val xAxisLegend: String) {
        DAILY("Daily", 30, CountType.DAILY, "Date"),
        WEEKLY("Weekly", 26, CountType.WEEKLY, "Week"),
        MONTHLY("Monthly", 12, CountType.MONTHLY, "Month");
    }

    private val tabComponents: Map<Tabs, CounterColumnChart> = Tabs.entries.associate { tab: Tabs ->
        tab to CounterColumnChart(
            counter = definition.counter.copy(countType = tab.type),
            maxNofCounts = tab.nofCount,
            xAxisLegend = tab.xAxisLegend,
            yAxisLegend = definition.yAxisLegend.data
        ).apply {
            styleModifier { display = if (currentTab == tab) "block" else "none" }
            this@CounterPeriodsChart.addChild(this)
        }
    }

    private var currentTab: Tabs = Tabs.MONTHLY

    private fun selectTab(tab: Tabs) {
        tabComponents[currentTab]?.html?.style?.display = "none"
        currentTab = tab
        tabComponents[currentTab]?.html?.style?.display = "block"
        rerender()
    }

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut =
        div {
            ul {
                addClass("nav nav-tabs ms-4")
                for (tab in Tabs.entries) {
                    li {
                        addClass("nav-item")
                        a {
                            addClass("nav-link")
                            if (tab == currentTab) {
                                addClass("active")
                            }
                            text(tab.label)
                            element.onclick = {
                                selectTab(tab)
                                it.preventDefault()
                            }
                        }
                    }
                }
            }
            tabComponents.forEach { (_, component) ->
                add(component.html)
            }
        }


}