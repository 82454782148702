package csaware.ecosystem

import csaware.main.CsawareServices
import dk.rheasoft.csaware.EcoSystemWithMembers
import kafffe.core.Model
import kafffe.core.RefreshingCacheModel

object EcoSystems {
    val ecoSystemsModel: Model<List<EcoSystemWithMembers>> =
        RefreshingCacheModel(::refreshModel, listOf(), timeToLiveSeconds = 60 * 60)

    private fun refreshModel(model: Model<List<EcoSystemWithMembers>>) {
        CsawareServices.ecoSystemGraphBackend("-").getEcoSystems {
            model.data = it
            for (ecoSystem in it) {
                CsawareServices.ecoSystemGraphService(ecoSystem.id)
            }
        }
    }

    fun refreshAll() {
        refreshModel(ecoSystemsModel)
    }

}