package csaware.mail

import dk.rheasoft.csaware.api.MailSimple
import csaware.main.CsawareServices
import csaware.main.UserInformation
import csaware.messages.CsawareMessages
import csaware.messages.CsawareMessagesObject.csawareMessageStrategy
import csaware.messages.i18nText
import dk.rheasoft.csaware.api.incident.StateHistory
import dk.rheasoft.csaware.api.incident.ThreatObservation
import kafffe.bootstrap.BasicColor
import kafffe.bootstrap.ModalSize
import kafffe.bootstrap.form.FormDialog
import kafffe.bootstrap.form.editSelectMultiple
import kafffe.bootstrap.form.textArea
import kafffe.core.modifiers.CssClassModifier
import kafffe.core.Model
import kafffe.core.modifiers.CssClassModifier.Companion.cssClassModifier
import kafffe.core.modifiers.StyleModifier

class MailEditDlg(mail: MailSimple, val refresh: () ->Unit) :
    FormDialog<MailSimple>(i18nText(CsawareMessages::mail_dialog_title), Model.of(mail)) {

    init {
        cssClassModifier("hgap-3 vgap-3")
        labelStrategy = csawareMessageStrategy("mail_")
        size = ModalSize.large
        modal.modifiersBody.add(StyleModifier {
            overflowY = "auto"
            maxHeight = "75vh"
        })
        modal.modifiersModal.add(StyleModifier {
            maxWidth = "90vw"
            width = "1400px"
        })
        modal.modifiersContent.add(CssClassModifier("bg-light"))

        val sysadminsModel: Model<List<String>> = Model.ofGet { UserInformation.threatManagers.data.map{it.email} }
        editSelectMultiple(MailSimple::to, sysadminsModel)
        readonly(MailSimple::from)
        input(MailSimple::subject)
        textArea(MailSimple::body).apply {
            lines = 9
        }

        submit("send", onOk = {
            CsawareServices.alerts.clearAlerts()
            CsawareServices.backend.sendMail(model.data) {
                println(it)
                refresh()
            }
            detach()
        }).color = BasicColor.primary
        cancel().color = BasicColor.secondary
    }

    companion object {
        fun showThreatAssignDialog(assignee: String, threat: ThreatObservation, newState: StateHistory) {
            val mail = MailSimple(
                    to = listOf(assignee),
                    from = newState.initiator,
                    subject = "CS-Aware assigned to you: ${threat.name} - ${threat.id}",
                    body = newState.description
            )
            MailEditDlg(mail) {
                // callback
            }.attach()
        }
    }
}