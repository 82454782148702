package dk.rheasoft.csaware.api

import kotlinx.serialization.ExperimentalSerializationApi
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

/**
 * Access Level or sharing level for DataLake exposure
 */
@Serializable(with = AccessLevelSerializer::class)
enum class AccessLevel(private val value: Int, val editable: Boolean = false, val selectable: Boolean = false) {
    Unknown(-2),

    /**
     * Not stored in DataLake
     */
    FrontendLocal(-1, editable = true, selectable = true),
    OrganisationalPrivate(0, editable = true, selectable = true),
    EcosystemGenerated (1),
    OrganisationalSharedWithEcosystem(2, editable = true, selectable = true),
    OrganisationalSharedWithAll(3, editable = true, selectable = true),
    PublicData(4);

    fun toInt(): Int = value

    companion object {
        /**
         * Convert from stored int
         */
        fun fromInt(value: Int) = entries.firstOrNull { it.value == value } ?: Unknown
    }

}

@OptIn(ExperimentalSerializationApi::class)
object AccessLevelSerializer : KSerializer<AccessLevel> {
    override val descriptor: SerialDescriptor =
        PrimitiveSerialDescriptor("dk.rheasoft.csaware.api.AccessLevel", PrimitiveKind.INT)

    override fun serialize(encoder: Encoder, value: AccessLevel) {
        encoder.encodeInt(value.toInt())
    }


    override fun deserialize(decoder: Decoder): AccessLevel {
        val value = decoder.decodeInt()
        return AccessLevel.fromInt(value)
    }
}