package csaware.systemdepend.backup

import csaware.comm.GraphBackend
import csaware.main.CsawareServices
import csaware.messages.CsawareMessages
import csaware.messages.CsawareMessagesObject.csawareMessageStrategy
import csaware.messages.i18nText
import csaware.systemdepend.SystemDependencyService
import dk.rheasoft.csaware.api.systemdependencies.SystemDependencyBackup
import dk.rheasoft.csaware.api.systemdependencies.SystemDependencyResourceDifference
import kafffe.bootstrap.*
import kafffe.bootstrap.form.FormDialog
import kafffe.bootstrap.pagination.BootstrapPagination
import kafffe.bootstrap.pagination.Pager
import kafffe.core.Label
import kafffe.core.Model
import kafffe.core.modifiers.CssClassModifier
import kafffe.core.modifiers.CssClassModifier.Companion.cssClassModifier
import kafffe.core.modifiers.StyleModifier
import kafffe.messages.MessagesObject.formatDateTime
import kotlinx.datetime.Instant
import kotlinx.datetime.toJSDate
import kotlin.math.ceil

class BackupDialog(val graphService: SystemDependencyService) : FormDialog<String>(i18nText(CsawareMessages::system_depend_backup_title), Model.of("")) {
    private val commentModel: Model<String> = Model.of("")
    private var diffMap: Map<Instant, SystemDependencyResourceDifference> = emptyMap()

    val backupsTable = BootstrapTable.create<SystemDependencyBackup>(listOf()) {
        rowClickHandler = { backup, _ -> showDetails(backup) }

        addStyle(BootstrapTableStyles.striped)
        modifiers.add(CssClassModifier("csaware-hover"))
        modifiersHeader.add(CssClassModifier("bg-primary"))
        col(i18nText(CsawareMessages::system_depend_backup_created), { Label(it.created.toJSDate().formatDateTime()) })
        col(i18nText(CsawareMessages::system_depend_backup_comment), { Label(it.comment) })
        col(i18nText(CsawareMessages::system_depend_backup_nodes_not_in_current), {
            val diff = diffMap[it.created]
            if (diff != null) {
                Label(diff.notInSecond.map { it.name }.joinToString(", "))
            } else {
                Label("")
            }
        })
        col(i18nText(CsawareMessages::system_depend_backup_nodes_in_current_not_in_this), {
            val diff = diffMap[it.created]
            if (diff != null) {
                Label(diff.notInFirst.map { it.name }.joinToString(", "))
            } else {
                Label("")
            }
        })
        col(i18nText(CsawareMessages::system_depend_backup_nodes_changed), {
            val diff = diffMap[it.created]
            if (diff != null) {
                Label(diff.changed.map { (first, _) -> first.name }.joinToString(", "))
            } else {
                Label("")
            }
        })
    }

    private val pageSize: Int = 10

    private val pager = Pager(1)
    private val paginator = BootstrapPagination(pager).apply {
        prevNextPage = true
        modifiers.add(CssClassModifier("float-end"))
    }

    private val restorer = RestoreForm(graphService, backupBackend(), ::afterRestore)

    private fun afterRestore(systemDependencyBackup: SystemDependencyBackup) {
        detach()
    }

    init {
        loadBackups()
        pager.changeListeners.add { loadBackups() }

        labelStrategy = csawareMessageStrategy("system_depend_backup_")
        size = ModalSize.extra_large
        modal.modifiersBody.add(StyleModifier {
            overflowY = "auto"
            maxHeight = "90vh"
        })
        modal.modifiersModal.add(StyleModifier {
            setProperty("--bs-modal-width", "80vw")
        })

        row {
            col(ColWidth(ResponsiveSize.md, 6)) {
                group {
                    cssClassModifier("hgap-3 vgap-3 border mb-3 p-3")
                    legend(i18nText(CsawareMessages::system_depend_backup_create_new))
                    input("comment", i18nText(CsawareMessages::system_depend_backup_comment), commentModel).apply {
                        required = true
                    }
                    submit(i18nText(CsawareMessages::system_depend_backup_create_new), onOk = ::backup)
                    cancel()
                }
                group {

                    addChild(backupsTable)
                    addChild(paginator)
                }

            }
            col(ColWidth(ResponsiveSize.md, 6)) {
                addChild(restorer)
            }
        }
    }

    private fun loadBackups() {
        CsawareServices.alerts.clearAlerts()
        val offset = pageSize * (pager.currentPage - 1)
        backupBackend().getBackups(offset, pageSize) { response ->
            val pageCount = ceil(response.nofResult.toDouble() / pageSize.toDouble()).toInt()
            if (pager.nofPages != pageCount) {
                pager.nofPages = pageCount
            }
            val current = graphService.model.data
            val backupMap: Map<Instant, SystemDependencyBackup> = response.result.associateBy { it.created }
            diffMap = backupMap.map { (time, backup) ->
                Pair(time, SystemDependencyResourceDifference(current, backup.graph.objects))
            }.toMap()

            backupsTable.model.data = response.result
        }
    }

    private fun backup() {
        backupBackend().backup(commentModel.data) {
            pager.first()
        }
    }

    private fun backupBackend(): GraphBackend = if (graphService.isOrganisationGraph())
        CsawareServices.systemDependenciesBackend
    else
        CsawareServices.ecoSystemGraphBackend(graphService.ecoSystemId!!)

    private fun showDetails(backup: SystemDependencyBackup) {
        restorer.backup = backup
    }

}