package csaware.systemdepend

import dk.rheasoft.csaware.api.systemdependencies.GraphView
import dk.rheasoft.csaware.api.systemdependencies.SystemDependencyConfig
import kafffe.core.KafffeComponentWithModel
import kafffe.core.KafffeHtmlBase
import kafffe.core.Model

class GraphViewDropdown(
    val config: Model<SystemDependencyConfig>,
    model: Model<GraphView?>,
    val dependencyFieldsModel: Model<List<String>>,
    val inline: Boolean = true
) : KafffeComponentWithModel<GraphView?>(model) {

    init {
        setModelChangedRerender()
    }

    override fun KafffeHtmlBase.kafffeHtml() =
        select {
            withStyle {
                if (inline) display = "inline-block"
                width = "fit-content"
            }
            withElement {
                addClass("form-select")
                value = model.data?.name ?: "--"
                onchange = {
                    val view = config.data.views.find { it.name == value }
                    model.data = view
                    if (view != null) {
                        dependencyFieldsModel.data = view.dependencyFields
                    } else {
                        dependencyFieldsModel.data = config.data.dependencyFields().map { it.id }
                    }
                }
            }
            option {
                withElement {
                    value = "--"
                    selected = (model.data == null)
                }
                text("--")
            }
            for (c in config.data.views) {
                option {
                    withElement {
                        value = c.name
                        selected = (c.name == model.data?.name)
                    }
                    text(c.name)
                }
            }
        }

}

