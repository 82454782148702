package csaware.systemdepend.nodedetails

import csaware.messages.CsawareMessagesObject
import csaware.messages.CsawareMessagesObject.csawareMessageStrategy
import csaware.messages.system_depend_field_validation_error
import csaware.systemdepend.SystemDependencyService
import csaware.systemdepend.config.SystemResourceConfigChangeDlg
import csaware.utilities.markdown.MarkDownInput
import dk.rheasoft.csaware.api.systemdependencies.*
import kafffe.bootstrap.*
import kafffe.bootstrap.form.*
import kafffe.core.*
import kafffe.core.modifiers.CssClassModifier
import kafffe.core.modifiers.CssClassModifier.Companion.cssClassModifier
import kafffe.core.modifiers.HtmlElementModifier.Companion.htmlElementModifier
import kafffe.core.modifiers.StyleModifier
import kotlinx.browser.window
import org.w3c.dom.DOMPoint
import org.w3c.dom.HTMLButtonElement
import org.w3c.dom.events.Event

class SystemResourceChangeDlg(
    systemResource: SystemDependencyResource,
    stateTxt: String,
    val connectedFrom: List<String>,
    private val graphService: SystemDependencyService
) :
// Use copy of data in order to be able to sync changes and do "cancel" by forgetting
    FormDialog<SystemDependencyResource>(
        Model.of("${systemResource.name}: $stateTxt"),
        Model.of(systemResource.copy())
    ) {

    private val nodeTypeModel: Model<NodeType> = Model.ofGetSet (
        getter = {
            config.nodeType(model.data.x_csaware_node_type) ?: NodeType.NULL
        },
        setter = { nodeType ->
            model.data.x_csaware_node_type = nodeType.id
        }
    )

    private var confirmPosition: DOMPoint? = null

    override fun onSubmit(event: Event) {
        if (isRendered) {
            event.preventDefault()
            event.stopPropagation()
            processForm(
                onOk =  {
                    onSubmitOk()
                    window.setTimeout({ detach() }, 3000)
                },
                onError = {
                    Modal.confirm(
                        title = labelStrategy.label("validation_error"),
                        question = labelStrategy.label("save_anyway"),
                        absolutePosition = confirmPosition,
                        yesHandler = {
                            updateValueModel()
                            onSubmitOk()
                            window.setTimeout({ detach() }, 3000)
                        })
                }
            )
        }
    }

    init {
        labelStrategy = csawareMessageStrategy("system_depend_")
    }

    private val config: SystemDependencyConfig
        get() = graphService.config.data

    private val addedFieldIds = mutableSetOf<String>()
    fun fieldsIncluded() : Set<String> =
        nodeTypeModel.data.allDefaultFields(config).map { it.fieldId }.toSet() + addedFieldIds

    private val resourceIds =
        graphService.model.data.sortedBy(SystemDependencyResource::name).map { it.id }

    // Map functional submodel list to mutable list of ids
    private val connectsToModel: Model<List<String>> = model.func(
        { p -> p.data.source.toList() },
        { p, v -> p.data.source = v.toMutableList() }
    )

    private val connectsTo =
        object : MultipleEditSelect<String>("connectedTo", connectsToModel, Model.of(resourceIds)) {
            override fun display(choice: String): String =
                graphService.byId(choice)?.name ?: ""
        }

    /**
     * Model of connected from ids that can edited, but are to be represented by connects to on the other SystemDependencyResources
     */
    val connectsFromModel: Model<List<String>> = Model.of(connectedFrom)
    private val connectsFrom =
        object : MultipleEditSelect<String>("connectedFrom", connectsFromModel, Model.of(resourceIds)) {
            override fun display(choice: String): String =
                graphService.byId(choice)?.name ?: ""
        }

    private val infoflowModel: Model<List<String>> =
        model.func({ p -> p.data.x_infoflow.toList() }, { p, v -> p.data.x_infoflow = v.toMutableList() })
    private val infoflow = MultipleEditSelectString(
        "infoFlow",
        infoflowModel,
        Model.of(graphService.config.data.getInfoflowValues())
    )

    private lateinit var fieldContainer: FormLayout<SystemDependencyResource>

    init {
        modal.moveable = true
        size = ModalSize.large
        modal.modifiersBody.add(StyleModifier {
            overflowY = "auto"
            maxHeight = "75vh"
        })
        modal.modifiersModal.add(StyleModifier {
            maxWidth = "90vw"
            width = "1600px"
        })
        modal.modifiersContent.add(CssClassModifier("bg-light"))
        // We need some hgap because we do not apply whitespace "\n" between buttons.
        row {
            col(ColWidth(ResponsiveSize.md, 6)) {
                row {
                    cssClassModifier("vgap-3")
                    readonly(SystemDependencyResource::id)
                    decorateAndAddComponent(labelStrategy.label("nodeType"), nodeTypeSelect())
                    input(SystemDependencyResource::name)

                    if (config.includeSource) {
                        decorateAndAdd(labelStrategy.label("connectedFrom"), connectsFrom)
                        decorateAndAdd(labelStrategy.label("connectedTo"), connectsTo)
                    }
                    decorateAndAdd(labelStrategy.label("infoFlow"), infoflow)

                }
                fieldContainer =
                    row {
                        cssClassModifier("vgap-3 mb-2")
                        for (field in config.fields.filter { includeEditorForField(it) }) {
                            addFieldEditor(this, field)
                        }
                    }
                addFieldAdder(this, fieldContainer)
            }
            col(ColWidth(ResponsiveSize.md, 6)) {
                val inp = MarkDownInput(
                    model.property(SystemDependencyResource::description)
                )
                decorateAndAddComponent(labelStrategy.label(SystemDependencyResource::description.name), inp)
            }
        }
        cssClassModifier("hgap-3")
        cssClassModifier("vgap-3")
        submit(labelStrategy.label("save")).apply {
            color = BasicColor.primary
        }.htmlElementModifier {
            val btn = this as HTMLButtonElement
            onclick = {
                confirmPosition = run {
                    val rect = btn.getBoundingClientRect()
                    val scrollLeft = window.pageXOffset
                    val scrollTop =  window.pageYOffset
                    DOMPoint(rect.right + scrollLeft, rect.top + scrollTop - 30.0)
                }
            }
        }
        cancel().color = BasicColor.secondary
    }

    private fun nodeTypeSelect() = object : KafffeComponent() {
        private fun valueSelected(nodeTypeId: String) {
            config.nodeType(nodeTypeId) ?.let {
                nodeTypeModel.data = it
                // replaceFields
                fieldContainer.removeAllChildren()
                for (field in config.fields.filter { includeEditorForField(it) }) {
                    addFieldEditor(fieldContainer, field)
                }
                fieldContainer.rerender()
                this@SystemResourceChangeDlg.rerender()
            }
        }

        override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut =
            select {
                addClass("form-select ms-2")
                withStyle {
                    width = "max-content"
                    // display = "inline-block"
                }
                withElement {
                    onchange = {
                        valueSelected(this.value)
                        it
                    }
                }
                config.nodeTypes.sortedBy { it.id }.forEach { nodeType ->
                    option {
                        withElement {
                            value = nodeType.id
                            if (nodeTypeModel.data.id == nodeType.id ) {
                                selected = true
                            }
                        }
                        text(nodeType.name)
                    }
                }
            }
    }


    private fun addFieldAdder(
        addTo: FormLayout<SystemDependencyResource>,
        fieldContainer: FormLayout<SystemDependencyResource>
    ) {
        addTo.group {
            cssClassModifier("border border-primary rounded p-2")
            val addFieldSelect =
                object : KafffeComponent() {

                    private fun valueSelected(fieldId: String) {
                        config.fields.filter { it.id == fieldId }.map { field ->
                            addFieldEditor(fieldContainer, field)
                        }
                        rerender()
                        fieldContainer.rerender()
                    }

                    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut =
                        select {
                            addClass("form-select ms-2")
                            withStyle {
                                width = "max-content"
                                display = "inline-block"
                            }
                            withElement {
                                onchange = {
                                    valueSelected(this.value)
                                    value = ""
                                    it
                                }
                            }
                            option {
                                withElement {
                                    value = ""
                                }
                                text("")
                            }
                            config.fields.filter { !includeEditorForField(it) }.forEach { field ->
                                option {
                                    withElement {
                                        value = field.id
                                    }
                                    text(field.label)
                                }
                            }
                        }
                }
            addChild(Label(labelStrategy.label("field_add")))
            addChild(addFieldSelect)
            addChild(BootstrapButton(labelStrategy.label("fields_config")) {
                SystemResourceConfigChangeDlg(graphService.config.data, graphService).apply {
                    onSubmitOk = {
                        graphService.storeConfig(model.data) {
                            addFieldSelect.rerender()
                        }
                    }
                    attach()
                    selectTab(SystemResourceConfigChangeDlg.Tab.Fields)
                }
            }.apply {
                iconClasses = "fas fa-wrench me-2"
                iconBefore = true
                color = BasicColor.info
                cssClassModifier("ms-4")
            })
        }
    }


    private fun addFieldEditor(formLayout: FormLayout<SystemDependencyResource>, field: SystemGraphField) {
        val hasValueSet = field.valueSet != null
        val single = field.cardinality.isSingle
        when {
            field.type == FieldType.DEPENDENCY -> {
                val valuesModel: Model<List<String>> = fieldValuesModel(field)

                val dependencyField =
                    object : MultipleEditSelect<String>(field.id, valuesModel, Model.of(resourceIds)) {
                        override fun display(choice: String): String =
                            graphService.byId(choice)?.name ?: ""
                    }
                val dependencyWithLabel = inputDecorator(Model.of(field.label), dependencyField)
                formLayout.addChild(dependencyWithLabel)
            }

            single && !hasValueSet -> {
                val valueModel: Model<String> = fieldValueModel(field)
                if (field.type == FieldType.MARKDOWN) {
                    formLayout.textArea(field.id, Model.of(field.label), valueModel).apply {
                        // validation
                        if (field.cardinality == Cardinality.One) {
                            required = true
                        }
                    }
                } else {
                    formLayout.input(field.id, Model.of(field.label), valueModel).apply {
                        if (field.type == FieldType.SECRET) {
                            inputType = "password"
                        }
                    }.apply {
                        // validation
                        if (field.cardinality == Cardinality.One) {
                            required = true
                        }
                        addValidator { input ->
                            val valid = field.type.validate(input.htmlInputElement.value)
                            ValidationResult(
                                valid,
                                if (valid) "" else CsawareMessagesObject.get()
                                    .system_depend_field_validation_error(field.type, input.htmlInputElement.value)
                            )
                        }
                    }
                }
            }

            single && hasValueSet -> {
                val valueModel: Model<String?> = fieldOptionalValueModel(field)
                val choices = graphService.config.data.getValueSet(field.valueSet!!).toList()
                val valueEdit = SingleEditSelectString(field.id, valueModel, Model.of(choices))
                val valueList = inputDecorator(Model.of(field.label), valueEdit)
                formLayout.addChild(valueList)
            }

            !single && !hasValueSet -> {
                val valuesModel: Model<List<String>> = fieldValuesModel(field)
                val valueEdit = MultipleEdit(field.id, valuesModel)
                val valueList = inputDecorator(Model.of(field.label), valueEdit)
                valueEdit.addValidator {
                    val values = it.currentValues()
                    val invalidValues: List<String> = values.filter { value -> !field.type.validate(value) }
                    when {
                        field.cardinality == Cardinality.OneToMany && values.isEmpty() -> {
                            ValidationResult(false, CsawareMessagesObject.get().validation_required)
                        }

                        invalidValues.isNotEmpty() -> {
                            ValidationResult(
                                false,
                                CsawareMessagesObject.get()
                                    .system_depend_field_validation_error(field.type, invalidValues.joinToString(", "))
                            )
                        }

                        else -> {
                            ValidationResult(true, "")
                        }
                    }
                }
                formLayout.addChild(valueList)
            }

            !single && hasValueSet -> {
                val valuesModel: Model<List<String>> = fieldValuesModel(field)
                val choices = graphService.config.data.getValueSet(field.valueSet!!).toList()
                val valueEdit = MultipleEditSelectString(field.id, valuesModel, Model.of(choices))
                val valueList = inputDecorator(Model.of(field.label), valueEdit)
                formLayout.addChild(valueList)
            }
        }
    }

    private fun includeEditorForField(field: SystemGraphField) =
        model.data.hasValue(field) || field.id in fieldsIncluded()

    private fun fieldValuesModel(field: SystemGraphField) = model.func(
        getData = { p -> p.data.dataLists[field.id]?.toList() ?: listOf() },
        setData = { p, v -> p.data.dataLists[field.id] = v.toMutableList() }
    )

    private fun fieldValueModel(field: SystemGraphField): FunctionalSubModel<String, SystemDependencyResource> {
        fun getData(p: Model<SystemDependencyResource>): String = p.data.data[field.id] ?: ""
        fun setData(p: Model<SystemDependencyResource>, value: String) {
            if (value.isBlank()) {
                p.data.data.remove(field.id)
            } else {
                p.data.data[field.id] = value
            }
        }
        return model.func(::getData, ::setData)
    }

    private fun fieldOptionalValueModel(field: SystemGraphField): FunctionalSubModel<String?, SystemDependencyResource> {
        fun getData(p: Model<SystemDependencyResource>): String? = p.data.data[field.id]
        fun setData(p: Model<SystemDependencyResource>, value: String?) {
            if (value.isNullOrBlank()) {
                p.data.data.remove(field.id)
            } else {
                p.data.data[field.id] = value
            }
        }
        return model.func(::getData, ::setData)
    }

}


