package csaware.policy

import csaware.main.UserInformation
import csaware.messages.CsawareMessagesObject
import csaware.messages.CsawareMessagesObject.csawareMessageStrategy
import csaware.utilities.UUID
import dk.rheasoft.csaware.api.Policy
import dk.rheasoft.csaware.api.PolicyComment
import kafffe.bootstrap.BasicColor
import kafffe.bootstrap.ModalSize
import kafffe.bootstrap.form.FormDialog
import kafffe.bootstrap.form.TextArea
import kafffe.core.Model
import kafffe.core.modifiers.CssClassModifier.Companion.cssClassModifier
import kafffe.core.property
import kotlinx.datetime.Clock


class PolicyApproveCommentDialog(title: String, comment: PolicyComment) : FormDialog<PolicyComment>(Model.of(title), Model.of(comment)) {
    companion object {
        fun approveComment(policy: Policy) = PolicyApproveCommentDialog(
            CsawareMessagesObject.get().policy_approve_comment_add,
            PolicyComment(
                id = UUID.generateUuid("pc--"),
                policyId = policy.id,
                responseToCommentId = null,
                comment = "",
                createdAt = Clock.System.now(),
                createdBy = UserInformation.current.email,
                updatedAt = Clock.System.now(),
                updatedBy = UserInformation.current.email,
            )
        )
    }

    init {
        cssClassModifier("vgap-3 hgap-3")
        labelStrategy = csawareMessageStrategy("policy_comment_")
        size = ModalSize.large

        decorateAndAddComponent(labelStrategy.label("comment"), TextArea("approveComment",model.property(PolicyComment::comment))
            .apply {  required= true
                 lines= 4 })
        submit("save").color = BasicColor.primary
        cancel().color = BasicColor.secondary
    }




}