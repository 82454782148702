package csaware.comm

import dk.rheasoft.csaware.api.QueryResult
import dk.rheasoft.csaware.api.aiqueries.DailySummaryResult
import dk.rheasoft.csaware.api.aiqueries.QuestionRequest
import dk.rheasoft.csaware.api.aiqueries.QuestionResult
import dk.rheasoft.csaware.api.oembed.OembedResponse
import dk.rheasoft.csaware.api.socialmedia.KeywordCountResult
import dk.rheasoft.csaware.api.socialmedia.SocialMediaAccount
import dk.rheasoft.csaware.api.socialmedia.SocialMediaEntry
import dk.rheasoft.csaware.utils.JsonUtilSerialization
import encodeURIComponent
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlin.time.Duration.Companion.days


open class SocialMediaBackend(exceptionHandler: (errorCode: Int, errorText: String) -> Unit) : CSAwareBackend(exceptionHandler) {

    //--- Social Media ------------------
    fun getSocialMediaEntries(
        keywords: Set<String>,
        language: String,
        offset: Int,
        pageSize: Int,
        receiver: (QueryResult<SocialMediaEntry>) -> Unit,
        filter: String,
        after: Instant = Clock.System.now() - 30.days,
        onError:(errCode:Int, errText:String) -> Unit
    ) {
        val filterParameter = "&filter=${encodeURIComponent(filter)}"
        val keywordsParameter =  "&keywords=${keywords.joinToString(",") { encodeURIComponent(it) }}"
        val path = "/socialmedia/observation/observations?offset=$offset&limit=$pageSize&newerThan=$after&language=$language$keywordsParameter$filterParameter"
        errorHandlerList.put(serverEndpoint+path,onError)
        getTxt(path) {
            val x: QueryResult<SocialMediaEntry> = SocialMediaEntry.fromQueryResultJson(it)
            if (filter.isNotEmpty()) {
                // find keyword matches
                for (entry in x.result) {
                    val strings = listOfNotNull(
                        entry.observation.title,
                        entry.observation.text,
                        entry.translation?.title,
                        entry.translation?.text
                    )
                    val matches: Set<String> = keywords.filter {
                        k -> strings.find { s -> s.contains(k, ignoreCase = true)  } != null
                    }.toSet()
                    entry.matches += matches
                }
            }
            receiver(x)
            errorHandlerList.remove(path)
        }
    }


    fun countKeywords(keywords: Set<String>,
                      language: String,
                      after: Instant,
         receiver: (KeywordCountResult) -> Unit) {
        val keywordsEncoded = keywords.joinToString(",") { encodeURIComponent(it) }
        getTxt("/socialmedia/observation/countKeywords?language=$language&keywords=$keywordsEncoded&newerThan=$after") {
            val result: KeywordCountResult = JsonUtilSerialization.json.decodeFromString(it)
            receiver(result)
        }
    }

    fun getSocialMediaAccounts(
        offset: Int,
        pagesize: Int,
        filter: String = "",
        receiver: (QueryResult<SocialMediaAccount>) -> Unit
    ) {
        getTxt("/socialmedia/accounts?offset=$offset&limit=$pagesize&filter=$filter") {
            val x: QueryResult<SocialMediaAccount> = SocialMediaAccount.fromQueryResultJson(it)
            receiver(x)
        }
    }


    fun includeSocialMediaAccount(account: SocialMediaAccount, receiver: (SocialMediaAccount) -> Unit) {
        sendJsonTxt("/socialmedia/account/include", "POST", account.toJson()) {
            val x: SocialMediaAccount = SocialMediaAccount.fromJson(it)
            receiver(x)
        }
    }
    fun excludeSocialMediaAccount(account: SocialMediaAccount, receiver: (SocialMediaAccount) -> Unit) {
        sendJsonTxt("/socialmedia/account/exclude", "POST", account.toJson()) {
            val x: SocialMediaAccount = SocialMediaAccount.fromJson(it)
            receiver(x)
        }
    }

    fun excludeSocialMediaAccounts(accounts:List<SocialMediaAccount>,receiver: (SocialMediaAccount) -> Unit) {
        for (account in accounts) {
            sendJsonTxt("/socialmedia/account/exclude", "POST", account.toJson()) {
                val x: SocialMediaAccount = SocialMediaAccount.fromJson(it)
                receiver(x)
            }
        }
    }

    fun includeSocialMediaAccounts(accounts:List<SocialMediaAccount>,receiver: (SocialMediaAccount) -> Unit) {
        for (account in accounts){
            sendJsonTxt("/socialmedia/account/include", "POST", account.toJson()) {
                val x: SocialMediaAccount = SocialMediaAccount.fromJson(it)
                receiver(x)
            }
        }
    }

    fun findAccount(name: String, receiver: (QueryResult<SocialMediaAccount>) -> Unit) {
        getTxt("/socialmedia/account/find?filter=${encodeURIComponent(name)}") {
            val result = SocialMediaAccount.fromQueryResultJson(it)
            receiver(result)
        }
    }

    fun aiSummaries(language: String, limit: Int, receiver: (DailySummaryResult) -> Unit) {
        getTxt("/socialmedia/ai/summaries?language=$language&limit=${encodeURIComponent(limit.toString())}") {
            val result = DailySummaryResult.fromJson(it)
            receiver(result)
        }
    }

    fun aiQuestion(question: QuestionRequest, receiver: (QuestionResult) -> Unit) {
        sendJsonTxt("/socialmedia/ai/question", "POST", question.toJsonString()) {
            val result = QuestionResult.fromJson(it)
            receiver(result)
        }
    }

}

