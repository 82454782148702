package csaware.messages

import kafffe.messages.Messages_en

open class CsawareMessages_en : Messages_en(), CsawareMessages {
    override val translation_automatic: String = "Automatic translation"
    override val settings: String = "Settings"
    override val nav_overview = "Overview"
    override val nav_threats = "Incidents"
    override val nav_threatsClosed = "Incidents Closed"
    //override val nav_threats = "Threats"
    //override val nav_threatsClosed = "Threats Closed"
    override val nav_config = "Configuration"
    override val tab_threats_open="Open"
    override val tab_threats_closed ="Closed"
    override val threat_group = "Group"

    override val environment_name = "Name"

    override val threat_group_all = "All"
    override val threat_nobody = "Nobody"
    override val threat_state = "State"
    override val threat_state_initator = "Who"
    override val threat_state_history = "History"
    override val threat_state_comment = "Comment"
    override val threat_course_of_action = "Course of Action"
    override val threat_where = "Where"
    override val threat_assignee = "Assigned to"

    override val threat_observed_data_objects = "Observed Data"
    override val threat_observed_data_id= "Id"
    override val threat_observed_data_type= "Type"
    override val threat_observed_data_data = "Data"

    override val threat_subtype = "Subtype"
    override val threat_firstObserved = "First observed"
    override val threat_endActive = "Closed at"
    override val threat_newState = "Change State"
    override val threat_selfHealingConfirm = "Confirm BCDR"

    override val threat_topTitle = "Top Threats"

    override val severity = "Severity"
    override val count = "Count"

    override val threat_level_5_name = "Critical"
    override val threat_level_5_text = "An attack is expected imminently."
    override val threat_level_4_name = "Severe"
    override val threat_level_4_text = "An attack is highly likely."
    override val threat_level_3_name = "Substantial"
    override val threat_level_3_text = "An attack is a strong possibility."
    override val threat_level_2_name = "Moderate"
    override val threat_level_2_text = "An attack is possible, but not likely."
    override val threat_level_1_name = "Low"
    override val threat_level_1_text = "An attack is unlikely."
    override val threat_level_0_name = ""
    override val threat_level_0_text = ""

    override val ThreatState_Active = "Active"
    override val ThreatState_Resolved = "Resolved"
    override val ThreatState_Ignored = "Ignored"
    override val ThreatState_HealingAwaitDecision = "BCDR needs decision"
    override val ThreatState_HealingAccepted = "BCDR accept"
    override val ThreatState_HealingDeclined = "BCDR decline"
    override val ThreatState_Healed = "BCDR Done"
    override val ThreatState_HealingFailed = "BCDR Failed"
    override val ThreatState_HealingInProgress = "Being Self Healed"
    override val ThreatState_Unknown = "unknown"

    override val user_management = "User Management"
    override val user_edit = "User Edit"
    override val user_new = "New user"
    override val user_subject = "Subject"
    override val user_email = "Email"
    override val user_firstName = "First Name"
    override val user_lastName = "Last Name"
    override val user_fullname  = "Name"
    override val user_roles = "Roles"
    override val user_action_import = "Import"
    override val user_action_export = "Export"

    override val user_preferences_edit ="UI Preferences"
    override val user_preferences_dataPresentationLanguage = "Data presentation language"
    override val user_preferences_uiLanguage ="User interface languge"

    override val system_preferences_edit= "System preferences"
    override val system_preferences_accountDataPresentationLanguage = "Default data presentation language"
    override val system_preferences_accountUiLanguage ="Default user interface languge"

    override val informationshare_management = "Information Sharing"
    override val informationshare_short_description = """Information sharing referrs to sharing of cybersecurity information with cybersecurity professionals outside your organization. 
Examples of such professionals may be computer security incident response teams (CSIRTs) your organization is collaborating with.

The purpose of sharing this information with cybersecurity professionals is for them to be better able to assess and analyze cybersecurity issues within organizations and to be able to set it in context with information gathered from other sources in order to be able to devise better mitigation and prevention mechanisms, which in turn may help your organization to better protect against attacks in the future.

If you decide to share, the information will be posted on a protected repository. Only people and organizations that have been authorized by your organization will be able to access the information."""
    override val informationshare_edit = "Edit Information Sharing"
    override val informationshare_id = "Id"
    override val informationshare_description = "Description"
    override val informationshare_state = "State"
    override val informationshare_create = "Created"
    override val informationshare_modified = "Last modified"
    override val informationshare_initiator = "Who"
    override val informationshare_history = "History"
    override val informationshare_summary = "Summary"

    override val ShareState_AwaitDecision = "Confirm sharing"
    override val ShareState_Accept = "accept"
    override val ShareState_Decline = "decline"
    override val ShareState_Unknown = "unknown"

    override val system_dependencies = "System"

    override val system_depend_id = "Id"
    override val system_depend_name  = "Name"
    override val system_depend_description= "Description"
    override val system_depend_connectedFrom= "Connected from"
    override val system_depend_connectedTo= "Connects to"
    override val system_depend_infoflow= "Infoflow"
    override val system_depend_category= "Category"
    override val system_depend_fields= "Fields"
    override val system_depend_fields_valueset = "Field Value Set"
    override val system_depend_fields_valueset_values = "Values"
    override val system_depend_node_types = "Node Types"
    override val system_depend_layout = "Layout"
    override val system_depend_layoutDirection = "Layout Direction"
    override val system_depend_layout_horizontal = "Horizontal"
    override val system_depend_layout_vertical = "Vertical"
    override val system_depend_layout_roots = "Roots"
    override val system_depend_layout_user_root = "Layout root"
    override val system_depend_layout_default_root = "Default layout"
    override val system_depend_spacing = "Spacing"

    override val system_depend_action_search = "Search System Resource"
    override val system_depend_action_new = "Create New"
    override val system_depend_action_edit = "Edit"
    override val system_depend_action_delete = "Delete"
    override val system_depend_action_delete_confirm_title = "Delete System Resource"
    override val system_depend_action_config = "Configuration"
    override val system_depend_action_import = "Import"
    override val system_depend_action_export = "Export"
    override val system_depend_action_config_import = "Configuration Import"
    override val system_depend_action_config_export = "Configuration Export"
    override val system_depend_action_print = "Print"

    override val system_depend_action_backup_or_restore = "Backup or Restore"
    override val system_depend_backup_title = "System Resources Backup"
    override val system_depend_backup_create_new = "New backup"
    override val system_depend_backup_created = "Created"
    override val system_depend_backup_comment = "Comment"
    override val system_depend_backup_nodes_not_in_current = "Not in current"
    override val system_depend_backup_nodes_in_current_not_in_this = "Not in this"
    override val system_depend_backup_nodes_changed = "Changed"
    override val system_depend_restore_state = "Change"
    override val system_depend_restore_operation = "Operation"
    override val system_depend_restore_operation_remove = "Remove"
    override val system_depend_restore_operation_restore = "Restore"
    override val system_depend_restore_revert_all_nodes = "Restore all System Nodes"
    override val system_depend_restore_revert_configuration = "Restore System Dependencies Configuration"

    override val system_depend_label_all = "All"

    override val system_depend_field_id = "ID"
    override val system_depend_field_label = "Label"
    override val system_depend_field_type = "Type"
    override val system_depend_field_cardinality = "Cardinality"
    override val system_depend_field_add = "Add Field"
    override val system_depend_fields_config = "Field Configuration.."
    override val system_depend_field_validation_error_txt = "'%value%' is not valid for '%type%'"

    override val sign_out = "Check Out"
    override val sign_out_disclaimer = """
        |The CS-AWARE Visualisation session as been cleared,  
        |you will have to logout of the external Authorisation Provider,  
        |if you do not want to be automatically signed in again. 
        |""".trimMargin()
    override val sign_out_link="Go to Your Authorisation Provider to Sign Out"

    override val mail_dialog_title = "Send Email"
    override val mail_to = "To"
    override val mail_from = "From"
    override val mail_subject = "Subject"
    override val mail_body = "Body"
    override val mail_send = "Send"

    override val socialmedia_reader : String = "Socialmedia"
    override val socialmedia_reader_filter_readstate_both = "Both read and unread"
    override val socialmedia_reader_filter_readstate_not_read = "Only not read"
    override val socialmedia_reader_filter_readstate_read = "Only read"

    override val socialmedia_reader_read_on_source  = "Read source"
    override val socialmedia_createdAt : String = "Created at"
    override val socialmedia_keywords : String = "Keywords"
    override val socialmedia_text : String = "Text"
    override val socialmedia_account : String = "Account"
    override val socialmedia_id= "Id"

    override  val socialmedia_system_where  = "Relates to"

    override val socialmedia_account_included = "Included"
    override val socialmedia_management_title = "Socialmedia Management"
    override val socialmedia_account_edit = "Edit"
    override val socialmedia_account_new = "New"
    override val socialmedia_account_mediaType = "Type"
    override val socialmedia_account_collectorState = "Collector"
    override val socialmedia_account_id = "Id"
    override val socialmedia_account_name = "Name"
    override val socialmedia_account_displayName = "Display name"
    override val socialmedia_account_description= "Description"
    override val socialmedia_account_lastCollected = "Last collected"

    override val socialmedia_account_twitter_new = "New X Account collection"
    override val socialmedia_account_include = "Include account collection"
    override val socialmedia_account_exclude = "Exclude account collection"

    override val socialmedia_account_action_export = "Export"
    override val socialmedia_account_action_import = "Import"
    override val socialmedia_account_action_include_page = "Include collection for accounts on the page"
    override val socialmedia_account_action_exclude_page = "Exclude collection for accounts on the page"

    override val socialmedia_create_threatobservation = "Create Threat Report"

    override val policy = "Policy"
    override val policy_edit = "Edit policy"
    override val policy_title: String = "Title"
    override val policy_purpose = "Purpose"
    override val policy_elements = "Elements"
    override val policy_need = "Need"
    override val policy_roles_responsibilities = "Role and responsibilities"
    override val policy_references = "References"
    override val policy_tags = "Tags"
    override val policy_systemNodeReferences = "System resource references"
    override val policy_new  = "Create policy"
    override val policy_createdAt = "Created at"
    override val policy_createdBy = "Created by"
    override val policy_updatedAt = "Updated at"
    override val policy_updatedBy = "Updateted by"

    override val policy_state  = "State"
    override val policy_state_draft  = "Draft"
    override val policy_state_approved  = "Approved"
    override val policy_state_draft_with_approved = "Draft with approved version"
    override val policy_state_obsolete = "Obsolete"
    override val policy_state_unknown = "Unknown"

    override val policy_type_organisation =  "Organisation"
    override val policy_type_template = "Template"

    override val policy_comment_comment = "Comment"
    override val policy_comment_add = "Add comment"
    override val policy_comment_edit = "Edit comment"
    override val policy_comment_respond = "Respond"
    override val policy_comment_delete = "Delete comment"

    override val policy_approve_comment_add = "Add approve comment"

    override val policy_history = "History"

    override val policy_action_edit = "Edit"
    override val policy_action_clone = "Clone"
    override val policy_action_approve = "Approve"
    override val policy_action_export = "Export"

    override val policy_action_ai_import = "AI import policy.."
    override val policy_import_extract_text = "Extract text from document (file)"

    override val policy_config  = "Configuration of tags and relation types"
    override val policy_relation_type = "Relation Type"
    override val policy_relation_id = "Id"
    override val policy_relation_name = "Name"
    override val policy_relation_reversed = "Reversed Relation"
    override val policy_template_action_edit = "New from the template"

    override val anomalies_ignores_saved = "Anomalies ignored"
    override val anomalies_incident_saved = "Incident saved"
    override val anomaly_details_module = "Module"
    override val anomaly_to_be_ignored = "To be ignored"
    override val anomaly_incident = "Incidents"
    override val anomaly_new_incident = "New incident"
    override val anomaly_unsaved_changes_titel ="There are unsaved changes"
    override val anomaly_unsaved_changes_message ="There are unsaved changes, do you want to proceed without saving them?"

    override val kpi_dimension_title_Collaborative_Mindset ="Collaborative Mindset"
    override val kpi_dimension_title_Dynamic_Cybersecurity_Strategy="Dynamic Cybersecurity Strategy"
    override val kpi_dimension_title_Organizational_Learning ="Organizational Learning"
    override val kpi_dimension_title_Regulatory_Compliance="Regulatory Compliance"
    override val kpi_dimension_title_Standards_and_Technologies= "Standards & Technologies"


    override val kpi_incident_total ="Incident Total"
    override val kpi_incident_sharing_ecosystem ="Incident Sharing Ecosystem"
    override val kpi_incident_sharing_global = "Incident Sharing Global"
    override val kpi_sharing_count_public = "sharing count public"
    override val kpi_incident_sharing_changes = "Incident Sharing Changes"

    override val kpi_time_of_count= "Time of count"
    override val kpi_number_of_incidents= "Number of Incidents"
    override val kpi_number_of_policies= "Number of Policies"
    override val kpi_number_of_changes= "Number of changes"
    override val kpi_number_of_assets= "Number of Assets"
    override val kpi_number_of_dependencies= "Number of dependencies"
    override val kpi_number_of_values= "Number of Values"
    override val kpi_number_of_logins="Number of logins"
    override val kpi_number_of_distinct_users="Number of distinct users"
    override val kpi_number_of_anomalies ="Number of Anomalies"
    override val kpi_number_of_new_anomalies ="Number of new Anomalies"
    override val kpi_number_of_dismissed_anomalies ="Number of dismissed Anomalies"
    override val kpi_number_of_new_incidents ="Number of new incidents"
    override val kpi_number_of_dot_dot_dot ="Number of ..."
    override val kpi_number_of_security_appliances ="Number of security appliances"
    override val kpi_number_of_policy_standards ="Number of policy standards"
    override val kpi_time= "Time"
    override val kpi_logins = "Logins"
    override val kpi_distinct_users = "Distinct Users"
    override val kpi_anomaly_count ="Anomaly Count"
    override val kpi_anomaly_timeline ="Anomaly Timeline"
    override val kpi_anomaly_dismissed ="Anomaly dismissed"

    override val kpi_anomaly_incident ="Anomaly Incident"
    override val kpi_system_graph_security_appliances ="System graph security appliances"
    override val kpi_security_appliances ="security appliances"
    override val kpi_policy_standards ="Policy standards"

    override val kpi_policy_count= "Policy Count"
    override val kpi_policy_changes= "Policy Changes"

    override val kpi_statistic_description_policy_count= "The number of policies on the platform. \n" +
            "Gives an indication as to how well your organisation is prepared to deal with the variety of cybersecurity related issues."
    override val kpi_statistic_description_policy_changes= "The number of adaptations made to the policies (weekly/monthly). \n" +
            "Gives an indication as to how well your organisation is ready to adopt to the ever changing cybersecurity landscape."
    override val kpi_statistic_description_system_graph_assets_d1= "The number of assets in the system graph. \n" +
            "Gives an indication as to how well your organisation has identified company assets that need to be protected \nin cyberspace."
    override val kpi_statistic_description_system_graph_dependencies_d1= "The number of dependencies in the system graph. \n" +
            "Gives an indication as to how well your organisation understands how your company's assets depend on each \n" +
            "other in terms of cybersecurity."
    override val kpi_statistic_description_system_graph_values_d1= "The number of values indicated for assets in the system graph.\n" +
            "Gives an indication about the cybersecurity knowledge management performed in order to keep the knowledge \n" +
            "available in the long-term"
    override val kpi_statistic_description_system_graph_changes_d1= "The number of adaptations made to the system graph (weekly/monthly). \n" +
            "Gives an indication as to how well your organisation maintains cybersecurity related knowledge in a world \n" +
            "that sees constant changes to your organisation and to the cybersecurity landscape."
    override val kpi_statistic_description_incident_total="The total number of incidents handled by the system (opened automatically by the system, or manually)." +
            "Gives an indication as to how well an organisation is prepared to identify and manage cybersecurity " +
            "issues - " +
            "either automatically through properly configured cybersecurity monitoring tools, or manually through " +
            "awareness and collaboration."
    override val kpi_statistic_description_incident_sharing_ecosystem ="The number of incidents with sharing level ‘ecosystem’.\n" +
            "Gives an indication as to how well your organisation collaborates with other related organisations\n" +
            "(including suppliers and customers) in order to manage cybersecurity issues in your organisation as well\n" +
            "as helping collaborating organisations to manage issues with services they depend on."
    override val kpi_statistic_description_incident_sharing_global_d2 = "The number of incidents with sharing level ‘global’.\n" +
            "Gives an indication as to how well your organisation participates in collaborative cybersecurity, as e.g.\n" +
            "outlined by the European cybersecurity strategy. Is also an indication as to how well your organisation\n" +
            "follows information sharing legal and regulatory compliance (e.g. NIS, GDPR)."
    override val kpi_statistic_description_incident_sharing_changes = "The number of adaptations to sharing levels (weekly/monthly).\n" +
            "Gives an indication as to how actively your organisation participates in information sharing to achieve\n" +
            "collaborative awareness and cooperation on resolving cybersecurity issues."
    override val kpi_statistic_description_frontend_logins="The number of logins to the system.\n Gives an indication as to how actively the organisation monitors and manages the cybersecurity situation in the organisation."
    override val kpi_statistic_description_distinct_users="The number of distinct users in the system.\n" +
            "Gives an indication as to how many resources your organisation dedicates to monitoring and managing the "+
            "cybersecurity situation in the organisation."
    override val kpi_statistic_description_anomaly_count ="The number of anomalies detected by the platform.\n" +
            "Gives an indication as to how well the automated detection of suspicious  cybersecurity behaviour is\n" +
            "configured within your organisation."
    override val kpi_statistic_description_anomaly_timeline ="The number of new anomalies detected by the system (weekly/monthly).\n" +
            "Gives an indication as to how quickly suspicious cybersecurity behaviour is assessed and managed within your organisation."
    override val kpi_statistic_description_anomaly_dismissed ="The number of anomalies that are classified by the human-in-the-loop as irrelevant.\n" +
            "Gives an indication as to how well the automated monitoring is configured to the specific context of your\n" +
            "organisation, and how well the organisation understands its own context. Lower is better."
    override val kpi_statistic_description_anomaly_incidents = "The count of anomalies considered as incidents (weekly/monthly).\n" +
            "Gives an indication as to how well automated monitoring of organisation specific context is configured,\n" +
            "and how well the human-in-the-loop understands abnormal behaviour that indicates a cybersecurity issue that\n" +
            "needs to be managed."
    override val kpi_statistic_description_system_graph_assets_d4 ="The number of assets in the system graph.\n" +
            "Gives an indication as to how well your organisation has identified company assets that need to be protected\n" +
            "in cyberspace. In the context of regulatory compliance, this indicates how well your company understands its\n" +
            "obligations of protecting company assets against cyber attacks."
    override val kpi_statistic_description_system_graph_dependencies_d4 ="The number of dependencies in the system graph.\n" +
            "Gives an indication as to how well your organisation understands how your company's assets depend on each\n" +
            "other in terms of cybersecurity. In the context of regulatory compliance this indicates how well your\n" +
            "organisation understands the importance of considering dependencies when protecting against cyber attacks."
    override val kpi_statistic_description_system_graph_values_d4 =""
    override val kpi_statistic_description_system_graph_changes_d4 ="The number of adaptations made to the system graph (weekly/monthly).\n" +
            "Gives an indication as to how well your organisation maintains cybersecurity related knowledge in a world\n" +
            "that sees constant changes to your organisation and to the cybersecurity landscape. In the context of\n" +
            "regulatory compliance, this gives an indication as to how well your company addresses dynamic cybersecurity\n" +
            "management procedures."
    override val kpi_statistic_description_incident_sharing_global_d4 ="The number of incidents with sharing level ‘global’.\n" +
            "Gives an indication as to how well your organisation participates in collaborative cybersecurity, as e.g.\n" +
            "outlined by the European cybersecurity strategy. In the context of regulatory compliance, this is an\n" +
            "indication as to how well your organisation follows information sharing legal and regulatory compliance (e.g. NIS, GDPR)."
    override val kpi_statistic_description_system_graph_security_appliances ="The number of security appliances identified in the system graph.\n" +
            "Gives an indication about how well the organisation follows standard security practices by applying\n" +
            "technological security controls to prevent and manage cybersecurity issues."
    override val kpi_statistic_description_policy_standards ="The number of cybersecurity policies that are derived from security standards."

    override val kpi_system_graph_assets= "System Graph Assets"
    override val kpi_system_graph_dependencies= "System Graph Dependencies"

    override val kpi_system_graph_values= "System Graph Values"
    override val kpi_system_graph_changes= "System Graph Changes"

    override val kpi_questionnaire= "Questionnaire"
    override val kpi_out_of_100= "out of 100"
    override val kpi_d1_section_view_description= "Explores the capacity to develop and deliver cybersecurity strategy, and to enhance the organisations cybersecurity \n" +
            "resilience by improving incident response, cyber defence and critical infrastructure (CI) protection capacities."
    override val kpi_d2_section_view_description = "Reviews important elements of a responsible cybersecurity culture such as the understanding of cyber-related risks,\n" +
            "the level of trust in services, understanding of privacy issues, collaboration within the organisation to promote\n" +
            "cybersecurity awareness, and contacts with relevant stakeholders"
    override val kpi_d3_section_view_description ="Reviews the availability, uptake and quality of relevant cybersecurity awareness-raising programmes,\n" +
            "formal cybersecurity educational programmes, and professional training programmes"
    override val kpi_d4_section_view_description ="Examines the organisation’s capacity to design and enact national legislation that\n" +
            "directly and indirectly relates to cybersecurity"
    override val kpi_d5_section_view_description ="Examines the implementation of cybersecurity standards and good practices, the deployment of processes and\n" +
            "controls, and the development of technologies and products in order to reduce cybersecurity risks"



    override val markdown_bold            = "Bold"
    override val markdown_italic          = "Italic"
    override val markdown_strikethrough   = "Strikethrough"
    override val markdown_heading         = "Heading"
    override val markdown_heading_smaller = "Heading smaller"
    override val markdown_heading_bigger  = "Heading bigger"
    override val markdown_heading_1x       = "Heading 1"
    override val markdown_heading_2x       = "Heading 2"
    override val markdown_heading_3x       = "Heading 3"
    override val markdown_code            = "Code"
    override val markdown_quote           = "Quote"
    override val markdown_unordered_list  = "Unordered list"
    override val markdown_ordered_list    = "Ordered list"
    override val markdown_clean_block     = "Clean _block"
    override val markdown_link            = "Insert Link"
    override val markdown_image           = "Insert Image"
    override val markdown_mde_table       = "Insert table"
    override val markdown_horizontal_rule = "Horizontal rule"
    override val markdown_preview         = "Toggle preview"
    override val markdown_side_by_side    = "Side by side view"
    override val markdown_fullscreen      = "Fullscreen"
    override val markdown_guide           = "Guide"
    override val markdown_undo            = "Undo"
    override val markdown_redo            = "Redo"

    override val validation_error: String = "Validation Error"
    override val save_anyway: String = "Save anyway"

    override val required_field_not_filled_error: String= "Fill in this field"
}

