package csaware.policy

import csaware.main.navigateTo
import csaware.messages.CsawareMessages
import csaware.messages.CsawareMessagesObject
import csaware.messages.i18nText
import csaware.messages.policy_type_text
import csaware.policy.config.PolicyConfigDialog
import csaware.systemdepend.SystemDependencyService
import csaware.utilities.ActionBar
import csaware.utilities.SearchInput
import csaware.utilities.componentTitle
import dk.rheasoft.csaware.api.PolicyType
import dk.rheasoft.csaware.api.access.MainFeature
import dk.rheasoft.csaware.api.access.Permission
import dk.rheasoft.csaware.api.policy.PolicyFilter
import kafffe.bootstrap.form.Dropdown
import kafffe.core.*
import kafffe.core.modifiers.StyleModifier
import kafffe.core.modifiers.onchange


/**
 * List polices in compoent with title and free text sarch input
 */
class Policies(private val graphService: SystemDependencyService) : KafffeComponent() {
    final override fun <Child : KafffeComponent> addChild(child: Child) = super.addChild(child)

    private val titleLabel = addChild(Label(i18nText(CsawareMessages::policy)))

    private val actionBar = addChild(ActionBar {
        item(i18nText(CsawareMessages::policy_new), "fa-solid fa-file-lines") {
            accessRequirement = (MainFeature.Policy to Permission.Write)
            action = { navigateTo("root/policy/new") }
        }
        item(i18nText(CsawareMessages::policy_config), "fas fa-cog") {
            accessRequirement = (MainFeature.Policy to Permission.Write)
            action = {
                PolicyConfigDialog().attach()
            }

        }
    })

    private val table = addChild(PolicyTable(graphService))

    private val filterSearchInput = addChild(
        SearchInput {table.filterModel.property(PolicyFilter::filter).data = it}
            .apply { modifiers.add(StyleModifier { maxWidth = "20vw" }) }
    )

    val dptd = DisplayPolicyTypeDropdown("dptd", table.filterModel.property(PolicyFilter::policyType)).apply { modifiers.add(StyleModifier { maxWidth = "10vw" }) }
    val policyTypeSearch = addChild(dptd).apply {
        dptd.onchange { dptd.updateValueModel() }
    }

     class DisplayPolicyTypeDropdown(id: String,
                                   valueModel: Model<PolicyType>,
                                   choicesModel: Model<List<PolicyType>> = Model.of(choices(),
                                       )
     ) : Dropdown<PolicyType>(id, valueModel, choicesModel) {

         override fun display(choice: PolicyType): String = CsawareMessagesObject.get().policy_type_text(choice)
         override fun valueFromString(strValue: String): PolicyType = PolicyType.valueOf(strValue)
         override fun valueToString(value: PolicyType): String = value.toString()
         companion object {
             private fun choices(): List<PolicyType> {
                 return PolicyType.entries
             }
         }

     }

    override fun KafffeHtmlBase.kafffeHtml() =
        div {
            componentTitle {
                add(titleLabel.html)
                div {addClass("row")
                    add(policyTypeSearch.html)
                    add(filterSearchInput.html)
                }
                add(actionBar.html)
            }
            add(table.html)
        }


}