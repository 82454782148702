package csaware.policy

import csaware.comm.CSAwareBackend
import csaware.main.CsawareServices
import csaware.main.navigateTo
import csaware.main.navigateToComponent
import csaware.messages.*
import csaware.systemdepend.SystemDependencyService
import csaware.utilities.SystemNodeToSystemGraphLink
import csaware.utilities.ColumnHeaderMultipleDropdownFilter
import csaware.utilities.EllipsisLabel
import dk.rheasoft.csaware.api.*
import dk.rheasoft.csaware.api.policy.PolicyFilter
import kafffe.bootstrap.BootstrapTable
import kafffe.bootstrap.BootstrapTableStyles
import kafffe.bootstrap.modifier.BootstrapPopoverModifier
import kafffe.bootstrap.modifier.BootstrapTooltipModifier
import kafffe.bootstrap.pagination.BootstrapPagination
import kafffe.bootstrap.pagination.Pager
import kafffe.core.*
import kafffe.core.modifiers.CssClassModifier
import kafffe.messages.MessagesObject.formatDateTime
import kotlinx.datetime.toJSDate
import org.w3c.dom.HTMLTableCellElement
import kotlin.math.ceil


class PolicyTable(
    private val graphService: SystemDependencyService,
    val largeSize: Boolean = true,
    val pageSize: Int = 10
) : KafffeComponent() {
    val filterModel: Model<PolicyFilter> = Model.of(PolicyFilter())
    val totalCountModel: Model<Int> = Model.of(0)

    // states filter
    private val statesFilterModel: Model<List<PolicyState>> = filterModel.property(PolicyFilter::states)
    private val statesByName = PolicyState.entries.associateBy { CsawareMessagesObject.get().policy_state_text(it) }
    private val statesAdaptingModel: Model<List<String>> = Model.ofGetSet(
        getter = {
            statesFilterModel.data.map { state -> CsawareMessagesObject.get().policy_state_text(state) }
        },
        setter = { values ->
            statesFilterModel.data = values.mapNotNull { name -> statesByName[name] }
            println(statesFilterModel.data.joinToString(", ") )
        }
    )

    private val tagsUsedModel: Model<List<String>> = RefreshingCacheModel({ tagsModel ->
        CsawareServices.policyBackend.getUsedPolicyTags { tagsModel.data = it }
    }, emptyList())

    val table = addChild(BootstrapTable.create<Policy>(listOf()) {
        rowClickHandler = { data, _ -> goTotemplateOrOrganisationView(data)}

        addStyle(BootstrapTableStyles.striped)
        modifiers.add(CssClassModifier("csaware-hover"))
        modifiersHeader.add(CssClassModifier("bg-primary"))

        col(i18nText(CsawareMessages::policy_title), { Label(it.policyData.title) })
        if (largeSize) {
            col(i18nText(CsawareMessages::policy_createdAt), { Label(it.createdAt.toJSDate().formatDateTime()) })
        }
        col(
            i18nText(CsawareMessages::policy_state),
            { Label(CsawareMessagesObject.get().policy_state_text(it.state)) },
            {
                ColumnHeaderMultipleDropdownFilter(it, statesAdaptingModel, Model.of(statesByName.keys.toList()))
            }
        )
        colEx(i18nText(CsawareMessages::policy_purpose), { data: Policy, cell: HTMLTableCellElement ->
            cell.tabIndex = 0
            val model: Model<String> = Model.of(data.policyData.purpose)
            val label = EllipsisLabel("20vw", model, lines = 2)
            label
        })
        if (largeSize) {
            colEx(i18nText(CsawareMessages::policy_references), { data: Policy, cell: HTMLTableCellElement ->
                cell.tabIndex = 0
                val model: Model<String> = Model.of(data.policyData.references)
                val label = EllipsisLabel("20vw", model, lines = 2)
                label
            })
        }
        col(
            i18nText(CsawareMessages::policy_systemNodeReferences),
                {
                    SystemNodeToSystemGraphLink(it.policyData.systemNodeReferences, graphService)
                } ,
            {
                val names = graphService.model.data.map { it.name }
                val nodeIdsFilterModel: Model<List<String>> = filterModel.property(PolicyFilter::nodes)
                val namesAdaptingModel: Model<List<String>> = Model.ofGetSet(
                    getter = {
                        nodeIdsFilterModel.data.mapNotNull { id -> graphService.byId(id)?.name }
                    },
                    setter = { values ->
                        nodeIdsFilterModel.data =
                            values.mapNotNull { name -> graphService.byName(name)?.id }
                    }

                )
                ColumnHeaderMultipleDropdownFilter(it, namesAdaptingModel, Model.of(names))
            }
        ).apply{ rowClick = false }
        col(
            i18nText(CsawareMessages::policy_tags),
            { Label(it.policyData.tags.joinToString(", ")) },
            { ColumnHeaderMultipleDropdownFilter(it, filterModel.property(PolicyFilter::tags), tagsUsedModel) }
        )
    })

    val pager = Pager(1)
    private val paginator = addChild(BootstrapPagination(pager).apply {
        prevNextPage = true
        modifiers.add(CssClassModifier("float-end"))
    })

    init {
        pager.changeListeners.add { loadData() }
        filterModel.listeners.add(ModelChangeListener { loadData() })
        loadData()
    }

    private fun goTotemplateOrOrganisationView(policy: Policy){
        if(policy.policytype.equals(PolicyType.TEMPLATE)){
            navigateToComponent(
                PolicyTemplateView(
                    Model.of(policy),
                    "root/policy/list",
                    CsawareServices.systemDependencyService
                ))
        }
        else {
            navigateTo("root/policy/${policy.id}/view")
        }
    }
    fun loadData() {
        CsawareServices.alerts.clearAlerts()
        // clear any tootltip,popovers
        BootstrapTooltipModifier.removeAll()
        BootstrapPopoverModifier.removeAll()
        if(filterModel.data.policyType.equals(PolicyType.TEMPLATE)) {
            val offset = pageSize * (pager.currentPage - 1)
            CsawareServices.policyBackend.getPolicyTemplates(
                offset,
                pageSize,
                filterModel.data,
                this::receiveData,
            )
        } else {
            val offset = pageSize * (pager.currentPage - 1)
            CsawareServices.policyBackend.getPolicies(
                offset,
                pageSize,
                filterModel.data,
                this::receiveData,
            )
        }
    }

    fun receiveData(response: QueryResult<Policy>) {
        totalCountModel.data = response.nofResult
        val pageCount = ceil(response.nofResult.toDouble() / pageSize.toDouble()).toInt()
        if (pager.nofPages != pageCount) {
            pager.nofPages = pageCount
        }
        table.data = response.result
        rerenderRecursive()
    }

    override fun KafffeHtmlBase.kafffeHtml() =
        div {
            if (totalCountModel.data > 0) {
                add(table.html)
                add(paginator.html)
            }
        }

    private val onServerUpdate: (UpdateEvent) -> Unit = { serverUpdated(it) }

    protected fun serverUpdated(msg: UpdateEvent) {
        if (msg.type == UpdateEvent.EntityType.Policy) {
            loadData()
        }
    }

    override fun attach() {
        super.attach()
        CSAwareBackend.updateListeners.add(onServerUpdate)
    }

    override fun detach() {
        CSAwareBackend.updateListeners.remove(onServerUpdate)
        super.detach()
    }
}