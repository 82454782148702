package csaware.anomalies

import csaware.messages.CsawareMessages
import csaware.messages.i18nText
import csaware.utilities.UUID.generateUuid
import dk.rheasoft.csaware.api.incident.ThreatObservation
import kafffe.core.KafffeComponent
import kafffe.core.KafffeHtmlBase
import kafffe.core.KafffeHtmlOut
import kafffe.core.Model
import kotlinx.dom.addClass
import kotlinx.dom.removeClass

open class IncidentsCollector(
    val dragHolder: AnomalyDragHolder,
    val targetsModel: Model<List<Target>>
) : KafffeComponent() {
    private val collectors = mutableListOf<IncidentCollector>()
    private val incidents = mutableListOf<ThreatObservation>()

    fun addIncident(incident: ThreatObservation): IncidentCollector {
        incidents.add(incident)
        val collector = IncidentCollector(Model.of(incident), dragHolder, targetsModel)
        addChild(collector)
        collectors.add(collector)
        rerender()
        return collector
    }

    fun newIncident(name: String =  "", description: String = ""): IncidentCollector {
        return addIncident(
            ThreatObservation(
            id = generateUuid("incident--"),
            name = name,
            severity = 2,
            description = description,
        )
        )
    }

    private var enterCount = 0

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut =
        div {
            addClass("card bg-white")
            withElement {
                val dropzone = this
                ondragenter = { dragEvent ->
                    dragEvent.preventDefault()
                    if (++enterCount == 1) dropzone.addClass("dragover")
                    false
                }
                ondragleave = { dragEvent ->
                    dragEvent.preventDefault()
                    if (--enterCount == 0) dropzone.removeClass("dragover")

                }
                ondragover = { dragEvent ->
                    dragEvent.preventDefault()
                }
                ondrop = { dragEvent ->
                    val collector = newIncident(
                        dragHolder.draggedObject?.anomaly?.category ?: "",
                        dragHolder.draggedObject?.anomaly?.description ?: ""
                    )
                    dragHolder.moveTo(collector.anomaliesModel)
                    enterCount = 0
                    dropzone.removeClass("dragover")
                    dragEvent.preventDefault()
                    dragEvent.stopPropagation()
                }
            }
            h3 {
                addClass("card-header")
                i { addClass("fa-solid fa-exclamation-triangle me-2") }
                text(i18nText(CsawareMessages::anomaly_incident).data)
            }
            div {
                addClass("card-body vgap-2")
                for (child in children) {
                    add(child.html)
                }
            }
            div {
                addClass("card-footer")
                button {
                    addClass("btn btn-secondary")
                    text(i18nText(CsawareMessages::anomaly_new_incident).data)
                    element.onclick = {
                        newIncident()
                    }
                }
            }
        }

    fun targets(): List<Target> =
        collectors.map { Target(
            it.incidentModel.data.name.ifBlank { it.incidentModel.data.id },
            "fa-solid fa-exclamation-triangle",
            it.anomaliesModel
        ) }

    fun hasUnsavedChanges(): Boolean = collectors.find { it.hasUnsavedChanges()  } != null

    fun removeCollector(incidentCollector: IncidentCollector) {
        collectors.remove(incidentCollector)
        incidents.remove(incidentCollector.incidentModel.data)
        removeChild(incidentCollector)
        rerender()
    }


}