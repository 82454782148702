package dk.rheasoft.csaware.api.incident

import kotlinx.serialization.Serializable

@Serializable
data class EcoSystemContext (
    val id: String,
    val title: String,
    val description: String,
    val link : String
)


