package csaware.utilities.markdown

import EasyMDE
import EasyMDE.Companion.cleanBlock
import EasyMDE.Companion.drawHorizontalRule
import EasyMDE.Companion.drawLink
import EasyMDE.Companion.drawTable
import EasyMDE.Companion.redo
import EasyMDE.Companion.toggleBlockquote
import EasyMDE.Companion.toggleBold
import EasyMDE.Companion.toggleCodeBlock
import EasyMDE.Companion.toggleFullScreen
import EasyMDE.Companion.toggleHeadingBigger
import EasyMDE.Companion.toggleHeadingSmaller
import EasyMDE.Companion.toggleItalic
import EasyMDE.Companion.toggleOrderedList
import EasyMDE.Companion.togglePreview
import EasyMDE.Companion.toggleSideBySide
import EasyMDE.Companion.toggleStrikethrough
import EasyMDE.Companion.toggleUnorderedList
import EasyMDE.Companion.undo
import csaware.messages.CsawareMessagesObject
import kafffe.bootstrap.external.jsCreate
import kafffe.bootstrap.form.FormValueProvider
import kafffe.core.*
import kotlinx.browser.window

/**
 * Markdown input field based on [EASYMDE](https://github.com/ionaru/easy-markdown-editor) .
 */
class MarkDownInput(model: Model<String>) : KafffeComponentWithModel<String>(model),
    FormValueProvider {


    init {
        KafffeApplication.addCss("easymde_css", "easymde/easymde.min.css")
        KafffeApplication.addJsScriptRef("easymde_js", "easymde/easymde.min.js")
    }

    private lateinit var mde: EasyMDE
    private var initValue: String = model.data

    var readOnly : Boolean by rerenderOnChange(false)

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut =
        textarea {
            addClass("form-control")
            withElement {
                // need to delay in order to have everything loaded before EASYMDE transforms textarea
                window.setTimeout(::applyMde, 300)
            }
        }


    private fun applyMde() {
        val options: EasyMDE.Options = jsCreate()
        options.apply {
            element = html
            autoDownloadFontAwesome = false
            initialValue = model.data
            toolbar =  defaultToolbarButtons()
            minHeight = "4rem"
            spellChecker = false
            status = false
        }
        if (readOnly) {
            options.toolbar = false
        }
        mde = EasyMDE(options)
        if (readOnly) {
            if (!mde.isPreviewActive()) {
                mde.togglePreview()
            }
            mde.codemirror.setOption("readOnly", true)
        }
    }

    override fun detach() {
        initValue = mde.value()
        super.detach()
    }

    /**
     * DSL builder for EasyMDE ToolbarIcon
     */
    private fun tb(initialize: EasyMDE.ToolbarIcon.() -> Unit) = jsCreate<EasyMDE.ToolbarIcon>().apply { initialize() }

    private fun defaultToolbarButtons() : Array<dynamic>
         = arrayOf(
            tb {
                name = "bold"
                action = toggleBold
                className = "fa fa-bold"
                title = CsawareMessagesObject.get().markdown_bold
            },
            tb {
                name = "italic"
                action = toggleItalic
                className = "fa fa-italic"
                title = CsawareMessagesObject.get().markdown_italic
            },
            tb {
                name = "strikethrough"
                action = toggleStrikethrough
                className = "fa fa-strikethrough"
                title = CsawareMessagesObject.get().markdown_strikethrough
            },
            "|",
            tb {
                name = "heading"
                action = toggleHeadingSmaller
                className = "fa fa-header fa-heading"
                title = CsawareMessagesObject.get().markdown_heading
            },
            tb {
                name = "heading-smaller"
                action = toggleHeadingSmaller
                className = "fa fa-header fa-heading header-smaller"
                title = CsawareMessagesObject.get().markdown_heading_smaller
            },
            tb {
                name = "heading-bigger"
                action = toggleHeadingBigger
                className = "fa fa-header fa-heading header-bigger"
                title = CsawareMessagesObject.get().markdown_heading_bigger
            },
            /*
            tb {
                name = "heading-1"
                action = toggleHeading1
                className = "fa fa-header fa-heading header-1"
                title = csawareMessages().markdown_heading_1
            },
            tb {
                name = "heading-2"
                action = toggleHeading2
                className = "fa fa-header fa-heading header-2"
                title = csawareMessages().markdown_heading_2
            },
            tb {
                name = "heading-3"
                action = toggleHeading3
                className = "fa fa-header fa-heading header-3"
                title = csawareMessages().markdown_heading_3
            },*/
            "|",
            tb {
                name = "code"
                action = toggleCodeBlock
                className = "fa fa-code"
                title = CsawareMessagesObject.get().markdown_code
            },
            tb {
                name = "quote"
                action = toggleBlockquote
                className = "fa fa-quote-left"
                title = CsawareMessagesObject.get().markdown_quote
            },
            tb {
                name = "unordered-list"
                action = toggleUnorderedList
                className = "fa fa-list-ul"
                title = CsawareMessagesObject.get().markdown_unordered_list
            },
            tb {
                name = "ordered-list"
                action = toggleOrderedList
                className = "fa fa-list-ol"
                title = CsawareMessagesObject.get().markdown_ordered_list

            },
            tb {
                name = "clean-block"
                action = cleanBlock
                className = "fa fa-eraser"
                title = CsawareMessagesObject.get().markdown_clean_block
            },
            "|",
            tb {
                name = "link"
                action = drawLink
                className = "fa fa-link"
                title = CsawareMessagesObject.get().markdown_link

            },
            /*
            tb {
                name = "image"
                action = drawImage
                className = "fa fa-image"
                title = csawareMessages().markdown_image

            },

             */
            tb {
                name = "mde_table"
                action = drawTable
                className = "fa fa-table"
                title = CsawareMessagesObject.get().markdown_mde_table
            },
            tb {
                name = "horizontal-rule"
                action = drawHorizontalRule
                className = "fa fa-minus"
                title = CsawareMessagesObject.get().markdown_horizontal_rule
            },
            "|",
            tb {
                name = "preview"
                action = togglePreview
                className = "fa fa-eye"
                noDisable = true
                title = CsawareMessagesObject.get().markdown_preview

            },
            tb {
                name = "side-by-side"
                action = toggleSideBySide
                className = "fa fa-columns"
                noDisable = true
                noMobile = true
                title = CsawareMessagesObject.get().markdown_side_by_side

            },
            tb {
                name = "fullscreen"
                action = toggleFullScreen
                className = "fa fa-arrows-alt"
                noDisable = true
                noMobile = true
                title = CsawareMessagesObject.get().markdown_fullscreen

            },
            "|",
            tb {
                name = "guide"
                action = "https://www.markdownguide.org/basic-syntax/"
                className = "fa fa-question-circle"
                noDisable = true
                title = CsawareMessagesObject.get().markdown_guide

            },
            "|",

            tb {
                name = "undo"
                action = undo
                className = "fa fa-undo"
                noDisable = true
                title = CsawareMessagesObject.get().markdown_undo
            },
            tb {
                name = "redo"
                action = redo
                className = "fa fa-repeat fa-redo"
                noDisable = true
                title = CsawareMessagesObject.get().markdown_redo
            }
        )


    override fun updateValueModel() {
        model.data = mde.value()
    }
}

