package dk.rheasoft.csaware.api.systemdependencies

data class SystemDependencyResourceDifference private constructor(
    val notInFirst: List<SystemDependencyResource>,
    val notInSecond: List<SystemDependencyResource>,
    val changed: List<Pair<SystemDependencyResource, SystemDependencyResource>>,
) {
    constructor(first: List<SystemDependencyResource>, second: List<SystemDependencyResource>) : this(
        notInSecond(first, second),
        notInSecond(second, first),
        changed(first, second),
    )
    constructor() : this(emptyList(), emptyList(), emptyList())

    companion object {
        fun notInSecond(
            first: List<SystemDependencyResource>,
            second: List<SystemDependencyResource>
        ): List<SystemDependencyResource> {
            val ids = second.map { it.id }.toSet()
            return first.filter { it.id !in ids }
        }

        fun changed(
            first: List<SystemDependencyResource>,
            second: List<SystemDependencyResource>
        ): List<Pair<SystemDependencyResource, SystemDependencyResource>> {
            val id2resource: Map<String, SystemDependencyResource> = second.associateBy { it.id }
            return first
                .filter {id2resource[it.id] != null}
                .filter { it != id2resource[it.id] }
                .map { Pair(it, id2resource[it.id]!!) }
        }
    }
}