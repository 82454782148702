package dk.rheasoft.csaware.json.adapter.base

import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlin.properties.ReadWriteProperty
import kotlin.reflect.KProperty

/**
 * Property delegate for String properties
 */
class JsonString(val defaultValue: String = "") : ReadWriteProperty<ObjectAdapter, String> {
    override fun getValue(thisRef: ObjectAdapter, property: KProperty<*>): String =
        thisRef.adapter.getString(property.name) ?: defaultValue

    override fun setValue(thisRef: ObjectAdapter, property: KProperty<*>, value: String) {
        thisRef.adapter.setString(property.name, value)
    }
}

/**
 * Property delegate for Timestamp properties
 */
class JsonTimestamp(val defaultValue: Instant = Clock.System.now()) : ReadWriteProperty<ObjectAdapter, Instant> {
    override fun getValue(thisRef: ObjectAdapter, property: KProperty<*>): Instant =
        thisRef.adapter.getString(property.name)?.let { Instant.parse(it.replace("+0000", "Z")) } ?: defaultValue

    override fun setValue(thisRef: ObjectAdapter, property: KProperty<*>, value: Instant) {
        thisRef.adapter.setString(property.name, value.toString())
    }
}

/**
 * Property delegate for Int properties.
 */
class JsonInt(val defaultValue: Int = 0) : ReadWriteProperty<ObjectAdapter, Int> {
    override fun getValue(thisRef: ObjectAdapter, property: KProperty<*>): Int =
        thisRef.adapter.getNumber(property.name)?.toInt() ?: defaultValue

    override fun setValue(thisRef: ObjectAdapter, property: KProperty<*>, value: Int) {
        thisRef.adapter.setNumber(property.name, value)
    }
}

/**
 * Property delegate for Long properties
 */
class JsonLong(val defaultValue: Long = 0L) : ReadWriteProperty<ObjectAdapter, Long> {
    override fun getValue(thisRef: ObjectAdapter, property: KProperty<*>): Long =
        thisRef.adapter.getNumber(property.name)?.toLong() ?: defaultValue

    override fun setValue(thisRef: ObjectAdapter, property: KProperty<*>, value: Long) {
        thisRef.adapter.setNumber(property.name, value)
    }
}

/**
 * Property delegate for Double properties.
 */
class JsonDouble(val defaultValue: Double = 0.0) : ReadWriteProperty<ObjectAdapter, Double> {
    override fun getValue(thisRef: ObjectAdapter, property: KProperty<*>): Double =
        thisRef.adapter.getNumber(property.name)?.toDouble() ?: defaultValue

    override fun setValue(thisRef: ObjectAdapter, property: KProperty<*>, value: Double) {
        thisRef.adapter.setNumber(property.name, value)
    }
}

/**
 * Property delegate for Boolean properties.
 */
class JsonBoolean(val defaultValue: Boolean = false)  : ReadWriteProperty<ObjectAdapter, Boolean> {
    override fun getValue(thisRef: ObjectAdapter, property: KProperty<*>): Boolean =
        thisRef.adapter.getBoolean(property.name) ?: defaultValue

    override fun setValue(thisRef: ObjectAdapter, property: KProperty<*>, value: Boolean) {
        thisRef.adapter.setBoolean(property.name, value)
    }
}

/**
 * Property delegate for typed ObjectAdapter properties
 */
class JsonObject<T : ObjectAdapter>(val construct: (adapter: JsonObjectAdapter) -> T) : ReadWriteProperty<ObjectAdapter, T>{
    override fun getValue(thisRef: ObjectAdapter, property: KProperty<*>): T =
        construct(thisRef.adapter.getObject(property.name))

    override fun setValue(thisRef: ObjectAdapter, property: KProperty<*>, value: T) {
        thisRef.adapter.setObject(property.name, value.adapter)
    }
}

/**
 * Property delegate for list of typed ObjectAdapter properties
 */
class JsonList<T : ObjectAdapter>(val construct: (adapter: JsonObjectAdapter) -> T) : ReadWriteProperty<ObjectAdapter, List<T>>{
    override fun getValue(thisRef: ObjectAdapter, property: KProperty<*>): List<T> {
        val array = thisRef.adapter.getArray(property.name)
        return array.objectIterator().asSequence() //
            .map { construct(it) } //
            .toList()
    }

    override fun setValue(thisRef: ObjectAdapter, property: KProperty<*>, value: List<T>) {
        val array = thisRef.adapter.createArray()
        value.map { it.adapter }.forEach { array.add(it) }
        thisRef.adapter.setArray(property.name, array)
    }
}

/**
 * Property delegate for list of String properties
 */
class JsonStringList() : ReadWriteProperty<ObjectAdapter, List<String>>{
    override fun getValue(thisRef: ObjectAdapter, property: KProperty<*>): List<String> {
        val array = thisRef.adapter.getArray(property.name)
        return array.stringIterator().asSequence().toList()
    }

    override fun setValue(thisRef: ObjectAdapter, property: KProperty<*>, value: List<String>) {
        val array = thisRef.adapter.createArray()
        value.forEach { array.add(it) }
        thisRef.adapter.setArray(property.name, array)
    }
}

/**
 * Property delegate for Map of String to Int
 */
class JsonStringIntMap()
    : ReadWriteProperty<ObjectAdapter, Map<String, Int>>{
    override fun getValue(thisRef: ObjectAdapter, property: KProperty<*>): Map<String,Int> {
        val obj = thisRef.adapter.getObject(property.name)
        return obj.propertyNames().associateWith { obj.getNumber(it)?.toInt() ?: 0 }
    }

    override fun setValue(thisRef: ObjectAdapter, property: KProperty<*>, value: Map<String,Int>) {
        val obj = thisRef.adapter.createObject()
        value.forEach { (k, v) -> obj.setNumber(k, v) }
        thisRef.adapter.setObject(property.name, obj)
    }
}

/**
 * Property delegate for Map of String to objectproperty
 */
class JsonStringMap<T : ObjectAdapter>(val construct: (adapter: JsonObjectAdapter) -> T)
    : ReadWriteProperty<ObjectAdapter, Map<String, T>>{
    override fun getValue(thisRef: ObjectAdapter, property: KProperty<*>): Map<String,T> {
        val obj = thisRef.adapter.getObject(property.name)
        return obj.propertyNames().associateWith { construct(obj.getObject(it)) }
    }

    override fun setValue(thisRef: ObjectAdapter, property: KProperty<*>, value: Map<String,T>) {
        val obj = thisRef.adapter.createObject()
        value.forEach { (k, v) -> obj.setObject(k, v.adapter) }
        thisRef.adapter.setObject(property.name, obj)
    }
}