package csaware.policy.config

import csaware.main.CsawareServices
import csaware.messages.CsawareMessages
import csaware.messages.CsawareMessagesObject
import csaware.messages.i18nText
import dk.rheasoft.csaware.api.policy.*
import kafffe.bootstrap.form.BootstrapForm
import kafffe.core.Model
import kafffe.core.modifiers.CssClassModifier.Companion.cssClassModifier
import org.w3c.dom.events.Event

/**
 * Form for maintenance of tags
 */
class PolicyRelationTypesForm(model: Model<PolicyRelationTypes>)  : BootstrapForm<PolicyRelationTypes>(model) {

    init {
        cssClassModifier("hgap-3 vgap-3")
        val listModel: Model<List<PolicyRelationType>> = Model.ofGetSet(
            setter = { value: List<PolicyRelationType> ->
                model.data.types = value.toMutableList()
            },
            getter = { model.data.types.toList() }

        )
        val input = PolicyRelationTypeListEditor(listModel)
        val msg = CsawareMessagesObject.get()
        val title = listOf(msg.policy_relation_id, msg.policy_relation_name, msg.policy_relation_reversed).joinToString(", ")
        decorateAndAddComponent(Model.of(title), input)
        submit(i18nText(CsawareMessages::save))
    }

    override fun onSubmit(event: Event) {
        if (isRendered) {
            event.preventDefault()
            event.stopPropagation(); // Should we support actual form submit
            processForm(::onOk)
        }
    }

    private fun onOk() {
        val subset = CsawareServices.configrationService.systemLevelConfig.data.subsetPolicyRelationTypes()
        subset.setPolicyRelationTypes(model.data)
        CsawareServices.configBackend.storeSystemLevelConfig(subset) {
            CsawareServices.configrationService.systemLevelConfig.data.setPolicyRelationTypes(model.data)
            // detach dialog
            parent?.detach()
        }
    }

}