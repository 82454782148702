package csaware.userpreferences

import csaware.main.CsawareServices
import csaware.messages.CsawareMessages
import csaware.messages.CsawareMessagesObject.csawareMessageStrategy
import csaware.messages.i18nText
import csaware.utilities.DisplayLanguageDropdown
import dk.rheasoft.csaware.api.User
import dk.rheasoft.csaware.api.UserPreferences
import kafffe.bootstrap.BasicColor
import kafffe.bootstrap.ColWidth
import kafffe.bootstrap.ModalSize
import kafffe.bootstrap.ResponsiveSize
import kafffe.bootstrap.form.FormDialog
import kafffe.core.Model
import kafffe.core.ModelChangeListener
import kafffe.core.modifiers.CssClassModifier
import kafffe.core.modifiers.CssClassModifier.Companion.cssClassModifier
import kafffe.core.modifiers.StyleModifier
import kafffe.core.property

class UserPreferencesEdit(user: User) :
    FormDialog<User>(
        i18nText(CsawareMessages::user_preferences_edit),
        Model.of(user)
    ) {


    val displayLanguageModel : Model<UserPreferences> = Model.of(model.data.preferences)
    private var changed = false
    init {
        cssClassModifier("hgap-3 vgap-3")
        labelStrategy = csawareMessageStrategy("user_")
        size = ModalSize.large
        modal.modifiersBody.add(StyleModifier {
            overflowY = "auto"
            maxHeight = "75vh"
        })
        modal.modifiersModal.add(StyleModifier {
            maxWidth = "90vw"
            width = "1400px"
        })
        modal.modifiersContent.add(CssClassModifier("bg-light"))

        readonly(User::email)
        readonly(User::firstName)
        readonly(User::lastName)

        row{
            col(ColWidth(ResponsiveSize.md, 3)) {
                group {
                    cssClassModifier("hgap-3 vgap-3")
                    val datapresentationlanguageModel =
                    displayLanguageModel.property(UserPreferences::dataPresentationLanguage)
                    val lang = DisplayLanguageDropdown("lang", datapresentationlanguageModel)
                    decorateAndAdd(i18nText(CsawareMessages::user_preferences_dataPresentationLanguage), lang)

                    val uilanguageModel = displayLanguageModel.property(UserPreferences::uiLanguage)
                    val uilang = DisplayLanguageDropdown("uilang", uilanguageModel)
                    decorateAndAdd(i18nText(CsawareMessages::user_preferences_uiLanguage), uilang)
                }
            }
        }

        displayLanguageModel.listeners.add(ModelChangeListener { changed = true})

        submit("save", onOk = {
            CsawareServices.alerts.clearAlerts()
            CsawareServices.userBackend.updateUserPreferences(model.data) {

            }
            detach()
        }).color = BasicColor.primary
        cancel().color = BasicColor.secondary
    }

}