package csaware.anomalies

import csaware.main.CsawareServices
import csaware.messages.CsawareMessages
import csaware.messages.i18nText
import csaware.systemdepend.SystemDependencyService
import csaware.utilities.SystemNodeToSystemGraphLink
import dk.rheasoft.csaware.api.incident.AnomalyWrapper
import kafffe.core.*
import kotlinx.browser.document
import kotlinx.dom.addClass
import kotlinx.dom.removeClass
import org.w3c.dom.HTMLDialogElement
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.events.MouseEvent

class AnomaliesTable(
    model: Model<List<AnomalyWrapper>>,
    val dragHolder: AnomalyDragHolder,
    val targetsModel: Model<List<Target>>,
    val graphService: SystemDependencyService = CsawareServices.systemDependencyService
) : KafffeComponentWithModel<List<AnomalyWrapper>>(model) {
    init {
        setModelChangedRerender()
    }

    private lateinit var popup: HTMLDialogElement
    private fun showPopup(event: MouseEvent, anomaly: AnomalyWrapper) {
        popup.innerHTML = ""
        KafffeHtml(popup).apply {
            div {
                addClass("modal-header")
                text("\u00A0")
                button {
                    addClass("btn-close float-end")
                    onClick {
                        hidePopup()
                    }
                }
            }
            add(AnomalyDetailsView(anomaly, graphService).html)
            for (target in targetsModel.data) {
                if (target.listModel !== model) {
                    a {
                        addClass("btn btn-light")
                        withStyle {
                            display = "block"
                            textAlign = "left"
                        }

                        element.onclick = {
                            dragHolder.source = super.model
                            dragHolder.draggedObject = anomaly
                            dragHolder.moveTo(target.listModel)
                            hidePopup()
                        }
                        i { addClass(target.iconClasses + " me-2") }
                        text(target.name)
                    }
                }
            }

//            // Position popup
            val coordX = event.clientX
            val coordY = event.clientY
//            // Adjust position if popup would be off-screen
//            if (coordX > window.innerWidth / 2) { coordX -= popup.offsetWidth }
//            if (coordY > window.innerHeight / 2) { coordY -= popup.offsetHeight }
            withStyle{

                marginTop = "${coordY}px"
                marginLeft = "${coordX}px"
                marginRight = "30px"
            }

        }
        popup.showModal()
    }

    private fun hidePopup() {
        popup.close()
        popup.innerHTML = ""
    }

    private var enterCount = 0

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut =
        div {
            addClass("table csaware-hover striped border")
            withElement {
                val dropzone = this
                ondragenter = { dragEvent ->
                    dragEvent.preventDefault()
                    if (++enterCount == 1) dropzone.addClass("dragover")
                    false
                }
                ondragleave = { dragEvent ->
                    dragEvent.preventDefault()
                    if (--enterCount == 0) dropzone.removeClass("dragover")
                }
                ondragover = { dragEvent -> dragEvent.preventDefault() }
                ondrop = { dragEvent ->
                    enterCount = 0
                    dropzone.removeClass("dragover")
                    dragHolder.moveTo(model)
                }
            }
            val idColWidth = "20%"
            val moduleColWidth = "10%"
            val categoryColWidth = "20%"
            val descriptionColWidth = "35%"
            val assetColWidth = "15%"
            popup = dialog {
                addClass("bg-light border row p-0")
                withStyle {
                    position = "absolute"
                }
            }.element



            div {
                addClass("thead bg-primary")
                div {
                    addClass("tr")
                    colHeader(idColWidth, i18nText(CsawareMessages::id).data)
                    colHeader(moduleColWidth, i18nText(CsawareMessages::anomaly_details_module).data)
                    colHeader(categoryColWidth, i18nText(CsawareMessages::system_depend_category).data)
                    colHeader(descriptionColWidth, i18nText(CsawareMessages::description).data)
                    colHeader(assetColWidth, i18nText(CsawareMessages::threat_where).data)
                }
            }
            div {
                addClass("tbody")
                var striped: Boolean = true
                for (anomaly in model.data) {
                    div {
                        addClass("tr")
                        if (striped) addClass("tr-stripe")
                        striped = !striped
                        withElement {
                            draggable = true
                            ondragstart = { event ->
                                document.body?.style?.setProperty("user-select", "none")
                                event.dataTransfer?.dropEffect = "move"
                                dragHolder.source = super.model
                                dragHolder.draggedObject = anomaly
                                true
                            }
                            ondragend ={
                                document.body?.style?.setProperty("user-select", "auto")
                            }
                            onclick = { c ->
                                c.preventDefault()
                                showPopup(c, anomaly)
                            }
                            oncontextmenu = { c ->
                                c.preventDefault()
                                showPopup(c, anomaly)
                            }

                        }

                        colHeader(idColWidth, anomaly.id)
                        colHeader(moduleColWidth, anomaly.anomaly.module.name)
                        colHeader(categoryColWidth, anomaly.anomaly.category)
                        colHeaderEllipsis(descriptionColWidth, anomaly.anomaly.description)
                        div {
                            withStyle {
                                display = "inline-block"
                                width = assetColWidth
                                overflowX = "hidden"
                                textOverflow = "ellipsis"
                            }
                            add(SystemNodeToSystemGraphLink(anomaly.assets.toSet(), graphService).html)
                        }
                    }

                }
            }
        }

    private fun KafffeHtml<HTMLDivElement>.colHeaderEllipsis(idColWidth: String, labelText: String) {
        div {
            withStyle {
                display = "inline-block"
                maxWidth = idColWidth
                width = idColWidth
            }
            div {
                addClass("ellipsis-2")
                withStyle {
                    maxWidth = "100%"
                    overflowX = "hidden"
                    overflowY = "hidden"
                    textOverflow = "ellipsis"
                }
                text(labelText)
            }
            // Popovers.markdownHover{ labelText }.modify(element)
        }
    }

    private fun KafffeHtml<HTMLDivElement>.colHeader(idColWidth: String, labelText: String) {
        div {
            withStyle {
                display = "inline-block"
                width = idColWidth
                overflowX = "hidden"
                textOverflow = "ellipsis"
            }
            text(labelText)
        }
    }
}