package dk.rheasoft.csaware.api.systemdependencies

import kotlinx.serialization.Serializable

@Serializable
data class SystemGraphField(
    var id: String,
    var label: String,
    var type: FieldType = FieldType.STRING,
    var cardinality: Cardinality = Cardinality.One,
    var valueSet: String? = null,
    /**
     * DashStyle pattern is for dependency fields, and has the form integer numbers separated with space,
     * alternating stroke length and gap  "4 2"
     */
    var dashStylePattern: String = "",
    var selectedByDefault: Boolean = true,
)