package csaware.systemdepend.config

import csaware.messages.CsawareMessagesObject.csawareMessageStrategy
import csaware.systemdepend.graph.shapes.NodeShapeRegistry
import csaware.systemdepend.graph.shapes.ShapeSelector
import dk.rheasoft.csaware.api.systemdependencies.NodeType
import dk.rheasoft.csaware.api.systemdependencies.NodeType.FieldRef
import dk.rheasoft.csaware.api.systemdependencies.SystemDependencyConfig
import kafffe.bootstrap.form.BootstrapForm
import kafffe.bootstrap.form.dropdown
import kafffe.bootstrap.form.editSelectMultiple
import kafffe.bootstrap.form.textArea
import kafffe.core.Model
import kafffe.core.modifiers.CssClassModifier.Companion.cssClassModifier
import kafffe.core.modifiers.onchange

class NodeTypeEditor(
    model: Model<NodeType>,
    val modelConfig: Model<SystemDependencyConfig>,
    val currentNodeTypes: Model<List<NodeType>>
) : BootstrapForm<NodeType>(model) {

    private fun fieldIdToName(id: String): String =
        modelConfig.data.fields.find { it.id == id }?.label ?: "--"

    private fun fieldNameToId(name: String): String =
        modelConfig.data.fields.find { it.label == name }?.id ?: "--"


    private fun inheritedFields(typeId: String?): Set<FieldRef> {
        val type = currentNodeTypes.data.find { typeId == it.id } ?: return emptySet()
        return type.defaultFields.toSet() + inheritedFields(type.inheritsNodeTypeId)
    }

    init {
        labelStrategy = csawareMessageStrategy("nodetype_")

        // We need some gap because we do not apply whitespace between elements.
        cssClassModifier("hgap-3 vgap-3")

        readonly(NodeType::id)
        input(NodeType::name)
        decorateAndAddComponent(
            Model.of("Shape"),
            ShapeSelector(
                Model.ofGetSet(
                    getter = { NodeShapeRegistry.fromStyleName(model.data.shape) },
                    setter = { shape -> model.data.shape = shape.styleName }
                )
            )
        )
        val potentialInheritFrom: Model<List<String>> = Model.ofGet {
            val potTypes =  listOf(NodeType.NULL) +
                    currentNodeTypes.data.filter { !descendant(it, model.data) }
            potTypes.map { it.id }
        }

        val inherits =  dropdown(
            idInput = "inheritsNodeTypeId",
            labelModel = labelStrategy.label(NodeType::inheritsNodeTypeId.name),
            valueModel = Model.ofGetSet(
                getter = {
                    model.data.inheritsNodeTypeId ?: NodeType.NULL.id
                },
                setter = {
                    model.data.inheritsNodeTypeId = if (it == NodeType.NULL.id) null else it
                }
            ),
            choiceModel =  potentialInheritFrom)

        val inheritFields = readonly(
            "inherited",
            Model.of("Inherited fields"),
            Model.ofGet { inheritedFields(model.data.inheritsNodeTypeId).joinToString(", "){ fieldIdToName(it.fieldId)} }
        )
        inherits.onchange {
            inherits.updateValueModel()
            inheritFields.rerender()
        }

        // fieldselector
        editSelectMultiple(
            idInput = "fields",
            labelModel = labelStrategy.label(NodeType::defaultFields.name),
            valueModel = Model.ofGetSet(
                getter = {
                    model.data.defaultFields.map { fieldIdToName(it.fieldId) }
                },
                setter = {
                    model.data.defaultFields = it.map {
                        FieldRef(fieldNameToId(it))
                    }.toMutableList()
                }
            ),
            choiceModel = Model.ofGet {
                val inheritedFieldsIds = inheritedFields(model.data.inheritsNodeTypeId).map { it.fieldId }.toSet()
                modelConfig.data.fields.filter { it.id !in inheritedFieldsIds  }.map { it.label }
            }
        )
        textArea(NodeType::description)
    }

    /**
     * Check if n1 is decendant from n1
     */
    private fun descendant(n1: NodeType, n2: NodeType): Boolean {
        val parent = currentNodeTypes.data.find { it.id == n1.inheritsNodeTypeId }
        return when {
            n1 == n2 -> true
            n1.inheritsNodeTypeId == null -> false
            parent == null -> false
            else -> descendant(parent, n2)
        }
    }
}