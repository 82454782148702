package csaware.utilities

import dk.rheasoft.csaware.api.Language

import kafffe.bootstrap.form.Dropdown
import kafffe.core.Model

/**
 * Dropdown for Language, translates back and forth enum value to localized display name
 */
class DisplayLanguageDropdown(id: String,
                              valueModel: Model<Language>,
                              choicesModel: Model<List<Language>> = Model.of(choices())
) : Dropdown<Language>(id, valueModel, choicesModel) {

    override fun display(choice: Language): String = choice.displayName
    override fun valueFromString(strValue: String): Language = Language.valueOf(strValue)
    override fun valueToString(value: Language): String = value.toString()

    companion object {
        private fun choices(): List<Language> {
            return Language.entries.minus(Language.UNKNOWN)
        }
    }
}