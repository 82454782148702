package dk.rheasoft.csaware.api.systemdependencies

enum class FieldType {
    STRING,
    DEPENDENCY,
    SECRET,
    IPv4 {
        override fun validate(value: String): Boolean {
            val regex = Regex("^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\$")
            return regex.matches(value)
        }
    },
    IPv6 {
        override fun validate(value: String): Boolean {
            val regex = Regex("^(?:[A-F0-9]{1,4}:){7}[A-F0-9]{1,4}\$", RegexOption.IGNORE_CASE)
            return regex.matches(value)
        }
    },
    CPE {
        override fun validate(value: String): Boolean {
            val cpe23 = Regex("^cpe:2\\.3:[aho](?::(?:[a-zA-Z0-9!\"#\$%&'()*+,\\\\\\-_.\\/;<=>?@\\[\\]^`{|}~]|\\\\:)+){10}\$")
            val cpe22 = Regex("^[c][pP][eE]:/[AHOaho]?(:[A-Za-z0-9\\._\\-~%]*){0,6}\$", RegexOption.IGNORE_CASE)
            return cpe22.matches(value) || cpe23.matches(value)
        }
    },
    URL {
    },
    MARKDOWN;

    open fun validate(value: String): Boolean = true

    companion object {
        fun parse(value: String): FieldType = try {
            valueOf(value)
        } catch (_: Throwable) {
            STRING
        }
    }
}