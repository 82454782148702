package csaware.infoshare

import csaware.main.UserInformation
import csaware.messages.CsawareMessages
import csaware.messages.CsawareMessagesObject.csawareMessageStrategy
import csaware.messages.i18nText
import dk.rheasoft.csaware.api.InformationShare
import dk.rheasoft.csaware.api.ShareState
import dk.rheasoft.csaware.api.access.MainFeature
import dk.rheasoft.csaware.api.access.Permission
import kafffe.bootstrap.BasicColor
import kafffe.bootstrap.ColWidth
import kafffe.bootstrap.ResponsiveSize
import kafffe.bootstrap.form.FormComponentConsumer
import kafffe.bootstrap.form.FormDialog
import kafffe.bootstrap.form.textArea

import kafffe.core.Model
import kafffe.core.modifiers.CssClassModifier
import kafffe.core.modifiers.CssClassModifier.Companion.cssClassModifier
import kafffe.core.modifiers.StyleModifier
import kafffe.messages.MessagesObject.formatDateTime
import kotlinx.datetime.toJSDate

class InformationShareEditDlg(share: InformationShare) :
        FormDialog<InformationShare>(Model.of(share.id), Model.of(share)) {

    init {
        modal.modifiersBody.add(StyleModifier {
            overflowY = "auto"
            maxHeight = "75vh"
        })
        modal.modifiersModal.add(StyleModifier {
            maxWidth = "90vw"
            width = "1000px"
        })
        cssClassModifier("hgap-3 vgap-3")
        labelStrategy = csawareMessageStrategy("informationshare_")
        modal.modifiersContent.add(CssClassModifier("bg-light"))
        treeForm(model.data)
    }

    private fun accept() {
        model.data.state = ShareState.SharingAccepted
        onSubmitOk()
        detach()
    }

    private fun decline() {
        model.data.state = ShareState.SharingDenied
        onSubmitOk()
        detach()
    }

    private fun FormComponentConsumer<InformationShare>.treeForm(infoShare: InformationShare) {

        row {
            col(ColWidth(ResponsiveSize.md, 7)) {
                cssClassModifier("hgap-3 vgap-3")

                textArea(InformationShare::summary).apply {
                    readOnly = true
                    lines = 3
                }.cssClassModifier("csaware-field")
                model.data.data.forEach { shareData ->
                    addChild(InformationShareDataEditor(Model.of(shareData)))
                }
            }
            col(ColWidth(ResponsiveSize.md, 5)) {
                cssClassModifier("hgap-3 vgap-3")
                readonly( i18nText(CsawareMessages::informationshare_create), Model.of(infoShare.created.toJSDate().formatDateTime())).cssClassModifier("csaware-field")
                if (UserInformation.hasAccess(MainFeature.InformationSharing, Permission.Write)) {
                    submit("accept", this@InformationShareEditDlg::accept).apply {
                        color = BasicColor.success
                        iconClasses = "fas fa-thumbs-up"
                    }

                    submit("decline", this@InformationShareEditDlg::decline).apply {
                        color = BasicColor.danger
                        iconClasses = "fas fa-thumbs-down"
                    }
                }
                cancel().color = BasicColor.secondary
            }
        }
    }
}