package csaware.policy

import csaware.main.navigateToComponent
import csaware.messages.CsawareMessagesObject
import csaware.systemdepend.SystemDependencyService
import csaware.utilities.ActionBar
import csaware.utilities.componentTitle
import dk.rheasoft.csaware.api.Policy
import dk.rheasoft.csaware.api.access.MainFeature
import dk.rheasoft.csaware.api.access.Permission
import kafffe.bootstrap.BasicColor
import kafffe.core.*



/**
 * Renders policy in a read opnly view with actions*/
class PolicyTemplateView(
    model: Model<Policy>,
    private val returnTarget: String,
    private val graphService: SystemDependencyService
) : KafffeComponentWithModel<Policy>(model) {
    init {
        setModelChangedRerender()
    }

    private val dataView = addChild(PolicyDataView(model, graphService))
   // private val commentsEditor = addChild(PolicyCommentsEditor(isSavedPolicy = true, model))

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut = div {
        componentTitle {
            add(createActionBar().html)
        }
        div {
            addClass("row")
            div {
                addClass("col-md-8")
                withStyle { maxWidth = "128ch" }
                add(dataView.html)
            }
          /*  div {
                addClass("col-md-4")
                withStyle { maxWidth = "80ch" }
                add(commentsEditor.html)
            }*/
        }
        componentTitle {
            //footer
            add(createActionBar().html)
        }
    }


    private fun createActionBar() =
        ActionBar {
            floatEnd = false
            size = ActionBar.Size.Large
            titleAsText = true
            
            item(Model.of(CsawareMessagesObject.get().policy_template_action_edit), "fa-solid fa-pencil") {
                btnColor = BasicColor.secondary
                accessRequirement = (MainFeature.Policy to Permission.Write)
                action = {
                    navigateToComponent(PolicyEditor.forEdit(model.data, returnTarget, graphService))
                }
            }

        }

}