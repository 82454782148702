package csaware.kpi_statistics

import csaware.threats.Popovers
import kafffe.core.KafffeComponent
import kafffe.core.KafffeHtmlBase
import kafffe.core.KafffeHtmlOut
import kafffe.core.Model

class TitleAndDescription(
    val titleModel: Model<String>,
    val descriptionModel: Model<String>,
    primaryChild: KafffeComponent? = null
) : KafffeComponent() {
    init {
        if (primaryChild != null) {
            addChild(primaryChild)
        }
    }
    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut =
        div {
            addClass("csaware_counter_column_chart")
            h5 {
                withStyle {
                    textAlign = "center"
                }
                text(titleModel.data)
                val popover = Popovers.showInfo("Info", { descriptionModel.data })
                popover.modify(this.element)
            }
            children.forEach { child ->
                add(child.html)
            }
        }

}