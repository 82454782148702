package csaware.utilities

import csaware.main.CsawareServices
import csaware.main.UserInformation
import dk.rheasoft.csaware.api.incident.ThreatGroupWithTranslations
import dk.rheasoft.csaware.api.access.MainFeature
import dk.rheasoft.csaware.api.access.Permission
import dk.rheasoft.csaware.api.config.SystemLevelConfig
import kafffe.core.Model
import kafffe.core.RefreshingCacheModel

/**
 * Service to cache and get different configuration stored in the Backend.
 */
class ConfigurationService {

     val threatGroupsModel: Model<List<ThreatGroupWithTranslations>> =
         RefreshingCacheModel(::refreshThreatGroups, listOf(), timeToLiveSeconds = 60 * 60)

    val systemLevelConfig: Model<SystemLevelConfig> =
        RefreshingCacheModel(::refreshSystemLevelConfig, SystemLevelConfig(), timeToLiveSeconds = 61 * 60)


    private fun refreshThreatGroups(model: Model<List<ThreatGroupWithTranslations>>) {
         if (UserInformation.hasAccess(MainFeature.Threats, Permission.Read)) {
             CsawareServices.threatsBackend.getThreatConfiguredGroups {
                 model.data = it
             }
         } else {
             model.data = emptyList()
         }
    }

    private fun refreshSystemLevelConfig(model: Model<SystemLevelConfig>) {
        if (UserInformation.hasAccess(MainFeature.SystemPreferences, Permission.Read)) {
            CsawareServices.configBackend.getSystemLevelConfig { model.data = it }
        } else {
            model.data = SystemLevelConfig()
        }
    }

    fun refreshAll() {
        refreshThreatGroups(threatGroupsModel)
        refreshSystemLevelConfig(systemLevelConfig)
    }

}