package csaware.kpi_statistics

import csaware.main.CsawareServices
import csaware.threats.Popovers
import dk.rheasoft.csaware.api.statistics.StatisticCounter
import dk.rheasoft.csaware.api.statistics.StatisticEntry
import dk.rheasoft.csaware.api.statistics.StatisticEntryWithAverages
import dk.rheasoft.csaware.utils.JsonUtilSerialization
import dk.rheasoft.csaware.utils.weekNumber
import externals.echarts.BarSeriesOption
import externals.echarts.EChartsOption
import externals.echarts.TooltipOption
import externals.echarts.ZRender
import kafffe.core.KafffeComponent
import kafffe.core.KafffeHtmlBase
import kafffe.core.KafffeHtmlOut
import kafffe.utils.DomObserverUtils
import kotlinx.datetime.Instant
import kotlinx.datetime.format
import kotlinx.datetime.format.DateTimeComponents
import kotlinx.datetime.format.char
import org.w3c.dom.HTMLDivElement
import kotlin.math.ceil
import kotlin.math.log10
import kotlin.math.max

class CounterColumnChart(
    val counter: StatisticCounter,
    val xAxisLegend: String,
    val yAxisLegend: String,
    val maxNofCounts: Int = 10,
) : KafffeComponent() {

    var entries: List<StatisticEntryWithAverages> = emptyList()

    var height: String by rerenderOnChange(if (counter.countType.isPeriod()) "20rem" else "22rem")
    var width: String by rerenderOnChange("40rem")

    init {
        load()
    }

    private fun load() {
        val parameters = mapOf(
            "title" to counter.title,
            "subTitle" to counter.subTitle,
            "countType" to counter.countType.name,
            "offset" to "0",
            "limit" to "$maxNofCounts"
        )
        CsawareServices.backend.getTxt("/statistics/counter/with_average/newest", parameters) {
            entries = JsonUtilSerialization.json.decodeFromString(it)
            rerender()
        }
    }

    private fun calculateNameGap(): Int {
        val maxValue = entries.maxOfOrNull { max(it.value, max(it.average, it.averageEcoSystem)) } ?: 0f
        // Convert max value to string to get number of digits
        val digits = ceil(max(1.0, log10(maxValue + 1.0))).toInt()
        // Adjusted formula: base gap of 25 pixels, add 5 pixels per digit
        val baseGap = 25
        val gapPerDigit = 5
        return baseGap + (digits * gapPerDigit)
    }

    private var myChart: ZRender? = null

    fun chart(chartElement: HTMLDivElement) {
        myChart = externals.echarts.init(chartElement)

        var option: EChartsOption = js(
            """{
                   grid: {
                        left: '10%',  
                        right: '5%',
                        top: '10%',
                        bottom: '15%'
                    },
                   xAxis : {
                      type: 'category',
                       data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                   },
                   yAxis: { 
                      type: 'value',
                      minInterval: 1,
                   },
                   legend: { data: ['this', 'Average'] },
                   series: [
                        {
                            data: [120, 200, 150, 80, 70, 110, 130],
                            type: 'bar'
                        }
                   ]
            }
            """
        ).unsafeCast<EChartsOption>()

        if (xAxisLegend.isNotEmpty()) {
            with(option.xAxis) {
                name = xAxisLegend
                nameLocation = "middle"
                nameGap = "30"
                nameTextStyle = js("{}")
                with(nameTextStyle) {
                    fontSize = 14
                    fontWeight = "bold"
                    color = "#333"
                }
            }
        }
        if (yAxisLegend.isNotEmpty()) {
            with(option.yAxis) {
                name = yAxisLegend
                nameGap = calculateNameGap()
                nameLocation = "middle"
                nameTextStyle = js("{}")
                with(nameTextStyle) {
                    fontSize = 14
                    fontWeight = "bold"
                    color = "#333"
                }
            }
        }

        option.tooltip = js("{}")
        with(option.tooltip as TooltipOption) {
            trigger = "axis"
            axisPointer = js("{}")
            axisPointer!!.type = "shadow"
        }

        val seriesOpts: Array<BarSeriesOption> = (option.series as Array<BarSeriesOption>)
        var ix = 0
        addSeries(seriesOpts, ix++, "My organisation", entries.map { StatisticEntry(it.periodStart, it.value) }, "#FFAC1C")
        addSeries(seriesOpts, ix++, "Average ecosystem", entries.map { StatisticEntry(it.periodStart, it.averageEcoSystem) }, "#32cd32")
        addSeries(seriesOpts, ix++, "Average all", entries.map { StatisticEntry(it.periodStart, it.average) }, "#4AB5E3")
        option.legend.data = seriesOpts.map { it.name }.toTypedArray()

        val periodStarts: List<Instant> = entries.map { it.periodStart }
        option.xAxis.data = when (counter.countType) {
            StatisticCounter.CountType.DAILY -> periodStarts.map { day(it) }.toTypedArray()
            StatisticCounter.CountType.WEEKLY -> periodStarts.map { it.weekNumber() }.toTypedArray()
            StatisticCounter.CountType.MONTHLY -> periodStarts.map {
                console.log("$it -> ${month(it)}")
                month(it)
            }.toTypedArray()

            else -> periodStarts.map { it.toString() }.toTypedArray()
        }

        console.log(JSON.stringify(option))
        myChart?.setOption(option)
    }

    private fun addSeries(
        barSeriesOptions: Array<BarSeriesOption>,
        ix: Int,
        name: String,
        entries1: List<StatisticEntry>,
        colour: String
    ) {
        val average = js("{}") as BarSeriesOption
        average.name = name
        average.type = "bar"
        average.data = entries1.map { it.value }.toTypedArray()
        barSeriesOptions[ix] = average
        barSeriesOptions[ix].itemStyle = js("{}")
        barSeriesOptions[ix].itemStyle!!.color = colour
    }

    private fun day(periodStart: Instant): String =
        periodStart.format(DateTimeComponents.Format { /*year(); char('-');*/ monthNumber(); char('-'); dayOfMonth() })

    private fun month(periodStart: Instant): String =
        periodStart.format(DateTimeComponents.Format { /*year(); char('-');*/ monthNumber() })

    override fun detach() {
        Popovers.removeAll()
        super.detach()
    }

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut =
        div {
            withElement {
                onclick = { toggleSize() }
                style.height = height
                style.width = width
            }
            DomObserverUtils.whenVisibleInViewport(this.element) {
                chart(it)
            }
        }

    private var zoomed = false
    private fun HTMLDivElement.toggleSize() {
        if (zoomed) {
            style.height = height
            style.width = width
            myChart?.resize()
        } else {
            style.height = "80vh"
            style.width = "80vw"
            myChart?.resize()
        }
        zoomed = !zoomed
    }

}