package csaware.policy

import csaware.main.CsawareServices
import csaware.main.UserInformation
import csaware.main.navigateTo
import csaware.main.navigateToComponent
import csaware.messages.CsawareMessages
import csaware.messages.CsawareMessagesObject
import csaware.messages.i18nText
import csaware.systemdepend.SystemDependencyService
import csaware.utilities.ActionBar
import csaware.utilities.OkModalPopUp
import csaware.utilities.componentTitle
import dk.rheasoft.csaware.api.Policy
import dk.rheasoft.csaware.api.PolicyState
import dk.rheasoft.csaware.api.PolicyWithApproval
import dk.rheasoft.csaware.api.access.MainFeature
import dk.rheasoft.csaware.api.access.Permission
import kafffe.bootstrap.*
import kafffe.bootstrap.navigation.NavSimpleContainer
import kafffe.core.*
import kotlinx.browser.window
import kotlinx.datetime.Clock


/**
 * Renders policy in a read opnly view with actions*/
class PolicyView(
    model: Model<Policy>,
    private val returnTarget: String,
    private val graphService: SystemDependencyService
) : KafffeComponentWithModel<Policy>(model) {

    private val tabs = NavSimpleContainer().apply {
        add("Comments", i18nText(CsawareMessages::policy_comment_comment), "fa-regular fa-comment") { PolicyCommentsEditor(isSavedPolicy = true, model) }
        add("History", i18nText(CsawareMessages::policy_history), "fa-solid fa-clock-rotate-left") { historyTable }
    }
    init {
        setModelChangedRerender()
        addChild(tabs)
    }

    private val dataView = addChild(PolicyDataView(model, graphService))
    private val historyTable = PolicyHistoryTable(graphService,10,model.data.id)

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut = div {
        componentTitle {
            add(createActionBar().html)
        }
        div {
            addClass("row")
            div {
                addClass("col-md-8")
                withStyle { maxWidth = "128ch" }
                add(dataView.html)
            }
            div {
                addClass("col-md-4")
                withStyle { maxWidth = "80ch"}
                withStyle { maxHeight = "75vh"}
                withStyle { overflowX = "auto"}
                withStyle { overflowY = "auto"}
                add(tabs.html)
                tabs.navigateTo(NavigationPath.fromString("container/Comments"))
            }
        }
        componentTitle {
            //footer
            add(createActionBar().html)
        }
    }


    private fun createActionBar() =
        ActionBar {
            floatEnd = false
            size = ActionBar.Size.Large
            titleAsText = true
            
            item(Model.of(CsawareMessagesObject.get().policy_action_edit), "fa-solid fa-pencil") {
                btnColor = BasicColor.secondary
                accessRequirement = (MainFeature.Policy to Permission.Write)
                action = {
                    navigateToComponent(PolicyEditor.forEdit(model.data, returnTarget, graphService))
                }
            }
            item(Model.of(CsawareMessagesObject.get().policy_action_clone), "fa-solid fa-clone") {
                btnColor = BasicColor.secondary
                accessRequirement = (MainFeature.Policy to Permission.Create)
                action = {
                    navigateToComponent(PolicyEditor.forClone(model.data, returnTarget, graphService))
                }
            }
            if(model.data.state != PolicyState.APPROVED){
                item(Model.of(CsawareMessagesObject.get().policy_action_approve), "fas fa-signature") {
                    btnColor = BasicColor.success
                    accessRequirement = (MainFeature.Policy to Permission.Write)
                    action = {
                        showApproveDialog(PolicyApproveCommentDialog.approveComment(model.data))
                    }
                }
            }
            item(Model.of(CsawareMessagesObject.get().policy_action_export), "fas fa-file-export") {
                btnColor = BasicColor.secondary
                accessRequirement = (MainFeature.Policy to Permission.Read)
                action = {
                    window.open("/policy/export/${model.data.id}")
                }
            }
        }

    private fun showApproveDialog(dlg: PolicyApproveCommentDialog) {
        dlg.onSubmitOk = {
            if(dlg.model.data.comment.isNotEmpty()) {
                model.data.updatedAt = Clock.System.now()
                model.data.updatedBy = UserInformation.current.email
                CsawareServices.policyBackend.approve(PolicyWithApproval( model.data,dlg.model.data.comment,),this::onApprovedError)
            }
            detach()
            navigateTo(returnTarget)
        }
        dlg.attach()
    }

    private fun onApprovedError(errcode:Int,errText:String){
        OkModalPopUp.confirmOk(Model.of("Error"), Model.of("Policy: ${model.data.policyData.title}, was not approved. "+errText),null)
     }

}