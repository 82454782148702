package csaware.utilities

import csaware.messages.CsawareMessagesObject
import kafffe.core.*
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.dom.addClass
import kotlinx.dom.removeClass
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.asList

class StringSelector(private val valueModel: Model<String>, private val valuesModel: Model<List<String>>, private val magicBlankValue: String) :
    KafffeComponent() {
    private var inputElement: HTMLInputElement? = null
    private var dropdownElement: HTMLDivElement? = null
    private val changeListener = ModelChangeListener {
        if (isActive()) {
            // ignore
        } else {
            rerenderRecursive()
        }
    }

    override fun attach() {
        super.attach()
        valueModel.listeners.add(changeListener)
    }

    override fun detach() {
        valueModel.listeners.remove(changeListener)
        super.detach()
    }

    private fun isActive(): Boolean =
        document.activeElement == inputElement


    override fun KafffeHtmlBase.kafffeHtml() =
        div {
            addClass("btn-group sd_search_bar ms-3")
            withElement {
                title = CsawareMessagesObject.get().system_depend_infoflow
            }
            inputElement = input {
                addClass("sd_search_input form-control")
                withElement {
                    value = modelToValue()
                    accessKey = "i"
                    type = "text"
                    onfocus = {
                        renderMatches()
                        it
                    }
                    onblur = {
                        // delay display none to after click in selection, otherwise the click seems to be lost
                        value = modelToValue()
                        window.setTimeout({ dropdownElement?.style?.display = "none" }, 600)
                        it
                    }
                    oninput = {
                        renderMatches()
                    }
                    onkeydown = {
                        when (it.key) {
                            "Escape" -> {
                                inputElement?.apply {
                                    value = ""
                                    valueModel.data = magicBlankValue
                                    blur()
                                }
                            }
                            "Enter" -> {
                                if (selectIndex >= 0) {
                                    value = modelToValue()
                                } else {
                                    valueModel.data =
                                        value.ifBlank { magicBlankValue }
                                }
                                inputElement?.blur()
                            }
                            "ArrowDown" -> selectNext()
                            "ArrowUp" -> selectPrev()
                            "ArrowRight" -> selectNext()
                            "ArrowLeft" -> selectPrev()
                        }
                        it
                    }
                }
            }.element
            button {
                onClick {
                    inputElement?.focus()
                }
                addClass("sd_search_icon btn")
                i {
                    addClass("fas fa-diagram-project")
                }
            }
            dropdownElement = div {
                addClass("sd_dropdown bg-light")
            }.element
        }

    private fun modelToValue() = if (valueModel.data == magicBlankValue) "" else valueModel.data

    private var selectIndex: Int = -1
    fun selectNext() {
        if (matches().size >= selectIndex + 1) {
            ++selectIndex
        }
        select(selectIndex)
    }

    private fun selectPrev() {
        if (selectIndex > 0) {
            --selectIndex
        }
        select(selectIndex)
    }

    fun select(index: Int) {
        val matches = matches()
        if (index in matches.indices) {
            valueModel.data = matches[index]
            // set and remove "sd_selected" class
            dropdownElement?.children?.asList()?.forEachIndexed { i, element ->
                if (i == index) {
                    element.addClass("sd_selected")
                } else {
                    element.removeClass("sd_selected")
                }
            }
        }
    }

    fun matches(): List<String> {
        val txt = inputElement?.value ?: ""
        return if (txt.isNotEmpty()) {
            valuesModel.data.filter { it.contains(txt, ignoreCase = true) }
        } else {
            valuesModel.data
        }
    }


    private fun renderMatches() {
        selectIndex = -1
        dropdownElement?.let {dropdownElement ->
            dropdownElement.innerHTML = ""
            val htmlConsumer = KafffeHtml(dropdownElement)
            val matches = matches()

            for (sdr in matches) {
                val item =
                    htmlConsumer.span {
                        addClass("badge badge-multi-value m-1")
                        if (sdr == valueModel.data) {
                            addClass("csaware-highlight-border")
                            i { addClass("fas fa-check csaware-highlight-color") }
                        }
                        element.onclick = {
                            valueModel.data =
                                if (sdr == valueModel.data) magicBlankValue else sdr; it.preventDefault()
                            inputElement?.apply {
                                value = modelToValue()
                                blur()
                            }
                            it
                        }
                        addHighlightMatch(inputElement!!.value, sdr)
                    }
            }
            dropdownElement.style.display = if (matches.isEmpty()) "none" else "block"
        }
    }

    private fun KafffeHtml<out HTMLElement>.addHighlightMatch(tobeMatched: String, txt: String) {
        if (txt.contains(tobeMatched, ignoreCase = true)) {
            val startIx = txt.indexOf(tobeMatched, ignoreCase = true)
            text(txt.substring(0, startIx))
            span {
                addClass("bg-success")
                text(txt.substring(startIx, startIx + tobeMatched.length))
            }
            text(txt.substring(startIx + tobeMatched.length))
        } else {
            text(txt)
        }
    }

}