package dk.rheasoft.csaware.api.policy

import dk.rheasoft.csaware.api.config.SystemLevelConfig
import kotlinx.serialization.Serializable

private const val MultiLanguageText_FallbackKey = ""

/**
 * iso2 languageCode to text
 */
@Serializable
class MultiLanguageText(val map: MutableMap<String, String> = mutableMapOf()) : MutableMap<String, String> by map {
    /**
     * gets the text for iso code with fallback
     */
    fun text(iso2code: String): String =
        get(iso2code) ?: get(MultiLanguageText_FallbackKey) ?: ""

    /**
     * sets the fallback text (default)
     */
    fun setFallBackText(text: String) {
        setText(MultiLanguageText_FallbackKey, text)
    }

    /**
     * sets the text for iso code
     */
    fun setText(iso2code: String, text: String) {
        set(iso2code, text)
    }
}

/**
 * A type of relation, with display name in supported langugages
 */
@Serializable
data class PolicyRelationType(
    /**
     * The id of the relation type, used on policy relations to identity the type
     */
    var id: String,
    /**
     * The display name of the type, to support multi language, the id will be a fallback if name is not defined for asked language
     */
    var name: MultiLanguageText,
    /**
     * The id of the correspond reverted relation type, if relations of this type can be considered 2-way,
     * like part_of-composed_of or parent-child
     */
    var revertedRelationId: String? = null
) {
    init {
        name.setFallBackText(id)
    }
}

/**
 * A list of possible policy relation types with display texts.
 */
@Serializable
data class PolicyRelationTypes(var types: MutableList<PolicyRelationType> = mutableListOf())


private const val POLICY_RELATION_TYPES = "policy.relation_types"

fun SystemLevelConfig.policyRelationTypes(): PolicyRelationTypes =
    getTyped(POLICY_RELATION_TYPES, PolicyRelationTypes())

fun SystemLevelConfig.setPolicyRelationTypes(value: PolicyRelationTypes) {
    setTyped(POLICY_RELATION_TYPES, value)
}

fun SystemLevelConfig.subsetPolicyRelationTypes(): SystemLevelConfig =
    this.subset(setOf(POLICY_RELATION_TYPES))
