package csaware.infoshare

import csaware.comm.CSAwareBackend
import csaware.main.CsawareServices
import csaware.main.updateInfoCount
import csaware.messages.*
import csaware.threats.Popovers
import csaware.translation.StandardTranslation
import csaware.utilities.componentTitle
import dk.rheasoft.csaware.api.InformationShare
import dk.rheasoft.csaware.api.QueryResult
import dk.rheasoft.csaware.api.UpdateEvent
import kafffe.bootstrap.BootstrapTable
import kafffe.bootstrap.BootstrapTableStyles
import kafffe.bootstrap.pagination.BootstrapPagination
import kafffe.bootstrap.pagination.Pager
import kafffe.core.KafffeComponent
import kafffe.core.KafffeHtmlBase
import kafffe.core.Label
import kafffe.core.modifiers.CssClassModifier
import kafffe.messages.MessagesObject.formatDateTime
import kotlinx.datetime.toJSDate
import org.w3c.dom.HTMLTableRowElement
import kotlin.math.ceil

class InformationShareView : KafffeComponent() {
    private val titleLabel = addChild(Label(i18nText(CsawareMessages::informationshare_management)))

    private val table = addChild(BootstrapTable.create<InformationShare>(listOf()) {
        rowClickHandler = this@InformationShareView::showInformationShareDlg

        addStyle(BootstrapTableStyles.striped)
        modifiers.add(CssClassModifier("csaware-hover"))
        modifiersHeader.add(CssClassModifier("bg-primary"))

        col(i18nText(CsawareMessages::id), { Label(it.id) })
        col(i18nText(CsawareMessages::informationshare_create), { Label(it.created.toJSDate().formatDateTime()) })
        col(i18nText(CsawareMessages::informationshare_modified), { Label(it.modified.toJSDate().formatDateTime()) })
        col(i18nText(CsawareMessages::informationshare_state), { Label(CsawareMessagesObject.get().shareState(it.state)) })

        col(i18nText(CsawareMessages::description), {
            val label = Label(it.summary)
            StandardTranslation.translate(it.summary) { translation ->
                label.text = translation
            }
            label
        })
    })

    private var pageSize: Int = 10

    private val pager = Pager(1)
    private val paginator = addChild(BootstrapPagination(pager).apply {
        prevNextPage = true
        modifiers.add(CssClassModifier("float-end"))
    })

    private val onServerUpdate: (UpdateEvent) -> Unit = { event: UpdateEvent ->
        if (event.type == UpdateEvent.EntityType.InformationShare) {
            loadData()
        }
    }

    override fun attach() {
        super.attach()
        CSAwareBackend.updateListeners.add(onServerUpdate)
    }

    override fun detach() {
        CSAwareBackend.updateListeners.remove(onServerUpdate)
        super.detach()
    }

    init {
        pager.changeListeners.add { loadData() }
        loadData()
    }

    private fun loadData() {
        CsawareServices.alerts.clearAlerts()
        val offset = pageSize * (pager.currentPage - 1)
        CsawareServices.infoShareBackend.getInformationShares(offset, pageSize, this::receiveData)
    }

    private fun receiveData(response: QueryResult<InformationShare>) {
        updateInfoCount(response.nofResult)

        val pageCount = ceil(response.nofResult.toDouble() / pageSize.toDouble()).toInt()
        if (pager.nofPages != pageCount) {
            pager.nofPages = pageCount
        }
        table.data = response.result
    }

    private fun showInformationShareDlg(share: InformationShare, rowElement: HTMLTableRowElement) {
        InformationShareEditDlg(share).apply {
            onSubmitOk = {
                CsawareServices.alerts.clearAlerts()
                CsawareServices.infoShareBackend.updateInformationShare(model.data) {
                    loadData()
                    rerenderRecursive()
                }
            }
            attach()
        }
    }

    override fun KafffeHtmlBase.kafffeHtml() =
        div {
            componentTitle {
                add(titleLabel.html)
                val popover = Popovers.showInfo(i18nText(CsawareMessages::informationshare_management).data)
                { i18nText(CsawareMessages::informationshare_short_description).data }
                popover.modify(this.element)
            }
            add(table.html)
            add(paginator.html)
        }

}