package dk.rheasoft.csaware.api.policy

import dk.rheasoft.csaware.api.PolicyState
import dk.rheasoft.csaware.api.PolicyType

data class PolicyFilter(
    var filter: String = "",
    var nodes: List<String> = emptyList(),
    var tags: List<String> = emptyList(),
    var states: List<PolicyState> = emptyList(),
    var policyType: PolicyType = PolicyType.ORGANISATION,
) {
    fun isEmpty() = filter.isEmpty()
            && nodes.isEmpty()
            && tags.isEmpty()
            && states.isEmpty()

}