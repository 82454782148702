package dk.rheasoft.csaware.utils

import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.decodeFromJsonElement
import kotlinx.serialization.modules.SerializersModule

object JsonUtilSerialization {

    val json: Json = Json {
        prettyPrint = true
        ignoreUnknownKeys = true
        coerceInputValues = true
        isLenient = true
        serializersModule = SerializersModule {
        }
    }

    inline fun <reified T> fromJson(jsonStr: String): T  = json.decodeFromString(jsonStr)
    inline fun <reified T> fromJson(jsonElm: JsonElement) : T = json.decodeFromJsonElement(jsonElm)


}
