package dk.rheasoft.csaware.test

import dk.rheasoft.csaware.api.incident.BcdrSelfHealingState
import dk.rheasoft.csaware.api.incident.CourseOfActionWithType
import kotlinx.serialization.Serializable

@Serializable
data class BcdrTestUpdateRequest(
    var threatId: String,
    var bcdrState: BcdrSelfHealingState,
    var courseOfActions: List<CourseOfActionWithType>,
//    var thirdParties: List<CourseOfAction>,
)
