package dk.rheasoft.csaware.json.adapter.base

/**
 * Generic adapter to access to JSON Object
 */
interface JsonObjectAdapter : JsonAdapter {

    fun propertyNames() : Iterable<String>

    fun hasProperty(property : String) : Boolean =
        getType(property) != JsonElementType.NULL

    fun getType(property : String) : JsonElementType

    fun getObject(property : String) : JsonObjectAdapter
    fun setObject(property : String, value: JsonObjectAdapter)

    fun getArray(property : String) : JsonArrayAdapter
    fun setArray(property : String, value: JsonArrayAdapter)

    fun getString(property : String) : String?
    fun setString(property : String, value : String)

    fun getNumber(property : String) : Number?
    fun setNumber(property : String, value : Number)

    fun getBoolean(property : String) : Boolean?
    fun setBoolean(property : String, value: Boolean)

}