package dk.rheasoft.csaware.api.systemdependencies

import dk.rheasoft.csaware.json.adapter.DatrixInstantSerializer
import dk.rheasoft.csaware.utils.JsonUtilSerialization
import dk.rheasoft.csaware.utils.SystemDependencyResourceSerializer
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.decodeFromJsonElement

/**
 * Holds data of a resource from Graphing "Wiki".
 */
@Serializable(with = SystemDependencyResourceSerializer::class)
data class SystemDependencyResource(
    var id: String,
    var name: String,
    /*** node type id */
    var x_csaware_node_type: String,
    @Serializable(with = DatrixInstantSerializer::class)
    var created: Instant,
    @Serializable(with = DatrixInstantSerializer::class)
    var modified: Instant,
    var description: String,
    var source: MutableList<String> = mutableListOf(),
    @Suppress("PropertyName") var x_infoflow: MutableList<String> = mutableListOf(),
    /** single value fields */
    var data: MutableMap<String, String> = mutableMapOf(),
    /** multi value fields */
    var dataLists: MutableMap<String, MutableList<String>> = mutableMapOf()
) {

    fun hasId() = id.isNotEmpty() && id != NULL.id

    companion object {
        const val KEYWORDS_ATTRIBUTE = "x_categories"
        const val CRITICAL_ASSETS_ATTRIBUTE = "x_critical_assets_ids"

        val NULL = SystemDependencyResource(
            id = "----",
            name = "",
            x_csaware_node_type = "null",
            created = Clock.System.now(),
            modified = Clock.System.now(),
            description = ""
        )
        val definedPropertyNames = setOf(
            SystemDependencyResource::id.name,
            SystemDependencyResource::name.name,
            SystemDependencyResource::created.name,
            SystemDependencyResource::modified.name,
            SystemDependencyResource::description.name,
            SystemDependencyResource::source.name,
            SystemDependencyResource::x_infoflow.name,
            SystemDependencyResource::x_csaware_node_type.name,
            SystemDependencyResource::data.name,
            SystemDependencyResource::dataLists.name,
            "type", "relationship_type"
        )

        fun fromJson(json: JsonObject): SystemDependencyResource =
            JsonUtilSerialization.json.decodeFromJsonElement(json)

        fun fromJson(json: String): SystemDependencyResource =
            JsonUtilSerialization.json.decodeFromString(json)

        fun fromListJson(json: String): List<SystemDependencyResource> =
            JsonUtilSerialization.json.decodeFromString(json)
    }

    val keywords: Set<String>
        get() = dataLists[KEYWORDS_ATTRIBUTE]?.toSet() ?: emptySet()

    var criticalAssetIds: Set<String>
        get() = dataLists[CRITICAL_ASSETS_ATTRIBUTE]?.toSet() ?: emptySet()
        set(value) {
            dataLists[CRITICAL_ASSETS_ATTRIBUTE] = value.toMutableList()
        }

    fun getFieldSingleValue(fieldId: String) : String? = data[fieldId]
    fun getFieldMultipleValueField(fieldId: String) : List<String> =
        if (fieldId == SystemDependencyConfig.sourceField.id) {
            source.toList()
        } else {
            dataLists[fieldId] ?: listOf()
        }

    fun allFieldValues() = data.values + dataLists.values.flatten() + x_infoflow

    /**
     * Checks if the given field has a value in this
     */
    fun hasValue(field: SystemGraphField) =
        if (field.cardinality.isSingle) {
            !getFieldSingleValue(field.id).isNullOrBlank()
        } else {
            getFieldMultipleValueField(field.id).isNotEmpty()
        }

    fun toJsonString(): String =
        JsonUtilSerialization.json.encodeToString(this)

}

@Serializable
data class SystemDependencyResourceWithSourceInOthers(
    val systemDependencyResource: SystemDependencyResource,
    val sourceInOtherResourceIds: Set<String>
) {
    fun toJsonString(): String =
        JsonUtilSerialization.json.encodeToString(this)

    companion object {
        fun fromJson(json: String): SystemDependencyResourceWithSourceInOthers =
            JsonUtilSerialization.json.decodeFromString(json)
    }
}