package csaware.threats

import csaware.comm.ThreatFilter
import csaware.main.CsawareServices
import csaware.main.UserInformation
import csaware.messages.CsawareMessagesObject
import csaware.systemdepend.SystemDependencyService
import dk.rheasoft.csaware.api.incident.ThreatObservation
import kafffe.core.Model
import kafffe.core.property

/**
 * Helper class for threat filtering used in tables.
 */
class ThreatFilterData(
    private val graphService: SystemDependencyService
) : ThreatFilter {
    val all: String = CsawareMessagesObject.get().threat_group_all
    private val nobody: String = CsawareMessagesObject.get().threat_nobody

    var group: String = all
    val groupChoices: Model<List<String>> = Model.of(listOf(all, "DDOS"))

    private var assignee: String = all
    val assigneeChoices: Model<List<String>> = Model.ofGet {
        val users = mutableListOf(all, nobody)
        users.addAll(UserInformation.threatManagers.data.map { it.email }.sorted())
        users.remove(UserInformation.current.email)
        users.add(2, UserInformation.current.email)
        users.toList()
    }

    private val whereName2Id: Map<String, String>
        get() =
            graphService.model.data.associate { it.name to it.id }

    var where: String = all
    val whereChoices: Model<List<String>> = Model.ofGet {
        val wheres = mutableListOf(all)
        wheres.addAll(whereName2Id.keys.sorted())
        wheres.toList()
    }

    val asModel: Model<ThreatFilterData> = Model.of(this)
    val groupModel = asModel.property(ThreatFilterData::group)
    val assigneeModel = asModel.property(ThreatFilterData::assignee)
    val whereModel = asModel.property(ThreatFilterData::where)

    override fun groupSearchValue() = when (group) {
        all -> ""
        else -> group
    }

    override fun assigneeSearchValue() = when (assignee) {
        all -> ""
        nobody -> ThreatObservation.nobodyFilter
        else -> assignee
    }

    override fun whereSearchValue() = when (where) {
        all -> ""
        nobody -> ThreatObservation.nobodyFilter
        else -> whereName2Id[where] ?: ""
    }

    override fun isFilter() =
        groupSearchValue().isNotBlank() || assigneeSearchValue().isNotBlank() || whereSearchValue().isNotBlank()

    private fun loadThreatTypes() {
        CsawareServices.threatsBackend.getThreatGroups {
            val choices = mutableListOf(all)
            choices.addAll(it.toList().sorted())
            groupChoices.data = choices.toList()
        }
    }

    init {
        loadThreatTypes()
    }
}