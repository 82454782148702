package csaware.systemdepend.config

import csaware.messages.CsawareMessagesObject.csawareMessageStrategy
import dk.rheasoft.csaware.api.systemdependencies.GraphView
import dk.rheasoft.csaware.api.systemdependencies.NodeType
import dk.rheasoft.csaware.api.systemdependencies.SystemDependencyConfig
import kafffe.bootstrap.form.BootstrapForm
import kafffe.bootstrap.form.checkbox
import kafffe.bootstrap.form.textArea
import kafffe.core.KafffeComponent
import kafffe.core.KafffeHtml
import kafffe.core.Model
import kafffe.core.modifiers.CssClassModifier.Companion.cssClassModifier
import kafffe.core.modifiers.StyleModifier.Companion.styleModifier
import kafffe.core.property
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement

class GraphViewEditor(
    model: Model<GraphView>,
    val modelConfig: Model<SystemDependencyConfig>
) : BootstrapForm<GraphView>(model) {


    private val typesList : KafffeComponent = ofKafffeHtml {
        val byInherits: Map<String?, List<NodeType>> = modelConfig.data.nodeTypes.groupBy { it.inheritsNodeTypeId }
        div {
            addListLevel(byInherits, null, 0)
        }
    }

    private val dependencyList : KafffeComponent = ofKafffeHtml {
        div {
            addDependcyList()
        }
    }

    init {
        labelStrategy = csawareMessageStrategy("graphview_")

        // We need some gap because we do not apply whitespace between elements.
        cssClassModifier("hgap-3 vgap-3")
        styleModifier {
            width = "30rem"
        }

        input(GraphView::name)
        textArea(GraphView::description)

        decorateAndAddComponent(labelStrategy.label(GraphView::dependencyFields.name), dependencyList)
        group {
            cssClassModifier("hgap-3 vgap-3 pt-3")
            checkbox(GraphView::includeAllTypes)
            decorateAndAddComponent(labelStrategy.label(GraphView::includedTypes.name), typesList)
        }
    }

    private fun KafffeHtml<HTMLDivElement>.addDependcyList() {
        val tagWidth = "20rem"
        modelConfig.data.dependencyFields().forEach { field ->
            div {
                addClass("input-group")
                div {
                    addClass("form-control list-group-item")
                    withElement {
                        style.width = tagWidth
                        style.maxWidth = tagWidth
                        style.paddingLeft = "0.5rem"
                        setAttribute("readOnly", "true")
                    }
                    text(field.label)
                }
                span {
                    addClass("input-group-append")
                    checkButton(
                        field.id,
                        model.property(GraphView::dependencyFields),
                        "fa-check",
                        Model.of("Include dependency")
                    ) {
                        dependencyList.rerender()
                    }
                }
            }
        }
    }

    private fun KafffeHtml<HTMLDivElement>.addListLevel(byInherits: Map<String?, List<NodeType>>, inheritsId: String?, level: Int) {
        val current = byInherits[inheritsId] ?: emptyList()
        val tagWidth = "20rem"
        current.forEach { nodeType ->
            div {
                addClass("input-group")
                div {
                    addClass("form-control list-group-item")
                    withElement {
                        style.width = tagWidth
                        style.maxWidth = tagWidth
                        style.paddingLeft = "${level + 0.5}rem"
                        setAttribute("readOnly", "true")
                    }
                    if (level > 0) text("↳ ")
                    text(nodeType.id)

                }
                span {
                    addClass("input-group-append")
                    checkButton(
                        nodeType.id,
                        model.property(GraphView::includedTypesWithDescendants),
                        "fa-check-double",
                        Model.of("Include with descendants")
                    ) {
                        typesList.rerender()
                    }
                    checkButton(
                        nodeType.id,
                        model.property(GraphView::includedTypes),
                        "fa-check",
                        Model.of("Include type")
                    ){
                        typesList.rerender()
                    }
                }
            }
            addListLevel(byInherits, nodeType.id, level + 1)

        }
    }

    private fun KafffeHtml<HTMLElement>.checkButton(
        id: String,
        nodeIdList: Model<List<String>>,
        checkIcon: String,
        titleModel: Model<String>,
        updated: () -> Unit
    ) {
        button {
            addClass("btn btn-outline-secondary")
            withElement {
                type = "button"
                title = titleModel.data
                onclick = {
                    if (id in nodeIdList.data) {
                        nodeIdList.data = nodeIdList.data - id
                    } else {
                        nodeIdList.data = nodeIdList.data + id
                    }
                    updated()
                    it
                }
            }
            val check = if (id in nodeIdList.data) checkIcon else ""
            i {
                addClass("fas $check fa-fw")
            }
        }
    }
}