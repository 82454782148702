package csaware.kpi_statistics

import csaware.main.CsawareServices
import csaware.messages.CsawareMessagesObject
import dk.rheasoft.csaware.api.statistics.NamedCount
import dk.rheasoft.csaware.api.statistics.NamedCounts
import dk.rheasoft.csaware.utils.JsonUtilSerialization
import kafffe.core.KafffeComponent
import kafffe.core.KafffeHtml
import kafffe.core.KafffeHtmlBase
import kafffe.core.KafffeHtmlOut
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement

class StatisticsView : KafffeComponent() {

    var statistics: NamedCounts? by rerenderOnChange(null)

    init {
        load()
    }

    private fun load() {
        CsawareServices.backend.getTxt("/statistics/current") {
            statistics = JsonUtilSerialization.json.decodeFromString(it)
        }
    }

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut =
        div {
            addClass("pb-4")
            h2 {
                text("Statistics")
            }

            val map: Map<String, Map<String, NamedCount>> = statistics?.groupByNameAndSubName() ?: emptyMap()

            frontend(map)

            policies(map)
            systemGraph(map)
            incident(map)
        }

    private fun KafffeHtml<HTMLDivElement>.frontend(map: Map<String, Map<String, NamedCount>>) {
        fieldset {
            addClass("border p-2 m-2")
            sectionTitle("CS-Aware Frontend")
            count("User logins",map["frontend"]?.get("AUTHENTICATE")?.count)
        }
    }

    private fun KafffeHtml<HTMLDivElement>.policies(map: Map<String, Map<String, NamedCount>>) {
        fieldset {
            addClass("border p-2 m-2")
            sectionTitle(CsawareMessagesObject.get().policy)
            val counts: Map<String, NamedCount>? = map["policy"]
            count("Approved", counts?.get("DRAFT")?.count)
            count("Drafts", counts?.get("APPROVED")?.count)
            count("Total", counts?.get("")?.count)
            count("Changes", map["policy"]?.get("STORE")?.count)
            count("Shared with ecosystem", counts?.get("AccessLevel2")?.count)
            count("Shared with public", counts?.get("AccessLevel3")?.count)

        }
    }

    private fun KafffeHtml<HTMLDivElement>.systemGraph(map: Map<String, Map<String, NamedCount>>) {
        fieldset {
            addClass("border p-2 m-2")
            sectionTitle(CsawareMessagesObject.get().system_dependencies)
            val counts: Map<String, NamedCount>? = map["system_graph"]
            count("Nodes", counts?.get("nodes")?.count)
            count("Standard dependencies", counts?.get("source_dependencies")?.count)
            count("Properties", counts?.get("properties")?.count)
            count("Changes", map["systemdependencynode"]?.get("CHANGE")?.count)
            count("Shared with ecosystem", counts?.get("AccessLevel2")?.count)
            count("Shared with public", counts?.get("AccessLevel3")?.count)

        }
    }

    private fun KafffeHtml<HTMLDivElement>.incident(map: Map<String, Map<String, NamedCount>>) {
        fieldset {
            addClass("border p-2 m-2")
            sectionTitle(CsawareMessagesObject.get().nav_threats)
            val counts: Map<String, NamedCount>? = map["incident"]
            val anomalyCounts: Map<String, NamedCount>? = map["anomaly"]
            count("Incidents", counts?.get("")?.count)
            count("Changes", map["threatobservation"]?.get("CHANGE")?.count)
            count("Anomalies", anomalyCounts?.get("")?.count)
            count("Unhandled", anomalyCounts?.get("Todo")?.count)
            count("Ignored", anomalyCounts?.get("Ignore")?.count)
            count("Shared with ecosystem", counts?.get("AccessLevel2")?.count)
            count("Shared with public", counts?.get("AccessLevel3")?.count)
        }
    }

    private fun <T : HTMLElement> KafffeHtml<T>.sectionTitle(titleText: String) {
        legend {
            addClass("float-none w-auto px-3")
            text(titleText)
        }
    }

    private fun <T : HTMLElement> KafffeHtml<T>.count(label: String, count: Int?) {
        div {
            span {
                element.style.apply {
                    width = "14rem"
                    display = "inline-block"
                    fontWeight = "700"
                }
                text(label)
            }
            text(count?.toString() ?: "--")
        }
    }

}