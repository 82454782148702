package csaware.threats

import csaware.main.UserConfiguration
import csaware.main.navigateTo
import csaware.messages.CsawareMessages
import csaware.messages.CsawareMessagesObject
import csaware.messages.i18nText
import csaware.messages.threatState
import csaware.utilities.markdown.MarkdownRender
import dk.rheasoft.csaware.api.incident.ThreatObservation
import kafffe.bootstrap.BootstrapTable
import kafffe.core.*
import kafffe.core.modifiers.CssClassModifier
import kafffe.messages.MessagesObject.formatDateTime
import kotlinx.datetime.toJSDate

class ThreatReadonlyView(model: Model<ThreatObservation>): KafffeComponentWithModel<ThreatObservation>(model) {
    init {
        setModelChangedRerender()
    }

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut = div {
        if (model.data.id.isNotEmpty()) {
            with(model.data) {
                div {
                    element.style.backgroundColor = UserConfiguration.default.severityColorMap[model.data.severity ?: 0]
                    add(ThreatStateSymbol(model, includeSeverityText = true).html)
                }
                h1 {
                    text(name)
                }

                add(MarkdownRender(description).html.apply { style.backgroundColor = "inherit" })

                // history
                if (model.data.stateHistory.isNotEmpty()) {
                    val table = BootstrapTable.create(model.data.stateHistory) {
                        h1 { text(CsawareMessagesObject.get().threat_state_history) }
                        applyDefaultStyle()
                        modifiers.add(CssClassModifier("csaware-field"))
                        col(i18nText(CsawareMessages::timestamp), { Label(it.time.toJSDate().formatDateTime()) })
                        col(i18nText(CsawareMessages::threat_state), { Label(CsawareMessagesObject.get().threatState(it.state)) })
                        col(i18nText(CsawareMessages::threat_state_initator), { Label(it.initiator) })
                        col(i18nText(CsawareMessages::threat_state_comment), { Label(it.description).preformatted() })
                    }
                    add(table.html)
                }

                // cource of actions
                if (model.data.courseOfActions.isNotEmpty()) {
                    h1 { text(CsawareMessagesObject.get().threat_course_of_action) }
                    val table = BootstrapTable.create(model.data.courseOfActions) {
                        applyDefaultStyle()
                        modifiers.add(CssClassModifier("csaware-field"))
                        col(i18nText(CsawareMessages::name), { Label(it.name) })
                        col(i18nText(CsawareMessages::description), { Label(it.description).preformatted() })
                        col(i18nText(CsawareMessages::action), { Label(it.action) })
                    }
                    add(table.html)
                }
            }
        }
    }

    fun onclick(name: String) {
        navigateTo("root/systemDependencies/$name")
    }
}