package csaware.anomalies

import dk.rheasoft.csaware.api.incident.AnomalyWrapper
import kafffe.core.Model

/**
 * Holds data regarding an anomaly dragged in UI
 */
class AnomalyDragHolder {
    /**
     * The list the anomaly is dragged from
     */
    var source: Model<List<AnomalyWrapper>> = Model.of(emptyList())

    /**
     * Dragged anomaly
     */
    var draggedObject : AnomalyWrapper? = null

    /**
     * Move this drag to another list model
     */
    fun moveTo(target: Model<List<AnomalyWrapper>>) {
        draggedObject?.let { anomaly: AnomalyWrapper ->
            if (source !== target && draggedObject !in target.data && draggedObject in source.data) {
                source.data = (source.data - anomaly)
                target.data = (target.data + anomaly)
            }
        }
    }
}