package dk.rheasoft.csaware.api.statistics

import kotlinx.serialization.Serializable

@Serializable
data class NamedCounts(val counts: List<NamedCount>) {

    fun groupByName(): Map<String, List<NamedCount>> =
        counts.groupBy{ it.name }

    fun groupByNameAndSubName(): Map<String, Map<String, NamedCount>> =
        groupByName().mapValues { (_, v: List<NamedCount>) -> v.associateBy { it.subName }}

}