package csaware.kpi_statistics

import bootstrap.Collapse
import dk.rheasoft.csaware.api.statistics.StatisticCounter
import kafffe.core.KafffeComponent
import kafffe.core.KafffeHtmlBase
import kafffe.core.KafffeHtmlOut
import kafffe.core.Model
import kotlinx.dom.addClass
import kotlinx.dom.removeClass
import org.w3c.dom.HTMLButtonElement
import org.w3c.dom.HTMLDivElement

open class StatisticSectionView(
    private val titleModel: Model<String>,
    counterDefs: List<StatisticCounterDef>,
    val descriptionModel: Model<String>,
) : KafffeComponent() {

    data class StatisticCounterDef(
        val title: Model<String>,
        val description: Model<String>,
        val counter: StatisticCounter,
        val xAxisLegend: Model<String>,
        val yAxisLegend: Model<String>
    )

    private var collapsed = false
    fun toggleCollapsed() {
        collapsed = !collapsed
    }

    private val counters: List<KafffeComponent> = counterDefs.map { cDef ->
        val comp: KafffeComponent =
        if (cDef.counter.countType.isPeriod()) {
            CounterPeriodsChart(cDef)
        } else {
            CounterColumnChart(
                counter = cDef.counter,
                xAxisLegend = cDef.xAxisLegend.data,
                yAxisLegend = cDef.yAxisLegend.data,
                maxNofCounts = 1
            )
        }
        addChild(TitleAndDescription(cDef.title, cDef.description , comp))
    }

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut {
        lateinit var btn: HTMLButtonElement
        lateinit var section: HTMLDivElement
        return div {
            addClass("accordion-item")
            h2 {
                addClass("accordion-header")
                button {
                    addClass("accordion-button")
                    withElement {
                        type = "button"
                        btn = this
                        if (!collapsed) btn.removeClass("collapsed") else btn.addClass("collapsed")
                    }
                    text(titleModel.data)
                }
            }
            div {
                addClass("accordion-collapse accordion-body")
                addClass(if (collapsed) "collapsed" else "show")
                p {
                    withStyle {
                        whiteSpace = "pre-wrap"
                        overflowWrap = "break-word"
                    }
                    text(descriptionModel.data)
                }
                section = this.element
                for (child in children) {
                    add(child.html)
                }
            }
        val collapse = Collapse(section)
        btn.onclick = {
            toggleCollapsed()
            collapse.toggle()
            if (!collapsed) {
                btn.removeClass("collapsed")
            } else {
                btn.addClass("collapsed")
            }
        }
     }
    }

}