package dk.rheasoft.csaware

import kotlinx.serialization.Serializable

@Serializable
data class EcoSystemWithMembers(
    val id: String,
    val name: String,
    val members: List<Organisation>
)

@Serializable
data class Organisation(
    val id: String,
    val name: String
)