package csaware.systemdepend

import csaware.main.UserInformation
import csaware.messages.CsawareMessages
import csaware.messages.CsawareMessagesObject
import csaware.messages.i18nText
import csaware.threats.ThreatCreateDlg
import csaware.systemdepend.backup.BackupDialog
import csaware.systemdepend.config.SystemResourceConfigChangeDlg
import csaware.systemdepend.nodedetails.SystemResourceChangeDlg
import csaware.utilities.FileImportDlg
import csaware.utilities.UUID.generateUuid
import dk.rheasoft.csaware.api.access.MainFeature
import dk.rheasoft.csaware.api.access.Permission
import dk.rheasoft.csaware.api.incident.ThreatObservation
import dk.rheasoft.csaware.api.systemdependencies.SystemDependencyResource
import kafffe.bootstrap.Modal
import kafffe.core.Model
import kotlinx.browser.window
import kotlinx.datetime.Clock
import kotlin.time.Duration.Companion.days

@Suppress("EnumEntryName")
enum class UiFunctions(
    val singleResource: Boolean,
    val label: String,
    val iconCls: String,
    val feature: MainFeature,
    val permission: Permission
) {
    createThreat(
        true,
        CsawareMessagesObject.get().socialmedia_create_threatobservation,
        "fas fa-exclamation-triangle",
        MainFeature.Threats,
        Permission.Write
    ) {
        override fun doIt(
            currentResource: SystemDependencyResource,
            selectedModel: Model<SystemDependencyResource>,
            graphService: SystemDependencyService
        ) {
            val newThreatId = generateUuid("tick--")
            val report = ThreatObservation(
                id = newThreatId,
                threatGroup = "Social Media",
                name = "",
                description = "",
                assignee = UserInformation.current.email,
                firstObserved = Clock.System.now(),
                endActive = Clock.System.now() + 365.days,
                lastObserved = Clock.System.now(),
                severity = 2,
                whereSightedRefs = mutableSetOf(currentResource.id)
            )
            ThreatCreateDlg.show(report, graphService)
        }
    },
    edit(
        true,
        CsawareMessagesObject.get().system_depend_action_edit,
        "fas fa-edit",
        MainFeature.SystemDependencies,
        Permission.Write
    ) {
        override fun doIt(
            currentResource: SystemDependencyResource,
            selectedModel: Model<SystemDependencyResource>,
            graphService: SystemDependencyService
        ) {
            if (currentResource.hasId()) {
                SystemResourceChangeDlg(
                    currentResource,
                    CsawareMessagesObject.get().system_depend_action_edit,
                    graphService.connectedFrom(currentResource),
                    graphService
                ).apply {
                    onSubmitOk = {
                        val sr = this.model.data
                        graphService.store(sr, selectedModel, connectsFromModel.data.toSet())
                    }
                    attach()
                }
            }
        }
    },
    delete(
        true,
        CsawareMessagesObject.get().system_depend_action_delete,
        "fas fa-trash",
        MainFeature.SystemDependencies,
        Permission.Delete
    ) {
        override fun doIt(
            currentResource: SystemDependencyResource,
            selectedModel: Model<SystemDependencyResource>,
            graphService: SystemDependencyService
        ) {
            if (currentResource.hasId()) {
                Modal.confirm(
                    i18nText(CsawareMessages::system_depend_action_delete_confirm_title), Model.of(
                        currentResource.name
                    )
                ) {
                    graphService.delete(currentResource, selectedModel)
                }
            }
        }

    },
    create(
        true,
        CsawareMessagesObject.get().system_depend_action_new,
        "far fa-plus-square",
        MainFeature.SystemDependencies,
        Permission.Create
    ) {
        override fun doIt(
            currentResource: SystemDependencyResource,
            selectedModel: Model<SystemDependencyResource>,
            graphService: SystemDependencyService
        ) {
            val id = generateUuid()
            val from = if (currentResource.id.isNotEmpty()) {
                mutableListOf(currentResource.id)
            } else {
                mutableListOf()
            }
            val resource =
                SystemDependencyResource(
                    id = id,
                    name = "",
                    x_csaware_node_type = "server",
                    created = Clock.System.now(),
                    modified = Clock.System.now(),
                    description = "Some new resource",
                    source = from
                )
            SystemResourceChangeDlg(
                resource,
                CsawareMessagesObject.get().system_depend_action_new,
                connectedFrom = from,
                graphService
            ).apply {
                onSubmitOk = {
                    val sr = this.model.data
                    graphService.store(sr, selectedModel, connectsFromModel.data.toSet())
                }
                attach()
            }
        }
    },
    config(
        false,
        CsawareMessagesObject.get().system_depend_action_config,
        "fas fa-wrench",
        MainFeature.SystemDependencyConfig,
        Permission.Write
    ) {
        override fun doIt(
            currentResource: SystemDependencyResource,
            selectedModel: Model<SystemDependencyResource>,
            graphService: SystemDependencyService
        ) {
            SystemResourceConfigChangeDlg(graphService.config.data, graphService).apply {
                attach()
            }
        }
    },
    export(
        false,
        CsawareMessagesObject.get().system_depend_action_export,
        "fas fa-file-export",
        MainFeature.SystemDependencies,
        Permission.Read
    ) {
        override fun doIt(
            currentResource: SystemDependencyResource,
            selectedModel: Model<SystemDependencyResource>,
            graphService: SystemDependencyService
        ) {
            if (graphService.isOrganisationGraph())
                window.parent.open("/sysdep/export")
            else
                window.parent.open("/ecosystem/${graphService.ecoSystemId}/sysdep/export")
        }
    },
    import(
        false,
        CsawareMessagesObject.get().system_depend_action_import,
        "fas fa-file-import",
        MainFeature.CsawareAdministration,
        Permission.Write
    ) {
        override fun doIt(
            currentResource: SystemDependencyResource,
            selectedModel: Model<SystemDependencyResource>,
            graphService: SystemDependencyService
        ) {
            FileImportDlg(i18nText(CsawareMessages::system_depend_action_import), "system_depend_").apply {
                onSubmitOk = {
                    val jsonData = fileData.data
                    graphService.import(jsonData, replaceCurrent = true)
                }
                attach()
            }
        }
    },
    configExport(
        false,
        CsawareMessagesObject.get().system_depend_action_config_export,
        "fas fa-file-export",
        MainFeature.SystemDependencyConfig,
        Permission.Read
    ) {
        override fun doIt(
            currentResource: SystemDependencyResource,
            selectedModel: Model<SystemDependencyResource>,
            graphService: SystemDependencyService
        ) {
            window.parent.open("/sysdep/config/export")
        }
    },
    configImport(
        false,
        CsawareMessagesObject.get().system_depend_action_config_import,
        "fas fa-file-import",
        MainFeature.CsawareAdministration,
        Permission.Write
    ) {
        override fun doIt(
            currentResource: SystemDependencyResource,
            selectedModel: Model<SystemDependencyResource>,
            graphService: SystemDependencyService
        ) {
            FileImportDlg(i18nText(CsawareMessages::system_depend_action_config_import), "system_depend_").apply {
                onSubmitOk = {
                    val jsonData = fileData.data
                    graphService.configImport(jsonData)
                }
                attach()
            }
        }
    },
    backup(
        false,
        CsawareMessagesObject.get().system_depend_action_backup_or_restore,
        "fas fa-cloud-arrow-up",
        MainFeature.SystemDependencyBackup,
        Permission.Write
    ) {
        override fun doIt(
            currentResource: SystemDependencyResource,
            selectedModel: Model<SystemDependencyResource>,
            graphService: SystemDependencyService
        ) {
            BackupDialog(graphService).attach()
        }
    },
    ;

    companion object {
        val resourceFunctions = entries.filter { it.singleResource }
        val globalFunctions = entries.filter { !it.singleResource }
    }

    abstract fun doIt(
        currentResource: SystemDependencyResource,
        selectedModel: Model<SystemDependencyResource>,
        graphService: SystemDependencyService
    )

}