package csaware.utilities

import kafffe.bootstrap.form.*
import kafffe.core.*
import kafffe.core.modifiers.HtmlElementModifier
import kotlinx.browser.window
import kotlinx.dom.addClass
import kotlinx.dom.removeClass
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.asList
import org.w3c.dom.events.KeyboardEvent
import kotlin.reflect.KProperty1

/**
 * Holds an editor thats allows selection of predefined values from a list, either by keyboard or by mouse. It also allows selection of new values not in the choice model
 * An input field will open on click or focus, and a dropdown will open with potetial matches, up and down arrow keys will move through current choices and enter will select the current one.
 * left and right arrow keys will move insertion point in the current values.
 * Backspace will delete previous value if at start of input field and Delete will delete next value if at the end.
 * Inspired by MultipleEditSelect in Kafffe
 */
class TagEditor(
    override val htmlId: String, valueModel: Model<List<String>>, val choiceModel: Model<List<String>>
) : KafffeComponentWithModel<List<String>>(valueModel), FormInput {

    var allowDuplicates = false

    private val currentValues: MutableList<String> = valueModel.data.toMutableList()

    /**
     * Modifiers to tweak look of selected values
     */
    val modifiersValue = mutableListOf<(value: String) -> HtmlElementModifier>()

    override fun updateValueModel() {
        model.data = currentValues.toList()
    }

    private var inputIx = 1000
    private lateinit var formControl: KafffeHtml<HTMLDivElement>
    private lateinit var inputControl: KafffeHtml<HTMLInputElement>
    private lateinit var dropdown: KafffeHtml<HTMLDivElement>
    private var haveFocus: Boolean = false

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut {
        formControl = div {
            addClass("form-control kf-multiple-edit")
            renderBadgesAndEdit()
            onClick {
                inputControl.element.focus()
            }
        }
        return formControl
    }


    private fun KafffeHtml<HTMLDivElement>.renderBadgesAndEdit() {

        if (inputIx > currentValues.size) inputIx = currentValues.size
        currentValues.forEachIndexed { index, choice ->
            if (index == inputIx) {
                renderInput()
            }
            a {
                addClass(valueCssClasses(choice))
                addClass("me-1")
                text(choice)
                text(" ")
                i {
                    addClass("fas fa-times")
                }
                onClick {
                    currentValues.removeAt(index)
                    if (inputIx >= index && inputIx > 0) {
                        inputIx--
                    }
                    rerender()
                    it.preventDefault()
                }
                modifiersValue.forEach { mv ->
                    mv(choice).modify(this.element)
                }
            }
        }
        if (inputIx == currentValues.size) {
            renderInput()
        }
    }

    /**
     * Function that set value classes for each selected value.
     * May need display=inline-block to work properly.
     * @see valueCssClassDefault
     */
    var valueCssClasses: (String) -> String = { valueCssClassDefault }
    val valueCssClassDefault = "badge bg-primary text-black "


    private fun KafffeHtml<HTMLDivElement>.renderInput() {
        span {
            withElement {
                with(style) {
                    display = "inline-block"
                    position = "relative"
                }
            }
            inputControl = input {
                addClass("kf-multiple-edit-input ms-1")
                withElement {
                    type = "text"
                    onfocus = {
                        haveFocus = true
                        it
                    }
                    onblur = {
                        haveFocus = false
                        window.setTimeout({ hideDropdown() }, 300)
                        it
                    }
                    onkeydown = { onkey(it) }
                    oninput = {
                        renderMatches()
                    }
                    if (haveFocus) {
                        window.setTimeout({ inputControl.element.focus() }, 200)
                    }
                }
            }
            dropdown = div {
                addClass("sd_dropdown bg-light")
            }
        }
    }

    private fun hideDropdown() {
        dropdown.element.style.display = "none"
    }

    private fun showDropdown() {
        dropdown.element.style.display = "block"
    }

    private fun onkey(keyEvent: KeyboardEvent) {
        if (inputControl.element.value.isBlank() ?: false) {
            when (keyEvent.key) {
                "ArrowLeft" -> {
                    inputIx--
                    rerender()
                }

                "ArrowRight" -> {
                    inputIx++
                    rerender()
                }

                "Backspace" -> {
                    if (inputIx > 0) {
                        inputIx--
                        currentValues.removeAt(inputIx)
                        rerender()
                    }
                }

                "Delete" -> {
                    if (inputIx < currentValues.size) {
                        currentValues.removeAt(inputIx)
                        rerender()
                    }
                }
            }
        }
        when (keyEvent.key) {
            "ArrowDown" -> selectNext()
            "ArrowUp" -> selectPrev()
            "Enter" -> {
                keyEvent.preventDefault()
                val m = matches()
                if (selectIndex in 0 until m.size) {
                    addSelection(m[selectIndex])
                } else if (!inputControl.element.value.isBlank()) {
                    addSelection(inputControl.element.value)
                }
            }
        }
    }

    val maxMatches: Int = 7
    var selectIndex: Int = -1
    fun selectNext() {
        if (maxMatches >= selectIndex + 1) {
            ++selectIndex
        }
        select(selectIndex)
    }

    fun selectPrev() {
        if (selectIndex > 0) {
            --selectIndex
        }
        select(selectIndex)
    }

    fun select(index: Int) {
        val matches = matches()
        if (index in 0 until matches.size) {
            // set and remove "sd_selected" class
            dropdown.element.children.asList().forEachIndexed { i, element ->
                if (i == index) {
                    element.addClass("sd_selected")
                } else {
                    element.removeClass("sd_selected")
                }
            }
        }
    }

    fun matches(): List<String> {
        val txt = inputControl.element.value
        return if (txt.length > 0) {
            (choiceModel.data.filter { it.startsWith(txt, ignoreCase = true) }
                .union(choiceModel.data.filter { it.contains(txt, ignoreCase = true) })).take(maxMatches).toList()
        } else {
            choiceModel.data.take(maxMatches).toList()
        }
    }

    fun renderMatches() {
        selectIndex = -1
        dropdown.element.innerHTML = ""
        val htmlConsumer = KafffeHtml(dropdown.element)
        val matches = matches()

        for (match in matches) {
            htmlConsumer.div {
                addClass("sd_dropdown_item")
                text(match)
                onClick {
                    addSelection(match)
                }
            }
        }

        if (matches.isEmpty()) hideDropdown() else showDropdown()
    }

    private fun addSelection(value: String) {
        if (allowDuplicates || value !in currentValues) {
            currentValues.add(inputIx, value)
            inputIx++
        }
        rerender()
    }

    private fun isSelected(choice: String): Boolean = currentValues.contains(choice)

    override fun component(): KafffeComponent = this

    private val extraValidation = ValidationExtra<TagEditor>()

    fun addValidator(validator: Validator<TagEditor>) {
        extraValidation.validators.add(validator)
    }

    fun removeValidator(validator: Validator<TagEditor>) {
        extraValidation.validators.remove(validator)
    }

    override var validationMessageModel: Model<String> = Model.ofGet {
        if (!extraValidation.result.valid) extraValidation.result.message else ""
    }

    override fun validate(): Boolean {
        extraValidation.clear()
        val valid = extraValidation.validate(this)
        if (isRendered) html.applyInputValidCssClasses(valid)
        return valid
    }
}

// DSL function for form component consumer DSL
fun <T : Any> FormComponentConsumer<T>.tagEditor(
    idInput: String, labelModel: Model<String>, valueModel: Model<List<String>>, choiceModel: Model<List<String>>
): TagEditor {
    val input = TagEditor(idInput, valueModel, choiceModel)
    decorateAndAdd(labelModel, input)
    return input
}

fun <T : Any> FormComponentConsumer<T>.tagEditor(
    property: KProperty1<T, List<String>>, choiceModel: Model<List<String>>
): TagEditor = tagEditor(property.name, labelStrategy.label(property.name), model.property(property), choiceModel)
