package dk.rheasoft.csaware.json.adapter.base

/**
 * Base class for adapter Objects to adapt typed access to generic JSON.
 */
open class ObjectAdapter(val adapter: JsonObjectAdapter) {

    fun toJsonString() = adapter.toJsonString()

    override fun toString(): String {
        return this::class.simpleName + "> " + toJsonString()
    }
}