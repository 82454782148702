package csaware.systemdepend.backup

import csaware.comm.GraphBackend
import csaware.messages.CsawareMessagesObject
import csaware.systemdepend.SystemDependencyService
import dk.rheasoft.csaware.api.systemdependencies.SystemDependencyBackup
import dk.rheasoft.csaware.api.systemdependencies.SystemDependencyResource
import dk.rheasoft.csaware.api.systemdependencies.SystemDependencyResourceDifference
import dk.rheasoft.csaware.api.systemdependencies.SystemDependencyRestoreRequest
import kafffe.core.KafffeComponent
import kafffe.core.KafffeHtml
import kafffe.core.KafffeHtmlBase
import kafffe.messages.MessagesObject.formatDateTime
import kotlinx.datetime.toJSDate
import org.w3c.dom.HTMLFormElement
import org.w3c.dom.HTMLTableRowElement

/**
 * Form to choose which parts of System Dependcies backup should be restored or reverted to.
 */
class RestoreForm(
    private val graphService: SystemDependencyService,
    private val graphBackend: GraphBackend,
    val afterRestore: (SystemDependencyBackup) -> Unit
) : KafffeComponent() {
    private var request = SystemDependencyRestoreRequest()
    private val current: List<SystemDependencyResource>
        get() = graphService.model.data

    private var difference: SystemDependencyResourceDifference = SystemDependencyResourceDifference()

    var backup: SystemDependencyBackup? = null
        set(value) {
            field = value
            if (value != null) {
                request = SystemDependencyRestoreRequest(value.created)
                difference = SystemDependencyResourceDifference(current, value.graph.objects)
                // setup default operation on nodes
                request.deleteNodeIds.addAll(difference.notInFirst.map { it.id })
                request.revertNodeIds.addAll(difference.notInSecond.map { it.id })
                request.revertNodeIds.addAll(difference.changed.map { it.second.id })
            } else {
                request = SystemDependencyRestoreRequest()
                difference = SystemDependencyResourceDifference()
            }
            rerender()
        }

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtml<HTMLFormElement> =
    // We are not using BootstrapFOrm, because this form is very different from usually forms, it mainly consist of tables
        // for the nodes and a state/operation to check or uncheck.
        backup?.let { (created, _, _, comment) ->
            form {
                h3 {
                    text("${created.toJSDate().formatDateTime()} - $comment")
                }
                table {
                    addClass("table table-striped csaware-hover")
                    thead {
                        addClass("bg-primary")
                        tr {
                            th { text(CsawareMessagesObject.get().system_depend_name) }
                            th { text(CsawareMessagesObject.get().system_depend_id) }
                            th { text(CsawareMessagesObject.get().system_depend_restore_state) }
                            th { text(CsawareMessagesObject.get().system_depend_restore_operation) }
                        }
                    }
                    tbody {
                        for (newNode in difference.notInFirst) {
                            tr {
                                td { text(newNode.name) }
                                td { text(newNode.id) }
                                td { text(CsawareMessagesObject.get().system_depend_backup_nodes_in_current_not_in_this) }
                                checkboxColumn(
                                    newNode,
                                    CsawareMessagesObject.get().system_depend_restore_operation_remove,
                                    request.deleteNodeIds
                                )
                            }
                        }
                        for (deletedNode in difference.notInSecond) {
                            tr {
                                td { text(deletedNode.name) }
                                td { text(deletedNode.id) }
                                td { text(CsawareMessagesObject.get().system_depend_backup_nodes_not_in_current) }
                                checkboxColumn(
                                    deletedNode,
                                    CsawareMessagesObject.get().system_depend_restore_operation_restore,
                                    request.revertNodeIds
                                )
                            }
                        }
                        for (changedNodePair in difference.changed.sortedBy { it.second.name }) {
                            tr {
                                td { text(changedNodePair.second.name) }
                                td { text(changedNodePair.second.id) }
                                td { text(CsawareMessagesObject.get().system_depend_backup_nodes_changed) }
                                checkboxColumn(
                                    changedNodePair.second,
                                    CsawareMessagesObject.get().system_depend_restore_operation_restore,
                                    request.revertNodeIds
                                )
                            }
                        }
                    }
                }

                div {
                    addClass("form-check")
                    input {
                        addClass("form-check-input")
                        withElement {
                            type = "checkbox"
                            checked = request.revertAllNodes
                            id = "revertAllNodes"
                            oninput = {
                                request.revertAllNodes = checked
                                false
                            }
                        }
                    }
                    label {
                        addClass("form-check-label")
                        element.setAttribute("for", "revertAllNodes")
                        text(CsawareMessagesObject.get().system_depend_restore_revert_all_nodes)
                    }
                }

                div {
                    addClass("form-check")
                    input {
                        addClass("form-check-input")
                        withElement {
                            type = "checkbox"
                            checked = request.revertConfiguration
                            id = "revertConfiguration"
                            oninput = {
                                request.revertConfiguration = checked
                                false
                            }
                        }
                    }
                    label {
                        addClass("form-check-label")
                        element.setAttribute("for", "revertConfiguration")
                        text(CsawareMessagesObject.get().system_depend_restore_revert_configuration)
                    }
                }

                button {
                    addClass("btn btn-primary")
                    text(CsawareMessagesObject.get().system_depend_restore_operation_restore)
                    element.onclick = {
                        graphBackend.restore(request, afterRestore)
                        false
                    }
                }

            }
        } ?: form {}

    private fun KafffeHtml<HTMLTableRowElement>.checkboxColumn(
        node: SystemDependencyResource, operationText: String, nodeIds: MutableSet<String>
    ) {
        td {
            addClass("form-check")
            input {
                addClass("form-check-input")
                withElement {
                    type = "checkbox"
                    checked = nodeIds.contains(node.id)
                    id = node.id
                    oninput = {
                        if (checked) {
                            nodeIds.add(node.id)
                        } else {
                            nodeIds.remove(node.id)
                        }
                    }
                }
            }
            label {
                addClass("form-check-label")
                element.setAttribute("for", node.id)
                text(operationText)
            }
        }
    }


}