package csaware.systemdepend.graph

import csaware.main.UserConfiguration
import csaware.systemdepend.graph.shapes.NodeShapeRegistry
import mxgraph.*
import org.w3c.dom.HTMLElement


private const val defaultFill = "var(--bs-info)"

open class MxGraphWithStyles(
    graphElement: HTMLElement,
    /**
     * Map of edgestyel name to dash pattern in form of "4 2", where the dummbers are dash and space length alternating,
     * there can be more than 2 numbers.
     */
    edgeStyleMap: Map<String, String> = emptyMap(),
) : mxGraph(graphElement) {

    override fun isCellSelectable(cell: Any): Boolean {
        return cell is mxCell && cell.isVertex()
    }

    override fun isCellMovable(cell: Any): Boolean = false
    override fun isCellEditable(cell: Any): Boolean = false
    override fun isCellResizable(cell: Any): Boolean = false

    init {
        NodeShapeRegistry.registerShapes()
        NodeShapeRegistry.putStyles(stylesheet)

        let {
            val resourceStyle = js("{}")
            resourceStyle[mxConstants.STYLE_FILLCOLOR] = defaultFill
            resourceStyle[mxConstants.STYLE_STROKECOLOR] = "#000000"
            resourceStyle[mxConstants.STYLE_STROKEWIDTH] = 1
            resourceStyle["width"] = "180px"
            resourceStyle["height"] = "180px"
            stylesheet.putCellStyle("vertice_normal_color", resourceStyle)
        }
        let {
            val resourceStyle = js("{}")
            resourceStyle[mxConstants.STYLE_FILLCOLOR] = defaultFill
            resourceStyle[mxConstants.STYLE_STROKECOLOR] = "#8DFF54"
            resourceStyle[mxConstants.STYLE_STROKEWIDTH] = 5
            stylesheet.putCellStyle("vertice_highlight_color", resourceStyle)
        }
        let {
            val resourceStyle = js("{}")
            resourceStyle[mxConstants.STYLE_OPACITY] = 35
            resourceStyle[mxConstants.STYLE_TEXT_OPACITY] = 40
            stylesheet.putCellStyle("lowlight", resourceStyle)
        }
        let {
            val edgeStyle = getStylesheet().getDefaultEdgeStyle().asDynamic()
            edgeStyle[mxConstants.STYLE_ROUNDED] = true
            edgeStyle[mxConstants.STYLE_STROKECOLOR] = "#ffffff"
            edgeStyle[mxConstants.STYLE_STROKEWIDTH] = 1
            stylesheet.putCellStyle("edge", edgeStyle)
            getStylesheet().putDefaultEdgeStyle(edgeStyle)
        }
        let {
            val resourceStyle = js("{}")
            resourceStyle[mxConstants.STYLE_ROUNDED] = true
            resourceStyle[mxConstants.STYLE_STROKECOLOR] = "#8DFF54"
            resourceStyle[mxConstants.STYLE_STROKEWIDTH] = 2
            stylesheet.putCellStyle("edge_highlight", resourceStyle)
        }
        let {
            val edgeStyle = js("{}")
            edgeStyle[mxConstants.STYLE_STARTARROW] = mxConstants.NONE
            edgeStyle[mxConstants.STYLE_ENDARROW] = mxConstants.ARROW_CLASSIC
            stylesheet.putCellStyle("singleArrow", edgeStyle)
        }
        let {
            val edgeStyle = js("{}")
            edgeStyle[mxConstants.STYLE_ENDARROW] = mxConstants.ARROW_CLASSIC
            edgeStyle[mxConstants.STYLE_STARTARROW] = mxConstants.ARROW_CLASSIC
            stylesheet.putCellStyle("doubleArrow", edgeStyle)
        }
        let {
            val resourceStyle = js("{}")
            resourceStyle[mxConstants.STYLE_ROUNDED] = true
            resourceStyle[mxConstants.STYLE_STROKECOLOR] = "#8DFF54"
            resourceStyle[mxConstants.STYLE_STROKEWIDTH] = 2
            stylesheet.putCellStyle("edge_empty", resourceStyle)
        }
        for ((name, pattern) in edgeStyleMap)  {
            if (pattern.isNotEmpty()) {
                val edgeStyle = js("{}")
                edgeStyle[mxConstants.STYLE_DASHED] = true
                edgeStyle[mxConstants.STYLE_DASH_PATTERN] = pattern
                stylesheet.putCellStyle(name, edgeStyle)
            }
        }
        val config: UserConfiguration = UserConfiguration.default
        for (severity in (1..5)) {
            val resourceStyle = js("{}")
            resourceStyle[mxConstants.STYLE_STROKECOLOR] = config.severityColor(severity)
            resourceStyle[mxConstants.STYLE_STROKEWIDTH] = 1 + severity
            stylesheet.putCellStyle("severity-$severity", resourceStyle)
        }
    }

}