package csaware.messages

import kafffe.messages.Messages_da

open class CsawareMessages_da : Messages_da(), CsawareMessages {
    override val translation_automatic: String = "Automatisk oversættelse"

    override val settings: String ="Indstillinger"

    override val environment_name = "Navn"

    override val nav_overview = "Overblik"
    override val nav_threats = "Hændelser"
    override val nav_threatsClosed = "Hændelser Lukket"
    override val nav_config = "Konfiguration"
    override val tab_threats_open="Åbne"
    override val tab_threats_closed ="Lukkede"
    override val threat_group = "Gruppe"
    override val threat_group_all = "Alle"
    override val threat_nobody = "Ingen"
    override val threat_state = "Tilstand"
    override val threat_state_initator = "Hvem"
    override val threat_state_history = "Historie"
    override val threat_state_comment = "Kommentar"
    override val threat_course_of_action = "Fremgangsmåde"
    override val threat_where = "Hvor"
    override val threat_assignee = "Ansvarlig"

    override val threat_observed_data_objects = "Observeret Data"
    override val threat_observed_data_id= "Id"
    override val threat_observed_data_type= "Type"
    override val threat_observed_data_data = "Data"

    override val threat_subtype = "Undertype"
    override val threat_firstObserved = "Første observation"
    override val threat_endActive = "Lukket"
    override val threat_newState = "Skift tilstand"
    override val threat_selfHealingConfirm = "Accepter BCDR"

    override val threat_topTitle = "Top trusler"

    override val severity = "Alvorlighed"
    override val count = "Antal"

    override val threat_level_5_name = "Kritisk"
    override val threat_level_5_text = "Der er en specifik trussel."
    override val threat_level_4_name = "Høj"
    override val threat_level_4_text = "Der er en erkendt trussel."
    override val threat_level_3_name = "Middel"
    override val threat_level_3_text = "Der er en generel trussel"
    override val threat_level_2_name = "Moderat"
    override val threat_level_2_text = "Der er en potentiel trussel."
    override val threat_level_1_name = "Lav"
    override val threat_level_1_text = "Der er ingen indikationer på en trussel."
    override val threat_level_0_name = ""
    override val threat_level_0_text = ""

    override val ThreatState_Active = "Aktiv"
    override val ThreatState_Resolved = "Løst"
    override val ThreatState_Ignored = "Ignoreret"
    override val ThreatState_HealingAwaitDecision = "BCDR venter på beslutning"
    override val ThreatState_HealingAccepted = "BCDR accepteret"
    override val ThreatState_HealingDeclined = "BCDR afvist"
    override val ThreatState_Healed = "BCDR udført"
    override val ThreatState_HealingFailed = "BCDR fejlede"
    override val ThreatState_HealingInProgress = "BCDR er undervejs"
    override val ThreatState_Unknown = "Ukendt"

    override val user_management = "Brugerstyring"
    override val user_edit = "Ret bruger"
    override val user_new = "Ny bruger"
    override val user_subject = "Subject"
    override val user_email = "Email"
    override val user_firstName = "Fornavn"
    override val user_lastName = "Efternavn"
    override val user_fullname  = "Navn"
    override val user_roles = "Roller"
    override val user_action_import = "Import"
    override val user_action_export = "Export"

    override val user_preferences_edit = "UI præferencer"
    override val user_preferences_dataPresentationLanguage = "Datapræsentations sprog"
    override val user_preferences_uiLanguage ="Brugergrænseflade sprog"

    override val system_preferences_edit = "System præferencer"
    override val system_preferences_accountDataPresentationLanguage = "Default datapræsentations sprog"
    override val system_preferences_accountUiLanguage ="Default brugergrænseflade sprog"


    override val informationshare_management = "Informationsdeling"
    override val informationshare_short_description = """Information sharing referrs to sharing of cybersecurity information with cybersecurity professionals outside your organization. 
Examples of such professionals may be computer security incident response teams (CSIRTs) your organization is collaborating with.

The purpose of sharing this information with cybersecurity professionals is for them to be better able to assess and analyze cybersecurity issues within organizations and to be able to set it in context with information gathered from other sources in order to be able to devise better mitigation and prevention mechanisms, which in turn may help your organization to better protect against attacks in the future.

If you decide to share, the information will be posted on a protected repository. Only people and organizations that have been authorized by your organization will be able to access the information."""
    override val informationshare_edit = "Ret Informationsdeling"
    override val informationshare_id = "Id"
    override val informationshare_description = "Beskrivelse"
    override val informationshare_state = "Tilstand"
    override val informationshare_create = "Oprettet"
    override val informationshare_modified = "Sidst ændret"
    override val informationshare_initiator = "Hvem"
    override val informationshare_history = "Historie"
    override val informationshare_summary = "Resumé"

    override val ShareState_AwaitDecision = "Bekræft deling"
    override val ShareState_Accept = "Accepteret"
    override val ShareState_Decline = "Afvist"
    override val ShareState_Unknown = "Ukendt"

    override val system_dependencies = "System"

    override val system_depend_id = "Id"
    override val system_depend_name  = "Navn"
    override val system_depend_description= "Beskrivelse"
    override val system_depend_connectedFrom= "Forbundet fra"
    override val system_depend_connectedTo= "Forbundet til"
    override val system_depend_infoflow= "Infoflow"
    override val system_depend_category= "Kategori"
    override val system_depend_fields= "Felter"
    override val system_depend_fields_valueset = "Feltværdiset"
    override val system_depend_fields_valueset_values = "Værdier"
    override val system_depend_node_types = "Node Types"
    override val system_depend_layout = "Layout"
    override val system_depend_layoutDirection = "Retning"
    override val system_depend_layout_horizontal = "Horizontal"
    override val system_depend_layout_vertical = "Vertikal"
    override val system_depend_layout_roots = "Rødder"
    override val system_depend_layout_user_root = "Layout rod"
    override val system_depend_layout_default_root = "Default layout"
    override val system_depend_spacing = "Afstand"

    override val system_depend_action_search = "Søg System Resource"
    override val system_depend_action_new = "Opret ny"
    override val system_depend_action_edit = "Ret"
    override val system_depend_action_delete = "Slet"
    override val system_depend_action_delete_confirm_title = "Slet System Resource"
    override val system_depend_action_config = "Konfiguration"
    override val system_depend_action_import = "Import"
    override val system_depend_action_export = "Export"
    override val system_depend_action_config_import = "Konfiguration import"
    override val system_depend_action_config_export = "Konfiguration export"
    override val system_depend_action_print = "Udskriv"

    override val system_depend_action_backup_or_restore = "Backup eller Gendan"
    override val system_depend_backup_title = "Systemressourcer Backup"
    override val system_depend_backup_create_new = "Ny backup"
    override val system_depend_backup_created = "Oprettet"
    override val system_depend_backup_comment = "Kommentar"
    override val system_depend_backup_nodes_not_in_current = "Ikke i nuværende"
    override val system_depend_backup_nodes_in_current_not_in_this = "Ikke i denne"
    override val system_depend_backup_nodes_changed = "Ændret"
    override val system_depend_restore_state = "Ændre"
    override val system_depend_restore_operation = "Operation"
    override val system_depend_restore_operation_remove = "Fjern"
    override val system_depend_restore_operation_restore = "Gendan"
    override val system_depend_restore_revert_all_nodes = "Gendan alle Systemnoder"
    override val system_depend_restore_revert_configuration = "Gendan Systemafhængigheder Konfiguration"

    override val system_depend_label_all = "Alle"

    override val system_depend_field_id = "Id"
    override val system_depend_field_label = "Navn"
    override val system_depend_field_type = "Type"
    override val system_depend_field_cardinality = "Kardinalitet"
    override val system_depend_field_add = "Inkludér felt"
    override val system_depend_fields_config = "Feltkonfiguration .."
    override val system_depend_field_validation_error_txt = "'%value%' opfylder ikke krav for '%type%'"

    override val sign_out = "Check Out"
    override val sign_out_disclaimer = """
        |The CS-AWARE Visualisation session as been cleared,  
        |you will have to logout of the external Authorisation Provider,  
        |if you do not want to be automatically signed in again. 
        |""".trimMargin()
    override val sign_out_link="Go to Your Authorisation Provider to Sign Out"

    override val mail_dialog_title = "Send Email"
    override val mail_to = "To"
    override val mail_from = "From"
    override val mail_subject = "Subject"
    override val mail_body = "Body"
    override val mail_send = "Send"


    override val socialmedia_reader : String = "Sociale medier"
    override val socialmedia_reader_filter_readstate_both = "Både læste og ulæste"
    override val socialmedia_reader_filter_readstate_not_read = "Kun ulæste"
    override val socialmedia_reader_filter_readstate_read = "Kun læste"

    override val socialmedia_reader_read_on_source  = "Læs kilde"
    override val socialmedia_createdAt : String = "Oprettet"
    override val socialmedia_keywords : String = "Nøgleord"
    override val socialmedia_text : String = "Tekst"
    override val socialmedia_account : String = "Konto"
    override val socialmedia_id:String = "Id"

    override  val socialmedia_system_where  = "Relaterer til"

    override val socialmedia_account_included = "Inkluderet"
    override val socialmedia_management_title = "Socialmedia Management"
    override val socialmedia_account_edit = "Rediger"
    override val socialmedia_account_new = "Ny"
    override val socialmedia_account_mediaType = "Type"
    override val socialmedia_account_collectorState = "Opsamler"
    override val socialmedia_account_id = "Id"
    override val socialmedia_account_name = "Navn"
    override val socialmedia_account_displayName = "Visnings navn"
    override val socialmedia_account_description= "Beskrivelse"
    override val socialmedia_account_lastCollected = "Sidst opsamlet"

    override val socialmedia_account_twitter_new = "Ny X konto opsamling"
    override val socialmedia_account_include = "Inkluder konto opsamling"
    override val socialmedia_account_exclude = "Ekskluder konto opsamling"
    override val socialmedia_account_action_include_page = "Inkluder opsamling for konti på siden"
    override val socialmedia_account_action_exclude_page = "Ekskluder opsamling for konti på siden"


    override val socialmedia_account_action_export = "Eksport"
    override val socialmedia_account_action_import = "Import"
    override val socialmedia_create_threatobservation = "Opret trusselsrapport"

    override val policy = "Retningslinjer"
    override val policy_edit = "Rediger retningslinje"
    override val policy_title: String = "Navn"
    override val policy_purpose = "Formål"
    override val policy_elements = "Elementer"
    override val policy_need = "Behov"
    override val policy_roles_responsibilities = "Roller og ansvar"
    override val policy_references = "Referencer"
    override val policy_tags = "Vedrører"
    override val policy_systemNodeReferences: String = "Referencer til systemressourcer"
    override val policy_new  = "Opret retningslinje"
    override val policy_createdAt = "Oprettet"
    override val policy_createdBy = "Oprettet af"
    override val policy_updatedAt = "Opdateret"
    override val policy_updatedBy = "Opdateret af"

    override val policy_state  = "Tilstand"
    override val policy_state_draft = "Udkast"
    override val policy_state_approved = "Godkendt"
    override val policy_state_draft_with_approved = "Udkast med godkendt version"
    override val policy_state_obsolete = "Forældet"
    override val policy_state_unknown = "Ukendt"

    override val policy_type_organisation = "Organisation"
    override val policy_type_template = "Skabelon"

    override val policy_comment_comment = "Kommentar"
    override val policy_comment_add = "Tilføj kommentar"
    override val policy_comment_edit = "Rediger kommentar"
    override val policy_comment_respond = "Svar"
    override val policy_comment_delete = "Slet kommentar"

    override val policy_approve_comment_add = "Tilføj godkendelses kommentar"

    override val policy_history = "Historik"

    override val policy_action_edit = "Rediger"
    override val policy_action_clone = "Klon"
    override val policy_action_approve = "Godkend"
    override val policy_action_export = "Eksport"

    override val policy_action_ai_import= "AI import policy.."
    override val policy_import_extract_text = "Udtræk tekst fra dokument (fil)"

    override val policy_config  = "Konfiguration af tags og relationstyper"
    override val policy_relation_type = "Relationstype"
    override val policy_relation_id = "Id"
    override val policy_relation_name = "Navn"
    override val policy_relation_reversed = "Omvendt relation"

    override val policy_template_action_edit: String = "Ny ud fra skabelon"


    override val anomalies_ignores_saved = "Ignorerede anomalier"
    override val anomalies_incident_saved = "Gemte hændelser"
    override val anomaly_details_module = "Modul"
    override val anomaly_to_be_ignored= "Skal ignoreres"
    override val anomaly_incident= "Hændelser"
    override val anomaly_new_incident= "Ny hændelse"
    override val anomaly_unsaved_changes_titel ="Der er ikke-gemte ændringer"
    override val anomaly_unsaved_changes_message ="Der er ikke-gemte ændringer. Vil du fortsætte uden at gemme ændringerne?"

    override val kpi_dimension_title_Dynamic_Cybersecurity_Strategy="Dynamisk cybersikkerhedsstrategi"
    override val kpi_dimension_title_Collaborative_Mindset ="Samarbejdende tankegang"
    override val kpi_dimension_title_Organizational_Learning ="Organisatorisk læring"
    override val kpi_dimension_title_Regulatory_Compliance="Regulativ overholdelse"
    override val kpi_dimension_title_Standards_and_Technologies= "Standarder og teknologier"

    override val kpi_incident_total = "Hændelse i alt"
    //  override val kpi_incident = "hændelse"
    override val kpi_incident_sharing_ecosystem = "Økosystem for deling af hændelser"
    //   override val kpi_sharing_count_eco = "antal deling til økosystem"
    override val kpi_incident_sharing_global = "Hændelsels deling global"
    override val kpi_sharing_count_public = "antal delinger til offentlig"
    override val kpi_incident_sharing_changes = "Delte hændelses ændringer"
    //    override val kpi_access_level_changes = "adgangsnivearændringer"
    override val kpi_statistic_description_incident_total= "Det samlede antal hændelser håndteret af systemet (åbnet automatisk af systemet eller manuelt).\n" +
            "Giver en indikation af, hvor godt en organisation er forberedt til at identificere og styre cybersikkerhed \n" +
            "problemer - enten automatisk gennem korrekt konfigurerede cybersikkerhedsovervågningsværktøjer eller manuelt gennem opmærksomhed og samarbejde."
    override val kpi_statistic_description_incident_sharing_ecosystem = "Antallet af hændelser med deleniveau 'økosystem'.\n" +
            "Giver en indikation af, hvor godt din organisation samarbejder med andre relaterede organisationer (inklusive leverandører og kunder)" +
            " for at håndtere cybersikkerhedsproblemer i din organisation samt hjælper samarbejdende organisationer med at håndtere problemer med tjenester," +
            " de er afhængige af."
    override val kpi_statistic_description_incident_sharing_global_d2 ="Antallet af hændelser med delingsniveau 'globalt'.\n" +
            "Giver en indikation af, hvor godt din organisation deltager i kollaborativ cybersikkerhed, som f.eks. skitseret af den europæiske cybersikkerhedsstrategi. " +
            "Er også en indikation af, hvor godt din organisation følger informationsdeling overholdelse af lovgivning og lovgivning (f.eks. NIS, GDPR)."
    override val kpi_statistic_description_incident_sharing_changes = "Antallet af tilpasninger til delingsniveauer (ugentlig/månedlig).\n" +
            "Giver en indikation af, hvor aktivt din organisation deltager i informationsdeling for at opnå samarbejdsbevidsthed og samarbejde " +
            "om løsning af cybersikkerhedsproblemer."
    override val kpi_statistic_description_frontend_logins ="Antallet af logins til systemet.\n" +
            "Giver en indikation af, hvor aktivt organisationen overvåger og styrer cybersikkerhedssituationen i organisationen."
    override val kpi_statistic_description_distinct_users ="Antallet af distinkte brugere i systemet. \n" +
            "Giver en indikation af, hvor mange ressourcer din organisation dedikerer til at overvåge og administrere\n" +
            "cybersikkerhedssituationen i organisationen."
    override val kpi_statistic_description_anomaly_count ="Antallet af uregelmæssigheder opdaget af platformen.\n" +
            "Giver en indikation af, hvor godt den automatiske detektering af mistænkelig cybersikkerhedsadfærd er\n" +
            "konfigureret i din organisation.\n"
    override val kpi_statistic_description_anomaly_timeline ="Antallet af nye anomalier opdaget af systemet (ugentlig/månedlig).\n" +
            "Giver en indikation af, hvor hurtigt mistænkelig cybersikkerhedsadfærd vurderes og håndteres inden for\n" +
            "din organisation."
    override val kpi_statistic_description_anomaly_dismissed ="Antallet af anomalier, der klassificeres af mennesket-i-løkken som irrelevante.\n" +
            "Giver en indikation af, hvor godt den automatiserede overvågning er konfigureret til din specifikke kontekst\n" +
            "organisation, og hvor godt organisationen forstår sin egen kontekst. Lavere er bedre."
    override val kpi_statistic_description_anomaly_incidents ="Antallet af uregelmæssigheder, der betragtes som hændelser (ugentlig/månedlig).\n" +
            "Giver en indikation af, hvor godt automatiseret overvågning af organisationsspecifik kontekst er konfigureret,\n" +
            "og hvor godt mennesket-i-løkken forstår unormal adfærd, der indikerer et cybersikkerhedsproblem, der skal håndteres."
    override val kpi_statistic_description_system_graph_assets_d4 ="Antallet af aktiver i systemgrafen.\n" +
            "Giver en indikation af, hvor godt din organisation har identificeret virksomhedsaktiver, der skal beskyttes i cyberspace. I forbindelse med overholdelse af lovgivningen indikerer dette, hvor godt din virksomhed forstår det " +
            "forpligtelser til at beskytte virksomhedens aktiver mod cyberangreb."
    override val kpi_statistic_description_system_graph_dependencies_d4 ="Antallet af afhængigheder i systemgrafen.\n" +
            "Giver en indikation af, hvor godt din organisation forstår, hvordan din virksomheds aktiver afhænger af hinanden med hensyn til cybersikkerhed. " +
            "I forbindelse med overholdelse af lovgivningen indikerer dette, hvor godt din organisation forstår vigtigheden af at overveje afhængigheder, når de beskytter mod cyberangreb."
    override val kpi_statistic_description_system_graph_values_d4 =""
    override val kpi_statistic_description_system_graph_changes_d4 ="Antallet af tilpasninger foretaget til systemgrafen (ugentlig/månedlig).\n" +
            "Giver en indikation af, hvor godt din organisation vedligeholder cybersikkerhedsrelateret viden i en verden, der ser konstante ændringer i din organisation og cybersikkerhedslandskabet." +
            " I forbindelse med overholdelse af lovgivning giver dette en indikation af, hvor godt din virksomhed adresserer dynamisk cybersikkerhed ledelsesprocedurer."
    override val kpi_statistic_description_incident_sharing_global_d4 ="Antallet af hændelser med delingsniveau \"global\"\n" +
            "Giver en indikation af, hvor godt din organisation deltager i kollaborativ cybersikkerhed, som f.eks. skitseret af den europæiske cybersikkerhedsstrategi. " +
            "I forbindelse med overholdelse af lovgivning er dette en indikation af, hvor godt din organisation følger informationsdeling juridisk og lovmæssig overholdelse (f.eks. NIS, GDPR)."
    override val kpi_statistic_description_system_graph_security_appliances ="Antallet af sikkerhedsudstyr identificeret i systemgrafen.\n" +
            "Giver en indikation af, hvor godt organisationen følger standardsikkerhedspraksis ved at anvende " +
            "teknologiske sikkerhedskontroller til at forebygge og håndtere cybersikkerhedsproblemer."
    override val kpi_statistic_description_policy_standards ="Antallet af cybersikkerhedsretningslinjer afledt af sikkerhedsstandarder."


    override val kpi_time_of_count= "Tidspunkt for optælling"
    override val kpi_number_of_incidents= "Antal hændelser"
    override val kpi_number_of_policies= "Antal retningslinjer"
    override val kpi_number_of_changes= "Antal ændringer"
    override val kpi_number_of_assets= "Antal aktiver"
    override val kpi_number_of_dependencies= "Antal afhængigheder"
    override val kpi_number_of_values= "Antal værdier"
    override val kpi_number_of_logins ="Antal logins"
    override val kpi_number_of_distinct_users ="Antal distinkte brugere"
    override val kpi_number_of_anomalies = "Antal anomalier"
    override val kpi_number_of_new_anomalies ="Antal nye anomalier"
    override val kpi_number_of_dismissed_anomalies ="Antal afviste anomalier"
    override val kpi_number_of_new_incidents ="Antal nye hændelser"
    override val kpi_number_of_dot_dot_dot ="Antal af ..."
    override val kpi_number_of_security_appliances ="Antal sikkerhedsapparater"
    override val kpi_number_of_policy_standards ="Antal politiske standarder"
    override val kpi_time= "Tid"
    override val kpi_logins ="Log ind"
    override val kpi_distinct_users = "Distinkte brugere"
    override val kpi_anomaly_count ="Anomali optælling"
    override val kpi_anomaly_timeline ="Anomali tidslinje "
    override val kpi_anomaly_dismissed ="Anomali afvist "
    override val kpi_anomaly_incident ="Anomali hændelse"
    override val kpi_system_graph_security_appliances ="Sikkerhedsudstyr til systemgraf"
    override val kpi_security_appliances ="sikkerhedsudstyr"
    override val kpi_policy_standards ="Retningslinje standarder"
    override val kpi_policy_count= "Retningslinjer optælling"
    override val kpi_policy_changes= "Retningslije ændringer"

    override val kpi_statistic_description_policy_count= "Antallet af retningslinjer på platformen. \n" +
            "Giver en indikation af, hvor godt din organisation er forberedt til at håndtere de mange forskellige cybersikkerhedsrelaterede problemer."
    override val kpi_statistic_description_policy_changes= "Antallet af tilpasninger af retningslinjer (ugentlig/månedlig). \n" +
            "Giver en indikation af, hvor godt din organisation er klar til at tilpasse sig det stadigt skiftende cybersikkerhedslandskab."
    override val kpi_statistic_description_system_graph_assets_d1= "Antallet af aktiver i systemgrafen. \n" +
            "Giver en indikation af, hvor godt din organisation har identificeret virksomhedsaktiver, der skal beskyttes i cyberspace."
    override val kpi_statistic_description_system_graph_dependencies_d1= "Antallet af afhængigheder i systemgrafen. \n" +
            "Giver en indikation af, hvor godt din organisation forstår, hvordan din virksomheds aktiver afhænger af hinanden med hensyn til cybersikkerhed."
    override val kpi_statistic_description_system_graph_values_d1= "Antallet af værdier angivet for aktiver i systemgrafen.\n" +
            "Giver en indikation om den cybersikkerhedsvidenstyring, der udføres for at bevare viden \n" +
            "tilgængelig på længere sigt"
    override val kpi_statistic_description_system_graph_changes_d1= "Antallet af tilpasninger foretaget til systemgrafen (ugentlig/månedlig). \n" +
            "Giver en indikation af, hvor godt din organisation vedligeholder cybersikkerhedsrelateret viden i en verden\n" +
            "der ser konstante ændringer i din organisation og til cybersikkerhedslandskabet."

    override val kpi_system_graph_assets= "System Graf Aktiver"
    override val kpi_system_graph_dependencies= "System Graf Afhængigheder"
    override val kpi_system_graph_values= "System Graf Værdier"
    override val kpi_system_graph_changes= "Ændringer i system grafen"
    override val kpi_questionnaire= "Spørgeskema"
    override val kpi_out_of_100= "ud af 100"
    override val kpi_d1_section_view_description= "Udforsker kapaciteten til at udvikle og levere cybersikkerhedsstrategi og til at forbedre organisationens cybersikkerhed \n" +
            "modstandsdygtighed ved at forbedre hændelsesrespons, cyberforsvar og beskyttelseskapaciteter for kritisk infrastruktur (CI)."
    override val kpi_d2_section_view_description= "Gennemgår vigtige elementer i en ansvarlig cybersikkerhedskultur, såsom forståelsen af cyberrelaterede risici,\n" +
            "niveauet af tillid til tjenester, forståelse af privatlivsspørgsmål, samarbejde i organisationen for at fremme cybersikkerhedsbevidsthed og kontakter med relevante interessenter"
    override val kpi_d3_section_view_description ="gennemgår tilgængeligheden, udbredelsen og kvaliteten af relevante cybersikkerhedsbevidstgørelsesprogrammer,\n" +
            "formelle cybersikkerhedsuddannelsesprogrammer og professionelle træningsprogrammer"
    override val kpi_d4_section_view_description="Undersøger organisationens kapacitet til at designe og vedtage national lovgivning, der direkte og indirekte relaterer til cybersikkerhed"
    override val kpi_d5_section_view_description ="Undersøger implementering af cybersikkerhedsstandarder og god praksis, implementering af processer og\n" +
            "kontrol og udvikling af teknologier og produkter til at reducere cybersikkerhedsrisici\n"


    override val markdown_bold            = "Fed"
    override val markdown_italic          = "Kursiv"
    override val markdown_strikethrough   = "Gennemstregning"
    override val markdown_heading         = "Overskrift"
    override val markdown_heading_smaller = "Mindre overskrift"
    override val markdown_heading_bigger  = "Større overskrift"
    override val markdown_heading_1x       = "Big Heading"
    override val markdown_heading_2x       = "Medium Heading"
    override val markdown_heading_3x       = "Small Heading"
    override val markdown_code            = "Kodeblok"
    override val markdown_quote           = "Citeringsblok"
    override val markdown_unordered_list  = "Liste uden nummerering"
    override val markdown_ordered_list    = "Liste med nummerering"
    override val markdown_clean_block     = "Fjern overskrift- og listeformatering"
    override val markdown_link            = "Opret Link"
    override val markdown_image           = "Indsæt billede"
    override val markdown_mde_table       = "Tabel"
    override val markdown_horizontal_rule = "Vandret linie"
    override val markdown_preview         = "Toggle Preview"
    override val markdown_side_by_side    = "Toggle Side by Side"
    override val markdown_fullscreen      = "Toggle Fuldskærm"
    override val markdown_guide           = "Markdown Guide (Formatterings tegn)"
    override val markdown_undo            = "Undo"
    override val markdown_redo            = "Redo"

    override val validation_error: String = "Valideringsfejl"
    override val save_anyway: String = "Gem alligevel"

    override val required_field_not_filled_error: String= "Udfyld dette felt"
}