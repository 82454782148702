package csaware.overview

import csaware.comm.CSAwareBackend
import csaware.main.CsawareServices
import csaware.main.UserConfiguration
import csaware.main.UserInformation
import csaware.main.updateInfoCount
import csaware.messages.CsawareMessages
import csaware.messages.CsawareMessagesObject
import csaware.messages.i18nText
import csaware.threats.ThreatCreateDlg
import csaware.socialmedia.DailySummaryViewer
import csaware.systemdepend.SystemDependencyService
import csaware.threats.Popovers
import csaware.threats.ThreatFilterData
import csaware.threats.ThreatChangeDlg
import csaware.threats.ThreatStateSymbol
import csaware.translation.TranslationModel
import csaware.utilities.ActionBar
import csaware.utilities.ColumnHeaderDropdownFilter
import csaware.utilities.SystemNodeToSystemGraphLink
import csaware.utilities.UUID
import dk.rheasoft.csaware.api.UpdateEvent
import dk.rheasoft.csaware.api.access.MainFeature
import dk.rheasoft.csaware.api.access.Permission
import dk.rheasoft.csaware.api.incident.ThreatObservation
import dk.rheasoft.csaware.api.incident.ThreatOverview
import kafffe.bootstrap.BootstrapTable
import kafffe.bootstrap.BootstrapTableStyles
import kafffe.bootstrap.modifier.BootstrapPopoverModifier
import kafffe.bootstrap.modifier.BootstrapTooltipModifier
import kafffe.core.*
import kafffe.core.modifiers.CssClassModifier
import kafffe.messages.MessagesObject.formatDate
import kafffe.messages.MessagesObject.formatDateTime
import kotlinx.browser.window
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.datetime.toJSDate
import org.w3c.dom.HTMLTableCellElement
import kotlin.time.Duration.Companion.days

class ThreatDashBoard(val config: UserConfiguration = UserConfiguration.default) : KafffeComponent() {

    private val threatTypeListModel = Model.of(listOf<ThreatObservation>())

    private var currentThreats: ThreatOverview = ThreatOverview(Clock.System.now(), listOf())
    private var historyThreats: MutableMap<Instant, ThreatOverview> = mutableMapOf()

    private var currentShownTime: Model<Instant> = Model.of(currentThreats.activeAt)
    private var dateLabelModel: Model<String> = currentShownTime.func(getData = { it.data.toJSDate().formatDate() })
    private val graphService: SystemDependencyService = CsawareServices.systemDependencyService

    val filter = ThreatFilterData(graphService)

    // Child Components
    val graph = addChild(DartGraph(clickHandler = { _, type -> filter.groupModel.data = type ?: filter.all }))
    private val dateLabel = addChild(Label(dateLabelModel))


    private val playButtons = addChild(PlayButtons(::playHandler))


    private val typeTableTitle =
        addChild(Label(Model.ofGet {
            CsawareMessagesObject.get().threat_topTitle + if (filter.group == filter.all) "" else ": ${filter.group}"
        }))

    private val typeTable = addChild(BootstrapTable(threatTypeListModel).apply {
        rowClickHandler = { data, _ -> ThreatChangeDlg.show(data, graphService) }

        addStyle(BootstrapTableStyles.striped)
        modifiers.add(CssClassModifier("csaware-hover"))
        modifiersHeader.add(CssClassModifier("bg-primary"))

        colEx(i18nText(CsawareMessages::severity), fun(rowData: ThreatObservation, cell: HTMLTableCellElement): ThreatStateSymbol {
            cell.style.backgroundColor = UserConfiguration.default.severityColorMap[rowData.severity ?: 0]
            return ThreatStateSymbol(Model.of(rowData), includeSeverityText = true)
        })
        col(i18nText(CsawareMessages::threat_firstObserved), { Label(it.firstObserved.toJSDate().formatDateTime()) })
        col(i18nText(CsawareMessages::threat_assignee),
            { Label(it.assignee) },
            { ColumnHeaderDropdownFilter(it, filter.assigneeModel, filter.all, filter.assigneeChoices) }
        )
        col(i18nText(CsawareMessages::threat_where),
            { SystemNodeToSystemGraphLink(it.whereSightedRefs, graphService) },
            { ColumnHeaderDropdownFilter(it, filter.whereModel, filter.all, filter.whereChoices) }
        ).apply {
            rowClick = false
        }

        colEx(i18nText(CsawareMessages::name), { observation, cell ->
            val translationModel = TranslationModel(Model.of(observation.description))
            Popovers.markdown { translationModel.data }.modify(cell)
            cell.tabIndex = 0
            Label(observation.name).apply { setModelChangedRerender() }
        }).apply { rowClick = false }
    })

    private val dailySummaryViewer: DailySummaryViewer by lazy { addChild(DailySummaryViewer()) }

    private val actionBar = addChild(ActionBar {
        item(i18nText(CsawareMessages::socialmedia_create_threatobservation), "fas fa-exclamation-triangle") {
            accessRequirement = (MainFeature.Threats to Permission.Write)
            action = ::createThreat
        }
    })

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut {
        return div {
            addClass("vgap-3") // vertical gap between children
            div {
                addClass("hgap-3") // horisontal gap between children
                // add(loadButton.html)
            }
            div {
                addClass("d-flex flex-wrap hgap-4 vgap-4")
                div {
                    addClass("card shadow border-0 flex-fill")
                    withStyle {
                        width = "55vw"
                        maxWidth = "80rem"
                    }
                    addClass("card border-0 shadow")
                    h3 {
                        addClass("card-header text-center hgap-4")
                        add(typeTableTitle.html)
                        add(dateLabel.html)
                        add(playButtons.html)
                    }
                    // addClass("me-auto")
                    div {
                        addClass("card-body text-center")
                        add(actionBar.html)
                        add(graph.html)
                        add(typeTable.html)
                    }
                }
                if (UserInformation.hasAccess(MainFeature.SocialMediaAI, Permission.Read)) {
                    div {
                        addClass("card shadow border-0 flex-fill")
                        withStyle {
                            width = "40vw"
                            maxWidth = "80rem"
                        }
                        add(dailySummaryViewer.html)
                    }
                }
            }
        }
    }

    private fun createThreat() {
        val newThreatId = UUID.generateUuid("tick--")
        val report = ThreatObservation(
            id = newThreatId,
            threatGroup = filter.group,
            name = "",
            description = "",
            assignee = UserInformation.current.email,
            firstObserved = Clock.System.now(),
            endActive = Clock.System.now() + 365.days,
            lastObserved = Clock.System.now(),
            severity = 2,
            whereSightedRefs = mutableSetOf()
        )
        ThreatCreateDlg.show(report, graphService)
    }


    init {
        graph.threats = ThreatOverview(Clock.System.now(), listOf())
        loadData()
        loadHistoryData()
    }

    private fun loadData() {
        updateInfoCount()
        CsawareServices.alerts.clearAlerts()
        // clear any tootltip,popovers
        BootstrapTooltipModifier.removeAll()
        BootstrapPopoverModifier.removeAll()
        CsawareServices.threatsBackend.getThreatGroups { groups: Set<String> ->
            graph.threatGroupsModel.data = groups.toList()
        }
        CsawareServices.threatsBackend.getThreatsOverview(Clock.System.now()) {
            currentThreats = it
            currentShownTime.data = it.activeAt
            graph.threats = it
            graph.redraw()
            loadCurrentType()
        }
    }

    private fun loadHistoryData() {
        for (day in 1..config.nofHistoryDays) {
            val time = Clock.System.now() + (-day).days
            CsawareServices.threatsBackend.getThreatsOverview(time) {
                historyThreats[it.activeAt] = it
            }
        }
    }

    private fun loadCurrentType() {
        CsawareServices.alerts.clearAlerts()
        if (!filter.isFilter()) {
            CsawareServices.threatsBackend.getThreatsCurrentActive(currentShownTime.data, 0, config.topNumberOfThreats) {
                threatTypeListModel.data = it.result
                typeTableTitle.rerender()
            }
        } else {
            CsawareServices.threatsBackend.getThreatsCurrentActiveWithFilter(
                currentShownTime.data,
                filter,
                0,
                config.topNumberOfThreats
            ) {
                threatTypeListModel.data = it.result
                typeTableTitle.rerender()
            }
        }
    }

    // Web Socket updateable
    private val onServerUpdate: (UpdateEvent) -> Unit = { serverUpdated(it) }

    private fun serverUpdated(event: UpdateEvent) {
        if (event.type == UpdateEvent.EntityType.ThreatObservation) {
            loadData()
        }
    }

    private val changeListener = ModelChangeListener { loadCurrentType() }

    override fun attach() {
        super.attach()
        CSAwareBackend.updateListeners.add(onServerUpdate)
        filter.asModel.listeners.add(changeListener)
    }

    override fun detach() {
        CSAwareBackend.updateListeners.remove(onServerUpdate)
        filter.asModel.listeners.remove(changeListener)
        stopPlay()
        super.detach()
    }

    // Play History
    private var timerHandle: Int = -1

    private fun playHandler(action: PlayButtons.PlayAction) {
        when (action) {
            PlayButtons.PlayAction.Play -> startPlay()
            PlayButtons.PlayAction.Stop -> stopPlay()

            PlayButtons.PlayAction.StepForward -> next()
            PlayButtons.PlayAction.StepBackward -> prev()

            PlayButtons.PlayAction.FastForward -> last()
            PlayButtons.PlayAction.FastBackward -> first()
        }
    }

    private fun startPlay() {
        timerHandle = window.setInterval({ next() }, config.playDelayMs)
        playButtons.playing = true
    }

    private fun stopPlay() {
        if (timerHandle != -1)
            window.clearInterval(timerHandle)
        timerHandle = -1
        playButtons.playing = false
    }


    private fun showDate(nextDate: Instant) {
        val next = historyThreats[nextDate] ?: currentThreats
        graph.threats = next
        graph.redraw()
        currentShownTime.data = next.activeAt
//        if (!playButtons.playing) {
        // reload details
        loadCurrentType()
//        }
    }

    private fun allDatesSorted(): MutableList<Instant> {
        val allDates = mutableListOf<Instant>()
        allDates.addAll(historyThreats.keys)
        allDates.add(currentThreats.activeAt)
        allDates.sort()
        return allDates
    }

    fun first() {
        showDate(allDatesSorted().first())
    }

    fun last() {
        // we want to do a reload at this time
        loadData()
    }

    fun prev() {
        val prevDate: Instant = currentShownTime.data
        val allDates = allDatesSorted()
        val nextDate = allDates.reversed().firstOrNull { prevDate > it } ?: allDates.last()
        println("${prevDate.toJSDate().formatDateTime()} ${nextDate.toJSDate().formatDateTime()}")
        showDate(nextDate)
    }

    fun next() {
        val prevDate: Instant = currentShownTime.data
        val allDates = allDatesSorted()
        val nextDate = allDates.firstOrNull { it > prevDate } ?: allDates.first()
        showDate(nextDate)
    }

}