package csaware.policy

import csaware.main.CsawareServices
import csaware.messages.CsawareMessages
import csaware.messages.i18nText
import dk.rheasoft.csaware.api.Policy
import dk.rheasoft.csaware.api.PolicyData
import kafffe.bootstrap.BootstrapTable
import kafffe.core.*
import kafffe.core.modifiers.CssClassModifier
import kafffe.messages.MessagesObject.formatDateTime
import kotlinx.datetime.Clock
import kotlinx.datetime.toJSDate

class PolicySimpleTableReader(entries: List<Policy>) : KafffeComponent() {

    private val selectionModel: Model<Policy> = Model.of(Policy(
        id = "",
        policyData = PolicyData(),
        createdAt = Clock.System.now(),
        updatedAt = Clock.System.now()
    ))

    val table: BootstrapTable<Policy> =
        BootstrapTable.create(entries) {
            applyDefaultStyle()
            rowClickHandler = { data, _ ->
                selectionModel.data = data
            }
            modifiers.add(CssClassModifier("csaware-field"))
            col(i18nText(CsawareMessages::policy_updatedAt), { Label(it.updatedAt.toJSDate().formatDateTime()) })
            col(i18nText(CsawareMessages::policy_title), { Label(it.policyData.title) })
            col(i18nText(CsawareMessages::policy_purpose), { Label(it.policyData.purpose) })
        }

    //private val detailsWidget = addChild(SocialMediaDetails(selectionModel, whereSightedRefsModel , includeTitle = false ))
    private val detailsWidget: KafffeComponent = addChild(
        PolicyDataView(selectionModel, CsawareServices.systemDependencyService)
    )



    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut {
        return div {
            addClass("row")
            div {
                addClass("col-6")
                add(table.html)
            }
            div {
                addClass("col-6")
                add(detailsWidget.html)
            }
        }
    }

}