package csaware.socialmedia

import csaware.main.CsawareServices
import csaware.main.UserInformation
import csaware.utilities.markdown.MarkdownRender
import dk.rheasoft.csaware.api.aiqueries.DailySummaryResult
import kafffe.bootstrap.BootstrapButton
import kafffe.core.KafffeComponent
import kafffe.core.KafffeHtmlBase
import kafffe.core.KafffeHtmlOut
import kafffe.core.Model
import kafffe.core.modifiers.StyleModifier.Companion.styleModifier
import kafffe.messages.MessagesObject.formatDate
import kotlinx.datetime.toJSDate
import org.w3c.dom.HTMLElement
import kotlin.js.Date

/**
 * VIewer that can load render DailySummaries from AI
 */
class DailySummaryViewer : KafffeComponent() {
    private var data = DailySummaryResult(emptyList())
    private var currentIndex = -1

    private fun load() {
        val language = UserInformation.current.preferences.dataPresentationLanguage.shortName
        CsawareServices.socialMediaBackend.aiSummaries(language, 10) {
            data = it
            currentIndex = 0
            rerenderRecursive()
        }
    }

    private val firstButton = addChild(BootstrapButton(Model.of("")) {
        currentIndex = data.summaries.size - 1
        rerenderRecursive()
    })
    private val prevButton = addChild(BootstrapButton(Model.of("")) {
        if (currentIndex < data.summaries.size - 1) {
            currentIndex++
            rerenderRecursive()
        }
    })
    private val nextButton = addChild(BootstrapButton(Model.of("")) {
        if (currentIndex > 0) {
            currentIndex--
            rerenderRecursive()
        }
    })
    private val lastButton = addChild(BootstrapButton(Model.of("")) {
        currentIndex = 0
        rerenderRecursive()
    })

    init {
        load()
        firstButton.iconClasses = "fas fa-fast-backward"
        prevButton.iconClasses = "fas fa-step-backward"
        nextButton.iconClasses = "fas fa-step-forward"
        lastButton.iconClasses = "fas fa-fast-forward"
    }

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut = div {
        if (currentIndex in 0..data.summaries.size) {
            h3 {
                addClass("hgap-3 text-center card-header")
                add(firstButton.html)
                add(prevButton.html)
                span {
                    addClass("ps-3 pe-3")
                    val localDate = data.summaries[currentIndex].day
                    val date = Date(localDate.year, localDate.monthNumber - 1, localDate.dayOfMonth)
                    text(date.formatDate())
                }
                add(nextButton.html)
                add(lastButton.html)
            }
            val markdown: HTMLElement = MarkdownRender(data.summaries[currentIndex].text).html
            markdown.style.maxHeight = "90vh"
            add(markdown)
        }
    }

}