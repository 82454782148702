package csaware.kpi_statistics

import csaware.main.CsawareServices
import dk.rheasoft.csaware.api.statistics.StatisticEntryWithAverages
import dk.rheasoft.csaware.utils.JsonUtilSerialization
import externals.echarts.*
import kafffe.core.KafffeComponent
import kafffe.core.KafffeHtmlBase
import kafffe.core.KafffeHtmlOut
import kafffe.core.Model
import kafffe.utils.DomObserverUtils
import org.w3c.dom.HTMLDivElement

class QuestionnaireColumnChart(
    val titleModel: Model<String>,
    val xAxisLegend: String = "",
    val yAxisLegend: String = "Out of 100",
) : KafffeComponent() {

    private val entries: MutableMap<String, Float> = mutableMapOf()
    private val averageEntries: MutableMap<String, Float> = mutableMapOf()
    private val averageEcoEntries: MutableMap<String, Float> = mutableMapOf()
    var height: String by rerenderOnChange("40rem")
    var width: String by rerenderOnChange("60rem")

    init {
        load()
    }

    private fun load() {
        val parameters = mapOf(
            "title" to "questionnaire",
            "countType" to "TOTAL",
            "offset" to "0",
            "limit" to "1"
        )
        for (dim in KpiDimensions.entries) {
            val parms = parameters + ("subTitle" to dim.questionnaireSubTitle)
            CsawareServices.backend.getTxt("/statistics/counter/with_average/newest", parms) {
                val list: List<StatisticEntryWithAverages> = JsonUtilSerialization.json.decodeFromString(it)
                list.firstOrNull()?.let {
                    entries[dim.questionnaireSubTitle] = it.value
                    averageEntries[dim.questionnaireSubTitle] = it.average
                    averageEcoEntries[dim.questionnaireSubTitle] = it.averageEcoSystem
                    rerender()
                }
            }
        }
    }

    private var myChart: ZRender? = null
    fun chart(chartElement: HTMLDivElement) {
        myChart = init(chartElement)
        var option: EChartsOption = js(
            """{
                   xAxis : {
                        type: 'category',
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                        axisLabel: {
                            rotate : 10,
                            align: 'center',
                            verticalAlign: 'top',
                            padding: 30
                        }
                   },
                   yAxis: { type: 'value' },
                   legend: { data: ['This', 'Average'] },
                   series: [
                        {
                            data: [120, 200, 150, 80, 70, 110, 130],
                            type: 'bar'
                        }
                   ]
            }
            """
        ).unsafeCast<EChartsOption>()
        option.tooltip = js("{}")
        with(option.tooltip as TooltipOption) {
            trigger = "axis"
            axisPointer = js("{}")
            axisPointer!!.type = "shadow"
        }

        if (xAxisLegend.isNotEmpty()) {
            with(option.xAxis) {
                name = xAxisLegend
                nameLocation = "middle"
                nameGap = "30"
                nameTextStyle = js("{}")
                with(nameTextStyle) {
                    fontSize = 14
                    fontWeight = "bold"
                    color = "#333"
                }
            }
        }
        if (yAxisLegend.isNotEmpty()) {
            with(option.yAxis) {
                name = yAxisLegend
                nameGap = "30"
                nameLocation = "middle"
                nameTextStyle = js("{}")
                with(nameTextStyle) {
                    fontSize = 14
                    fontWeight = "bold"
                    color = "#333"
                }
            }
        }

        option.xAxis.data = KpiDimensions.entries.map { it.title }.toTypedArray()

        var ix = 0
        val barSeriesOptions = (option.series as Array<BarSeriesOption>)

        addSeries(barSeriesOptions,ix++, "My organisation", entries, "#FFAC1C")
        if (averageEcoEntries != entries) {
            addSeries(barSeriesOptions, ix++, "Average ecosystem", averageEcoEntries, "#32cd32")
        }
        if (averageEntries != entries) {
            addSeries(barSeriesOptions, ix++, "Average all", averageEntries, "#4AB5E3")
        }
        option.legend.data =  barSeriesOptions.map { it.name }.toTypedArray()


        console.log(JSON.stringify(option))
        myChart?.setOption(option)
    }

    private fun addSeries(
        barSeriesOptions: Array<BarSeriesOption>,
        ix: Int,
        name: String,
        entries1: Map<String, Float>,
        colour: String
    ) {
        val average = js("{}") as BarSeriesOption
        average.name = name
        average.type = "bar"
        average.data = KpiDimensions.entries.map { entries1[it.questionnaireSubTitle] ?: 0.0 }.toTypedArray()
        barSeriesOptions[ix] = average
        barSeriesOptions[ix].itemStyle = js("{}")
        barSeriesOptions[ix].itemStyle!!.color = colour
    }

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut =
        div {
            addClass("csaware_counter_column_chart")
            withElement {
                onclick = { toggleSize() }
                style.height = height
                style.width = width
            }
            DomObserverUtils.whenVisibleInViewport(this.element) {
                chart(it)
            }
        }

    private var zoomed = false
    private fun HTMLDivElement.toggleSize() {
        if (zoomed) {
            style.height = height
            style.width = width
            myChart?.resize()
        } else {
            style.height = "80vh"
            style.width = "80vw"
            myChart?.resize()
        }
        zoomed = !zoomed
    }
}