package csaware.comm

import dk.rheasoft.csaware.api.QueryResult
import dk.rheasoft.csaware.api.incident.AnomalyWrapper
import dk.rheasoft.csaware.api.incident.ThreatObservation
import dk.rheasoft.csaware.utils.JsonUtilSerialization
import kotlinx.serialization.encodeToString

open class AnomalyBackend(exceptionHandler: (errorCode: Int, errorText: String) -> Unit) : CSAwareBackend(exceptionHandler) {

    fun getByState(
        state: AnomalyWrapper.State,
        offset: Int,
        pagesize: Int,
        receiver: (QueryResult<AnomalyWrapper>) -> Unit
    ) {
        getTxt("/threats/anomaly/listByState?state=${state.name}&offset=$offset&limit=$pagesize") {
            val x: QueryResult<AnomalyWrapper> = JsonUtilSerialization.json.decodeFromString(it)
            receiver(x)
        }
    }

    fun storeIgnored(
        tobeIgnored: List<AnomalyWrapper>,
        receiver: () -> Unit
    ) {
        val json = JsonUtilSerialization.json.encodeToString(tobeIgnored)
        postJson("/threats/anomaly/ignore", json) {
            receiver()
        }
    }

    fun storeIncident(incident: ThreatObservation, receiver: () -> Unit) {
        val json = JsonUtilSerialization.json.encodeToString(incident)
        postJson("/threats/anomaly/incident", json) {
            receiver()
        }
    }

}

