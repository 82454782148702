package csaware.systemdepend.nodedetails

import bootstrap.Collapse
import csaware.systemdepend.SystemDependencyService
import dk.rheasoft.csaware.api.systemdependencies.SystemDependencyResource
import kafffe.core.*
import kotlinx.dom.addClass
import kotlinx.dom.removeClass
import org.w3c.dom.HTMLButtonElement
import org.w3c.dom.HTMLDivElement


class NodeDetails(
    selectionModel: Model<SystemDependencyResource>,
    hightlightModel: Model<String>,
    private val graphService: SystemDependencyService
) : KafffeComponentWithModel<SystemDependencyResource>(selectionModel) {
    init {
        setModelChangedRerenderRecursive()
    }

    companion object {
        var threatCollapse : Model<Boolean> = Model.of(true)
        var someCollapse : Model<Boolean> = Model.of(true)
        var detailsCollapse : Model<Boolean> = Model.of(true)
        var policiesCollapse : Model<Boolean> = Model.of(true)
    }

    private val details = addChild(SystemDependencyResourceDetails(model, hightlightModel, graphService))
    private val threats = addChild(Threats(model, graphService))
    private val socialMedia = addChild(SocialMedia(model, graphService))
    private val policies = addChild(Policies(model, graphService))

    override fun KafffeHtmlBase.kafffeHtml() =
            div {
                addClass("accordion")
                withElement {
                    style.apply {
                        width = "100%"
                        maxHeight = "85vh"
                        overflowY = "auto"
                    }
                }
                accordionSection(threatCollapse, threats.title, threats)
                accordionSection(someCollapse, socialMedia.title, socialMedia)
                accordionSection(detailsCollapse, details.title, details)
                accordionSection(policiesCollapse, policies.title, policies)
            }

    private fun KafffeHtml<HTMLDivElement>.accordionSection(
        collapseModel: Model<Boolean>,
        sectionHeader: KafffeComponent,
        child: KafffeComponent
    ) {
        lateinit var btn: HTMLButtonElement
        lateinit var section: HTMLDivElement
        div {
            addClass("accordion-item")
            h2 {
                addClass("accordion-header")
                button {
                    addClass("accordion-button")
                    withElement {
                        type = "button"
                        btn = this
                        if (collapseModel.data)  btn.removeClass("collapsed") else btn.addClass("collapsed")
                    }
                    add(sectionHeader.html)
                }
            }
            div {
                addClass("accordion-collapse accordion-body")
                addClass(if (collapseModel.data) "collapse" else "show")
                section= this.element
                    add(child.html)
            }
        }
        val collapse = Collapse(section)
        btn.onclick = {
            collapseModel.data = !collapseModel.data
            collapse.toggle()
            if (collapseModel.data) btn.removeClass("collapsed") else btn.addClass("collapsed")
        }
    }


}