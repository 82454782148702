package dk.rheasoft.csaware.api

import dk.rheasoft.csaware.utils.JsonUtilSerialization
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.decodeFromJsonElement


@Serializable
data class Policy(
    var policytype: PolicyType = PolicyType.ORGANISATION,
    var id:String,
    var state:PolicyState = PolicyState.DRAFT,
    var policyData: PolicyData,
    val createdAt: Instant,
    val createdBy: String="",
    var updatedAt: Instant,
    var updatedBy: String="",
    var accessLevel: AccessLevel = AccessLevel.OrganisationalPrivate,

    ) {

//YYYY-MM-DDTHH:MM:SS.ssssssZ
    fun toJsonString(): String =
        JsonUtilSerialization.json.encodeToString(this)

    companion object {
        fun QueryResult<Policy>.toJson(): String =
            JsonUtilSerialization.json.encodeToString(QueryResult.serializer(serializer()), this)

        fun fromQueryResultJson(json: String): QueryResult<Policy> =
            JsonUtilSerialization.json.decodeFromString(QueryResult.serializer(serializer()), json)

        fun fromJson(json: JsonObject): Policy=
            JsonUtilSerialization.json.decodeFromJsonElement(json)

        fun fromJson(json: String): Policy =
            JsonUtilSerialization.json.decodeFromString(json)

    }
}

@Serializable
data class PolicyWithApproval(
    var policy: Policy,
    val approvalComment: String
    ) {
    fun toJsonString(): String =
        JsonUtilSerialization.json.encodeToString(this)

    companion object {
        fun QueryResult<PolicyWithApproval>.toJson(): String =
            JsonUtilSerialization.json.encodeToString(QueryResult.serializer(serializer()), this)

        fun fromQueryResultJson(json: String): QueryResult<PolicyWithApproval> =
            JsonUtilSerialization.json.decodeFromString(QueryResult.serializer(serializer()), json)

        fun fromJson(json: JsonObject): PolicyWithApproval=
            JsonUtilSerialization.json.decodeFromJsonElement(json)

        fun fromJson(json: String): PolicyWithApproval =
            JsonUtilSerialization.json.decodeFromString(json)

    }
}

@Serializable
data class InsertOrUpdatePolicyResult(
    var policyId: String,
    val success: Boolean
) {
    fun toJsonString(): String =
        JsonUtilSerialization.json.encodeToString(this)

    companion object {

        fun toJsonString(): String =
            JsonUtilSerialization.json.encodeToString(this)

        fun fromJson(json: String): InsertOrUpdatePolicyResult =
            JsonUtilSerialization.json.decodeFromString(json)

    }
}


@Serializable
data class PolicyData(
    var title: String = "",
    var purpose: String = "",
    var elements: String="",
    var need: String="",
    var rolesResponsibilities: String="",
    var references: String="",
    val systemNodeReferences: MutableSet<String> = mutableSetOf(),
    var tags: List<String> = emptyList()) {

    fun toJsonString(): String =
        JsonUtilSerialization.json.encodeToString(this)

    companion object {
        fun QueryResult<PolicyData>.toJson(): String =
            JsonUtilSerialization.json.encodeToString(QueryResult.serializer(serializer()), this)

        fun fromQueryResultJson(json: String): QueryResult<PolicyData> =
            JsonUtilSerialization.json.decodeFromString(QueryResult.serializer(serializer()), json)


        fun fromJson(json: JsonObject): PolicyData =
            JsonUtilSerialization.json.decodeFromJsonElement(json)

        fun fromJson(json: String): PolicyData =
            JsonUtilSerialization.json.decodeFromString(json)

    }
}

@Serializable
/**
 * A working copy edit, created when am edit to Policy is stored, identified by policyId and createdAt
 */
data class PolicyEditHistoryElement(
    var policyId:String,
    var state:PolicyState = PolicyState.DRAFT,
    var policyData: PolicyData,
    val createdAt: Instant,
    val createdBy: String="",
    var accessLevel: AccessLevel = AccessLevel.OrganisationalPrivate,
    ) {


    fun toJsonString(): String =
        JsonUtilSerialization.json.encodeToString(this)

    companion object {
        fun QueryResult<PolicyEditHistoryElement>.toJson(): String =
            JsonUtilSerialization.json.encodeToString(QueryResult.serializer(serializer()), this)

        fun fromQueryResultJson(json: String): QueryResult<PolicyEditHistoryElement> =
            JsonUtilSerialization.json.decodeFromString(QueryResult.serializer(serializer()), json)

        fun fromJson(json: JsonObject): PolicyEditHistoryElement =
            JsonUtilSerialization.json.decodeFromJsonElement(json)

        fun fromJson(json: String): PolicyEditHistoryElement  =
            JsonUtilSerialization.json.decodeFromString(json)

    }
}

/**
 * User comments to policy editing, identified by policyID and createdAt
 */
@Serializable
data class PolicyComment(
    var id: String,
    var policyId: String,
    var responseToCommentId: String?,
    var comment: String,
    val createdAt: Instant,
    val createdBy: String="",
    val updatedAt: Instant,
    val updatedBy: String="",

    ) {


    fun toJsonString(): String =
        JsonUtilSerialization.json.encodeToString(this)

    companion object {
        fun QueryResult<PolicyComment>.toJson(): String =
            JsonUtilSerialization.json.encodeToString(QueryResult.serializer(serializer()), this)

        fun fromQueryResultJson(json: String): QueryResult<PolicyComment> =
            JsonUtilSerialization.json.decodeFromString(QueryResult.serializer(serializer()), json)

        fun fromJson(json: JsonObject): PolicyComment=
            JsonUtilSerialization.json.decodeFromJsonElement(json)

        fun fromJson(json: String): PolicyComment =
            JsonUtilSerialization.json.decodeFromString(json)

    }
}

@Serializable
enum class PolicyState{
    DRAFT,
    APPROVED,
    DRAFT_WITH_APPROVED,
    OBSOLETE,
    UNKNOWN;

    companion object {
        fun parse(value: String): PolicyState = try {
            PolicyState.valueOf(value)
        } catch (any: Throwable) {
            UNKNOWN
        }
    }
}

enum class PolicyType{
    ORGANISATION,
    TEMPLATE;

    companion object {
        fun parse(value: String): PolicyType = try {
            PolicyType.valueOf(value)
        } catch (any: Throwable) {
            ORGANISATION
        }
    }
}

@Serializable
data class PolicyDataExtractRequest(val languageCode: String, val text: String) {
    fun toJsonString(): String =
        JsonUtilSerialization.json.encodeToString(this)

    companion object {
        fun fromJson(json: String): PolicyDataExtractRequest =
            JsonUtilSerialization.json.decodeFromString(json)
    }
}

@Serializable
data class PolicyDataExtractResponse(val languageCode: String, val policyData: PolicyData) {
    fun toJsonString(): String =
        JsonUtilSerialization.json.encodeToString(this)

    companion object {
        fun fromJson(json: String): PolicyDataExtractResponse =
            JsonUtilSerialization.json.decodeFromString(json)
    }
}
