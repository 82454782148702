package csaware.main

import csaware.kpi_statistics.StatisticsView
import csaware.utilities.componentTitle
import kafffe.core.KafffeComponent
import kafffe.core.KafffeHtml
import kafffe.core.KafffeHtmlBase
import kafffe.core.kafffenv
import kafffe.core.modifiers.CssClassModifier.Companion.cssClassModifier
import org.w3c.dom.HTMLElement

class About : KafffeComponent() {
    data class UsedSw(val name: String, val link: String)

    private val useSw = listOf(
        UsedSw("Kotlin", "https://kotlinlang.org/"),
        UsedSw("Fuel", "https://fuel.gitbook.io"),
        UsedSw("Sparkjava", "http://sparkjava.com/"),
        UsedSw("JSON Web Token", "https://www.jsonwebtoken.io/"),
        UsedSw("Bootstrap", "https://getbootstrap.com/docs/5.2/about/overview/"),
        UsedSw("Flag icons", "https://www.countryflags.com/"),
        UsedSw("Fontawesome", "https://fontawesome.com"),
        UsedSw("Apache POI", "https://poi.apache.org/"),
        UsedSw("Apache Tika", "https://tika.apache.org/"),
        UsedSw("Flying Saucer PDF", "https://github.com/flyingsaucerproject/flyingsaucer"),
    )

    private val statistics = addChild(StatisticsView()).apply {
        cssClassModifier("bg-info p-2")
    }

    override fun KafffeHtmlBase.kafffeHtml() =
        div {
            addClass("row")
            div {
                addClass("col-lg-7 col-md-12")
                componentTitle {
                    text("About CS-Aware")
                }
                p {
                    +"CS-AWARE is a holistic cybersecurity awareness solution, aimed at local public administrations, "
                    +"non-governmental organizations and "
                    +"small & medium enterprises(SME), to protect against and detect cyber attacks. "
                }
                p {
                    +"CS-AWARE has received funding from the European Union’s Horizon 2020 research and innovation"
                    +" programme under grant agreement No 740723. "
                    +"The follow-up project: CS-AWARE-NEXT, has received funding from Horizon Europe grant "
                    +"agreement No 101069543. "
                }
                p { +"The European Commission is not responsible for any use that may be made of the information it contains." }
                p {
                    a("https://cs-aware.com") {
                        element.target = "_blank"
                        +"Read more about the original project and the company"
                    }
                }

                p {
                    //  a("https://cs-aware.eu/project-partners/") {
                    a(hrefValue = "https://www.cs-aware-next.eu/project_overview/") {
                        element.target = "_blank"
                        +"Read more about the current project and project partners"
                    }
                }
                h2 {
                    text("Used software")
                }
                ul {
                    useSw.forEach {
                        li {
                            a(it.link) {
                                element.target = "_blank"
                                text(it.name)
                            }
                        }
                    }
                }

            }
            div {
                addClass("col-lg-5 col-md-12")
                div {
                    addClass("bg-info mb-4 p-2")
                    h2 {
                        text("Environment")
                    }
                    environmentValue("Name:", kafffenv.name as String)
                    environmentValue("Id:", kafffenv.environmentId as String)
                    environmentValue("Version:", kafffenv.version as String)
                    environmentValue("Build time:", kafffenv.buildtime as String)
                    img {
                        withElement {
                            src = "images/SVG-logo-cs-aware_white.svg"
                            style.maxWidth = "80%"
                        }
                    }
                }

                add(statistics.html)
            }
        }

    private fun <T : HTMLElement> KafffeHtml<T>.environmentValue(label: String, value: String) {
        div {
            span {
                element.style.apply {
                    width = "14rem"
                    display = "inline-block"
                    fontWeight = "700"
                }
                text(label)
            }
            text(value)
        }
    }
}