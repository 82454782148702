package dk.rheasoft.csaware.api.systemdependencies

import dk.rheasoft.csaware.api.LayoutDirection
import dk.rheasoft.csaware.utils.JsonUtilSerialization
import kotlinx.serialization.Serializable
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.decodeFromJsonElement
import kotlinx.serialization.json.encodeToJsonElement
import kotlinx.serialization.json.jsonObject

/**
 * Holds data of a resource from Graphing "Wiki".
 */
@Serializable
data class SystemDependencyConfig(
    var fields: MutableList<SystemGraphField> = mutableListOf(),
    var valueSets: MutableMap<String, MutableSet<String>> = mutableMapOf(),
    var layoutDirection: LayoutDirection = LayoutDirection.vertical,
    var rootNodeIds: MutableList<String> = mutableListOf(),
    var spacing: Int = 45,
    var includeSource: Boolean = true,
    @Deprecated("Included in nodeTypes")
    private var shapeMap: MutableMap<String, String> = mutableMapOf(),
    var nodeTypes: MutableList<NodeType> = mutableListOf(),
    var views: MutableList<GraphView> = mutableListOf(),
) {

    val edgeStyleMap: Map<String, String>
        get() = dependencyFields().associateBy { it.id }.mapValues { it.value.dashStylePattern }

    fun dependencyFields(): List<SystemGraphField> =
        if (includeSource) {
            fields.filter { it.type == FieldType.DEPENDENCY } + sourceField
        } else {
            fields.filter { it.type == FieldType.DEPENDENCY }
        }


    fun getValueSet(key: String): MutableSet<String> = valueSets.getOrPut(key) { mutableSetOf() }
    fun getInfoflowValues(): List<String> = getValueSet(infoflowValueSet).toList()

    fun addValueList(name: String) {
        valueSets[name] = mutableSetOf()
    }

    fun findField(fieldId: String): SystemGraphField? = fields.find { it.id == fieldId }
    fun findFieldsOfType(fieldType: FieldType): List<SystemGraphField> = fields.filter { it.type == fieldType }


    /**
     * Remove undefined fields from resource
     */
    fun removeUndefinedFields(resource: SystemDependencyResource) {
        val singleFieldsIds = fields.filter { it.cardinality.isSingle }.map { it.id }
        resource.data.keys.forEach { if (it !in singleFieldsIds) resource.data.remove(it) }
        val multiFieldsIds = fields.filter { it.cardinality.isMany }.map { it.id }
        resource.dataLists.keys.forEach { if (it !in multiFieldsIds) resource.dataLists.remove(it) }
    }


    fun toJsonString(): String =
        JsonUtilSerialization.json.encodeToString(this)

    fun toJsonObject(): JsonObject =
        JsonUtilSerialization.json.encodeToJsonElement(this).jsonObject

    fun nodeType(nodeTypeId: String?): NodeType? = nodeTypes.find { it.id == nodeTypeId }

    init {
        upgradeToUseNodetypes()
        upgradeToIncludeGraphView()
    }

    private fun upgradeToIncludeGraphView() {
        if (views.isEmpty()) {
            views = mutableListOf(
                GraphView(
                    "All Nodes",
                    includeAllTypes = true
                )
            )
        }
    }

    /**
     * Convert old config to one with explicit NodeTypes
     */
    private fun upgradeToUseNodetypes() {
        if (nodeTypes.isEmpty()) {
            val nodeTypeIds = valueSets["node_type"] ?: mutableListOf()
            nodeTypes = nodeTypeIds.map {
                NodeType(
                    id = it,
                    name = it,
                    shape = shapeMap[it] ?: "rectangle_shape",
                    // defaultFields =
                )
            }.toMutableList()

            shapeMap.clear()
            valueSets.remove("node_type")
            findField("x_csaware_node_type")?.let { field ->
                fields.remove(field)
            }
        }
    }

    companion object {
        /**
         * Pseudo field for the default dependencies, which are handled a bit different from other depnedncy fields.
         */
        val sourceField =
            SystemGraphField("source", "Source", FieldType.DEPENDENCY, Cardinality.ZeroToMany, selectedByDefault = true)

        /** Name of value set used for Information Flows*/
        const val infoflowValueSet = "infoflow"
        fun default(): SystemDependencyConfig {
            val defaultJson = """
                {
                  "fields": [
                    {
                      "id": "x_csaware_node_type",
                      "label": "Node Type",
                      "valueSet": "node_type"
                    },
                    {
                      "id": "x_categories",
                      "label": "Category",
                      "cardinality": "ZeroToMany"
                    },
                    {
                      "id": "x_csaware_ip",
                      "type": "IPv4",
                      "label": "IPv4",
                      "cardinality": "ZeroToMany"
                    },
                    {
                      "id": "x_csaware_depends_on",
                      "type": "DEPENDENCY",
                      "label": "Uses",
                      "cardinality": "ZeroToMany",
                      "dashStylePattern": "4 2 1 2",
                      "selectedByDefault": true 
                    }
                  ],
                  "spacing": 47,
                  "shapeMap": {
                    "app": "App",
                    "kurt": "Secret-user",
                    "pump": "Pump",
                    "tank": "Tank",
                    "user": "User",
                    "wifi": "Wifi",
                    "actor": "Actor",
                    "globe": "Data center",
                    "users": "Users",
                    "laptop": "Laptop",
                    "region": "Region",
                    "router": "Router",
                    "server": "Server",
                    "switch": "Switch",
                    "tablet": "Tablet",
                    "service": "ellipse_shape",
                    "building": "House",
                    "database": "Database",
                    "firewall": "Firewall",
                    "internet": "Cloud",
                    "personel": "Personel",
                    "triangle": "Tank",
                    "committee": "Cap",
                    "telemetry": "Telemetry",
                    "workstation": "Workstation",
                    "access point": "User customer",
                    "organisation": "rectangle_shape",
                    "backup server": "Server backup",
                    "environmental": "Environmental",
                    "building_official": "Building official"
                  },
                  "valueSets": {
                    "infoflow": [
                      "ERP_DATA",
                      "GIS",
                      "TEST"
                    ],
                    "node_type": [
                      "firewall",
                      "internet",
                      "database",
                      "router",
                      "switch",
                      "building",
                      "committee",
                      "server",
                      "actor",
                      "personel",
                      "telemetry",
                      "access point",
                      "wifi",
                      "workstation",
                      "tablet",
                      "building_official",
                      "region",
                      "app",
                      "laptop",
                      "tank",
                      "pump",
                      "environmental",
                      "kurt",
                      "triangle",
                      "user",
                      "users",
                      "globe",
                      "backup server",
                      "service",
                      "organisation"
                    ]
                  },
                  "rootNodeIds": [
                    "identity--e.2E7-64be.7-4bd4.8-85a6.1-d.3E1b"
                  ],
                    "edgeStyleMap": {
                      "x_csaware_depends_on": "4 2"
                    },
                  "layoutDirection": "horizontal"
                }
            """.trimIndent()
            return fromJson(defaultJson)
        }

        fun defaultEcoSystem(): SystemDependencyConfig {
            val defaultJson = """{
          "fields": [
            {
              "id": "x_csaware_node_type",
              "label": "Node Type",
              "valueSet": "node_type"
            },
            {
              "id": "x_categories",
              "label": "Category",
              "cardinality": "ZeroToMany"
            },
            {
              "id": "x_csaware_managed_by",
              "type": "DEPENDENCY",
              "label": "Managed by",
              "cardinality": "ZeroToMany",
              "selectedByDefault": true 
            },
            {
              "id": "x_csaware_supplied_by",
              "type": "DEPENDENCY",
              "label": "Supplied by",
              "cardinality": "ZeroToMany",
              "dashStylePattern": "4 2 1 2",
              "selectedByDefault": true 
            }
          ],
          "spacing": 47,
          "shapeMap": {
            "service": "ellipse_shape",
            "organisation": "rectangle_shape"
          },
          "valueSets": {
            "infoflow": [
              "ERP_DATA",
              "GIS",
              "TEST"
            ],
            "node_type": [
              "service",
              "organisation"
            ]
          },
          "layoutDirection": "horizontal",
          "includeSource": false
        }""".trimIndent()
            return fromJson(defaultJson)
        }

        fun fromJson(json: JsonObject): SystemDependencyConfig =
            JsonUtilSerialization.json.decodeFromJsonElement(json)

        fun fromJson(json: String): SystemDependencyConfig =
            JsonUtilSerialization.json.decodeFromString(json)

    }

    fun inheritanceChain(nodeTypeId: String?): List<NodeType> {
        val nodeTypesById: Map<String, NodeType> = nodeTypes.associateBy { it.id }
        val nodeType = nodeTypesById[nodeTypeId]
        return if (nodeType == null) {
            emptyList()
        } else {
            listOf(nodeType) + inheritanceChain(nodeType.inheritsNodeTypeId)
        }
    }


    /**
     * Get all node types that are included in the given [view].
     * This includes all node types that are in [GraphView.includedTypes],
     * and also all node types that are descendants of node types in [GraphView.includedTypesWithDescendants].
     *
     * @return A list of all included node types.
     */
    fun nodetypesInView(view: GraphView): List<NodeType> {
        return nodeTypes.filter {
            it.id in view.includedTypes || inheritanceChain(it.id).any { it.id in view.includedTypesWithDescendants }
        }
    }

}

