package dk.rheasoft.csaware.api.policy

import dk.rheasoft.csaware.api.config.SystemLevelConfig
import kotlinx.serialization.Serializable

@Serializable
data class PolicyTags(var tags: MutableList<String> = mutableListOf())

private const val POLICY_TAGS = "policy.tags"

fun SystemLevelConfig.policyTags() :PolicyTags =
    getTyped(POLICY_TAGS, PolicyTags())
fun SystemLevelConfig.setPolicyTags(value: PolicyTags) {
    setTyped(POLICY_TAGS, value)
}

fun SystemLevelConfig.subsetPolicyTags(): SystemLevelConfig =
    this.subset(setOf(POLICY_TAGS))
