package csaware.threats

import csaware.messages.CsawareMessages
import csaware.messages.i18nText
import dk.rheasoft.csaware.api.incident.ThreatObservation
import kafffe.bootstrap.BootstrapTable
import kafffe.core.*
import kafffe.core.modifiers.CssClassModifier
import kafffe.messages.MessagesObject.formatDateTime
import kotlinx.datetime.toJSDate

class ThreatSimpleTableReader(
    entries: List<ThreatObservation>,
) : KafffeComponent() {

    private val selectionModel: Model<ThreatObservation> = Model.of(ThreatObservation.empty())

    val table: BootstrapTable<ThreatObservation> =
        BootstrapTable.Companion.create(entries) {
            applyDefaultStyle()
            rowClickHandler = { data, _ ->
                selectionModel.data = data
            }
            modifiers.add(CssClassModifier("csaware-field"))
            col(i18nText(CsawareMessages::timestamp), { Label(it.lastObserved.toJSDate().formatDateTime()) })
            col(i18nText(CsawareMessages::name), { Label(it.name) })
            col(i18nText(CsawareMessages::description), { Label(it.description) })
        }

    //private val detailsWidget = addChild(SocialMediaDetails(selectionModel, whereSightedRefsModel , includeTitle = false ))
    private val detailsWidget: KafffeComponent = addChild(
        ThreatReadonlyView(selectionModel)
    )

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut {
        return div {
            addClass("row")
            div {
                addClass("col-6")
                add(table.html)
            }
            div {
                addClass("col-6")
                add(detailsWidget.html)
            }
        }
    }

}