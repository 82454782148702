package csaware.socialmedia

import csaware.main.CsawareServices
import csaware.systemdepend.SystemDependencyService
import dk.rheasoft.csaware.api.QueryResult
import dk.rheasoft.csaware.api.socialmedia.SocialMediaAccount
import dk.rheasoft.csaware.api.socialmedia.SocialMediaEntry
import kafffe.bootstrap.BootstrapTable
import kafffe.core.*


class SocialMediaSimpleTableReader(
    entries: List<SocialMediaEntry>,
    private val graphService: SystemDependencyService = CsawareServices.systemDependencyService
) : KafffeComponent() {

    private var accountById: Map<String, SocialMediaAccount> = mapOf()
    private val selectionModel: Model<SocialMediaEntry?> = ModelNullable()

    val table: BootstrapTable<SocialMediaEntry> =
        addChild(SocialMediaObservationTable.create(accountById, selectionModel, graphService, small = true))

    // Common
    private val whereSightedRefsModel: Model<Set<String>> = Model.ofGet {
        graphService
            .findAllHavingByKeywords(selectionModel.data?.matches ?: emptySet())
            .map { it.id }
            .toSet()
    }

    //private val detailsWidget = addChild(SocialMediaDetails(selectionModel, whereSightedRefsModel , includeTitle = false ))
    private val detailsWidget = addChild(SocialMediaDetails(
        selectionModel,
        whereSightedRefsModel,
        graphService,
        includeTitle = false
    ))

    init {
        loadAccounts()
        table.data = entries
    }

    private fun loadAccounts() {
        CsawareServices.alerts.clearAlerts()

        CsawareServices.socialMediaBackend.getSocialMediaAccounts(
            0,
            1000
        ) { accountResult: QueryResult<SocialMediaAccount> ->
            accountById = accountResult.result.associateBy { it.id }
            SocialMediaObservationTable.accountById = accountById
            table.rerender()
        }
    }

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut {
        return div {
            addClass("row")
            div {
                addClass("col-6")
                add(table.html)
            }
            div {
                addClass("col-6")
                add(detailsWidget.html)
            }
        }
    }

}