/**
 * Helper functions to access the data and setup rand data for testing
 */
package csaware.overview

import dk.rheasoft.csaware.api.incident.ThreatGroupOverview
import dk.rheasoft.csaware.api.incident.ThreatOverview


fun noThreat(type: String) = ThreatGroupOverview(type, 0, 0.0, 0, 0)

/**
 * Finds the maximum threat level of all the threats
 */
fun ThreatOverview.level() = threatGroups.maxOfOrNull { it.severityMax } ?: 0

/**
 * Gets a the threat object of the given type or a No Threat Object for the type
 */
operator fun ThreatOverview.get(threatType: String): ThreatGroupOverview = threatGroups.find { threatType == it.group }
        ?: noThreat(threatType)

/**
 * Calculate the threat ratio. Return a number between 0.0 and 1.0 where 0.0 is no threat and 1.0 is full threat.
 */
fun ThreatGroupOverview.levelRatio(maxSeveritySum: Double): Double {
    val weightedAvgRatio = severityAvg * countSum.toDouble() / maxSeveritySum
    val maxRatio = severityMax / 5.0
    // severityMax count 2 times as much as the average
    return (weightedAvgRatio + 2.0 * maxRatio) / 3.0
}

