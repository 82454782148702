package dk.rheasoft.csaware.api.access

@Suppress("EnumEntryName")
enum class UserRole(val grants: List<Grant>, val allowedUserAdministration: Boolean = true) {
    Viewer(
        listOf(
            Grant(MainFeature.AutomaticTranslation, setOf(Permission.Execute)),
            Grant(MainFeature.InformationSharing, Permission.readonly),
            Grant(MainFeature.SocialMedia, Permission.readonly),
            Grant(MainFeature.SocialMediaAI, Permission.all),
            Grant(MainFeature.SystemDependencies, Permission.readonly),
            Grant(MainFeature.SystemDependencyConfig, Permission.readonly),
            Grant(MainFeature.Threats, Permission.readonly),
            Grant(MainFeature.Anomalies, Permission.readonly),
            Grant(MainFeature.Policy,Permission.readonly),
            Grant(MainFeature.EcoSystem,Permission.readonly),
        )
    ),
    Viewer_No_AI(
        listOf(
            Grant(MainFeature.AutomaticTranslation, setOf(Permission.Execute)),
            Grant(MainFeature.InformationSharing, Permission.readonly),
            Grant(MainFeature.SocialMedia, Permission.readonly),
            Grant(MainFeature.SystemDependencies, Permission.readonly),
            Grant(MainFeature.SystemDependencyConfig, Permission.readonly),
            Grant(MainFeature.Threats, Permission.readonly),
            Grant(MainFeature.Anomalies, Permission.readonly),
            Grant(MainFeature.Policy,Permission.readonly),
            Grant(MainFeature.EcoSystem,Permission.readonly),
        )
    ),
    UserAdministrator(
        listOf(
            Grant(MainFeature.UserManagement, Permission.all),
            Grant(MainFeature.Email, setOf(Permission.Execute)),
        )
    ),
    SystemAdministrator(
        listOf(
            Grant(MainFeature.AutomaticTranslation, setOf(Permission.Execute)),
            Grant(MainFeature.InformationSharing, Permission.all),
            Grant(MainFeature.SocialMedia, Permission.all),
            Grant(MainFeature.SocialMediaAccount, Permission.all),
            Grant(MainFeature.SocialMediaAI, Permission.all),
            Grant(MainFeature.SystemDependencies, Permission.all),
            Grant(MainFeature.SystemDependencyConfig, Permission.all),
            Grant(MainFeature.SystemDependencyBackup, Permission.all),
            Grant(MainFeature.Threats, Permission.all),
            Grant(MainFeature.Anomalies, Permission.all),
            Grant(MainFeature.Email, setOf(Permission.Execute)),
            Grant(MainFeature.SystemPreferences, Permission.all),
            Grant(MainFeature.Policy,Permission.all),
            Grant(MainFeature.EcoSystem,Permission.all),
        )
    ),
    SystemAdministrator_No_AI(
        listOf(
            Grant(MainFeature.AutomaticTranslation, setOf(Permission.Execute)),
            Grant(MainFeature.InformationSharing, Permission.all),
            Grant(MainFeature.SocialMedia, Permission.all),
            Grant(MainFeature.SocialMediaAccount, Permission.all),
            Grant(MainFeature.SocialMediaAI, Permission.all),
            Grant(MainFeature.SystemDependencies, Permission.all),
            Grant(MainFeature.SystemDependencyConfig, Permission.all),
            Grant(MainFeature.SystemDependencyBackup, Permission.all),
            Grant(MainFeature.Threats, Permission.all),
            Grant(MainFeature.Anomalies, Permission.all),
            Grant(MainFeature.Email, setOf(Permission.Execute)),
            Grant(MainFeature.SystemPreferences, Permission.all),
            Grant(MainFeature.Policy,Permission.all),
            Grant(MainFeature.EcoSystem,Permission.all),
        )
    ),
    IncidentManager(
        listOf(
            Grant(MainFeature.AutomaticTranslation, setOf(Permission.Execute)),
            Grant(MainFeature.SocialMedia, Permission.all),
            Grant(MainFeature.SocialMediaAccount, Permission.readonly),
            Grant(MainFeature.SocialMediaAI, Permission.all),
            Grant(MainFeature.SystemDependencies, Permission.readonly),
            Grant(MainFeature.SystemDependencyConfig, Permission.readonly),
            Grant(MainFeature.Threats, Permission.all),
            Grant(MainFeature.Anomalies, Permission.all),
            Grant(MainFeature.Email, setOf(Permission.Execute)),
            Grant(MainFeature.Policy,Permission.all),
            Grant(MainFeature.EcoSystem,Permission.readonly),
        )
    ),
    IncidentManager_No_AI(
        listOf(
            Grant(MainFeature.AutomaticTranslation, setOf(Permission.Execute)),
            Grant(MainFeature.SocialMedia, Permission.all),
            Grant(MainFeature.SocialMediaAccount, Permission.readonly),
            Grant(MainFeature.SystemDependencies, Permission.readonly),
            Grant(MainFeature.SystemDependencyConfig, Permission.readonly),
            Grant(MainFeature.Threats, Permission.all),
            Grant(MainFeature.Anomalies, Permission.all),
            Grant(MainFeature.Email, setOf(Permission.Execute)),
            Grant(MainFeature.Policy,Permission.all),
            Grant(MainFeature.EcoSystem,Permission.all),
        )
    ),
    InstanceAdministrator(
        listOf(
            Grant(MainFeature.CsawareAdministration, Permission.all),
            Grant(MainFeature.AutomaticTranslation, setOf(Permission.Execute)),
            Grant(MainFeature.SystemDependencyBackup, Permission.all),
            Grant(MainFeature.Email, setOf(Permission.Execute)),
        ),
        allowedUserAdministration = false
    ),
    ;

    /**
     * Cheks whether the current role gives the permission to the given feature.
     */
    fun hasAccess(feature: MainFeature, permission: Permission): Boolean =
        grants.any { feature == it.feature && permission in it.permissions }

    val  namePrettified: String  = name.replace("_", " ")

    companion object {
        /**
         * Get the roles the UserAdministrator is allowed to assign to a user
         */
        fun userAdminAsignable(): Set<UserRole> = entries.filter { it.allowedUserAdministration }.toSet()
    }
}