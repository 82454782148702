package dk.rheasoft.csaware.api.incident

import dk.rheasoft.csaware.api.AccessLevel
import dk.rheasoft.csaware.api.QueryResult
import dk.rheasoft.csaware.api.socialmedia.SocialMediaObservation
import dk.rheasoft.csaware.utils.JsonUtilSerialization
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.encodeToString
import kotlin.time.Duration.Companion.days

@Serializable
data class  ThreatObservation(
    var id: String,
    @SerialName("attackType")
    var threatGroup: String = "",

    var name: String = "",
    var description: String = "",

    val whereSightedRefs: MutableSet<String> = mutableSetOf(),
    /**
     * Email of user (SYStem Admininstrator) that currently have this assigned.
     * There assignee have no history for now, but the iniator of a change is in the state history,
     * so the person that assigned this can be seen it the history.
     */
    var assignee: String = "",

    var incidentState: IncidentState = IncidentState.Open,

    /**
     * Time when this was first observed
     */
    var firstObserved: Instant = Clock.System.now(),

    /**
     * Time when this is no longer considered and active threat
     */
    var endActive: Instant = Clock.System.now() + 365.days,

    /**
     *
     */
    var lastObserved: Instant = Clock.System.now(),

    var severity: Int? = null,

    var courseOfActions: List<CourseOfAction> = listOf(),
    val stateHistory: MutableList<StateHistory> = mutableListOf(),
    var anomalies: MutableList<AnomalyWrapper> = mutableListOf(),

    var accessLevel: AccessLevel = AccessLevel.OrganisationalPrivate,
    var bcdrRelevancy: Boolean = true,
    var bcdrSelfHealingState: BcdrSelfHealingState = BcdrSelfHealingState.Unknown,
    var contextState: ContextState = ContextState.Unknown,

    val socialMediaObservation: MutableList<SocialMediaObservation> = mutableListOf(),
    val policyTemplateContext: MutableList<PolicyTemplateContext> = mutableListOf(),
    val policyContext: MutableList<PolicyContext> = mutableListOf(),
    val ecoSystemContext: MutableList<EcoSystemContext> = mutableListOf(),
) {
    init {
        if (stateHistory.isEmpty()) {
            stateHistory.add(StateHistory("CS-Aware", ThreatState.Active, firstObserved, "initial"))
        }
    }

    var state: ThreatState
        get() =
            if (incidentState == IncidentState.Closed) {
                ThreatState.Resolved
            } else {
                when(bcdrSelfHealingState) {
                    BcdrSelfHealingState.Unknown -> when (incidentState) {
                        IncidentState.Unknown -> ThreatState.Active
                        IncidentState.Open -> ThreatState.Active
                        IncidentState.Progress -> ThreatState.Active
                        IncidentState.Closed -> ThreatState.Resolved
                    }

                    BcdrSelfHealingState.NeedsUserAccept -> ThreatState.HealingAwaitDecision
                    BcdrSelfHealingState.UserAccepted -> ThreatState.HealingAccepted
                    BcdrSelfHealingState.UserDeclined -> ThreatState.HealingDeclined
                    BcdrSelfHealingState.Processing -> ThreatState.HealingInProgress
                    BcdrSelfHealingState.Done -> ThreatState.Healed
                    BcdrSelfHealingState.PartialDone -> ThreatState.HealingFailed
                    BcdrSelfHealingState.Skipped -> ThreatState.HealingFailed
                }
            }
        set(value) {
            when (value) {
                ThreatState.Active -> incidentState = IncidentState.Open
                ThreatState.Resolved -> incidentState = IncidentState.Closed
                ThreatState.Ignored -> incidentState = IncidentState.Closed
                ThreatState.HealingAwaitDecision -> bcdrSelfHealingState = BcdrSelfHealingState.NeedsUserAccept
                ThreatState.HealingAccepted -> {
                    bcdrSelfHealingState = BcdrSelfHealingState.UserAccepted
                    incidentState = IncidentState.Progress
                }

                ThreatState.HealingDeclined -> {
                    bcdrSelfHealingState = BcdrSelfHealingState.UserDeclined
                    incidentState = IncidentState.Progress
                }
                ThreatState.Healed ->  bcdrSelfHealingState = BcdrSelfHealingState.Done
                ThreatState.HealingFailed ->  bcdrSelfHealingState = BcdrSelfHealingState.PartialDone
                ThreatState.HealingInProgress ->  bcdrSelfHealingState = BcdrSelfHealingState.Processing
                ThreatState.Unknown -> incidentState = IncidentState.Open
            }
        }


    val active: Boolean get() = endActive > Clock.System.now()


    fun changeState(
        initiator: String,
        newState: ThreatState,
        description: String,
        time: Instant = Clock.System.now()
    ): StateHistory {
        state = newState
        endActive = if (newState.active) {
            Clock.System.now() + 365.days
        } else {
            time
        }
        val stateHistoryElement = StateHistory(initiator, newState, time, description)
        stateHistory.add(0, stateHistoryElement)
        return stateHistoryElement
    }

    fun toJsonString(): String = JsonUtilSerialization.json.encodeToString(this)

    companion object {
        /* filter value used to signal filter those that have no assignee */
        const val nobodyFilter = "@@@@"

        fun fromJson(json: String) : ThreatObservation =
            JsonUtilSerialization.json.decodeFromString(json)

        fun QueryResult<ThreatObservation>.toJsonString() : String =
            JsonUtilSerialization.json.encodeToString(QueryResult.serializer(serializer()),this)

        fun fromQueryResultJson(json: String) : QueryResult<ThreatObservation> =
            JsonUtilSerialization.json.decodeFromString(QueryResult.serializer(serializer()),json)

        fun empty(): ThreatObservation = ThreatObservation("")
    }
}
