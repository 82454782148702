package csaware.utilities

import csaware.main.CsawareServices
import csaware.main.navigateTo
import csaware.systemdepend.SystemDependencyService
import kafffe.core.KafffeComponent
import kafffe.core.KafffeHtmlBase
import kafffe.core.KafffeHtmlOut

open class SystemNodeToSystemGraphLink(
    private val systemNodeRefs: Set<String>,
    private val graphService: SystemDependencyService,
    private val linkClass: String = "link-primary"
): KafffeComponent() {


    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut =
        div{
            val namesFromIds = graphService.namesFromIds(systemNodeRefs)
            namesFromIds.forEachIndexed{ index: Int, name: String ->
                a {
                    text(name)
                    withElement {
                        className = linkClass
                        style.whiteSpace = "nowrap"
                        href="#"
                        onclick = {
                            onclick(name)
                        }
                    }
                }
                if (index < namesFromIds.size - 1) {
                    text(", ")
                }
            }
        }

    open fun onclick(name: String) {
        navigateTo("root/systemDependencies/$name")
    }
}