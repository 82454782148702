@file:OptIn(ExperimentalSerializationApi::class)

package dk.rheasoft.csaware.api.incident

import kotlinx.serialization.EncodeDefault
import kotlinx.serialization.ExperimentalSerializationApi
import kotlinx.serialization.Serializable

@Serializable
data class CourseOfAction(
        var id: String,
        var name: String = "",
        var description: String = "",
        var action: String = ""
        // Support any custom attributes, prepare for STIX 2
)

@Serializable
data class CourseOfActionWithType(
        var id: String,
        var name: String = "",
        var description: String = "",
        var action: String = "",
        @EncodeDefault
        var type: String = "course-of-action"
        // Support any custom attributes, prepare for STIX 2
) {
        constructor(courseOfAction: CourseOfAction) :
                this(
                        courseOfAction.id,
                        courseOfAction.name,
                        courseOfAction.description,
                        courseOfAction.action
                )
}