package csaware.anomalies

import csaware.main.CsawareServices
import csaware.messages.CsawareMessages
import csaware.messages.CsawareMessagesObject
import csaware.messages.i18nText
import csaware.utilities.AccessLevelDropdown
import csaware.utilities.SeverityDropdown
import csaware.utilities.ThreatGroupDropdown
import csaware.utilities.markdown.MarkDownInput
import dk.rheasoft.csaware.api.incident.AnomalyWrapper
import dk.rheasoft.csaware.api.incident.ThreatObservation
import dk.rheasoft.csaware.api.systemdependencies.SystemDependencyResource
import kafffe.bootstrap.ColWidth
import kafffe.bootstrap.ResponsiveSize
import kafffe.bootstrap.form.BootstrapForm
import kafffe.bootstrap.form.MultipleEditSelect
import kafffe.core.*
import kafffe.core.modifiers.CssClassModifier.Companion.cssClassModifier
import kafffe.core.modifiers.onchange
import kotlinx.dom.addClass
import kotlinx.dom.removeClass

open class IncidentCollector(
    val incidentModel: Model<ThreatObservation>,
    val dragHolder: AnomalyDragHolder,
    val targetsModel: Model<List<Target>>
) : KafffeComponent() {
    val anomaliesModel: Model<List<AnomalyWrapper>> = Model.of(emptyList())

    init {
        anomaliesModel.listeners.add(ModelChangeListener {
            checkCriticalAssetIds()
        })
    }
    private var changedData = false

    private val anomaliesTable = addChild(AnomaliesTable(anomaliesModel, dragHolder, targetsModel))
    private lateinit var whereSigtedEdit: MultipleEditSelect<String>
    private val incidentForm = addChild(BootstrapForm<ThreatObservation>(incidentModel).apply {
        cssClassModifier("hgap-3")
        cssClassModifier("vgap-3")
        row {
            cssClassModifier("vgap-3")
            col(ColWidth(ResponsiveSize.md, 6)) {
                input(ThreatObservation::name).apply {
                    required = true
                    onchange {
                        changedData = true
                        updateValueModel()
                    }
                }
            }
            col(ColWidth(ResponsiveSize.md, 6)) {
                whereSigtedEdit = whereSigtedEdit(model)
                decorateAndAdd(i18nText(CsawareMessages::threat_where), whereSigtedEdit)
            }
            col(ColWidth(ResponsiveSize.md, 4)) {
                val threatGroups = CsawareServices.configrationService.threatGroupsModel.data
                val threatGroupDropdown =
                    ThreatGroupDropdown("threatGroup", model.property(ThreatObservation::threatGroup), threatGroups)
                decorateAndAdd(i18nText(CsawareMessages::threat_group), threatGroupDropdown)
            }
            col(ColWidth(ResponsiveSize.md, 4)) {
                val severityDropdown = SeverityDropdown("severity", model.property(ThreatObservation::severity))
                decorateAndAdd(i18nText(CsawareMessages::severity), severityDropdown)
            }
            col(ColWidth(ResponsiveSize.md, 4)) {
                val accessLevelDropdown = AccessLevelDropdown("accessLevel", model.property(ThreatObservation::accessLevel))
                decorateAndAdd(i18nText(CsawareMessages::accessLevel), accessLevelDropdown)
            }
        }
        val inp = MarkDownInput(model.property(ThreatObservation::description))
        decorateAndAddComponent(i18nText(CsawareMessages::description), inp)
    })

    private fun whereSigtedEdit(model: Model<ThreatObservation>): MultipleEditSelect<String> {
        val whereSightedRefsModel: Model<List<String>> = model.func(
            { p -> p.data.whereSightedRefs.toList() },
            { p, v ->
                p.data.whereSightedRefs.clear()
                p.data.whereSightedRefs.addAll(v)
            }
        )
        val systemResourceIds =
            CsawareServices.systemDependencyService.model.data.sortedBy(SystemDependencyResource::name).map { it.id }
        return object : MultipleEditSelect<String>("whereSightedRefs", whereSightedRefsModel, Model.of(systemResourceIds)) {
            override fun display(choice: String): String =
                CsawareServices.systemDependencyService.byId(choice)?.name ?: ""
        }
    }


    private var enterCount = 0

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut =
        div {
            addClass("card")
            withElement {
                val dropzone = this
                ondragenter = { dragEvent ->
                    dragEvent.preventDefault()
                    if (++enterCount == 1) dropzone.addClass("dragover")
                    false
                }
                ondragleave = { dragEvent ->
                    dragEvent.preventDefault()
                    if (--enterCount == 0) dropzone.removeClass("dragover")

                }
                ondragover = { dragEvent ->
                    dragEvent.preventDefault()
                }
                ondrop = { dragEvent ->
                    dragHolder.moveTo(anomaliesModel)
                    dragEvent.preventDefault()
                    enterCount = 0
                    dropzone.removeClass("dragover")
                    dragEvent.stopPropagation()
                }
            }
            h4 {
                addClass("card-header")
                i { addClass("fa-solid fa-exclamation-triangle me-1") }
                text(incidentModel.data.id)
            }
            div {
                addClass("card-body")
                add(incidentForm.html)
                div { addClass("mt-2") }
                add(anomaliesTable.html)
            }
            div {
                addClass("card-footer")
                button {
                    addClass("btn btn-primary")
                    withElement {
                        onclick = {
                            incidentForm.processForm(onOk = {save()})
                        }
                    }
                    text(CsawareMessagesObject.get().save)
                }
            }
        }

    private fun checkCriticalAssetIds() {
        val nodes: List<SystemDependencyResource> = CsawareServices.systemDependencyService.model.data
        for (anomaly in anomaliesModel.data) {
            for (assetId in  anomaly.assets) {
                // system resouce with critical id
                val matchingNodes = nodes.filter { it.criticalAssetIds.contains(assetId) || it.id == assetId }
                if (matchingNodes.isEmpty()) {
                     CriticalAssetNodeAssignDialog(assetId) { node: SystemDependencyResource ->
                         incidentModel.data.whereSightedRefs.add(node.id)
                         whereSigtedEdit.updateFromValueModel()
                     }.attach()
                } else {
                    incidentModel.data.whereSightedRefs.addAll(matchingNodes.map { it.id })
                    whereSigtedEdit.updateFromValueModel()
                }
            }
        }
    }

    /**
     *
     */
    fun hasUnsavedChanges(): Boolean =
        anomaliesModel.data.isNotEmpty()

    fun save() {
//     val assets =  if (incidentModel.data.assets.isNotEmpty())
//            incidentModel.data.assets
//        else
        val assets =  anomaliesModel.data.flatMap { it.assets }.toSet().toList()
        val incident = incidentModel.data.copy()
        incident.anomalies = anomaliesModel.data.toMutableList()
        CsawareServices.anomalyBackend.storeIncident(incident) {
            CsawareServices.alerts.infoAdd(CsawareMessagesObject.get().anomalies_incident_saved)
            parentOfType(IncidentsCollector::class)?.removeCollector(this@IncidentCollector)
        }
    }

}