package csaware.systemdepend.nodedetails

import csaware.messages.CsawareMessagesObject
import csaware.policy.PolicyTable
import csaware.systemdepend.SystemDependencyService
import dk.rheasoft.csaware.api.systemdependencies.SystemDependencyResource
import dk.rheasoft.csaware.api.policy.PolicyFilter
import kafffe.core.*

class Policies(nodeSelectionModel: Model<SystemDependencyResource>,
    private val graphService: SystemDependencyService) :
    KafffeComponentWithModel<SystemDependencyResource>(nodeSelectionModel) {


    private val table = addChild(PolicyTable(graphService, largeSize = false, pageSize = 6)).apply {
        filterModel.property(PolicyFilter::nodes).data = listOf(nodeSelectionModel.data.id)
    }

    val title: KafffeComponent = ofKafffeHtml {
        div {
            text(CsawareMessagesObject.get().policy)
            sup {
                addClass("ms-1 badge badge-pill bg-dark")
                element.style.fontSize = "0.7rem"
                text(table.totalCountModel.data.toString())
            }
        }
    }

    init {
        table.totalCountModel.listeners.add(ModelChangeListener { title.rerender() })
        modelChangedStandardBehavior = {
            table.filterModel.property(PolicyFilter::nodes).data = listOf(nodeSelectionModel.data.id)
        }
    }

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut {
        return div {
            add(table.html)
            div {
                withStyle { clear = "both" }
            }
        }
    }
}