package csaware.anomalies

import csaware.main.CsawareServices
import csaware.messages.CsawareMessages
import csaware.messages.CsawareMessagesObject.csawareMessageStrategy
import csaware.messages.i18nText
import csaware.systemdepend.SystemSearch
import dk.rheasoft.csaware.api.systemdependencies.SystemDependencyResource
import kafffe.bootstrap.BasicColor
import kafffe.bootstrap.BootstrapButton
import kafffe.bootstrap.ModalSize
import kafffe.bootstrap.form.FormDialog
import kafffe.bootstrap.form.Input
import kafffe.bootstrap.form.ValidationResult
import kafffe.core.Model
import kafffe.core.ModelChangeListener
import kafffe.core.modifiers.CssClassModifier.Companion.cssClassModifier
import kafffe.core.property

/**
 * Dialog for adding critical asset id to a resource in the system graph
 */
class CriticalAssetNodeAssignDialog(
    criticalAssetId: String,
    /**
     * Callback for when a node is seleceted and saved for the criticalAssetId
     */
    val nodeSelected: (node: SystemDependencyResource) -> Unit
) :
    FormDialog<CriticalAssetNodeAssignDialog.CriticalAssetNode>(
        i18nText(CsawareMessages::criticalasset_assign_title),
        Model.of(CriticalAssetNode(criticalAssetId))
    ) {
    data class CriticalAssetNode(
        val criticalAssetId: String
    ) {
        var resource: SystemDependencyResource = SystemDependencyResource.NULL
        val resourceName: String get() = resource.name
    }

    init {
        size = ModalSize.medium
        cssClassModifier("vgap-3 hgap-3")
        labelStrategy = csawareMessageStrategy("criticalasset_assign_")
        readonly(CriticalAssetNode::criticalAssetId)

        val nodeSearch = SystemSearch(
            selectedResourceModel = model.property(CriticalAssetNode::resource),
            resourcesModel = CsawareServices.systemDependencyService.model,
            graphService = CsawareServices.systemDependencyService
        )
        nodeSearch.formComponent = true

        decorateAndAddComponent(labelStrategy.label("systemResourceSearch"), nodeSearch)

        val resourceName = readonly(CriticalAssetNode::resourceName).apply {
            required = true
            addValidator{input: Input<String> ->
                ValidationResult(input.htmlInputElement.value.isNotEmpty(), i18nText(CsawareMessages::required_field_not_filled_error).data)
        }}


        model.listeners.add(ModelChangeListener {
            resourceName.rerender()
        })

        button("add"){ btn ->
            processForm(
                onOk = { process(btn) },
                onError = { btn.processingDone() }
            )
        }.apply {
            color = BasicColor.primary
            iconClasses = "fa-solid fa-brain"
            useSpinner()
        }
        cancel().color = BasicColor.secondary
    }

    private fun process(button: BootstrapButton) {
        val node = model.data.resource.copy()
        if (node != SystemDependencyResource.NULL) {
            val criticalAssetId = model.data.criticalAssetId
            node.criticalAssetIds += criticalAssetId
            CsawareServices.systemDependenciesBackend.storeSystemDependency(node) {
                CsawareServices.systemDependencyService.refresh()
                button.processingDone()
                nodeSelected(node)
                detach()
            }
        }
    }
}