package dk.rheasoft.csaware.api.incident

import kotlinx.datetime.Instant
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * CS-Connect Discussion used in context of an Incident/ThreatObservaton
 */
@Serializable
data class Discussion (
    @SerialName("channel_id")
    val id: String = "",
    val name: String = "",
    val type: String = "",
    val description: String = "",
    @SerialName("channel_url")
    val url: String = "",
    val created: Instant? = null,
    val modified: Instant? = null,
)

@Serializable
data class DiscussionFull (
    val id: String = "",
    val name: String = "",
    val type: String = "",
    val description: String = "",
    @SerialName("channel_url")
    val url: String = "",
    val created: Instant? = null,
    val modified: Instant? = null,
    val published: Instant? = null,
    // objectRefs
)

/**
 * CS-Connect Discussion used in context of an Incident/ThreatObservaton
 */
@Serializable
data class DiscussionFullWrapper (
    // ignore everything except embbed
    val discussion: DiscussionFull,
    @SerialName("channel_id")
    val id: String = "",
    val name: String = "",
    val type: String = "",
    val description: String = "",
    @SerialName("channel_url")
    val url: String = "",
    val created: Instant? = null,
    val modified: Instant? = null,
)


