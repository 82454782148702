package dk.rheasoft.csaware.api.systemdependencies

enum class Cardinality {
    ZeroOrOne, One, ZeroToMany, OneToMany;

    val isSingle: Boolean get() = this in setOf(ZeroOrOne, One)
    val isMany: Boolean get() = !isSingle

    companion object {
        fun parse(value: String): Cardinality {
            return try {
                valueOf(value)
            } catch (_: Throwable) {
                One
            }
        }
    }
}