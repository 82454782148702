package dk.rheasoft.csaware.api.incident

import kotlinx.serialization.ExperimentalSerializationApi
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

/**
 * State of an Incident with regards to Context generation
 */
@Serializable(with = ContextStateSerializer::class)
enum class ContextState(private val value: String) {
    Unknown(""),
    ;
    fun toInternal(): String = value

    companion object {
        /**
         * Convert from stored int
         */
        fun fromInternal(value: String) = entries.firstOrNull { it.value == value } ?: Unknown
    }

}

@OptIn(ExperimentalSerializationApi::class)
object ContextStateSerializer : KSerializer<ContextState> {
    override val descriptor: SerialDescriptor =
        PrimitiveSerialDescriptor("dk.rheasoft.csaware.api.incident.ContextState", PrimitiveKind.INT)

    override fun serialize(encoder: Encoder, value: ContextState) {
        encoder.encodeString(value.toInternal())
    }


    override fun deserialize(decoder: Decoder): ContextState =
        ContextState.fromInternal(decoder.decodeString())
}