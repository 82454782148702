package csaware.socialmedia

import csaware.main.CsawareServices
import csaware.main.UserInformation
import csaware.main.navigateTo
import csaware.messages.CsawareMessages
import csaware.messages.i18nText
import csaware.systemdepend.SystemDependencyService
import csaware.utilities.ActionBar
import csaware.utilities.SearchInput
import csaware.utilities.componentTitle
import dk.rheasoft.csaware.api.QueryResult
import dk.rheasoft.csaware.api.access.MainFeature
import dk.rheasoft.csaware.api.access.Permission
import dk.rheasoft.csaware.api.socialmedia.SocialMediaAccount
import dk.rheasoft.csaware.api.socialmedia.SocialMediaEntry
import kafffe.bootstrap.BootstrapTable
import kafffe.bootstrap.pagination.BootstrapPagination
import kafffe.bootstrap.pagination.Pager
import kafffe.core.*
import kafffe.core.modifiers.CssClassModifier
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import kotlin.math.ceil
import kotlin.time.Duration.Companion.days


class SocialMediaReader(
    private val graphService: SystemDependencyService = CsawareServices.systemDependencyService
) : KafffeComponent() {

    private val titleLabel = addChild(Label(i18nText(CsawareMessages::socialmedia_reader)))
    private var aiMode: Boolean by rerenderOnChange(false)

    // ------------  Non AI -----
    private var filterInput: String = ""
    private val searchInput = addChild(SearchInput {
        filterInput = it
        pager.first()
    })


    private var accountById: Map<String, SocialMediaAccount> = mapOf()
    private val selectionModel: Model<SocialMediaEntry?> = ModelNullable()

    val table: BootstrapTable<SocialMediaEntry> =
        addChild(SocialMediaObservationTable.create(accountById, selectionModel, graphService))

    var pageSize: Int = 10

    val pager = Pager(1)
    private val paginator = addChild(BootstrapPagination(pager).apply {
        prevNextPage = true
        modifiers.add(CssClassModifier("float-end"))
    })


    // Common
    private val whereSightedRefsModel: Model<Set<String>> = Model.ofGet {
        graphService
            .findAllHavingByKeywords(selectionModel.data?.matches ?: emptySet())
            .map { it.id }
            .toSet()
    }

    // ------------  AI -----
    private val questionAI: QuestionAI = addChild(QuestionAI(selectionModel, graphService))

    //private val detailsWidget = addChild(SocialMediaDetails(selectionModel, whereSightedRefsModel , includeTitle = false ))
    private val detailsWidget = addChild(SocialMediaDetails(
        selectionModel,
        whereSightedRefsModel,
        graphService,
        includeTitle = false
    ))

    init {
        pager.changeListeners.add { loadData() }
        loadAccounts()
        loadData()
    }

    private fun loadAccounts() {
        CsawareServices.alerts.clearAlerts()

        CsawareServices.socialMediaBackend.getSocialMediaAccounts(
            0,
            1000
        ) { accountResult: QueryResult<SocialMediaAccount> ->
            accountById = accountResult.result.associateBy { it.id }
            questionAI.accountById = accountById
            SocialMediaObservationTable.accountById = accountById
            table.rerender()
        }
    }

    private var loading: Boolean = true

    fun loadData() {
        CsawareServices.alerts.clearAlerts()
        loading = true
        rerender()
        val offset = pageSize * (pager.currentPage - 1)
        val language = UserInformation.current.preferences.dataPresentationLanguage.shortName
        val keywords: Set<String> = graphService.usedKeyWords()
        val after: Instant = Clock.System.now() - 30.days
        CsawareServices.socialMediaBackend.getSocialMediaEntries(
            keywords,
            language = language,
            offset,
            pageSize,
            this::receiveData,
            filterInput,
            after,
            this::receiveDataOnError,
        )
    }

    private fun receiveData(response: QueryResult<SocialMediaEntry>) {
        loading = false
        val pageCount = ceil(response.nofResult.toDouble() / pageSize.toDouble()).toInt()
        if (pager.nofPages != pageCount) {
            pager.nofPages = pageCount
        }
        table.data = response.result
        rerender()
    }

    private fun receiveDataOnError(errcode:Int,errText:String) {
        table.data = listOf()
        loading = false
        rerender()
    }

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut {

        return div {
            addClass("row")
            div {
                addClass("col-lg-8")
                tableWidget()
            }
            div {
                addClass("col-lg-4")
                add(detailsWidget.html)
            }
        }
    }

    private fun KafffeHtml<HTMLDivElement>.tableWidget() {
        div {
            tableHeader()
            if (aiMode) {
                add(questionAI.html)
            } else {
                if (loading) {
                    waitSpinner()
                }
                add(table.html)
                add(paginator.html)
            }
        }
    }

    private fun <ET : HTMLElement> KafffeHtml<ET>.waitSpinner() {
        div {
            // overlay container
            withStyle {
                position = "fixed"
                width = "100%"
                height = "100%"
                top = "4rem"
                left = "0"
                bottom = "0"
                right = "0"
                textAlign = "center"
                backgroundColor = "rgba(5, 181, 227, 0.5)"
                zIndex = "999"
                cursor = "pointer"
            }

            div {
                withStyle {
                    position = "absolute"
                    top = "50%"
                    left = "50%"
                }
                addClass("spinner-border mt-4")
                span {
                    addClass("visually-hidden")
                    text("Loading...")
                }
            }
        }
    }


    private fun KafffeHtml<HTMLDivElement>.tableHeader() {
        componentTitle {
            add(titleLabel.html)
            if (UserInformation.hasAccess(MainFeature.SocialMediaAI, Permission.Read)) {
                div {
                    addClass("btn-group form-check form-switch ms-3")
                    input {
                        addClass("btn-check")
                        withElement {
                            id = "aiSwitch"
                            type = "checkbox"
                            setAttribute("role", "switch")
                            checked = aiMode
                            this.onclick = { event ->
                                aiMode = !aiMode
                                event.preventDefault()
                            }
                        }
                    }
                    label {
                        addClass("btn btn-primary ms-1")
                        withElement {
                            setAttribute("for", "aiSwitch")
                        }
                        i {
                            addClass("fas fa-question ms-2 me-2")
                        }
                        text("AI")
                    }

                }
            }
            if (!aiMode) {
                add(searchInput.html)
            }
            add(actionBar.html)
        }
    }

    private val actionBar = addChild(ActionBar {
        item(i18nText(CsawareMessages::socialmedia_management_title), "fas fa-cog") {
            accessRequirement = (MainFeature.SocialMediaAccount to Permission.Write)
            action = { navigateTo("root/socialmedia_management") }
        }
    })
}