package csaware.kpi_statistics

import csaware.messages.CsawareMessages
import csaware.messages.i18nText
import dk.rheasoft.csaware.api.statistics.StatisticCounter
import dk.rheasoft.csaware.api.statistics.StatisticCounter.CountType
import kafffe.core.KafffeComponent
import kafffe.core.Model
import kafffe.core.modifiers.CssClassModifier.Companion.cssClassModifier

class KpiPage : KafffeComponent() {
    private fun counterSetup(
        title: String,
        countTitle: String,
        subTitle: String,
        type: CountType,
        description: String,
        xAxisLegend: String,
        yAxisLegend: String
    ) =
        counterSetup(Model.of(title), countTitle, subTitle, type, Model.of(description), xAxisLegend, yAxisLegend)

    init {
        cssClassModifier("accordion")
    }

    private fun counterSetup(
        titleModel: Model<String>,
        countTitle: String,
        subTitle: String,
        type: CountType,
        descriptionModel: Model<String>,
        xAxisLegend: String,
        yAxisLegend: String
    ): StatisticSectionView.StatisticCounterDef =
        StatisticSectionView.StatisticCounterDef(
            titleModel,
            descriptionModel,
            StatisticCounter(
                title = countTitle,
                subTitle = subTitle,
                countType = type
            ),
            Model.of(xAxisLegend),
            Model.of(yAxisLegend),
        )

    private val questionnaire: StatisticSectionView by lazy {
        QuestionnaireSection(
            Model.of("Questionnaire"),
        )
    }

    private val section1: StatisticSectionView
            by lazy {
                StatisticSectionView(
                    KpiDimensions.d1.titleModel,
                    listOf(
                        counterSetup(
                            i18nText(CsawareMessages::kpi_policy_count).data, "policy", "count", CountType.TOTAL,
                            i18nText(CsawareMessages::kpi_statistic_description_policy_count).data.trimIndent(), i18nText(CsawareMessages::kpi_time_of_count).data, i18nText(CsawareMessages::kpi_number_of_policies).data
                        ),
                        counterSetup(
                            i18nText(CsawareMessages::kpi_policy_changes).data, "policy", "changes", CountType.WEEKLY,
                            i18nText(CsawareMessages::kpi_statistic_description_policy_changes).data.trimIndent(), i18nText(CsawareMessages::kpi_time).data, i18nText(CsawareMessages::kpi_number_of_changes).data
                        ),
                        counterSetup(
                            i18nText(CsawareMessages::kpi_system_graph_assets).data, "system graph", "assets", CountType.TOTAL,
                            i18nText(CsawareMessages::kpi_statistic_description_system_graph_assets_d1).data.trimIndent(), i18nText(CsawareMessages::kpi_time_of_count).data, i18nText(CsawareMessages::kpi_number_of_assets).data
                        ),
                        counterSetup(
                            i18nText(CsawareMessages::kpi_system_graph_dependencies).data, "system graph", "dependencies", CountType.TOTAL,
                            i18nText(CsawareMessages::kpi_statistic_description_system_graph_dependencies_d1).data.trimIndent(), i18nText(CsawareMessages::kpi_time_of_count).data, i18nText(CsawareMessages::kpi_number_of_dependencies).data
                        ),
                        counterSetup(
                            i18nText(CsawareMessages::kpi_system_graph_values).data, "system graph", "fields with value", CountType.TOTAL,
                            i18nText(CsawareMessages::kpi_statistic_description_system_graph_values_d1).data.trimIndent(), i18nText(CsawareMessages::kpi_time_of_count).data, i18nText(CsawareMessages::kpi_number_of_values).data
                        ),
                        counterSetup(
                            i18nText(CsawareMessages::kpi_system_graph_changes).data, "system graph", "changes", CountType.WEEKLY,
                            i18nText(CsawareMessages::kpi_statistic_description_system_graph_changes_d1).data.trimIndent(), i18nText(CsawareMessages::kpi_time).data, i18nText(CsawareMessages::kpi_number_of_changes).data
                        ),
                    ),
                        Model.of( i18nText(CsawareMessages::kpi_d1_section_view_description).data.trimIndent()
                    )
                )
            }


    private val section2: StatisticSectionView
            by lazy {
                StatisticSectionView(
                    KpiDimensions.d2.titleModel,
                    listOf(
                        counterSetup(
                            i18nText(CsawareMessages::kpi_incident_total).data, "incident", "count", CountType.TOTAL,
                            i18nText(CsawareMessages::kpi_statistic_description_incident_total).data.trimIndent()
                            , i18nText(CsawareMessages::kpi_time_of_count).data,  i18nText(CsawareMessages::kpi_number_of_incidents).data
                        ),
                        counterSetup(
                            i18nText(CsawareMessages::kpi_incident_sharing_ecosystem).data,"incident","sharing count eco", CountType.TOTAL,
                            i18nText(CsawareMessages::kpi_statistic_description_incident_sharing_ecosystem).data.trimIndent()
                            , i18nText(CsawareMessages::kpi_time_of_count).data,  i18nText(CsawareMessages::kpi_number_of_incidents).data
                        ),
                        counterSetup(
                            i18nText(CsawareMessages::kpi_incident_sharing_global).data, "incident", "sharing count public", CountType.TOTAL,
                            i18nText(CsawareMessages::kpi_statistic_description_incident_sharing_global_d2).data.trimIndent()
                            , i18nText(CsawareMessages::kpi_time_of_count).data,  i18nText(CsawareMessages::kpi_number_of_incidents).data
                        ),
                        counterSetup(
                            i18nText(CsawareMessages::kpi_incident_sharing_changes).data, "incident", "access_level_changes", CountType.WEEKLY,
                            i18nText(CsawareMessages::kpi_statistic_description_incident_sharing_changes).data.trimIndent()
                            , i18nText(CsawareMessages::kpi_time).data,  i18nText(CsawareMessages::kpi_number_of_changes).data
                        ),
                    ),
                    Model.of( i18nText(CsawareMessages::kpi_d2_section_view_description).data.trimIndent()
                    )
                )
            }

    private val section3: StatisticSectionView
            by lazy {
                StatisticSectionView(
                    KpiDimensions.d3.titleModel,
                    listOf(
                        counterSetup(
                            i18nText(CsawareMessages::kpi_logins).data, "frontend", "authenticate", CountType.WEEKLY,
                         i18nText(CsawareMessages::kpi_statistic_description_frontend_logins).data.trimIndent(), i18nText(CsawareMessages::kpi_time).data,  i18nText(CsawareMessages::kpi_number_of_logins).data
                        ),
                        counterSetup(
                            i18nText(CsawareMessages::kpi_distinct_users).data,"frontend", "distinct_users", CountType.WEEKLY, i18nText(CsawareMessages::kpi_statistic_description_distinct_users).data.trimIndent(), i18nText(CsawareMessages::kpi_time).data,
                            i18nText(CsawareMessages::kpi_number_of_distinct_users).data
                        ),
                        counterSetup(
                            i18nText(CsawareMessages::kpi_anomaly_count).data,"anomaly", "count", CountType.TOTAL, i18nText(CsawareMessages::kpi_statistic_description_anomaly_count).data.trimIndent(), i18nText(CsawareMessages::kpi_time_of_count).data,i18nText(CsawareMessages::kpi_number_of_anomalies).data
                        ),
                        counterSetup(
                            i18nText(CsawareMessages::kpi_anomaly_timeline).data, "anomaly", "new", CountType.WEEKLY, i18nText(CsawareMessages::kpi_statistic_description_anomaly_timeline).data.trimIndent()
                            , i18nText(CsawareMessages::kpi_time).data,i18nText(CsawareMessages::kpi_number_of_new_anomalies).data
                        ),
                        counterSetup(
                            i18nText(CsawareMessages::kpi_anomaly_dismissed).data, "anomaly", "ignore", CountType.WEEKLY, i18nText(CsawareMessages::kpi_statistic_description_anomaly_dismissed).data.trimIndent()
                            , i18nText(CsawareMessages::kpi_time).data,i18nText(CsawareMessages::kpi_number_of_dismissed_anomalies).data
                        ),
                        counterSetup(
                            i18nText(CsawareMessages::kpi_anomaly_incident).data, "anomaly", "incident", CountType.WEEKLY, i18nText(CsawareMessages::kpi_statistic_description_anomaly_incidents).data.trimIndent()
                            , i18nText(CsawareMessages::kpi_time).data,i18nText(CsawareMessages::kpi_number_of_new_incidents).data
                        ),
                    ),
                    Model.of(
                        i18nText(CsawareMessages::kpi_d3_section_view_description).data.trimIndent()
                    )
                )
            }

    private val section4: StatisticSectionView
            by lazy {
                StatisticSectionView(
                    KpiDimensions.d4.titleModel,
                    listOf(
                        counterSetup(
                            i18nText(CsawareMessages::kpi_system_graph_assets).data, "system graph", "assets", CountType.TOTAL,
                            i18nText(CsawareMessages::kpi_statistic_description_system_graph_assets_d4).data.trimIndent(), i18nText(CsawareMessages::kpi_time_of_count).data, i18nText(CsawareMessages::kpi_number_of_assets).data
                        ),
                        counterSetup(
                            i18nText(CsawareMessages::kpi_system_graph_dependencies).data, "system graph", "dependencies", CountType.TOTAL,
                            i18nText(CsawareMessages::kpi_statistic_description_system_graph_dependencies_d4).data.trimIndent(), i18nText(CsawareMessages::kpi_time_of_count).data, i18nText(CsawareMessages::kpi_number_of_dependencies).data
                        ),
                        counterSetup(
                            i18nText(CsawareMessages::kpi_system_graph_values).data, "system graph", "fields with value", CountType.TOTAL,
                            i18nText(CsawareMessages::kpi_statistic_description_system_graph_values_d4).data.trimIndent(), i18nText(CsawareMessages::kpi_time).data, i18nText(CsawareMessages::kpi_number_of_dot_dot_dot).data
                        ),
                        counterSetup(
                            i18nText(CsawareMessages::kpi_system_graph_changes).data, "system graph", "changes", CountType.WEEKLY,
                            i18nText(CsawareMessages::kpi_statistic_description_system_graph_changes_d4).data.trimIndent(), i18nText(CsawareMessages::kpi_time).data, i18nText(CsawareMessages::kpi_number_of_changes).data
                        ),
                        counterSetup(
                            i18nText(CsawareMessages::kpi_incident_sharing_global).data, "incident", "sharing count public", CountType.TOTAL,
                            i18nText(CsawareMessages::kpi_statistic_description_incident_sharing_global_d4).data.trimIndent()
                            , i18nText(CsawareMessages::kpi_time).data,  i18nText(CsawareMessages::kpi_number_of_incidents).data
                        ),
                    ),
                    Model.of(
                       i18nText(CsawareMessages::kpi_d4_section_view_description).data.trimIndent()
                    )
                )
            }

    private val section5: StatisticSectionView
            by lazy {
                StatisticSectionView(
                    KpiDimensions.d5.titleModel,
                    listOf(
                        counterSetup(
                            i18nText(CsawareMessages::kpi_system_graph_security_appliances).data, "system graph", "security appliances", CountType.TOTAL,
                            i18nText(CsawareMessages::kpi_statistic_description_system_graph_security_appliances).data.trimIndent()
                            , i18nText(CsawareMessages::kpi_time_of_count).data,  i18nText(CsawareMessages::kpi_number_of_security_appliances).data
                        ),
                        counterSetup(
                            i18nText(CsawareMessages::kpi_policy_standards).data,"policy", "standards", CountType.TOTAL,
                            i18nText(CsawareMessages::kpi_statistic_description_policy_standards).data.trimIndent()
                            , i18nText(CsawareMessages::kpi_time).data,  i18nText(CsawareMessages::kpi_number_of_policy_standards).data
                        ),
                    ),
                    Model.of(
                     i18nText(CsawareMessages::kpi_d5_section_view_description).data.trimIndent()
                    )
                )
            }

    init {
        addChild(section1)
        addChild(section2)
        addChild(section3)
        addChild(section4)
        addChild(section5)
        addChild(questionnaire)
        questionnaire.toggleCollapsed()
    }

}
