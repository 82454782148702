package dk.rheasoft.csaware.api.systemdependencies

import dk.rheasoft.csaware.json.adapter.DatrixInstantSerializer
import dk.rheasoft.csaware.utils.JsonUtilSerialization
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.decodeFromJsonElement

@Serializable
data class SystemDependency(
    var type: String,
    var id: String,
    var name: String,
    @Serializable(with = DatrixInstantSerializer::class)
    var created: Instant,
    @Serializable(with = DatrixInstantSerializer::class)
    var modified: Instant,
    var version: String,
    var objects: MutableList<SystemDependencyResource> = mutableListOf(),
    @SerialName("x_csaware_root_node")
    var rootNode: String = ""
) {
    /**
     * Replaces system dependency resources "source" that reference other by name, with id of other.
     */
    fun resolveSourceReferenceByName() {
        resolveSourceReferenceByName(objects)
    }

    fun toJsonString(): String =
        JsonUtilSerialization.json.encodeToString(this)

    fun cloneToHeaderWithoutObjects() =
        fromJson(toJsonString()).apply { objects = mutableListOf() }

    companion object {
        /**
         * Replaces system dependency resources "source" that reference other by name, with id of other.
         */
        fun resolveSourceReferenceByName(resources: List<SystemDependencyResource>) {
            val resourcesNameMap = resources.associateBy { it.name }
            val resourcesIdMap = resources.associateBy { it.id }

            for (resource: SystemDependencyResource in resources) {
                val idList = resource.source.map { sourceKey: String ->
                    if (sourceKey !in resourcesIdMap.keys && sourceKey in resourcesNameMap.keys)
                        resourcesNameMap[sourceKey]!!.id
                    else sourceKey
                }
                resource.source = idList.toMutableList()
            }
        }

        fun fromJson(json: JsonObject): SystemDependency =
            JsonUtilSerialization.json.decodeFromJsonElement(json)

        fun fromJson(json: String): SystemDependency =
            JsonUtilSerialization.json.decodeFromString(json)

        var NULL = SystemDependency("", "---", "", Clock.System.now(), Clock.System.now(), "")

    }
}