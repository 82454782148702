package csaware.policy.config

import csaware.main.UserInformation
import dk.rheasoft.csaware.api.policy.MultiLanguageText
import dk.rheasoft.csaware.api.policy.PolicyRelationType
import kafffe.bootstrap.form.listeditor.ListEditor
import kafffe.core.KafffeHtml
import kafffe.core.Model
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLInputElement

/**
 * Editor to maintain a list of PolicyRelationType
 */
class PolicyRelationTypeListEditor(model: Model<List<PolicyRelationType>>) : ListEditor<PolicyRelationType>(model) {

    override fun createNewElement(): PolicyRelationType = PolicyRelationType(id = "", name = MultiLanguageText())

    override fun KafffeHtml<HTMLDivElement>.elementEditor(element: PolicyRelationType, index: Int) {
        val current = currentList[index]
        var nameInput: HTMLInputElement? = null
        input {
            addClass("form-control")
            withElement {
                value = element.id
                type = "text"
                onchange = {
                    if (value.isNotBlank()) {
                        current.id = value
                        // sets default for nameInput if it is blank
                        if (nameInput?.value.isNullOrBlank()) {
                            val prettified = value.lowercase()
                                .replace("-", " ")
                                .replace("_", " ")
                                .replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() }
                            current.name.setText(UserInformation.current.iso2LanguageData, prettified)
                            nameInput?.value = prettified
                        }
                    }
                }
                onkeydown = { keyEvent -> elementKeyHandler(keyEvent, element) }
                if (index == focusAfterRerender) {
                    delayedFocus()
                }
            }
        }
        input {
            addClass("form-control")
            withElement {
                nameInput = this
                value = element.name.text(UserInformation.current.iso2LanguageData)
                type = "text"
                onchange = {
                    if (value.isNotBlank()) {
                        current.name.setText(UserInformation.current.iso2LanguageData, value)
                    }
                }
                onkeydown = { keyEvent -> elementKeyHandler(keyEvent, element) }
                if (index == focusAfterRerender) {
                    delayedFocus()
                }
            }
        }
        // Dropdown with ids of others and a "null"
        div {
            addClass("d-inline-block")
            select {
                addClass("form-select")
                option {
                    withElement {
                        value = "-"
                    }
                    text("-")
                }
                currentList.forEach { relation ->
                    option {
                        withElement {
                            value = relation.id
                        }
                        text(relation.name.text(UserInformation.current.iso2LanguageData))
                    }
                }
                withElement {
                    value = current.revertedRelationId ?: "-"
                    onchange = {
                        if (value == "-") {
                            current.revertedRelationId = null
                        } else {
                            current.revertedRelationId = value
                        }
                    }
                }
            }
        }
    }
}