package csaware.test

import csaware.comm.CSAwareBackend
import csaware.utilities.UUID
import dk.rheasoft.csaware.api.ShareState
import kafffe.bootstrap.Alert
import kafffe.bootstrap.AlertsPanel
import kafffe.bootstrap.BasicColor
import kafffe.bootstrap.BootstrapLevel
import kafffe.bootstrap.form.BootstrapForm
import kafffe.bootstrap.form.textArea
import kafffe.core.DivContainer
import kafffe.core.Label
import kafffe.core.Model
import kafffe.core.modifiers.StyleModifier.Companion.styleModifier
import kotlin.js.JSON.parse
import kotlin.js.Json

class InfoShareTestPage : DivContainer() {


    fun template(state: String): String {
        val uuid = UUID.generateUuid("InformationShare--")
        val uuid1 = UUID.generateUuid("Indicator--")
        val uuid2 = UUID.generateUuid("Indicator--")
        return """
        {
        	"type": "InformationShare",
        	"id": "$uuid",
        	"state": "$state",
        	"summary": "Some description of the information to be shared",
        	"created": "2020-01-16T09:22:53.000Z",
        	"modified": "2020-01-16T09:22:53.000Z",
        	"data": [
        		{
        			"id": "$uuid1",
        			"name": "some other indicator name",
        			"editable": true,
        			"deletable": true,
        			"isChanged": false,
        			"isDeleted": false,
        			"information": "Malicious indicator of a database threat. Copyright LPA"
        		},
        		{
        			"id": "$uuid2",
        			"name": "some indicator name",
        			"editable": false,
        			"deletable": true,
        			"isChanged": false,
        			"isDeleted": false,
        			"information": "Just another threat to handle. Copyright LPA"
        		}
        	]
        }
    """.trimIndent()
    }

    val alerts = addChild(AlertsPanel())

    data class FormData(var shareData: String)

    val input = Model.of(FormData(template(ShareState.PermissionNeeded.name )))

    val form = addChild(BootstrapForm(input).apply {
        button(Model.of("Clear information share store")) { clearInfoShareStore() }.color = BasicColor.warning
        button(Model.of("Generate Information Share")) { generateInfoShare() }.color = BasicColor.info
        textArea(FormData::shareData).apply {
            styleModifier {
                height = "40vh"
                width = "80vw"
            }
            required = true
        }
        submit()
        onSubmitOk = this@InfoShareTestPage::submitOk
    })

    val spacer = addChild(Label(Model.of("-")))

    private fun clearInfoShareStore() {
        alerts.clearAlerts()
        backend().sendJsonTxt("/test/informationshare/clear", "POST", "") {
            alerts.addAlert((Alert("InformationShare cleared")))
        }
    }

    private fun generateInfoShare() {
        alerts.clearAlerts()
        backend().sendJsonTxt("/test/informationshare/generate", "POST", "") {
            alerts.addAlert(Alert(("InformationShare records generated")))
        }
    }

    init {
        loadData()
    }

    fun loadData() {
        alerts.clearAlerts()
    }

    private fun submitOk() {
        alerts.clearAlerts()
        println(input.data.shareData)
        val d: Json = parse(input.data.shareData)
        treePrint(d)
        backend().sendJsonTxt("/test/informationshare/decision/update", "POST", input.data.shareData) {
            alerts.addAlert(Alert("Information Share test send: $it", BootstrapLevel.info))
        }
    }

    private fun treePrint(d: Json, level: Int = 0) {
        val keys: Array<String> = js("Object").keys(d).unsafeCast<Array<String>>()
        for (a in keys) {
            if (jsTypeOf(d[a]) == "object") {
                if (a != "labels") {
                    //   println(a)
                    treePrint(d[a].unsafeCast<Json>(), level + 1)
                }
            } else {
                if (a in setOf("id", "name", "description")) {
                    for (i in 1..level) print("  ")
                    println("$a = ${d[a]}")
                }
            }
        }
    }

    private fun errorHandler(code: Int, text: String) {
        alerts.addAlert(Alert("($code) $text", BootstrapLevel.danger))
    }

    private fun backend() = CSAwareBackend(this::errorHandler)
}