package csaware.test

import csaware.main.CsawareServices
import csaware.main.UserInformation
import dk.rheasoft.csaware.api.access.MainFeature
import dk.rheasoft.csaware.api.access.Permission
import kafffe.messages.NavLanguageSelector
import kafffe.bootstrap.*
import kafffe.bootstrap.navigation.Nav
import kafffe.core.Model
import kafffe.core.NavigationElement
import kafffe.core.NavigationPath
import kafffe.messages.Messages
import kafffe.messages.i18nText
import kotlinx.browser.window

@OptIn(ExperimentalJsExport::class)
@JsExport
fun init() {
    CsawareServices.initServices()
    CsawareServices.systemDependencyService.expireModels()
    window.onload = {
        UserInformation.loadCurrent {
            if (UserInformation.hasAccess(MainFeature.CsawareAdministration, Permission.Read)) {
                CsawareServices.refeshData()
                val rootNavigation = NavigationElement.create("root") {
                    component("anomalies") { AnomalyTestPage() }
                    component("bcdr") { BcdrTestPage() }
                    component("infoshare") { InfoShareTestPage() }
                }
                BootstrapRoot().apply {
                    val container = BootstrapContainer.fluid()
                    rootNavigation.componentNavigator = { container.replaceContent(it) }
                    addChild(Nav.create(rootNavigation) {
                        addExpand(ResponsiveSize.md)
                        style = ColorStrength.light
                        background = BasicColor.primary

                        brand(" -- Test", "images/BrandLogo.png")
                        toggle("menuToggle")
                        toggleBlock("menuToggle") {
                            item(Model.of("Anomalies"), NavigationPath.fromString("root/anomalies"), "fas fa-shapes")
                            item(Model.of("BCDR"), NavigationPath.fromString("root/bcdr"), "fas fa-hospital")
                            item(Model.of("Info Share"), NavigationPath.fromString("root/infoshare"), "fas fa-info")
                            addChild(NavLanguageSelector(i18nText(Messages::languageSelect)))
                        }
                    })
                    addChild(container)
                    attach()
                }
                rootNavigation.navigateTo(NavigationPath.fromString("root/anomalies"))
            }
        }
    }
}


