package csaware.threats

import csaware.anomalies.AnomalyDetailsView
import csaware.mail.MailEditDlg
import csaware.main.CsawareServices
import csaware.main.UserInformation
import csaware.messages.CsawareMessages
import csaware.messages.CsawareMessagesObject
import csaware.messages.CsawareMessagesObject.csawareMessageStrategy
import csaware.messages.i18nText
import csaware.messages.threatState
import csaware.policy.PolicySimpleTableReader
import csaware.socialmedia.SocialMediaSimpleTableReader
import csaware.systemdepend.SystemDependencyService
import csaware.utilities.AccessLevelDropdown
import csaware.utilities.SeverityDropdown
import csaware.utilities.ThreatGroupDropdown
import csaware.utilities.markdown.MarkDownInput
import dk.rheasoft.csaware.api.Policy
import dk.rheasoft.csaware.api.access.MainFeature
import dk.rheasoft.csaware.api.access.Permission
import dk.rheasoft.csaware.api.incident.*
import dk.rheasoft.csaware.api.socialmedia.SocialMediaEntry
import dk.rheasoft.csaware.api.systemdependencies.SystemDependencyResource
import kafffe.bootstrap.*
import kafffe.bootstrap.form.*
import kafffe.bootstrap.modifier.BootstrapPopoverModifier
import kafffe.bootstrap.modifier.BootstrapTooltipModifier
import kafffe.bootstrap.navigation.NavSimpleContainer
import kafffe.core.*
import kafffe.core.modifiers.CssClassModifier
import kafffe.core.modifiers.CssClassModifier.Companion.cssClassModifier
import kafffe.core.modifiers.HtmlElementModifier
import kafffe.core.modifiers.StyleModifier
import kafffe.messages.MessagesObject.formatDateTime
import kotlinx.browser.window
import kotlinx.datetime.Clock
import kotlinx.datetime.toJSDate
import org.w3c.dom.HTMLDialogElement
import org.w3c.dom.HTMLInputElement

class ThreatChangeDlg(observation: ThreatObservation, private val graphService: SystemDependencyService) :
    FormDialog<ThreatObservation>(Model.of("${observation.name}: ${observation.id}"), Model.of(observation)) {
    private val newState = StateHistory(
        time = Clock.System.now(),
        state = observation.state,
        description = "",
        initiator = UserInformation.current.email
    )

    private val assigneeModel: Model<String?> = Model.ofNullable(observation.assignee)
    private val assigneesModel: Model<List<String>> = Model.ofGet {
        val admins: List<String> = UserInformation.threatManagers.data.map { it.email }
        listOf("") + admins
    }

    private val courseOfActionTable = BootstrapTable.create(observation.courseOfActions) {
        applyDefaultStyle()
        modifiers.add(CssClassModifier("csaware-field"))
        col(i18nText(CsawareMessages::name), { Label(it.name) })
        col(i18nText(CsawareMessages::description), { Label(it.description).preformatted() })
        col(i18nText(CsawareMessages::action), { Label(it.action) })
    }

    private lateinit var anomaliesPopup: HTMLDialogElement
    private fun showAnomaliesPopup(anomaly: AnomalyWrapper) {
        anomaliesPopup.innerHTML = ""
        KafffeHtml(anomaliesPopup).apply {
            add(AnomalyDetailsView(anomaly, graphService).html)
            withStyle {
                // setPropertyValue("inset", "0")
                margin = "auto"
            }
        }
        anomaliesPopup.showModal()
    }

    private fun hideAnomaliesPopup() {
        anomaliesPopup.close()
        anomaliesPopup.innerHTML = ""
    }

    private val anomaliesTable = BootstrapTable.create(observation.anomalies) {
        applyDefaultStyle()
        rowClickHandler = { data, _ -> showAnomaliesPopup(data) }
        modifiers.add(CssClassModifier("csaware-field"))
        col(i18nText(CsawareMessages::timestamp), { Label(it.created.toJSDate().formatDateTime()) })
        col(i18nText(CsawareMessages::anomaly_details_module), { Label(it.anomaly.module.name) })
        col(i18nText(CsawareMessages::system_depend_category), { Label(it.anomaly.category) })
        col(i18nText(CsawareMessages::description), { Label(it.anomaly.description).preformatted() })
    }

    private val histTable = BootstrapTable.create(observation.stateHistory) {
        applyDefaultStyle()
        modifiers.add(CssClassModifier("csaware-field"))
        col(i18nText(CsawareMessages::timestamp), { Label(it.time.toJSDate().formatDateTime()) })
        col(i18nText(CsawareMessages::threat_state), { Label(CsawareMessagesObject.get().threatState(it.state)) })
        col(i18nText(CsawareMessages::threat_state_initator), { Label(it.initiator) })
        col(i18nText(CsawareMessages::threat_state_comment), { Label(it.description).preformatted() })
    }

    private val tabs = NavSimpleContainer().apply {
        if (histTable.data.isNotEmpty()) {
            add("history", i18nText(CsawareMessages::threat_state_history), "fas fa-history") { histTable }
        }
        if (courseOfActionTable.data.isNotEmpty()) {
            add(
                "course_of_action", i18nText(CsawareMessages::threat_course_of_action), "fas fa-key"
            ) { courseOfActionTable }
        }
        if (anomaliesTable.data.isNotEmpty()) {
            add(
                "anomalies", i18nText(CsawareMessages::nav_anomalies), "fas fa-bell"
            ) { anomaliesTable }
        }
    }

    private val descriptionField = MarkDownInput(model.property(ThreatObservation::description))

    private fun loadData() {
        CsawareServices.alerts.clearAlerts()
        // clear any tooltip,popovers
        BootstrapTooltipModifier.removeAll()
        BootstrapPopoverModifier.removeAll()
        loadContextDiscussions()
        loadContextPolicies()
        loadContextThreats()
        loadContextSocialMedia()
    }

    private fun loadContextDiscussions() {
        CsawareServices.threatsBackend.threatContextDiscussion(model.data.id) {
            val discussions: List<DiscussionFull> = it
            if (discussions.isNotEmpty()) {
                val table = BootstrapTable.create(discussions) {
                    applyDefaultStyle()
                    rowClickHandler = { data, _ -> window.open(data.url, "_blank") }
                    modifiers.add(CssClassModifier("csaware-field"))
                    col(i18nText(CsawareMessages::timestamp), { Label(it.modified?.toJSDate()?.formatDateTime() ?: "") })
                    col(i18nText(CsawareMessages::name), { Label(it.name) })
                    col(i18nText(CsawareMessages::description), { Label(it.description) })
                    col(i18nText(CsawareMessages::link), { Label(it.url) })
                }
                tabs.add(
                    "discussions", i18nText(CsawareMessages::threat_context_dicussions), "fas fa-comments"
                ) { table }
                tabs.rerenderRecursive()
            }
        }
    }

    private fun loadContextPolicies() {
        CsawareServices.threatsBackend.threatContextPolicies(model.data.id) {
            val policies: List<Policy> = it
            if (policies.isNotEmpty()) {
                tabs.add(
                    "policies", i18nText(CsawareMessages::threat_context_policies), "fa-solid fa-file-lines"
                ) { PolicySimpleTableReader(policies) }
                tabs.rerenderRecursive()
            }
        }
    }

    private fun loadContextThreats() {
        CsawareServices.threatsBackend.threatContextThreats(model.data.id) {
            val threats: List<ThreatObservation> = it
            if (threats.isNotEmpty()) {
                tabs.add(
                    "threats", i18nText(CsawareMessages::threat_context_threats), "fas fa-exclamation-triangle"
                ) { ThreatSimpleTableReader(threats) }
                tabs.rerenderRecursive()
            }
        }
    }

    private fun loadContextSocialMedia() {
        CsawareServices.threatsBackend.threatContextSocialMedia(model.data.id) {
            val posts: List<SocialMediaEntry> = it
            if (posts.isNotEmpty()) {
                val reader = SocialMediaSimpleTableReader(posts)
                tabs.add(
                    "socialmedia", i18nText(CsawareMessages::threat_context_socialmedia), "fas fa-share-alt"
                ) { reader }
                tabs.rerenderRecursive()
            }
        }
    }


    init {
        loadData()
        labelStrategy = csawareMessageStrategy("threat_")
        size = ModalSize.large
        modal.modifiersBody.add(StyleModifier {
            overflowY = "auto"
            maxHeight = "90vh"
        })
        modal.modifiersModal.add(StyleModifier {
            maxWidth = "95rem"
            width = "95vw"
        })
        modal.modifiersContent.add(CssClassModifier("bg-light"))
        // We need some hgap because we do not apply whitespace "\n" between buttons.
        cssClassModifier("hgap-3")
        cssClassModifier("vgap-3")
        row {
            col(ColWidth(ResponsiveSize.md, 12)) {
                addTypeAndWhereInfo()
                addChild(Label(labelStrategy.label("description")))
                descriptionField.apply {
                    cssClassModifier("form-control")
                }
                addChild(descriptionField)
            }
            col(ColWidth(ResponsiveSize.md, 12), subModel = Model.of(newState)) {
                addNewStateHandling()
            }
        }
        addChild(ofKafffeHtml {
            dialog {
                addClass("bg-light border row p-0")
                withStyle {
                    position = "absolute"
                }
                element.onclick = { e -> hideAnomaliesPopup(); e.preventDefault(); e.stopPropagation() }
                anomaliesPopup = element
            }
        })
        addChild(tabs)
        tabs.navigateTo(NavigationPath.fromString("container/history"))
    }

    private fun FormLayout<StateHistory>.addNewStateHandling() {
        val awaitHealingDecision = newState.state == ThreatState.HealingAwaitDecision
        val healingInProgress =
            newState.state == ThreatState.Healed || model.data.state == ThreatState.HealingInProgress
        cssClassModifier("hgap-3 vgap-3 mb-3 mt-4")
        row {

            col(ColWidth(ResponsiveSize.md, 5)) {
                cssClassModifier("vgap-3")
                if (!awaitHealingDecision) {
                    // legend("newState")
                    // readonly(i18nText(CsawareMessages::timestamp), Model.of(model.data.time.formatDateTime()))
                    val stateModel = model.property(StateHistory::state)
                    val st = ThreatStateDropdown("st", stateModel)
                    if (this@ThreatChangeDlg.model.data.bcdrSelfHealingState.isInBcdr()) {
                        val dropdownAndBtn = KafffeComponent().apply {
                            cssClassModifier("input-group")
                            addChild(st)
                            addChild(ofKafffeHtml {
                                button {
                                    addClass("btn btn-secondary")
                                    text("BCDR..")
                                    element.title = getBcdrUrl()
                                    element.onclick = {
                                        window.open(getBcdrUrl(), "_blank")
                                    }
                                }
                            })
                        }
                        decorateAndAddComponent(i18nText(CsawareMessages::threat_state), dropdownAndBtn)
                    } else {
                        decorateAndAdd(i18nText(CsawareMessages::threat_state), st)
                    }
                } else {
                    // legend("selfHealingConfirm ")
                    model.data.description = this@ThreatChangeDlg.model.data.stateHistory[0].description
                }
                editSelectSingle(
                    "assignee", i18nText(CsawareMessages::threat_assignee), assigneeModel, assigneesModel
                ).apply {
                    modifiersInputControl.add(HtmlElementModifier.create {
                        (this as HTMLInputElement).readOnly = !UserInformation.hasAccess(MainFeature.Threats, Permission.Write)
                    })
                }
            }
            col(ColWidth(ResponsiveSize.md, 7)) {
                cssClassModifier("vgap-3")
                textArea(
                    "comment", labelStrategy.label("state_comment"), model.property(StateHistory::description)
                ).apply {
                    required = true
                    lines = 4
                }
            }
        }

        if (awaitHealingDecision) {
            if (UserInformation.hasAccess(MainFeature.Threats, Permission.Write)) {
                submit("accept", this@ThreatChangeDlg::accept).apply {
                    color = BasicColor.success
                    iconClasses = "fas fa-thumbs-up"
                }
                submit("decline", this@ThreatChangeDlg::decline).apply {
                    color = BasicColor.danger
                    iconClasses = "fas fa-thumbs-down"
                }
                submit("update", this@ThreatChangeDlg::save).apply {
                    color = BasicColor.info
                    iconClasses = "fas fa-save"
                }
            }
            cancel().color = BasicColor.secondary
        } else if (healingInProgress) {
            if (UserInformation.hasAccess(MainFeature.Threats, Permission.Write)) {
                submit("save", this@ThreatChangeDlg::save).apply {
                    color = BasicColor.success
                    iconClasses = "fas fa-thumbs-up"
                }
            }
            cancel().color = BasicColor.secondary
        } else {
            if (UserInformation.hasAccess(MainFeature.Threats, Permission.Write)) {
                submit().color = BasicColor.primary
            }
            cancel().color = BasicColor.secondary
        }
    }

    private fun addTypeAndWhereInfo() {
        row {
            cssClassModifier("vgap-3")
            col(ColWidth(ResponsiveSize.md, 6)) {
                input(ThreatObservation::name)
            }
            col(ColWidth(ResponsiveSize.md, 6)) {
                decorateAndAdd(i18nText(CsawareMessages::threat_where), whereSigthdeEdit())
            }
            col(ColWidth(ResponsiveSize.md, 4)) {
                val threatGroups = CsawareServices.configrationService.threatGroupsModel.data
                val threatGroupDropdown =
                    ThreatGroupDropdown("threatGroup", model.property(ThreatObservation::threatGroup), threatGroups)
                decorateAndAddComponent(i18nText(CsawareMessages::threat_group), threatGroupDropdown)
            }
            col(ColWidth(ResponsiveSize.md, 4)) {
                val severityDropdown = SeverityDropdown("severity", model.property(ThreatObservation::severity))
                decorateAndAddComponent(i18nText(CsawareMessages::severity), severityDropdown)
            }
            col(ColWidth(ResponsiveSize.md, 4)) {
                val accessLevelDropdown = AccessLevelDropdown("accessLevel", model.property(ThreatObservation::accessLevel))
                decorateAndAddComponent(i18nText(CsawareMessages::accessLevel), accessLevelDropdown)
            }
        }
    }

    private fun whereSigthdeEdit(): MultipleEditSelect<String> {
        val whereSightedRefsModel: Model<List<String>> = model.func(
            { p -> p.data.whereSightedRefs.toList() },
            { p, v ->
                p.data.whereSightedRefs.clear()
                p.data.whereSightedRefs.addAll(v)
            }
        )
        val systemResourceIds =
            graphService.model.data.sortedBy(SystemDependencyResource::name).map { it.id }
        return object : MultipleEditSelect<String>("whereSightedRefs", whereSightedRefsModel, Model.of(systemResourceIds)) {
            override fun display(choice: String): String =
                graphService.byId(choice)?.name ?: ""
        }
    }

    private fun save() {
        onSubmitOk()
        detach()
    }

    private fun accept() {
        newState.state = ThreatState.HealingAccepted
        onSubmitOk()
        detach()
        Modal.confirm(Model.of("Goto BCDR"), Model.of(getBcdrUrl())) {
            window.open(getBcdrUrl(), "_blank")
        }
    }

    private fun getBcdrUrl(): String =
        kafffenv.bcdr_url.replace("{{incident-id}}", model.data.id)


    private fun decline() {
        newState.state = ThreatState.HealingDeclined
        onSubmitOk()
        detach()
    }

    private fun changeState(observation: ThreatObservation, newState: StateHistory, assignee: String) {
        CsawareServices.alerts.clearAlerts()
        observation.changeState(UserInformation.current.email, newState.state, newState.description)
        val reassigned = (observation.assignee != assignee)
        if (reassigned) {
            observation.assignee = assignee
        }
        CsawareServices.threatsBackend.updateThreatObservation(observation) {
            if (reassigned && assignee.isNotBlank() && !assignee.equals(UserInformation.current.email, ignoreCase = true)) {
                MailEditDlg.showThreatAssignDialog(assignee, observation, newState)
            }
            with(observation.stateHistory) {
                clear()
                addAll(it.stateHistory)
            }
            rerenderRecursive()
        }
    }

    companion object {
        fun showWithoutReload(observation: ThreatObservation, graphService: SystemDependencyService) {
            ThreatChangeDlg(observation, graphService).apply {
                onSubmitOk = {
                    changeState(observation, newState, assigneeModel.data ?: "")
                }
                attach()
            }
        }

        fun show(observation: ThreatObservation, graphService: SystemDependencyService) {
            // reload threat with details (history and course of actions)
            CsawareServices.threatsBackend.threatWithDetails(observation.id) { obs: ThreatObservation ->
                showWithoutReload(obs, graphService)
            }
        }

    }
}