package csaware.systemdepend

import dk.rheasoft.csaware.api.systemdependencies.FieldType
import dk.rheasoft.csaware.api.systemdependencies.SystemDependencyConfig
import kafffe.bootstrap.form.MultipleDropdown
import kafffe.core.Model
import kafffe.core.modifiers.StyleModifier.Companion.styleModifier

class DependencyDropdown(
    val systemDependencyConfig: Model<SystemDependencyConfig>,
    model: Model<List<String>>,
    choiceModel: Model<List<String>> = Model.ofGet { fieldIds(systemDependencyConfig) },
    inline: Boolean = true,
    htmlId: String = "depdrop"
) : MultipleDropdown<String>(htmlId, model, choiceModel) {
    init {
        styleModifier {
            if (inline) {
                display = "inline-block"
            }
        }
        valueCssClasses = { valueCssClassDefault + " ms-1 me-1"}
        updateModelOnChange = true
        modelChangedStandardBehavior = { updateFromValueModel() }
    }

    companion object {
        private fun fieldIds(config: Model<SystemDependencyConfig>): List<String> =
            listOf("source") +
            config.data
                .findFieldsOfType(FieldType.DEPENDENCY)
                .map { it.id }
    }

    override fun display(choice: String): String =
        systemDependencyConfig.data.findField(choice)?.label ?: choice

}

