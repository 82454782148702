package csaware.messages

import kafffe.messages.Messages_fr

open class CsawareMessages_fr : Messages_fr(), CsawareMessages {
    override val translation_automatic: String = "Traduction automatique"
    override val settings: String = "Ρaramètres"
    override val nav_overview = "Vue d'ensemble"
    override val nav_threats = "Incidents"//"Des menaces"
    override val nav_threatsClosed = "Incidents clos"//"Menaces fermées"
    override val nav_config = "Configuration"
    override val threat_group = "Groupe"
    override val tab_threats_open = "Ouvert"
    override val tab_threats_closed ="Fermé"
    override val threat_group_all = "Toutes"
    override val threat_nobody = "personne"
    override val threat_state = "Etat"
    override val threat_state_initator = "Qui"
    override val threat_state_history = "Histoire"
    override val threat_state_comment = "Commentaire"
    override val threat_course_of_action = "Plan d'action"
    override val threat_where = "Où"
    override val threat_assignee = "Cessionnaire"

    override val environment_name = "Nom"

    override val threat_observed_data_objects = "Données observées"
    override val threat_observed_data_id = "Id"
    override val threat_observed_data_type = "Type"
    override val threat_observed_data_data = "Données"

    override val threat_subtype = "Sous-type"
    override val threat_firstObserved = "Premier observé"
    override val threat_endActive = "Fermé à"
    override val threat_newState = "Changer d'état"
    override val threat_selfHealingConfirm = "Confirmer l'BCDR"

    override val threat_topTitle = "Principales menaces"

    override val severity = "Gravité"
    override val count = "Compter"

    override val threat_level_5_name = "Critique"
    override val threat_level_5_text = "Une attaque est imminente."
    override val threat_level_4_name = "Sévère"
    override val threat_level_4_text = "Une attaque est très probable."
    override val threat_level_3_name = "substantiel"
    override val threat_level_3_text = "Forte possibilité d'attaque."
    override val threat_level_2_name = "Modérée"
    override val threat_level_2_text = "Une attaque est possible mais peu probable."
    override val threat_level_1_name = "Faible"
    override val threat_level_1_text = "Une attaque est peu probable."
    override val threat_level_0_name = "Inexistant"
    override val threat_level_0_text = "Aucune attaque prévue"

    override val ThreatState_Active = "Active"
    override val ThreatState_Resolved = "Résolu"
    override val ThreatState_Ignored = "Ignoré"
    override val ThreatState_HealingAwaitDecision = "L'BCDR a besoin de décision"
    override val ThreatState_HealingAccepted = "BCDR accepter"
    override val ThreatState_HealingDeclined = "BCDR refusée"
    override val ThreatState_Healed = "BCDR réalisée"
    override val ThreatState_HealingFailed = "L'BCDR a échoué"
    override val ThreatState_HealingInProgress = "En cours d'BCDR"
    override val ThreatState_Unknown = "Inconnu"
    override val informationshare_summary = "Summary"

    override val user_management = "Gestion des utilisateurs"
    override val user_edit = "Modification de l'utilisateur"
    override val user_new = "Nouvel utilisateur"
    override val user_subject = "Sujet"
    override val user_email = "Email"
    override val user_firstName = "Prénom"
    override val user_lastName = "Nom de famille"
    override val user_fullname  = "Nom"
    override val user_roles = "Rôles"
    override val user_action_import = "Importation"
    override val user_action_export = "Exportation"

    override val user_preferences_edit ="Préférences de l'interface utilisateur"
    override val user_preferences_dataPresentationLanguage = "Langue de présentation des données"
    override val user_preferences_uiLanguage ="Langue de l'interface utilisateur"

    override val system_preferences_edit ="Préférences de système"
    override val system_preferences_accountDataPresentationLanguage = "Langue de présentation des données par défaut"
    override val system_preferences_accountUiLanguage ="Langue de l'interface utilisateur par défaut"

    override val informationshare_management = "Partage d'information"
    override val informationshare_short_description =
        """Information sharing referrs to sharing of cybersecurity information with cybersecurity professionals outside your organization. 
Examples of such professionals may be computer security incident response teams (CSIRTs) your organization is collaborating with.

The purpose of sharing this information with cybersecurity professionals is for them to be better able to assess and analyze cybersecurity issues within organizations and to be able to set it in context with information gathered from other sources in order to be able to devise better mitigation and prevention mechanisms, which in turn may help your organization to better protect against attacks in the future.

If you decide to share, the information will be posted on a protected repository. Only people and organizations that have been authorized by your organization will be able to access the information."""
    override val informationshare_edit = "Modifier le partage d'informations"
    override val informationshare_id = "Id"
    override val informationshare_description = "Description"
    override val informationshare_state = "L' état"
    override val informationshare_create = "Créé"
    override val informationshare_modified = "Dernière modification"
    override val informationshare_initiator = "Qui"
    override val informationshare_history = "Histoire"

    override val ShareState_AwaitDecision = "Confirmer le partage"
    override val ShareState_Accept = "Acceptez"
    override val ShareState_Decline = "Refuser"
    override val ShareState_Unknown = "Inconnu"

    override val system_dependencies = "Système"

    override val system_depend_id = "Id"
    override val system_depend_name = "Nom"
    override val system_depend_description = "Description"
    override val system_depend_connectedFrom = "Connecté de"
    override val system_depend_connectedTo = "Se connecte à"
    override val system_depend_infoflow = "Flux d'information"
    override val system_depend_category = "Catégorie"
    override val system_depend_fields = "Champ"
    override val system_depend_fields_valueset = "Ensemble de valeurs de champ"
    override val system_depend_fields_valueset_values = "Valeurs"
    override val system_depend_node_types = "Node Types"
    override val system_depend_layout = "Plan"
    override val system_depend_layoutDirection = "Direction de mise en page"
    override val system_depend_layout_horizontal = "Horizontale"
    override val system_depend_layout_vertical = "Verticale"
    override val system_depend_layout_roots = "Racines"
    override val system_depend_layout_user_root = "Racines de la mise en page"
    override val system_depend_layout_default_root = "Mise en page par défaut"
    override val system_depend_spacing = "Espacement"

    override val system_depend_action_search = "Système de recherche"
    override val system_depend_action_new = "Créer un nouveau"
    override val system_depend_action_edit = "Modifier"
    override val system_depend_action_delete = "Supprimer"
    override val system_depend_action_delete_confirm_title = "Supprimer la ressource système"
    override val system_depend_action_config = "Configuration"
    override val system_depend_action_import = "Importation"
    override val system_depend_action_export = "Exportation"
    override val system_depend_action_config_import = "Configuration Importation"
    override val system_depend_action_config_export = "Configuration Exportation"
    override val system_depend_action_print = "Impression"

    override val system_depend_action_backup_or_restore = "Sauvegarder ou Restaurer"
    override val system_depend_backup_title = "Sauvegarde des Ressources Système"
    override val system_depend_backup_create_new = "Nouvelle sauvegarde"
    override val system_depend_backup_created = "Créé"
    override val system_depend_backup_comment = "Commentaire"
    override val system_depend_backup_nodes_not_in_current = "Pas dans l'actuel"
    override val system_depend_backup_nodes_in_current_not_in_this = "Pas dans celui-ci"
    override val system_depend_backup_nodes_changed = "Modifié"
    override val system_depend_restore_state = "Changer"
    override val system_depend_restore_operation = "Opération"
    override val system_depend_restore_operation_remove = "Supprimer"
    override val system_depend_restore_operation_restore = "Restaurer"
    override val system_depend_restore_revert_all_nodes = "Restaurer tous les Nœuds Système"
    override val system_depend_restore_revert_configuration = "Restaurer la Configuration des Dépendances Système"

    override val system_depend_label_all = "Tout"

    override val system_depend_field_id = "Id"
    override val system_depend_field_label = "Nom "
    override val system_depend_field_type = "Type"
    override val system_depend_field_cardinality = "Cardinalité"
    override val system_depend_field_add = "Inclure un champ"
    override val system_depend_fields_config = "Configuration du champ.."
    override val system_depend_field_validation_error_txt = "'%value%' n'est pas valide pour '%type%'"

    override val sign_out = "Déconnexion"
    override val sign_out_disclaimer = """
        |La session de visualisation CS-AWARE a été effacée,  
        |vous devrez vous déconnecter du fournisseur d'autorisation externe,  
        |si vous ne souhaitez pas vous reconnecter automatiquement. 
        |""".trimMargin()
    override val sign_out_link = "Accédez à votre fournisseur d'autorisation pour vous déconnecter"

    override val mail_dialog_title = "Envoyer un mail"
    override val mail_to = "À"
    override val mail_from = "De"
    override val mail_subject = "Objet"
    override val mail_body = "Corps"
    override val mail_send = "Envoyé"

    override val socialmedia_reader: String = "Médias sociaux"
    override val socialmedia_reader_filter_readstate_both = "Lu et non lu"
    override val socialmedia_reader_filter_readstate_not_read = "Seulement non lu"
    override val socialmedia_reader_filter_readstate_read = "Seulement lu"

    override val socialmedia_reader_read_on_source = "Lire la source"
    override val socialmedia_createdAt: String = "Créé à"
    override val socialmedia_keywords: String = "Mots-clés"
    override val socialmedia_text: String = "Texte"
    override val socialmedia_account: String = "Compte"
    override val socialmedia_id:String = "Id"

    override val socialmedia_system_where = "Concerne"

    override val socialmedia_account_included = "Inclus"
    override val socialmedia_management_title = "Gestion des médias sociaux"
    override val socialmedia_account_edit = "Modifier"
    override val socialmedia_account_new = "Nouveau"
    override val socialmedia_account_mediaType = "Type"
    override val socialmedia_account_collectorState = "Collector"
    override val socialmedia_account_id = "Id"
    override val socialmedia_account_name = "Nom"
    override val socialmedia_account_displayName = "Nom d'affichage"
    override val socialmedia_account_description = "Description"
    override val socialmedia_account_lastCollected = "Dernière collecte"

    override val socialmedia_account_twitter_new = "Nouvelle collecte de compte X"
    override val socialmedia_account_include = "Inclure la collecte des comptes"
    override val socialmedia_account_exclude = "Exclure la collecte de comptes"

    override val socialmedia_account_action_export = "Exporter"
    override val socialmedia_account_action_import = "Importer"
    override val socialmedia_account_action_include_page = "Inclure la collecte pour les comptes sur la page"
    override val socialmedia_account_action_exclude_page = "Exclure la collecte pour les comptes sur la page"

    override val socialmedia_create_threatobservation = "Créer un rapport de menace"

    override val policy = "Politique de sécurité"
    override val policy_edit = "Modifier Politique"
    override val policy_title = "Titre"
    override val policy_purpose = "Purpose"
    override val policy_elements = "Elements"
    override val policy_need = "Need"
    override val policy_roles_responsibilities = "Role and responsibilities"
    override val policy_references = "References"
    override val policy_tags = "Tags"
    override val policy_systemNodeReferences = "Références des ressources du système"
    override val policy_new  = "Créer Politique"
    override val policy_createdAt = "Created at"
    override val policy_createdBy = "Created by"
    override val policy_updatedAt = "Updated at"
    override val policy_updatedBy = "Updateted by"

    override val policy_state = "État"
    override val policy_state_draft = "Brouillon"
    override val policy_state_approved = "Approuvée"
    override val policy_state_draft_with_approved = "Projet avec version approuvée"
    override val policy_state_obsolete = "Obsoléte"
    override val policy_state_unknown = "Inconnu"

    override val policy_type_organisation =  "Organisation"
    override val policy_type_template = "Modéle"

    override val policy_comment_comment = "Commentaire"
    override val policy_comment_add = "Ajouter un commentaire"
    override val policy_comment_edit = "Modifier le commentaire"
    override val policy_comment_respond = "Répondre"
    override val policy_comment_delete = "Supprimer le commentaire"

    override val policy_approve_comment_add = "Ajouter approuver le commentaire"

    override val policy_history = "Histoire"

    override val policy_action_edit = "Modifier"
    override val policy_action_clone = "Cloner"
    override val policy_action_approve = "Approuver"
    override val policy_action_export = "Exporter"

    override val policy_action_ai_import = "Politique d'importation d'IA.."
    override val policy_import_extract_text = "Extraire le texte du document (fichier)"

    override val policy_config  = "Configuration des étiquettes et des types de relation"
    override val policy_relation_type = "Type de relation"
    override val policy_relation_id = "Id"
    override val policy_relation_name = "Nom"
    override val policy_relation_reversed = "Relation inversée"
    override val policy_template_action_edit= "Nouveau du modèle"

    override val anomalies_ignores_saved = "Anomalies ignorées"
    override val anomalies_incident_saved = "Incident enregistré"
    override val anomaly_details_module = "Module"
    override val anomaly_to_be_ignored= "Être ignoré"
    override val anomaly_incident= "Incidents"
    override val anomaly_new_incident= "Nouvel incident"
    override val anomaly_unsaved_changes_titel="Il y a des modifications non enregistrées"
    override val anomaly_unsaved_changes_message="Il y a des modifications non enregistrées, souhaitez-vous continuer sans les enregistrer?"

    override val kpi_dimension_title_Dynamic_Cybersecurity_Strategy="Stratégie de cybersécurité dynamique"
    override val kpi_dimension_title_Collaborative_Mindset ="Esprit collaboratif"
    override val kpi_dimension_title_Organizational_Learning ="Apprentissage organisationnel"
    override val kpi_dimension_title_Regulatory_Compliance="Conformité réglementaire"
    override val kpi_dimension_title_Standards_and_Technologies= "Normes et technologies"


    override val kpi_incident_total ="Nombre total d'incidents"
    override val kpi_incident_sharing_ecosystem = "Écosystème de partage d'incidents"
    override val kpi_incident_sharing_global= "Partage d'incidents à l'échelle mondiale"
    override val kpi_sharing_count_public  = "compte de partage public"
    override val kpi_incident_sharing_changes = "Modifications du partage d'incidents"
    override val kpi_statistic_description_incident_total="Le nombre total d'incidents traités par le système (ouverts automatiquement ou manuellement par le système).\n" +
            "Fournit une indication du degré de préparation d’une organisation à détecter et à gérer la cybersécurité \n" +
            "Problèmes – soit automatiquement via des outils de surveillance de la cybersécurité correctement configurés, soit manuellement via la sensibilisation et la collaboration."
    override val kpi_statistic_description_incident_sharing_ecosystem= "Le nombre d'incidents avec le niveau de partage 'écosystème'." +
            "Donne une indication de la manière dont votre organisation collabore avec d'autres organisations associées (y compris les fournisseurs et les clients) afin de gérer les problèmes de cybersécurité " +
            "dans votre organisation ainsi que d'aider les organisations collaboratrices à gérer les problèmes liés aux services dont elles dépendent."
    override val kpi_statistic_description_incident_sharing_global_d2="Le nombre d’incidents avec un niveau de partage « global ».\n" +
            "Donne une indication sur la manière dont votre organisation participe à la cybersécurité collaborative, " +
            "comme par ex. définies par la stratégie européenne de cybersécurité. C'est également une indication de la manière dont votre " +
            "organisation respecte la conformité légale et réglementaire en matière de partage d'informations (par exemple, NIS, RGPD).n"
    override val kpi_statistic_description_incident_sharing_changes= "Le nombre d'adaptations aux niveaux de partage (hebdomadaire/mensuel).\n" +
            "Donne une indication de la manière dont votre organisation participe activement au partage d’informations pour parvenir à " +
            "une sensibilisation collaborative et à une coopération pour résoudre les problèmes de cybersécurité."
    override val kpi_statistic_description_frontend_logins ="Le nombre de connexions au système.\n" +
            "Donne une indication sur la manière dont l'organisation surveille et gère activement la situation de cybersécurité dans l'organisation."
    override val kpi_statistic_description_distinct_users ="Le nombre d'utilisateurs distincts dans le système.\n" +
            "Donne une indication sur le nombre de ressources que votre organisation consacre à la surveillance et à la gestion de la situation de cybersécurité dans l'organisation."
    override val kpi_statistic_description_anomaly_count ="Le nombre d'anomalies détectées par la plateforme.\n" +
            "Donne une indication sur la manière dont la détection automatisée des comportements suspects en matière de cybersécurité est\n" +
            "configurée au sein de votre organisation."
    override val kpi_statistic_description_anomaly_timeline ="Nombre de nouvelles anomalies détectées par le système (hebdomadaire/mensuel).\n" +
            "Donne une indication de la rapidité avec laquelle les comportements suspects en matière de cybersécurité sont évalués et gérés au sein de\n" +
            "votre organisation."
    override val kpi_statistic_description_anomaly_dismissed ="Nombre d'anomalies classées par l'intervenant comme non pertinentes.\n" +
            "Donne une indication de la manière dont la surveillance automatisée est configurée en fonction du contexte spécifique de\n" +
            "votre organisation et de la manière dont l'organisation comprend son propre contexte. Plus le nombre est faible, mieux c'est."
    override val kpi_statistic_description_anomaly_incidents ="Nombre d'anomalies considérées comme des incidents (hebdomadaire/mensuel).\n" +
            "Donne une indication de la manière dont la surveillance automatisée du contexte spécifique de l'organisation est configurée et " +
            "de la manière dont l'intervenant comprend les comportements anormaux qui indiquent un problème de cybersécurité qui doit être géré."
    override val kpi_statistic_description_system_graph_assets_d4 ="Le nombre d'actifs dans le graphique système.\n" +
            "Donne une indication sur la façon dont votre organisation a identifié les actifs de l'entreprise qui doivent être protégés\n" +
            "dans le cyberespace. Dans le contexte de la conformité réglementaire, cela indique dans quelle mesure votre entreprise comprend ses\n" +
            "obligations de protection des actifs de l'entreprise contre les cyberattaques.n"
    override val kpi_statistic_description_system_graph_dependencies_d4 ="Le nombre de dépendances dans le graphique système.\n" +
            "Donne une indication sur la façon dont votre organisation comprend comment les actifs de votre entreprise dépendent les uns des autres en termes de cybersécurité. Dans le contexte de la conformité réglementaire, " +
            "cela indique dans quelle mesure votre organisation comprend l'importance de prendre en compte les dépendances lors de la protection contre les cyberattaques.\n"
    override val kpi_statistic_description_system_graph_values_d4 =""
    override val kpi_statistic_description_system_graph_changes_d4 ="Le nombre d'adaptations apportées au graphique système (hebdomadaire/mensuel).\n" +
            "Donne une indication sur la façon dont votre organisation maintient les connaissances liées à la cybersécurité dans un monde qui connaît des changements constants dans " +
            "votre organisation et dans le paysage de la cybersécurité. Dans le contexte de la conformité réglementaire, cela donne une indication sur la façon dont votre entreprise  " +
            "gère les procédures de gestion dynamique de la cybersécurité.\n"
    override val kpi_statistic_description_incident_sharing_global_d4 ="Le nombre d'incidents avec un niveau de partage « global ».\n" +
            "Donne une indication sur la manière dont votre organisation participe à la cybersécurité collaborative, comme le souligne par exemple la stratégie européenne de cybersécurité. Dans le contexte de la conformité réglementaire, " +
            "il s'agit d'une indication sur la manière dont votre organisation respecte la conformité légale et réglementaire en matière de partage d'informations (par exemple NIS, RGPD). \""
    override val kpi_statistic_description_system_graph_security_appliances ="Nombre d'équipements de sécurité identifiés dans le graphique système.\n" +
            "Fournit une indication de la mesure dans laquelle l'organisation suit les pratiques de sécurité standard en appliquant\n" +
            "des contrôles de sécurité technologiques pour prévenir et gérer les problèmes de cybersécurité.\n"
    override val kpi_statistic_description_policy_standards ="Nombre de politiques de cybersécurité dérivées des normes de sécurité."

    override val kpi_time_of_count= "Heure du décompte"
    override val kpi_number_of_incidents= "Nombre d'incidents"
    override val kpi_number_of_policies= "Nombre de polices"
    override val kpi_number_of_changes= "Nombre de changements"
    override val kpi_number_of_assets= "Nombre d'actifs"
    override val kpi_number_of_dependencies= "Nombre de dépendances"
    override val kpi_number_of_values= "Nombre de valeurs"
    override val kpi_number_of_logins ="Nombre de connexions"
    override val kpi_number_of_distinct_users ="Nombre d'utilisateurs distincts"
    override val kpi_number_of_anomalies ="Nombre d'anomalies"
    override val kpi_number_of_new_anomalies ="Nombre de nouvelles anomalies"
    override val kpi_number_of_dismissed_anomalies ="Nombre d'anomalies rejetées"
    override val kpi_number_of_new_incidents ="Nombre de nouveaux incidents"
    override val kpi_number_of_dot_dot_dot ="Nombre de ..."
    override val kpi_number_of_security_appliances ="Nombre d'appareils de sécurité"
    override val kpi_number_of_policy_standards ="Nombre de normes de politique"
    override val kpi_time= "Temps"

    override val kpi_logins ="Connexions "
    override val kpi_distinct_users ="Utilisateurs distincts"
    override val kpi_anomaly_count ="Nombre d'anomalies"
    override val kpi_anomaly_timeline ="Chronologie des anomalies "
    override val kpi_anomaly_dismissed ="Anomalie rejetée"
    override val kpi_anomaly_incident ="Incident d'anomalie"
    override val kpi_system_graph_security_appliances ="Équipement de sécurité pour le graphique système"
    override val kpi_security_appliances ="équipement de sécurité"
    override val kpi_policy_standards ="Normes de politique"
    override val kpi_policy_count= "Nombre de polices"
    override val kpi_policy_changes= "Changements de politique"


    override val kpi_statistic_description_policy_count= "Le nombre de politiques sur la plateforme. \n" +
            "Donne une indication de la capacité de votre organisation à faire face aux divers problèmes liés à la cybersécurité."
    override val kpi_statistic_description_policy_changes= "Le nombre d'adaptations apportées aux politiques (hebdomadaire/mensuel). \n" +
            "Donne une indication de la capacité de votre organisation à s'adapter au paysage de la cybersécurité en constante évolution."
    override val kpi_statistic_description_system_graph_assets_d1= "Le nombre d'actifs dans le graphique système. \n" +
            "Donne une indication de la manière dont votre organisation a identifié les actifs de l'entreprise qui doivent être protégés dans le cyberespace."
    override val kpi_statistic_description_system_graph_dependencies_d1= "Le nombre de dépendances dans le graphique système. \n" +
            "Donne une indication de la mesure dans laquelle votre organisation comprend comment les actifs de votre entreprise dépendent les uns des autres en termes de cybersécurité."
    override val kpi_statistic_description_system_graph_values_d1= "Nombre de valeurs indiquées pour les actifs dans le graphique système.\n" +
            "Donne une indication sur la gestion des connaissances en cybersécurité effectuée afin de conserver les connaissances \n" +
            "disponible à long terme"
    override val kpi_statistic_description_system_graph_changes_d1= "Le nombre d'adaptations apportées au graphique du système (hebdomadaire/mensuel). \n" +
            "Donne une indication de la manière dont votre organisation maintient ses connaissances liées à la cybersécurité dans un monde\n" +
            "qui voit des changements constants dans votre organisation et dans le paysage de la cybersécurité."

    override val kpi_system_graph_assets= "Actifs du graphique système"
    override val kpi_system_graph_dependencies= "Dépendances du graphique système"
    override val kpi_system_graph_values= "Valeurs du graphique système"
    override val kpi_system_graph_changes= "Modifications du graphique système"
    override val kpi_questionnaire= "Questionnaire"
    override val kpi_out_of_100= "sur 100"
    override val kpi_d1_section_view_description= "Explorez la capacité à développer et à mettre en œuvre une stratégie de cybersécurité et à améliorer la cybersécurité de l'organisation. \n" +
            "résilience en améliorant les capacités de réponse aux incidents, de cyberdéfense et de protection des infrastructures critiques (IC)."
    override val kpi_d2_section_view_description ="Examine les éléments importants d'une culture de cybersécurité responsable, tels que la compréhension des risques liés à la cybersécurité,\n" +
            "le niveau de confiance dans les services, la compréhension des problèmes de confidentialité, la collaboration au sein de l'organisation pour " +
            "promouvoir la sensibilisation à la cybersécurité et les contacts avec les parties prenantes concernées"
    override val kpi_d3_section_view_description ="Examine la disponibilité, l'adoption et la qualité des programmes pertinents de sensibilisation à la cybersécurité,\n" +
            "des programmes éducatifs formels en matière de cybersécurité et des programmes de formation professionnelle"
    override val kpi_d4_section_view_description ="Examine la capacité de l'organisation à concevoir et à promulguer une législation nationale qui\n" +
            "est directement et indirectement liée à la cybersécurité"
    override val kpi_d5_section_view_description ="Examine la mise en œuvre des normes et des bonnes pratiques de cybersécurité, la mise en œuvre des processus et\n" +
            "des contrôles, ainsi que le développement de technologies et de produits pour réduire les risques de cybersécurité"


    override val markdown_bold = "Gras"
    override val markdown_italic = "Italique"
    override val markdown_strikethrough = "Barré"
    override val markdown_heading = "Titre"
    override val markdown_heading_smaller = "Titre plus petit"
    override val markdown_heading_bigger = "Titre plus grand"
    override val markdown_heading_1x = "Titre 1"
    override val markdown_heading_2x = "Titre 2"
    override val markdown_heading_3x = "Titre 3"
    override val markdown_code = "Code"
    override val markdown_quote = "Citation"
    override val markdown_unordered_list = "Liste non ordonnée"
    override val markdown_ordered_list = "Liste ordonnée"
    override val markdown_clean_block = "Bloc propre"
    override val markdown_link = "Insérer un lien"
    override val markdown_image = "Insérer une image"
    override val markdown_mde_table = "Insérer un tableau"
    override val markdown_horizontal_rule = "Règle horizontale"
    override val markdown_preview = "Prévisualiser"
    override val markdown_side_by_side = "Vue côte à côte"
    override val markdown_fullscreen = "Plein écran"
    override val markdown_guide = "Guide"
    override val markdown_undo = "Annuler"
    override val markdown_redo = "Rétablir"

    override val validation_error: String = "Erreur de validation"
    override val save_anyway: String = "Sauvegarder quand même"

    override val required_field_not_filled_error: String= "Remplissez ce champ"
}

