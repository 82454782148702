package dk.rheasoft.csaware.api.incident

import kotlinx.serialization.ExperimentalSerializationApi
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

/**
 * State of an Incident
 */
@Serializable(with = IncidentState.Serializer::class)
enum class IncidentState(private val value: String) {
    Unknown(""),
    Open("open"),
    Progress("progress"),
    Closed("closed");

    fun toInternal(): String = value

    companion object {
        /**
         * Convert from stored int
         */
        fun fromInternal(value: String): IncidentState = entries.firstOrNull { it.value == value } ?: Unknown
    }

    @OptIn(ExperimentalSerializationApi::class)
    object Serializer : KSerializer<IncidentState> {
        override val descriptor: SerialDescriptor =
            PrimitiveSerialDescriptor("dk.rheasoft.csaware.api.incident.IncidentState", PrimitiveKind.INT)

        override fun serialize(encoder: Encoder, value: IncidentState) {
            encoder.encodeString(value.toInternal())
        }


        override fun deserialize(decoder: Decoder): IncidentState {
            val value = decoder.decodeString()
            return IncidentState.fromInternal(value)
        }
    }
}

