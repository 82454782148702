package csaware.utilities

import kafffe.bootstrap.*
import kafffe.core.Model
import kafffe.core.*
import kafffe.messages.Messages
import kafffe.messages.i18nText
import org.w3c.dom.DOMPoint
import org.w3c.dom.HTMLDivElement



/**
 * Modal component / dialog support. Unlike using bootstrap with jquery and static markup, the modals will usually be created and shown and then removed on close.
 */
open class OkModalPopUp(title:Model<String>) : Modal(title){


    companion object {

        /**
         * Constructs and shows confirm dialog
         */
        fun confirmOk(
            title: Model<String>,
            message: Model<String>,
            absolutePosition: DOMPoint?,
        ): TextDialogOk {
            val okconfirm = TextDialogOk(title, message)
            okconfirm.absolutePosition = absolutePosition
            okconfirm.ok({
                okconfirm.detach()
            })
            okconfirm.attach()
            return okconfirm
        }

        fun ok(title: Model<String>, question: Model<String>,) =
            confirmOk(title, question, null)
    }


    fun addOkButton(okHandler: () -> Unit, ok: Model<String> = Model.of("Ok")) {
        with(footerChildren) {
            add(BootstrapButton(ok, { okHandler() }).apply {
                iconClasses = "fas fa-check"
                color = BasicColor.primary
            })
        }
    }

    fun ok(okHandler: () -> Unit = { detach() }) = this.addOkButton(okHandler, ok = i18nText(Messages::ok))

}

open class TextDialogOk(titleText: Model<String>, val bodyText: Model<String>) : OkModalPopUp(titleText) {
    override fun KafffeHtml<HTMLDivElement>.createBody() {
        text(bodyText.data)
    }

}


