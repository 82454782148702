package csaware.systemdepend.config

import dk.rheasoft.csaware.api.systemdependencies.GraphView
import dk.rheasoft.csaware.api.systemdependencies.SystemDependencyConfig
import kafffe.bootstrap.form.FormValueProvider
import kafffe.core.KafffeComponentWithModel
import kafffe.core.KafffeHtml
import kafffe.core.KafffeHtmlBase
import kafffe.core.Model
import org.w3c.dom.HTMLDivElement

/**
 * Editor for graph views.
 */
class GraphViewsEditor(
    model: Model<MutableList<GraphView>>,
    val modelConfig: Model<SystemDependencyConfig>
) : KafffeComponentWithModel<MutableList<GraphView>>(model), FormValueProvider {

    private val currentViews: MutableList<GraphView> =
        // Deep clone to not make edits directly in real model
        model.data.sortedBy { it.name }.map { it.copy() }.toMutableList()

    /**
     * The current valueSet under editing
     */
    private var selectedTypeModel = Model.of(currentViews.firstOrNull() ?: GraphView("All"))
    private val selectedType get() = selectedTypeModel.data

    /**
     * Selects a new node type to be edited.
     * When called, it will update the current editor content with the new selected node type.
     * @param view The new node type to be edited.
     */
    private fun selectType(view: GraphView) {
        editor.updateValueModel()
        selectedTypeModel.data = view

        editor = createEditor()
        replaceContent(editor)
    }


    override fun updateValueModel() {
        model.data = currentViews
    }

    private var editor = addChild(createEditor())

    private fun createEditor(): GraphViewEditor =
        GraphViewEditor(selectedTypeModel, modelConfig)

    override fun KafffeHtmlBase.kafffeHtml() =
        div {
            addClass("form-group")
            div {
                addClass("row")
                renderList()
                div {
                    addClass("list-group me-4 col vgap-4")
                    add(editor.html)
                }
            }
        }

    private val tagWidth = "16rem"

    /**
     * Render a list of the current node types, allowing to add new ones.
     *
     * The list is grouped by inheritance, and allows to add new node types, which are inserted at the top of the list.
     * The edit dialog is then opened for the newly added type.
     */
    private fun KafffeHtml<HTMLDivElement>.renderList() {
        div {
            addClass("list-group col")
            withStyle {
                maxHeight = "60vh"
                overflowY = "auto"
                minWidth = "25rem"
            }

            div {
                addClass("input-group mt-1")
                val nameInput = input {
                    addClass("form-control list-group-item")
                    withElement {
                        type = "text"
                        value = ""
                        style.width = tagWidth
                        style.maxWidth = tagWidth
                    }
                }.element
                span {
                    addClass("input-group-append")
                    button {
                        addClass("btn btn-secondary")
                        withElement {
                            type = "button"
                            onclick = {
                                val newName = nameInput.value
                                if (newName.isNotBlank() && newName !in currentViews.map { it.name }) {
                                    val newType = GraphView(
                                        name = newName,
                                        dependencyFields = modelConfig.data.dependencyFields().map { it.id }
                                    )
                                    currentViews.add(0, newType)
                                    selectType(newType)
                                }
                            }
                        }
                        i {
                            addClass("fas fa-plus")

                        }
                    }
                }
            }

            addList()

        }
    }

    private fun KafffeHtml<HTMLDivElement>.addList() {
        currentViews.forEach { view ->
            div {
                addClass("input-group")
                div {
                    addClass("form-control list-group-item")
                    if (view.name == selectedType.name) {
                        addClass("selected")
                    } else {
                        element.onclick = {
                            selectType(view)
                            it.preventDefault()
                        }
                    }
                    withElement {
                        style.width = tagWidth
                        style.maxWidth = tagWidth
                        style.paddingLeft = "0.5rem"
                        setAttribute("readOnly", "true")
                    }
                    text(view.name)
                }
                span {
                    addClass("input-group-append")
                    button {
                        addClass("btn btn-secondary")
                        withElement {
                            type = "button"
                            onclick = {
                                currentViews.remove(view)
                                rerender()
                            }
                        }
                        i {
                            addClass("fas fa-trash")
                        }

                    }
                }
            }
        }
    }


}