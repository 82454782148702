package dk.rheasoft.csaware.api.statistics

import kotlinx.datetime.*
import kotlinx.serialization.Serializable
import kotlin.time.Duration.Companion.days

@Serializable
data class StatisticCounter(val title: String, val subTitle: String, val countType: CountType) {
    fun isDue(lastEntry: Instant): Boolean =
        countType.isDue(lastEntry)


    enum class CountType {
        DAILY, WEEKLY, MONTHLY, TOTAL;

        fun isPeriod(): Boolean = this in setOf(DAILY, WEEKLY, MONTHLY)

        fun nextPeriodStart(periodStart: Instant): Instant = when (this) {
            DAILY -> periodStart + 1.days
            WEEKLY -> periodStart + 7.days
            MONTHLY -> periodStart.plus(1, DateTimeUnit.MONTH, TimeZone.UTC)
            else -> periodStart + 1.days
        }

        fun isDue(lastEntry: Instant): Boolean {
            val endPeriod = nextPeriodStart(nextPeriodStart(lastEntry))
            return endPeriod <= Clock.System.now()
        }

        companion object {
            fun fromString(value: String): CountType = when (value.uppercase()) {
                "DAILY" -> DAILY
                "WEEKLY" -> WEEKLY
                "MONTHLY" -> MONTHLY
                "TOTAL" -> TOTAL
                else -> TOTAL
            }
        }

    }
}

@Serializable
data class StatisticEntry(
    val periodStart: Instant,
    val value: Float,
)

@Serializable
data class StatisticEntryWithAverages(
    val periodStart: Instant,
    val value: Float,
    val average: Float,
    val averageEcoSystem: Float,
)


