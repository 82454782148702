package dk.rheasoft.csaware.api.systemdependencies

import kotlinx.serialization.Serializable

/**
 * Describes a graph view, including which nodes types to display and which dependency fields to display.
 */
@Serializable
data class GraphView(
    var name: String,
    var description: String = "",
    var includeAllTypes: Boolean = false,
    var includedTypes: List<String> = listOf(),
    var includedTypesWithDescendants: List<String> = listOf(),
    var dependencyFields: List<String> = listOf()
) {
}