package csaware.anomalies

import csaware.main.CsawareServices
import csaware.messages.CsawareMessages
import csaware.messages.CsawareMessagesObject
import csaware.messages.i18nText
import dk.rheasoft.csaware.api.incident.AnomalyWrapper
import kafffe.core.KafffeComponent
import kafffe.core.KafffeHtmlBase
import kafffe.core.KafffeHtmlOut
import kafffe.core.Model
import kotlinx.dom.addClass
import kotlinx.dom.removeClass

open class ToBeIgnoredCollector(val dragHolder: AnomalyDragHolder, targetsModel: Model<List<Target>>) : KafffeComponent() {
    val anomaliesModel: Model<List<AnomalyWrapper>> = Model.of(emptyList())

    private val anomaliesTable = addChild(AnomaliesTable(anomaliesModel, dragHolder, targetsModel))

    private var enterCount = 0

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut =
        div {
            addClass("card")
            withElement {
                val dropzone = this
                ondragenter = { dragEvent ->
                    dragEvent.preventDefault()
                    if (++enterCount == 1) dropzone.addClass("dragover")
                    false
                }
                ondragleave = { dragEvent ->
                    dragEvent.preventDefault()
                    if (--enterCount == 0) dropzone.removeClass("dragover")

                }
                ondragover = { dragEvent ->
                    dragEvent.preventDefault()
                }
                ondrop = { dragEvent ->
                    dragEvent.preventDefault()
                    enterCount = 0
                    dropzone.removeClass("dragover")
                    dragHolder.moveTo(anomaliesModel)
                }
            }
            h3 {
                addClass("card-header")
                i { addClass("fa-solid fa-eye-slash me-2") }
                text(i18nText(CsawareMessages::anomaly_to_be_ignored).data)
            }
            div {
                addClass("card-body")
                add(anomaliesTable.html)
            }
            div {
                addClass("card-footer")
                button {
                    addClass("btn btn-primary")
                    withElement {
                        onclick = {
                            save()
                        }
                    }
                    text(CsawareMessagesObject.get().save)
                }
            }
        }

    fun hasUnsavedChanges(): Boolean =
        anomaliesModel.data.isNotEmpty()

    fun save() {
        CsawareServices.anomalyBackend.storeIgnored(anomaliesModel.data) {
            CsawareServices.alerts.infoAdd(CsawareMessagesObject.get().anomalies_ignores_saved)
            anomaliesModel.data = emptyList()
        }
    }
}