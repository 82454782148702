package csaware.utilities

import csaware.messages.CsawareMessagesObject
import dk.rheasoft.csaware.api.AccessLevel
import kafffe.bootstrap.form.FormInput
import kafffe.core.KafffeComponent
import kafffe.core.KafffeComponentWithModel
import kafffe.core.KafffeHtmlBase
import kafffe.core.Model
import kafffe.messages.MessagesObject
import org.w3c.dom.HTMLSelectElement

/**
 * FormInput Component to select Severity based on their names
 */
class AccessLevelDropdown(
    val idInput: String,
    valueModel: Model<AccessLevel>,
    val values: List<AccessLevel> = AccessLevel.entries.filter { it.selectable }
) : KafffeComponentWithModel<AccessLevel>(valueModel), FormInput {

    /**
     * Controls whether a selection is required
     */
    var required: Boolean by rerenderOnChange(false)

    /**
     * Controls whether the current value can be changed
     */
    var readOnly: Boolean by rerenderOnChange(false)

    private lateinit var htmlSelect: HTMLSelectElement

    override fun KafffeHtmlBase.kafffeHtml() =
        if (model.data.editable) {
            select {
                withElement {
                    htmlSelect = this
                    addClass("form-select")
                    id = idInput
                    value = model.data.toInt().toString()
                    required = this@AccessLevelDropdown.required
                    disabled = this@AccessLevelDropdown.readOnly
                }
                for (c in values) {
                    option {
                        withElement {
                            value = c.toInt().toString()
                            selected = (c == model.data)
                        }
                        text(CsawareMessagesObject.get().accessLevelName(c))
                    }
                }
            }
        } else {
            select {
                withElement {
                    htmlSelect = this
                    addClass("form-select")
                    id = idInput
                    value = model.data.toInt().toString()
                    required = this@AccessLevelDropdown.required
                    readOnly = true
                }
                option {
                    withElement {
                        value = model.data.toInt().toString()
                        selected = true
                    }
                    text(CsawareMessagesObject.get().accessLevelName(model.data))
                }

            }
        }


    override val htmlId: String get() = idInput

    override var validationMessageModel: Model<String> =
        Model.ofGet { if (required) MessagesObject.get().validation_required else htmlSelect.validationMessage }

    override fun component(): KafffeComponent = this

    override fun updateValueModel() {
        model.data = AccessLevel.fromInt(htmlSelect.value.toInt())
    }

    /**
     * Chcks if the current value is valid
     */
    override fun validate(): Boolean = htmlSelect.checkValidity()
}