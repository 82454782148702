package dk.rheasoft.csaware.api.incident

import dk.rheasoft.csaware.utils.JsonUtilSerialization
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.encodeToString

@Serializable
data class ThreatGroupOverview(
    val group: String,
    val severityMax: Int,
    val severityAvg: Double,
    val docCount: Int,
    val countSum: Int
)

@Serializable
data class ThreatOverview(
    val activeAt: Instant,
    val threatGroups: List<ThreatGroupOverview>
) {
    fun toJsonString(): String = JsonUtilSerialization.json.encodeToString(this)

    companion object {
        fun fromJson(jsonString: String): ThreatOverview =
            JsonUtilSerialization.json.decodeFromString(jsonString)

        fun empty(): ThreatOverview = ThreatOverview(Clock.System.now(), emptyList())
    }
}