package dk.rheasoft.csaware.api.systemdependencies

import kotlinx.serialization.Serializable

@Serializable
data class NodeType (
    var id: String,
    var name: String = id,
    var inheritsNodeTypeId: String? = null,
    var description: String = "",
    var shape: String = "rectangle_shape",
    var defaultFields: MutableList<FieldRef> = mutableListOf(),
) {
    companion object {
        val NULL = NodeType(id = "----", name = "----")
    }

    @Serializable
    data class FieldRef (
        var fieldId: String,
        // TODO May be override validation requirement
    )

    fun isOfType(config: SystemDependencyConfig, nodeTypeId: String): Boolean =
        (nodeTypeId == id) ||
                (config.nodeType(inheritsNodeTypeId)?.isOfType(config, nodeTypeId)) ?: false


    /**
     * Defualt fields including inhereted fields
     */
    fun allDefaultFields(config: SystemDependencyConfig): Set<FieldRef>  =
        if (inheritsNodeTypeId == null) {
            defaultFields.toSet()
        } else {
            inheritedFields(config, inheritsNodeTypeId) +
            defaultFields.toSet()
        }

    private fun inheritedFields(
        config: SystemDependencyConfig,
        inheritsNodeTypeId: String?
    ): Set<FieldRef> = (config.nodeType(inheritsNodeTypeId)?.allDefaultFields(config)?.toSet()  ?: emptySet())
}
