package csaware.utilities

import csaware.main.UserInformation
import dk.rheasoft.csaware.api.access.Grant
import dk.rheasoft.csaware.api.access.MainFeature
import dk.rheasoft.csaware.api.access.Permission
import dk.rheasoft.csaware.api.access.UserRole
import kafffe.bootstrap.BasicColor
import kafffe.core.*
import org.w3c.dom.HTMLButtonElement
import org.w3c.dom.HTMLElement

@DslMarker
annotation class ActionItemMarker

/**
 * Action bar primarily intended for local actions on the various views added above to the right.
 */
@ActionItemMarker
class ActionBar(initBlock: ActionBar.() -> Unit = {}) : KafffeComponent() {
    enum class Size(val cssClass: String) { Small("btn-group-sm"), Medium(""), Large("btn-group-lg") }

    var size = Size.Small
    var floatEnd: Boolean = true
    val items = mutableListOf<Item>()
    var titleAsText: Boolean = false

    // DSL
    init {
        initBlock()
    }

    fun item(titleModel: Model<String>, iconClasses: Model<String>, itemBuilder: SimpleItem.() -> Unit = {}): SimpleItem {
        val item = SimpleItem(titleModel, iconClasses)
        items.add(item)
        item.itemBuilder()
        return item
    }

    fun item(titleModel: Model<String>, iconClasses: String, itemBuilder: SimpleItem.() -> Unit = {}): SimpleItem =
        item(titleModel, Model.of(iconClasses), itemBuilder)

    fun dropdownItem(
        titleModel: Model<String>, iconClasses: Model<String>, itemBuilder: ComposedItem.() -> Unit = {}
    ): ComposedItem {
        val item = ComposedItem(titleModel, iconClasses)
        items.add(item)
        item.itemBuilder()
        return item
    }

    fun dropdownItem(titleModel: Model<String>, iconClasses: String, itemBuilder: ComposedItem.() -> Unit): ComposedItem =
        dropdownItem(titleModel, Model.of(iconClasses), itemBuilder)

    /**
     * Item classes
     */
    @ActionItemMarker
    abstract class Item(val titleModel: Model<String>, private val iconClasses: Model<String>) {
        var btnColor: BasicColor = BasicColor.secondary

        var accessRequirement: Pair<MainFeature, Permission>? = null

        // DSL
        var action: () -> Unit = {}

        fun isAllowed(): Boolean =
            accessRequirement?.let { (f, p) -> UserInformation.hasAccess(f, p) } ?: true

        abstract fun render(titleAsText: Boolean, iconToTheLeft: Boolean): HTMLElement

        protected fun KafffeHtml<HTMLButtonElement>.renderTextAndIcon(
            titleAsText: Boolean, iconToTheLeft: Boolean
        ) {
            when {
                titleAsText && iconToTheLeft -> {
                    i {
                        addClass(iconClasses.data)
                        addClass("fa-fw me-2")
                    }
                    text(titleModel.data)
                }

                titleAsText && !iconToTheLeft -> {
                    text(titleModel.data)
                    i {
                        addClass(iconClasses.data)
                        addClass("fa-fw me-2")
                    }
                }

                else -> {
                    i {
                        addClass(iconClasses.data)
                        addClass("fa-fw")
                    }
                }
            }
        }

    }

    /**
     * ComposedItem currently rendered as a dropdown
     */
    class ComposedItem(titleModel: Model<String>, iconClasses: Model<String>) : Item(titleModel, iconClasses) {
        private val items = mutableListOf<Item>()

        // DSL
        fun item(titleModel: Model<String>, iconClasses: Model<String>, itemBuilder: SubItem.() -> Unit = {}): SubItem {
            val item = SubItem(titleModel, iconClasses)
            item.btnColor = BasicColor.light
            items.add(item)
            item.itemBuilder()
            return item
        }

        fun item(titleModel: Model<String>, iconClasses: String, itemBuilder: SubItem.() -> Unit = {}): SubItem =
            item(titleModel, Model.of(iconClasses), itemBuilder)

        override fun render(titleAsText: Boolean, iconToTheLeft: Boolean): HTMLElement = htmlStart.div {
            addClass("${btnColor.backgroundClass}")
            button {
                addClass("btn ${btnColor.btnClass} dropdown-toggle")
                withElement {
                    id = "dropdownConfigMenuButton"
                    setAttribute("data-bs-toggle", "dropdown")
                    setAttribute("aria-haspopup", "true")
                    setAttribute("aria-expanded", "false")
                    style.textAlign = "left"
                }
                i { addClass("fas fa-cog") }

            }

            div {
                addClass("dropdown-menu dropdown-menu-right bg-light")
                element.setAttribute("aria-labelledby", "dropdownConfigMenuButton")
                for (action in items) {
                    if (action.isAllowed()) {
                        add(action.render(titleAsText = true, iconToTheLeft))
                    }
                }
            }
        }.element
    }

    class SimpleItem(titleModel: Model<String>, iconClasses: Model<String>) : Item(titleModel, iconClasses) {
        override fun render(titleAsText: Boolean, iconToTheLeft: Boolean): HTMLElement = htmlStart.button {
            addClass("btn ${btnColor.btnClass}")
            withElement {
                title = titleModel.data
                onclick = { action() }
            }
            renderTextAndIcon(titleAsText, iconToTheLeft)
        }.element

    }

    class SubItem(titleModel: Model<String>, iconClasses: Model<String>) : Item(titleModel, iconClasses) {
         override fun render(titleAsText: Boolean, iconToTheLeft: Boolean): HTMLElement = htmlStart.button {
            addClass("dropdown-item ${btnColor.backgroundClass}")
            withElement {
                title = titleModel.data
                onclick = { action() }
            }
            renderTextAndIcon(titleAsText, iconToTheLeft)
        }.element

    }

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut = div {
        addClass("btn-group ${size.cssClass}")
        if (floatEnd) {
            addClass("float-end")
        }
        for (action in items) {
            if (action.isAllowed()) {
                add(action.render(titleAsText, iconToTheLeft = true))
            }
        }
        for (child in children) {
            add(child.html)
        }
    }
}