package csaware.systemdepend.config

import dk.rheasoft.csaware.api.systemdependencies.NodeType
import dk.rheasoft.csaware.api.systemdependencies.SystemDependencyConfig
import kafffe.bootstrap.form.FormValueProvider
import kafffe.core.KafffeComponentWithModel
import kafffe.core.KafffeHtml
import kafffe.core.KafffeHtmlBase
import kafffe.core.Model
import org.w3c.dom.HTMLDivElement

/**
 * Editor for node types.
 * It allows to add, remove and edit node types.
 */
class NodeTypesEditor(
    model: Model<MutableList<NodeType>>,
    val modelConfig: Model<SystemDependencyConfig>,
    val countFunction: () -> Map<String, Int>
) : KafffeComponentWithModel<MutableList<NodeType>>(model), FormValueProvider {

    private val currentNodeTypes: MutableList<NodeType> =
        // Deep clone to not make edits directly in real model
        model.data.sortedBy { it.id }.map { it.copy() }.toMutableList()

    /**
     * The current valueSet under editing
     */
    private var selectedTypeModel = Model.of(currentNodeTypes.firstOrNull() ?: NodeType.NULL)
    private val selectedType get() = selectedTypeModel.data

    /**
     * Selects a new node type to be edited.
     * When called, it will update the current editor content with the new selected node type.
     * @param nodeType The new node type to be edited.
     */
    private fun selectType(nodeType: NodeType) {
        editor.updateValueModel()
        selectedTypeModel.data = nodeType

        editor = createEditor()
        replaceContent(editor)
    }


    override fun updateValueModel() {
        model.data = currentNodeTypes
    }

    private var editor = addChild(createEditor())

    private fun createEditor(): NodeTypeEditor =
        NodeTypeEditor(selectedTypeModel, modelConfig, Model.ofGet { currentNodeTypes.toList() })

    override fun KafffeHtmlBase.kafffeHtml() =
        div {
            addClass("form-group")
            div {
                addClass("row")
                renderList()
                div {
                    addClass("list-group me-4 col vgap-4")
                    add(editor.html)
                }
            }
        }

    private val tagWidth = "16rem"

    /**
     * Render a list of the current node types, allowing to add new ones.
     *
     * The list is grouped by inheritance, and allows to add new node types, which are inserted at the top of the list.
     * The edit dialog is then opened for the newly added type.
     */
    private fun KafffeHtml<HTMLDivElement>.renderList() {
        div {
            addClass("list-group col")
            withStyle {
                maxHeight = "60vh"
                overflowY = "auto"
                minWidth = "25rem"
            }

            div {
                addClass("input-group mt-1")
                val idInput = input {
                    addClass("form-control list-group-item")
                    withElement {
                        type = "text"
                        value = ""
                        style.width = tagWidth
                        style.maxWidth = tagWidth
                    }
                }.element
                span {
                    addClass("input-group-append")
                    button {
                        addClass("btn btn-secondary")
                        withElement {
                            type = "button"
                            onclick = {
                                val newId = idInput.value
                                if (newId.isNotBlank() && newId !in currentNodeTypes.map { it.id }) {
                                    val newType = NodeType(id = newId)
                                    currentNodeTypes.add(0, newType)
                                    selectType(newType)
                                }
                            }
                        }
                        i {
                            addClass("fas fa-plus")

                        }
                    }
                }
            }

            val byInherits: Map<String?, List<NodeType>> = currentNodeTypes.groupBy { it.inheritsNodeTypeId }
            addListLevel(byInherits, null, 0)

        }
    }

    private fun KafffeHtml<HTMLDivElement>.addListLevel(byInherits: Map<String?, List<NodeType>>, inheritsId: String?, level: Int) {
        val current = byInherits[inheritsId] ?: emptyList()

        current.forEach { nodeType ->
            val usageCount = countFunction()[nodeType.id] ?: 0
            div {
                addClass("input-group")
                div {
                    addClass("form-control list-group-item")
                    if (nodeType.id == selectedType.id) {
                        addClass("selected")
                    } else {
                        element.onclick = {
                            selectType(nodeType)
                            it.preventDefault()
                        }
                    }
                    withElement {
                        style.width = tagWidth
                        style.maxWidth = tagWidth
                        style.paddingLeft = "${level+0.5}rem"
                        setAttribute("readOnly", "true")
                    }
                    if (level > 0) text("↳ ")
                    text(nodeType.id)
                    if (usageCount > 0) {
                        sup {
                            addClass("badge badge-pill bg-warning")
                            text("$usageCount")
                        }
                    }
                }
                span {
                    addClass("input-group-append")
                    button {
                        addClass("btn btn-secondary")
                        withElement {
                            type = "button"
                            if (usageCount > 0 || byInherits.containsKey(nodeType.id)) {
                                disabled = true
                            } else {
                                onclick = {
                                    currentNodeTypes.remove(nodeType)
                                    rerender()
                                }
                            }
                        }
                        i {
                            addClass("fas fa-trash")
                        }

                    }
                }
            }
            addListLevel(byInherits, nodeType.id, level + 1)

        }
    }


}