package dk.rheasoft.csaware.api.systemdependencies

/**
 * Filters the system dependency resources to include only those with node types that are
 * included in the specified graph view.
 *
 * @param graphView The graph view which specifies which node types to be included.
 * @param config The system dependency configuration to use for retrieving node types.
 * @return A list of system dependency resources whose node types are included in the graph view.
 */
fun List<SystemDependencyResource>.filterIncludedInVIew(
    graphView: GraphView,
    config: SystemDependencyConfig
): List<SystemDependencyResource> {
    if (graphView.includeAllTypes) return this
    val nodetypesInView = config.nodetypesInView(graphView).map { it.id }.toSet()
    return this.filter { it.x_csaware_node_type in nodetypesInView }
}