package dk.rheasoft.csaware.api.incident

import dk.rheasoft.csaware.utils.JsonUtilSerialization
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.encodeToString

@Serializable
data class StateHistory(
    var initiator: String = "here comes initials/system",
    var state: ThreatState,
    var time: Instant,
    var description: String
) {
    fun toJsonString(): String = JsonUtilSerialization.json.encodeToString(this)

    companion object {
        fun fromJson(jsonString: String): StateHistory =
            JsonUtilSerialization.json.decodeFromString(jsonString)
    }
}