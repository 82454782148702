package csaware.comm

import csaware.main.CsawareServices
import dk.rheasoft.csaware.api.*
import dk.rheasoft.csaware.api.config.SystemLevelConfig
import dk.rheasoft.csaware.api.policy.PolicyFilter
import dk.rheasoft.csaware.utils.JsonUtilSerialization
import encodeURIComponent
import kotlinx.serialization.decodeFromString
import kafffe.bootstrap.Alert
import kafffe.bootstrap.BootstrapLevel
import kotlinx.serialization.encodeToString
import org.w3c.files.File

open class ConfigBackend(exceptionHandler: (errorCode: Int, errorText: String) -> Unit) : CSAwareBackend(exceptionHandler) {
    private fun encodeListParameter(list: Iterable<String>) = list.joinToString(",") { encodeURIComponent(it) }

    /**
     * Gets the all teh system level configuration key-values
     */
    fun getSystemLevelConfig(receiver: (SystemLevelConfig) -> Unit) {
        getTxt("/config/systemlevel/get"){
            val x: SystemLevelConfig = JsonUtilSerialization.json.decodeFromString(it)
            receiver(x)
        }
    }

    fun storeSystemLevelConfig(config: SystemLevelConfig, receiver: () -> Unit) {
        sendJsonTxt("/config/systemlevel/store", "POST", JsonUtilSerialization.json.encodeToString(config)) {
            receiver()
        }
    }


}

