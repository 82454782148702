package csaware.socialmedia

import csaware.messages.CsawareMessages
import csaware.messages.i18nText
import csaware.systemdepend.SystemDependencyService
import csaware.threats.Popovers
import csaware.utilities.EllipsisLabel
import dk.rheasoft.csaware.api.socialmedia.SocialMediaAccount
import dk.rheasoft.csaware.api.socialmedia.SocialMediaEntry
import dk.rheasoft.csaware.api.socialmedia.SocialMediaType
import kafffe.bootstrap.BootstrapTable
import kafffe.bootstrap.BootstrapTableStyles
import kafffe.bootstrap.external.Placement
import kafffe.bootstrap.external.placementEnum
import kafffe.bootstrap.modifier.BootstrapTooltipModifier
import kafffe.core.Label
import kafffe.core.Model
import kafffe.core.modifiers.CssClassModifier
import kafffe.core.modifiers.CssClassModifier.Companion.cssClassModifier
import kafffe.core.modifiers.StyleModifier.Companion.styleModifier
import kafffe.messages.MessagesObject.formatDateTime
import kotlinx.datetime.toJSDate
import kotlinx.dom.addClass
import kotlinx.dom.removeClass
import org.w3c.dom.HTMLCollection
import org.w3c.dom.HTMLTableCellElement
import org.w3c.dom.HTMLTableRowElement

object SocialMediaObservationTable {

    var accountById: Map<String, SocialMediaAccount> = mapOf()

    fun create(
        accountByIdVal: Map<String, SocialMediaAccount>,
        selectionModel: Model<SocialMediaEntry?>,
        graphService: SystemDependencyService,
        small: Boolean = false
    ) = BootstrapTable.create<SocialMediaEntry>(listOf()) {
        accountById = accountByIdVal
        rowClickHandler = { entry: SocialMediaEntry, rowElement: HTMLTableRowElement ->
            Popovers.removeAll()
            selectionModel.data = entry
            rowElement.parentElement?.let { parent ->
                val siblings: HTMLCollection = parent.children
                for (i in 0 until siblings.length) {
                    val child = siblings.item(i)
                    if (child is HTMLTableRowElement) {
                        child.removeClass("cs-aware-selected")
                    }
                }
            }
            rowElement.addClass("cs-aware-selected")
        }

        addStyle(BootstrapTableStyles.striped)
        modifiers.add(CssClassModifier("csaware-hover"))
        modifiersHeader.add(CssClassModifier("bg-primary"))

        colEx(i18nText(CsawareMessages::socialmedia_account), { data: SocialMediaEntry, cell: HTMLTableCellElement ->
            cell.tabIndex = 0
            val acc: String = accountById[data.observation.userId]?.name ?: data.observation.userId

            val model: Model<String> = Model.of(if (small) "" else acc)
            val label = EllipsisLabel("20vw", model, lines = 1).apply {
                styleModifier {
                    whiteSpace = "nowrap"
                }
                cssClassModifier(
                    when (data.observation.sourceType) {
                        //SocialMediaType.Twitter -> "sy sy_small sy_twitter"
                        SocialMediaType.Twitter -> "sy sy_small sy_x-twitter"
                        SocialMediaType.Reddit -> "sy sy_small sy_reddit"
                        SocialMediaType.Bluesky -> "sy sy_small sy_bluesky"
                        else -> "sy sy_small sy_rss"
                    }
                )

                val mod = BootstrapTooltipModifier(Model.of(model.data))
                mod.options.placementEnum = Placement.right
                mod.attach(this)
                modifiers.add(mod)
            }
            label
        }
        )

        col(i18nText(CsawareMessages::socialmedia_createdAt), { Label(it.observation.createdAt.toJSDate().formatDateTime()) })
        //col(i18nText(CsawareMessages::socialmedia_text), { Label(it.observation.text) })
        colEx(i18nText(CsawareMessages::socialmedia_text), { data: SocialMediaEntry, cell: HTMLTableCellElement ->
            cell.tabIndex = 0
            val text = data.getText()
            val title = data.getTitle()
            val fullText = when {
                title.isNotEmpty() && text.isNotEmpty() -> "$title\n\n$text"
                title.isNotEmpty() -> title
                else -> text
            }
            //letting the former model code be for now - JAVI - to be deleted later
            // val translationModel = TranslationModel(Model.of(fullText))
            val model: Model<String> = Model.of(fullText)
            //val label = EllipsisLabel("20vw", translationModel.translationModel(), lines = 2).apply { setModelChangedRerender() }
            val label = EllipsisLabel(if (small) "30rem" else "20vw", model, lines = 4)
//            val popover = Popovers.markdown { model.data }
//            popover.modify(cell)
            cell.addEventListener("click", { selectionModel.data = data; true }, true)
            label
        }).apply {
            rowClick = false
        }
        if (!small) {
            col(i18nText(CsawareMessages::socialmedia_keywords), { Label(it.matches.joinToString(", ")) })
            col(
                i18nText(CsawareMessages::socialmedia_system_where),
                { KeywordsWhereLink(graphService, it.matches) }
            ).apply {
                rowClick = false
            }
        }
    }
}