package csaware.messages

import kafffe.messages.Messages_it

open class CsawareMessages_it : Messages_it(), CsawareMessages {
    override val translation_automatic: String = "Traduzione automatica"
    override val settings: String = "Impostazioni"
    override val nav_overview = "Panoramica"
    override val nav_threats = "Incidenti"//""Minacce"
    override val nav_threatsClosed = "Incidenti chiusi"//"Minacce Risolte"
    override val nav_config = "Configurazione"

    override val tab_threats_open = "Aprire"
    override val tab_threats_closed ="Chiuso"
    override val threat_group = "Gruppo"
    override val threat_group_all = "Tutte"
    override val threat_nobody = "Nobody"
    override val threat_state = "Stato"
    override val threat_state_initator = "Chi"
    override val threat_state_history = "Storico"
    override val threat_state_comment = "Commento"
    override val threat_course_of_action = "Sequenza delle azioni"
    override val threat_where = "Dove"
    override val threat_assignee = "Assegnatario"

    override val environment_name = "Nome"

    override val threat_observed_data_objects = "Dati Analizzati"
    override val threat_observed_data_id= "Id"
    override val threat_observed_data_type= "Tipo"
    override val threat_observed_data_data = "Dati"

    override val threat_subtype = "Sottotipo"
    override val threat_firstObserved = "Prima osservazione"
    override val threat_endActive = "Risolto il"
    override val threat_newState = "Cambio Stato"
    override val threat_selfHealingConfirm = "Conferma il BCDR"

    override val threat_topTitle = "Principali Minacce"

    override val severity = "Severità"
    override val count = "Numerosità"

    override val threat_level_5_name = "Critica"
    override val threat_level_5_text = "Un attacco è previsto imminentemente."
    override val threat_level_4_name = "Severa"
    override val threat_level_4_text = "Un attacco è altamente probabile."
    override val threat_level_3_name = "Elevata"
    override val threat_level_3_text = "Un attacco è probabilie."
    override val threat_level_2_name = "Moderata"
    override val threat_level_2_text = "Un attacco è possibile, ma non probabile."
    override val threat_level_1_name = "Basso"
    override val threat_level_1_text = "Un attacco non è probabile."
    override val threat_level_0_name = ""
    override val threat_level_0_text = ""

    override val ThreatState_Active = "Attiva"
    override val ThreatState_Resolved = "Risolta"
    override val ThreatState_Ignored = "Ignorata"
    override val ThreatState_HealingAwaitDecision = "Necessaria una decisione per BCDR "
    override val ThreatState_HealingAccepted = "Accetta BCDR"
    override val ThreatState_HealingDeclined = "Rifiuta BCDR"
    override val ThreatState_Healed = "BCDR Effettuato"
    override val ThreatState_HealingFailed = "BCDR Fallito"
    override val ThreatState_HealingInProgress = "Self Healed in corso"
    override val ThreatState_Unknown = "Sconosciuto"

    override val user_management = "Gestione Utente"
    override val user_edit = "Modifica Utente"
    override val user_new = "Nuovo Utente"
    override val user_subject = "Oggetto"
    override val user_email = "Email"
    override val user_firstName = "Nome"
    override val user_lastName = "Cognome"
    override val user_fullname = "Nome"
    override val user_roles = "Ruoli"
    override val user_action_import = "Importa"
    override val user_action_export = "Esporta"

    override val user_preferences_edit = "Preferenze dell'interfaccia utente"
    override val user_preferences_dataPresentationLanguage = "Linguaggio di presentazione dei dati"
    override val user_preferences_uiLanguage ="Lingua dell'interfaccia utente"

    override val system_preferences_edit = "Preferenze di Sistema"
    override val system_preferences_accountDataPresentationLanguage = "Lingua di presentazione dei dati predefinita"
    override val system_preferences_accountUiLanguage ="Lingua dell'interfaccia utente predefinita"

    override val informationshare_management = "Condivisione delle informazioni"
    override val informationshare_short_description = """Information sharing referrs to sharing of cybersecurity information with cybersecurity professionals outside your organization. 
Examples of such professionals may be computer security incident response teams (CSIRTs) your organization is collaborating with.

The purpose of sharing this information with cybersecurity professionals is for them to be better able to assess and analyze cybersecurity issues within organizations and to be able to set it in context with information gathered from other sources in order to be able to devise better mitigation and prevention mechanisms, which in turn may help your organization to better protect against attacks in the future.

If you decide to share, the information will be posted on a protected repository. Only people and organizations that have been authorized by your organization will be able to access the information."""
    override val informationshare_edit = "Modifica la Condivisione delle Informazioni"
    override val informationshare_id = "Id"
    override val informationshare_description = "Descrizione"
    override val informationshare_state = "Stato"
    override val informationshare_create = "Created"
    override val informationshare_modified = "Ultima modifica"
    override val informationshare_initiator = "Chi"
    override val informationshare_history = "Storico"
    override val informationshare_summary = "Summary"

    override val ShareState_AwaitDecision = "Conferma condivisione"
    override val ShareState_Accept = "accetta"
    override val ShareState_Decline = "rifiuta"
    override val ShareState_Unknown = "non definita"

    override val system_dependencies = "Sistema"

    override val system_depend_id = "Id"
    override val system_depend_name  = "Nome"
    override val system_depend_description= "Descrizione"
    override val system_depend_connectedFrom= "Connesso da"
    override val system_depend_connectedTo= "Connesso a"
    override val system_depend_infoflow= "Flusso di informazioni"
    override val system_depend_category= "Categoria"
    override val system_depend_fields= "Campi"
    override val system_depend_fields_valueset = "Insieme di Valori"
    override val system_depend_fields_valueset_values = "Valori"
    override val system_depend_node_types = "Node Types"
    override val system_depend_layout = "Layout"
    override val system_depend_layoutDirection = "Layout Direzione"
    override val system_depend_layout_horizontal = "Orizzontale"
    override val system_depend_layout_vertical = "Verticale"
    override val system_depend_layout_roots = "Roots"
    override val system_depend_layout_user_root = "Layout root"
    override val system_depend_layout_default_root = "Default layout"
    override val system_depend_spacing = "Spacing"

    override val system_depend_action_search = "Cerca Risorse di Sistema"
    override val system_depend_action_new = "Crea Nuovo"
    override val system_depend_action_edit = "Modifica"
    override val system_depend_action_delete = "Cancella"
    override val system_depend_action_delete_confirm_title = "Cancella Risorse di Sistema"
    override val system_depend_action_config = "Configurazione"
    override val system_depend_action_import = "Importa"
    override val system_depend_action_export = "Esporta"
    override val system_depend_action_config_import = "Configurazione Importa"
    override val system_depend_action_config_export = "Configurazione Esporta"
    override val system_depend_action_print = "Stampa"

    override val system_depend_action_backup_or_restore = "Backup o Ripristino"
    override val system_depend_backup_title = "Backup delle Risorse di Sistema"
    override val system_depend_backup_create_new = "Nuovo backup"
    override val system_depend_backup_created = "Creato"
    override val system_depend_backup_comment = "Commento"
    override val system_depend_backup_nodes_not_in_current = "Non nel corrente"
    override val system_depend_backup_nodes_in_current_not_in_this = "Non in questo"
    override val system_depend_backup_nodes_changed = "Modificato"
    override val system_depend_restore_state = "Modifica"
    override val system_depend_restore_operation = "Operazione"
    override val system_depend_restore_operation_remove = "Rimuovi"
    override val system_depend_restore_operation_restore = "Ripristina"
    override val system_depend_restore_revert_all_nodes = "Ripristina tutti i Nodi di Sistema"
    override val system_depend_restore_revert_configuration = "Ripristina la Configurazione delle Dipendenze di Sistema"

    override val system_depend_label_all = "Tutto"

    override val system_depend_field_id = "Id"
    override val system_depend_field_label = "Nome"
    override val system_depend_field_type = "Tipo"
    override val system_depend_field_cardinality = "Cardinalità "
    override val system_depend_field_add = "Includi campo"
    override val system_depend_fields_config = "Configurazione dei campi.."
    override val system_depend_field_validation_error_txt = "'%value%' non è valido per '%type%'"

    override val sign_out = "Check Out"
    override val sign_out_disclaimer = """
        |The CS-AWARE Visualisation session as been cleared,  
        |you will have to logout of the external Authorisation Provider,  
        |if you do not want to be automatically signed in again. 
        |""".trimMargin()
    override val sign_out_link="Go to Your Authorisation Provider to Sign Out"

    override val mail_dialog_title = "Send Email"
    override val mail_to = "To"
    override val mail_from = "From"
    override val mail_subject = "Subject"
    override val mail_body = "Body"
    override val mail_send = "Send"

    override val socialmedia_reader : String = "Social media"
    override val socialmedia_reader_filter_readstate_both = "Sia letto che non letto"
    override val socialmedia_reader_filter_readstate_not_read = "Solo non letto"
    override val socialmedia_reader_filter_readstate_read = "Solo letta"

    override val socialmedia_reader_read_on_source = "Leggi la fonte"
    override val socialmedia_createdAt : String = "Creato in data"
    override val socialmedia_keywords : String = "Parole chiave"
    override val socialmedia_text : String = "Testo"
    override val socialmedia_account : String = "Account"
    override val socialmedia_id:String = "Id"

    override val socialmedia_system_where = "Relativo a"

    override val socialmedia_account_included = "Incluso"
    override val socialmedia_management_title = "Gestione dei social media"
    override val socialmedia_account_edit = "Modifica"
    override val socialmedia_account_new = "Nuovo"
    override val socialmedia_account_mediaType = "Tipo"
    override val socialmedia_account_collectorState = "Raccoglitore"
    override val socialmedia_account_id = "Id"
    override val socialmedia_account_name = "Nome"
    override val socialmedia_account_displayName = "Nome del display"
    override val socialmedia_account_description= "Descrizione"
    override val socialmedia_account_lastCollected = "Ultima raccolta"

    override val socialmedia_account_twitter_new = "Nuova raccolta di account X"
    override val socialmedia_account_include = "Includere la raccolta dei conti"
    override val socialmedia_account_exclude = "Escludere la riscossione dell'account"

    override val socialmedia_account_action_export = "Esportazione"
    override val socialmedia_account_action_import = "Importa"
    override val socialmedia_account_action_include_page = "Includi la raccolta per gli account nella pagina"
    override val socialmedia_account_action_exclude_page = "Escludi la raccolta per gli account nella pagina"
    override val socialmedia_create_threatobservation = "Crea rapporto sulle minacce"

    override val policy = "Policy"
    override val policy_edit = "Edit policy"
    override val policy_title = "Title"
    override val policy_purpose = "Purpose"
    override val policy_elements = "Elements"
    override val policy_need = "Need"
    override val policy_roles_responsibilities = "Role and responsibilities"
    override val policy_references = "References"
    override val policy_tags = "Tags"
    override val policy_systemNodeReferences = "System resource references"
    override val policy_new  = "Create policy"
    override val policy_createdAt = "Created at"
    override val policy_createdBy = "Created by"
    override val policy_updatedAt = "Updated at"
    override val policy_updatedBy = "Updateted by"

    override val policy_state  = "Stato"
    override val policy_state_draft = "Bozza"
    override val policy_state_approved = "Approvato"
    override val policy_state_draft_with_approved = "Bozza con versione approvata"
    override val policy_state_obsolete = "Obsoleto"
    override val policy_state_unknown = "Sconosciute"

    override val policy_type_organisation =  "Organizzazione"
    override val policy_type_template = "Modello"

    override val policy_comment_comment = "Commento"
    override val policy_comment_add = "Aggiungi commento"
    override val policy_comment_edit = "Modifica commento"
    override val policy_comment_respond = "Rispondi"
    override val policy_comment_delete = "Elimina commento"

    override val policy_approve_comment_add = "Aggiungi commento di approvazione"

    override val policy_history = "Storia"

    override val policy_action_edit = "Modifica"
    override val policy_action_clone = "Clona"
    override val policy_action_approve = "Approva"
    override val policy_action_export = "Esporta"

    override val policy_action_ai_import = "Politica di importazione AI.."
    override val policy_import_extract_text = "Estrarre testo dal documento (file)"

    override val policy_config  = "Configurazione di tag e tipi di relazione"
    override val policy_relation_type = "Tipo di relazione"
    override val policy_relation_id = "Id"
    override val policy_relation_name = "Nome"
    override val policy_relation_reversed = "Relazione inversa"
    override val policy_template_action_edit= "Nuovo dal modello"

    override val anomalies_ignores_saved = "Anomalie ignorate"
    override val anomalies_incident_saved = "Incidente salvato"
    override val anomaly_details_module = "Modulo"
    override val anomaly_to_be_ignored= "Essere ignorato"
    override val anomaly_incident= "Incidenti"
    override val anomaly_new_incident= "Nuovo incidente"
    override val anomaly_unsaved_changes_titel="Sono presenti modifiche non salvate"
    override val anomaly_unsaved_changes_message="Sono presenti modifiche non salvate, vuoi procedere senza salvarle?"

    override val kpi_dimension_title_Dynamic_Cybersecurity_Strategy="Strategia dinamica di sicurezza informatica"
    override val kpi_dimension_title_Collaborative_Mindset ="Mentalità collaborativa"
    override val kpi_dimension_title_Organizational_Learning ="Apprendimento organizzativo"
    override val kpi_dimension_title_Regulatory_Compliance="Conformità normativa"
    override val kpi_dimension_title_Standards_and_Technologies= "Standard e tecnologie"

    override val kpi_incident_total ="Numero totale di incidenti"
    override val kpi_incident_sharing_ecosystem= "Ecosistema di condivisione degli incidenti"
    override val kpi_incident_sharing_global = "Condivisione incidente globale"
    override val kpi_sharing_count_public  = "conteggio condivisioni pubbliche"
    override val kpi_incident_sharing_changes = "Modifiche alla condivisione dell'incidente"
    override val kpi_statistic_description_incident_total="Il numero totale di incidenti gestiti dal sistema (aperti automaticamente o manualmente dal sistema).\n" +
            "Fornisce un'indicazione di quanto è preparata un'organizzazione a rilevare e gestire la sicurezza informatica\n" +
            "Problemi: automaticamente tramite strumenti di monitoraggio della sicurezza informatica opportunamente configurati o manualmente attraverso la consapevolezza e la collaborazione."
    override val kpi_statistic_description_incident_sharing_ecosystem ="= Il numero di incidenti con livello di condivisione “ecosistema”.\n" +
            "Fornisce un'indicazione su quanto bene la tua organizzazione collabora con altre organizzazioni correlate (inclusi fornitori e clienti)" +
            " al fine di gestire i problemi di sicurezza informatica nella tua organizzazione e aiuta le organizzazioni che collaborano a gestire i" +
            " problemi con i servizi da cui dipendono."
    override val kpi_statistic_description_incident_sharing_global_d2= "Il numero di incidenti con livello di condivisione \"globale\".\n" +
            "Fornisce un'indicazione su quanto bene la tua organizzazione partecipa alla sicurezza informatica collaborativa, come ad es. delineati " +
            "dalla strategia europea per la cybersecurity. Fornisce anche un'indicazione del modo in cui la tua organizzazione segue la conformità legale " +
            "e normativa sulla condivisione delle informazioni (ad esempio NIS, GDPR)."
    override val kpi_statistic_description_incident_sharing_changes ="Il numero di adattamenti ai livelli di condivisione (settimanale/mensile).\n" +
            "Fornisce un'indicazione su quanto attivamente la tua organizzazione partecipa alla condivisione delle informazioni per ottenere consapevolezza collaborativa " +
            "e cooperazione nella risoluzione dei problemi di sicurezza informatica.\n"
    override val kpi_statistic_description_frontend_logins ="Numero di login al sistema.\n" +
            "Fornisce un'indicazione di quanto attivamente l'organizzazione monitora e gestisce la situazione di sicurezza informatica nell'organizzazione."
    override val kpi_statistic_description_distinct_users ="Numero di utenti distinti nel sistema.\n" +
            "Fornisce un'indicazione di quante risorse la tua organizzazione dedica al monitoraggio e alla gestione della\n" +
            "situazione di sicurezza informatica nell'organizzazione."
    override val kpi_statistic_description_anomaly_count ="Numero di anomalie rilevate dalla piattaforma.\n" +
            "Fornisce un'indicazione di quanto bene è configurato il rilevamento automatico di comportamenti sospetti di sicurezza " +
            "informatica all'interno della tua organizzazione."
    override val kpi_statistic_description_anomaly_timeline ="Numero di nuove anomalie rilevate dal sistema (settimanale/mensile).\n" +
            "Fornisce un'indicazione sulla rapidità con cui i comportamenti sospetti in materia di sicurezza informatica vengono valutati" +
            " e gestiti all'interno della tua organizzazione."
    override val kpi_statistic_description_anomaly_dismissed ="Numero di anomalie classificate dall'operatore umano come irrilevanti.\n" +
            "Fornisce un'indicazione su quanto bene il monitoraggio automatizzato è configurato per il contesto specifico della tua\n" +
            "organizzazione e quanto bene l'organizzazione comprende il proprio contesto. Più basso è, meglio è."
    override val kpi_statistic_description_anomaly_incidents ="Conteggio delle anomalie considerate incidenti (settimanale/mensile).\n" +
            "Fornisce un'indicazione su quanto bene è configurato il monitoraggio automatizzato del contesto specifico dell'organizzazione " +
            "e quanto bene l'operatore umano comprende il comportamento anomalo che indica un problema di sicurezza informatica che deve essere gestito."
    override val kpi_statistic_description_system_graph_assets_d4 ="Il numero di asset nel grafico di sistema.\n" +
            "Fornisce un'indicazione di quanto bene la tua organizzazione ha identificato gli asset aziendali che devono essere protetti\n" +
            "nel cyberspazio. Nel contesto della conformità normativa, questo indica quanto bene la tua azienda comprende i suoi\n" +
            "obblighi di protezione degli asset aziendali dagli attacchi informatici."
    override val kpi_statistic_description_system_graph_dependencies_d4 ="Il numero di dipendenze nel grafico di sistema.\n" +
            "Fornisce un'indicazione di quanto bene la tua organizzazione comprende come gli asset della tua azienda dipendono l'uno dall'altro in termini di sicurezza informatica. " +
            "Nel contesto della conformità normativa, questo indica quanto bene la tua organizzazione comprende l'importanza di considerare le dipendenze quando ci si protegge dagli attacchi informatici."
    override val kpi_statistic_description_system_graph_values_d4 =""
    override val kpi_statistic_description_system_graph_changes_d4 ="Il numero di adattamenti apportati al grafico di sistema (settimanali/mensili).\n" +
            "Fornisce un'indicazione di quanto bene la tua organizzazione mantiene le conoscenze relative alla sicurezza informatica in un mondo che vede cambiamenti costanti nella tua organizzazione e nel panorama della sicurezza informatica. " +
            "Nel contesto della conformità normativa, questo fornisce un'indicazione di quanto bene la tua azienda affronta le procedure dinamiche di gestione della sicurezza informatica."
    override val kpi_statistic_description_incident_sharing_global_d4 ="Il numero di incidenti con livello di condivisione \"globale\". Fornisce un'indicazione di quanto bene la tua organizzazione partecipa alla sicurezza informatica collaborativa, " +
            "come ad esempio delineato dalla strategia europea per la sicurezza informatica. Nel contesto della conformità normativa, questa è un'indicazione di quanto bene la tua organizzazione " +
            "segue la conformità legale e normativa sulla condivisione delle informazioni (ad esempio NIS, GDPR)."
    override val kpi_statistic_description_system_graph_security_appliances = "Il numero di attrezzature di sicurezza identificate nel grafico di sistema.\n" +
            "Fornisce un'indicazione di quanto bene l'organizzazione segue le pratiche di sicurezza standard applicando\n" +
            "controlli di sicurezza tecnologici per prevenire e gestire i problemi di sicurezza informatica.\n"
    override val kpi_statistic_description_policy_standards ="Il numero di policy di sicurezza informatica derivate da standard di sicurezza"

    override val kpi_time_of_count= "Tempo di conteggio"
    override val kpi_number_of_incidents= "Numero di incidenti"
    override val kpi_number_of_policies= "Numero di politiche"
    override val kpi_number_of_changes= "Numero di modifiche"
    override val kpi_number_of_assets= "Numero di beni"
    override val kpi_number_of_dependencies= "Numero di dipendenze"
    override val kpi_number_of_values= "Numero di valori"
    override val kpi_number_of_logins ="Numero di login"
    override val kpi_number_of_distinct_users ="Numero di utenti distinti"
    override val kpi_number_of_anomalies ="Numero di anomalie"
    override val kpi_number_of_new_anomalies ="Numero di nuove anomalie"
    override val kpi_number_of_dismissed_anomalies ="Numero di anomalie ignorate"
    override val kpi_number_of_new_incidents ="Numero di nuovi incidenti"
    override val kpi_number_of_dot_dot_dot ="Numero di ..."
    override val kpi_number_of_security_appliances  ="Numero di dispositivi di sicurezza"
    override val kpi_number_of_policy_standards ="Numero di standard di policy"
    override val kpi_time= "Tempo"
    override val kpi_logins ="Login"
    override val kpi_distinct_users ="Utenti distinti"
    override val kpi_anomaly_count ="Conteggio anomalie"
    override val kpi_anomaly_timeline ="Cronologia anomalie"
    override val kpi_anomaly_dismissed ="Anomalia ignorata"
    override val kpi_anomaly_incident ="Incidente anomalo"
    override val kpi_system_graph_security_appliances ="Attrezzature di sicurezza per System Graph"
    override val kpi_security_appliances ="attrezzature di sicurezza"
    override val kpi_policy_standards ="Standard di policy"
    override val kpi_policy_count= "Numero di politiche"
    override val kpi_policy_changes= "Cambiamenti politici"

    override val kpi_statistic_description_policy_count= "Il numero di policy sulla piattaforma. \n" +
            "Fornisce un'indicazione su quanto la tua organizzazione è preparata ad affrontare la varietà di problemi legati alla sicurezza informatica."
    override val kpi_statistic_description_policy_changes= "Il numero di adattamenti apportati alle politiche (settimanale/mensile). \n" +
            "Fornisce un'indicazione su quanto la tua organizzazione è pronta ad adattarsi al panorama in continua evoluzione della sicurezza informatica."
    override val kpi_statistic_description_system_graph_assets_d1= "Il numero di asset nel grafico di sistema. \n" +
            "Fornisce un'indicazione su quanto bene la tua organizzazione ha identificato le risorse aziendali che devono essere protette nel cyberspazio."
    override val kpi_statistic_description_system_graph_dependencies_d1= "Il numero di dipendenze nel grafico di sistema. \n" +
            "Fornisce un'indicazione su quanto bene la tua organizzazione comprende come le risorse della tua azienda dipendono l'una dall'altra in termini di sicurezza informatica."
    override val kpi_statistic_description_system_graph_values_d1= "Il numero di valori indicati per gli asset nel grafico di sistema.\n" +
            "Fornisce un'indicazione sulla gestione della conoscenza della sicurezza informatica eseguita al fine di conservare la conoscenza \n" +
            "disponibile a lungo termine"
    override val kpi_statistic_description_system_graph_changes_d1= "Il numero di adattamenti apportati al grafico del sistema (settimanale/mensile). \n" +
            "Fornisce un'indicazione su quanto bene la tua organizzazione mantiene le conoscenze relative alla sicurezza informatica in un mondo\n" +
            "che vede cambiamenti costanti nella tua organizzazione e nel panorama della sicurezza informatica."

    override val kpi_system_graph_assets= "Risorse del grafico di sistema"
    override val kpi_system_graph_dependencies= "Dipendenze del grafico di sistema"
    override val kpi_system_graph_values= "Valori del grafico di sistema"
    override val kpi_system_graph_changes= "Modifiche al grafico del sistema"
    override val kpi_questionnaire= "Questionario"
    override val kpi_out_of_100= "su 100"
    override val kpi_d1_section_view_description= "Esplora la capacità di sviluppare e fornire una strategia di sicurezza informatica e di migliorare la sicurezza informatica delle organizzazioni \n" +
            "resilienza migliorando le capacità di risposta agli incidenti, di difesa informatica e di protezione delle infrastrutture critiche (CI)."
    override val kpi_d2_section_view_description ="Esamina elementi importanti di una cultura della sicurezza informatica responsabile, come la comprensione dei rischi legati al cyber,\n" +
            "il livello di fiducia nei servizi, la comprensione dei problemi di privacy, la collaborazione all’interno dell’organizzazione per promuovere la consapevolezza della sicurezza " +
            "informatica e i contatti con le parti interessate rilevanti"
    override val kpi_d3_section_view_description ="Esamina la disponibilità, l'adozione e la qualità dei programmi di sensibilizzazione sulla sicurezza informatica pertinenti, " +
            "dei programmi educativi formali sulla sicurezza informatica e dei programmi di formazione professionale"
    override val kpi_d4_section_view_description ="Esamina la capacità dell'organizzazione di progettare e promulgare una legislazione nazionale che\n" +
            "si riferisce direttamente e indirettamente alla sicurezza informatica"
    override val kpi_d5_section_view_description ="Esamina l'implementazione di standard e buone pratiche di sicurezza informatica, l'implementazione di processi e\n" +
            "controlli e lo sviluppo di tecnologie e prodotti per ridurre i rischi di sicurezza informatica"


    override val markdown_bold = "Grassetto"
    override val markdown_italic = "Corsivo"
    override val markdown_strikethrough = "Barrato"
    override val markdown_heading = "Intestazione"
    override val markdown_heading_smaller = "Titolo più piccolo"
    override val markdown_heading_bigger = "Titolo più grande"
    override val markdown_heading_1x = "Intestazione 1"
    override val markdown_heading_2x = "Intestazione 2"
    override val markdown_heading_3x = "Intestazione 3"
    override val markdown_code = "Codice"
    override val markdown_quote = "Citazione"
    override val markdown_unordered_list = "Elenco non ordinato"
    override val markdown_ordered_list = "Elenco ordinato"
    override val markdown_clean_block = "Blocco pulito"
    override val markdown_link = "Inserisci link"
    override val markdown_image = "Inserire immagine"
    override val markdown_mde_table = "Inserire tabella"
    override val markdown_horizontal_rule = "Regola orizzontale"
    override val markdown_preview = "Alterna anteprima"
    override val markdown_side_by_side = "Vista laterale"
    override val markdown_fullscreen = "Schermo intero"
    override val markdown_guide = "Guida"
    override val markdown_undo = "Annullare"
    override val markdown_redo = "Rifare"

    override val validation_error: String = "Errore di convalida"
    override val save_anyway: String = "Salvare comunque"
    override val required_field_not_filled_error: String= "Compila questo campo"
}