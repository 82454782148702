package csaware.test

import csaware.comm.ThreatsBackend
import csaware.main.UserConfiguration
import csaware.messages.CsawareMessages
import csaware.threats.ThreatStateSymbol
import dk.rheasoft.csaware.api.QueryResult
import dk.rheasoft.csaware.api.incident.BcdrSelfHealingState
import dk.rheasoft.csaware.api.incident.CourseOfActionWithType
import dk.rheasoft.csaware.api.incident.ThreatObservation
import dk.rheasoft.csaware.test.BcdrTestUpdateRequest
import dk.rheasoft.csaware.utils.JsonUtilSerialization
import kafffe.bootstrap.*
import kafffe.bootstrap.form.*
import kafffe.bootstrap.pagination.BootstrapPagination
import kafffe.bootstrap.pagination.Pager
import kafffe.core.DivContainer
import kafffe.core.Label
import kafffe.core.Model
import kafffe.core.PropertyModel
import kafffe.core.modifiers.CssClassModifier
import kafffe.core.modifiers.StyleModifier.Companion.styleModifier
import kafffe.core.modifiers.onchange
import kafffe.messages.MessagesObject.formatDateTime
import kotlinx.datetime.Clock
import kotlinx.datetime.toJSDate
import kotlinx.serialization.encodeToString
import org.w3c.dom.HTMLTableCellElement
import kotlin.math.ceil

class BcdrTestPage : DivContainer() {
    private var courseOfActionName = ""
    private val updateRequest = BcdrTestUpdateRequest(
        "NULL",
        BcdrSelfHealingState.Unknown,
        listOf(
            CourseOfActionWithType(
                "course-of-action--8e2e2d2b-17d4-4cbf-938f-98ee46b3cd3f",
                "jqueryui_mitigation",
                "CS-AWARE SIMULATION: Download and apply. Link: https://access.redhat.com/errata/RHSA-2015:1462",
                "Action"
                )
        )

    )

    private fun template(): String = JsonUtilSerialization.json.encodeToString(updateRequest)

    val alerts = addChild(AlertsPanel())

    data class FormData(var healingdata: String)

    val input = Model.of(FormData(template()))
    private val bundleNames: Model<List<String>> = Model.of(listOf(""))

    private var coaSelector: DropdownString
    private var inputTextArea: TextArea

    val form = addChild(BootstrapForm(input).apply {
        coaSelector = dropdown(
            "select",
            Model.of("Course of action"),
            PropertyModel(this@BcdrTestPage::courseOfActionName),
            bundleNames
        )
        coaSelector.onchange {
            coaSelector.updateValueModel()
            loadCourseOfActions()
        }
        inputTextArea = textArea(FormData::healingdata).apply {
            styleModifier {
                height = "20vh"
                width = "80vw"
            }
            required = true
        }
        submit()
        onSubmitOk = this@BcdrTestPage::submitOk
    })

    val spacer = addChild(Label(Model.of("-")))

    val table = addChild(BootstrapTable.create<ThreatObservation>(listOf()) {
        addStyle(BootstrapTableStyles.striped)
        addStyle(BootstrapTableStyles.bordered)
        modifiers.add(CssClassModifier("csaware-hover"))
        col(Model.of("Pick"), { observation ->
            DivContainer().apply {
                modifiers.add(CssClassModifier("btn-group"))
                fun btn(state: BcdrSelfHealingState) = addChild(
                    BootstrapButton(Model.of(state.toInternal())) {
                        updateRequest.threatId = observation.id
                        updateRequest.bcdrState = state
                        input.data = FormData(template())
                        inputTextArea.rerender()
                    }
                )
                when (observation.bcdrSelfHealingState) {
                    BcdrSelfHealingState.Unknown -> btn(BcdrSelfHealingState.NeedsUserAccept)
                    BcdrSelfHealingState.NeedsUserAccept -> btn(BcdrSelfHealingState.NeedsUserAccept).color = BasicColor.danger
                    BcdrSelfHealingState.UserAccepted -> btn(BcdrSelfHealingState.Processing)
                    BcdrSelfHealingState.UserDeclined -> btn(BcdrSelfHealingState.Skipped)
                    BcdrSelfHealingState.Processing -> {
                        btn(BcdrSelfHealingState.Done)
                        btn(BcdrSelfHealingState.PartialDone)
                        btn(BcdrSelfHealingState.Skipped)
                    }
                    BcdrSelfHealingState.Done -> btn(BcdrSelfHealingState.NeedsUserAccept)
                    BcdrSelfHealingState.PartialDone -> btn(BcdrSelfHealingState.NeedsUserAccept)
                    BcdrSelfHealingState.Skipped -> btn(BcdrSelfHealingState.NeedsUserAccept)
                }
            }
        })
        colEx(csaware.messages.i18nText(CsawareMessages::severity),
            fun(rowData: ThreatObservation, cell: HTMLTableCellElement): ThreatStateSymbol {
                cell.bgColor = UserConfiguration.default.severityColorMap[rowData.severity ?: 0]
                return ThreatStateSymbol(Model.of(rowData), includeSeverityText = true)
            })
        col(csaware.messages.i18nText(CsawareMessages::threat_endActive), { Label(it.endActive.toJSDate().formatDateTime()) })
        col(
            csaware.messages.i18nText(CsawareMessages::threat_firstObserved),
            { Label(it.firstObserved.toJSDate().formatDateTime()) })
        col(csaware.messages.i18nText(CsawareMessages::threat_group), { Label(it.threatGroup) })
        col(csaware.messages.i18nText(CsawareMessages::name), { Label(it.name) })

    })

    var pageSize: Int = 6
    val pager = Pager(1)

    val paginator = addChild(BootstrapPagination(pager).apply {
        prevNextPage = true
        modifiers.add(CssClassModifier("float-end"))
    })

    init {
        pager.changeListeners.add { loadData() }
    }

    init {
        loadData()
    }

    private fun loadCourseOfActions() {
        backend().getTxt("/test/usecase/bcdr/$courseOfActionName") { data ->
            updateRequest.courseOfActions = JsonUtilSerialization.json.decodeFromString(data)
            coaSelector.rerender()
            input.data = FormData(template())
            inputTextArea.rerender()
        }
    }

    fun loadData() {
        alerts.clearAlerts()
        val offset = pageSize * (pager.currentPage - 1)
        backend().getThreatsCurrentActive(Clock.System.now(), offset, pageSize, this::receiveData)
        backend().getTxt("/test/usecase/bcdr/list") {
            val json: Array<String> = JSON.parse(it)
            bundleNames.data = json.toList()
            rerenderRecursive()
        }
    }

    private fun receiveData(response: QueryResult<ThreatObservation>) {
        val pageCount = ceil(response.nofResult.toDouble() / pageSize.toDouble()).toInt()
        if (pager.nofPages != pageCount) {
            pager.nofPages = pageCount
        }
        table.data = response.result
    }

    private fun submitOk() {
        alerts.clearAlerts()
        println(input.data.healingdata)
        // treePrint(parse(input.data.healingdata))
        backend().sendJsonTxt("/test/bcdr/update", "POST", input.data.healingdata) {
            alerts.addAlert(Alert("Self Healing test send: $it", BootstrapLevel.info))
        }
    }

    private fun errorHandler(code: Int, text: String) {
        alerts.addAlert(Alert("($code) $text", BootstrapLevel.danger))
    }

    private fun backend() = ThreatsBackend(this::errorHandler)
}