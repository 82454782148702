package csaware.messages

import kafffe.messages.Messages_de

open class CsawareMessages_de : Messages_de(), CsawareMessages {
    override val translation_automatic: String = "Automatische Übersetzung"
    override val settings: String = "Einstellungen"

    override val environment_name = "Name"

    override val nav_overview = "Überblick"
    override val nav_threats = "Vorfälle"//"Bedrohungen"
    override val nav_threatsClosed = "Geschlossene Vorfälle"// "Bedrohungen"
    override val nav_config = "Konfiguration"
    override val threat_group = "Bedrohungsgruppe"

    override val tab_threats_open="Offen"
    override val tab_threats_closed ="Geschlossen"

    override val threat_group_all = "Alle"
    override val threat_nobody = "Niemand"
    override val threat_state = "Zustand"
    override val threat_state_initator = "Wer"
    override val threat_state_history = "Verlauf"
    override val threat_state_comment = "Kommentar"
    override val threat_course_of_action = "Handlungsempfehlung"
    override val threat_where = "Wo"
    override val threat_assignee = "Zugeteilt an"

    override val threat_observed_data_objects = "Beobachtete Daten"
    override val threat_observed_data_id= "Id"
    override val threat_observed_data_type= "Art"
    override val threat_observed_data_data = "Daten"

    override val threat_subtype = "Unterkategorie"
    override val threat_firstObserved = "Zuerst beobachtet"
    override val threat_endActive = "Geschlossen um"
    override val threat_newState = "Zustand ändern"
    override val threat_selfHealingConfirm = "BCDR bestätigen"

    override val threat_topTitle = "Höchste Bedrohungen"

    override val severity = "Ernsthaftigkeit"
    override val count = "Anzahl"

    override val threat_level_5_name = "Kritisch"
    override val threat_level_5_text = "Eine kritische Auswirkung dieses Events auf die Systeme oder Services wird erwartet."
    override val threat_level_4_name = "Ernst"
    override val threat_level_4_text = "Eine ernste Auswirkung dieses Events auf die Systeme oder Services wird erwartet."
    override val threat_level_3_name = "Substantiell"
    override val threat_level_3_text = "Eine substantielle Auswirkung dieses Events auf die Systeme oder Services wird erwartet."
    override val threat_level_2_name = "Moderat"
    override val threat_level_2_text = "Eine moderate Auswirkung dieses Events auf die Systeme oder Services wird erwartet."
    override val threat_level_1_name = "Niedrig"
    override val threat_level_1_text = "Eine niedrige Auswirkung dieses Events auf die Systeme oder Services wird erwartet."
    override val threat_level_0_name = ""
    override val threat_level_0_text = ""

    override val ThreatState_Active = "Aktiv"
    override val ThreatState_Resolved = "Gelöst"
    override val ThreatState_Ignored = "Ignoriert"
    override val ThreatState_HealingAwaitDecision = "BCDR erwartet Entscheidung"
    override val ThreatState_HealingAccepted = "BCDR akzeptieren"
    override val ThreatState_HealingDeclined = "Selbstheilungsvorschlag ablehnen"
    override val ThreatState_Healed = "BCDR erfolgreich"
    override val ThreatState_HealingFailed = "BCDR gescheitert"
    override val ThreatState_HealingInProgress = "BCDR in Arbeit"
    override val ThreatState_Unknown = "Unbekannt"

    override val user_management = "Benutzerverwaltung"
    override val user_edit = "Benutzer bearbeiten"
    override val user_new = "Neuer Benutzer"
    override val user_subject = "Subjekt"
    override val user_email = "Email"
    override val user_firstName = "Vorname"
    override val user_lastName = "Nachname"
    override val user_fullname  = "Name"
    override val user_roles = "Rollen"
    override val user_action_import = "Importieren"
    override val user_action_export = "Exportieren"

    override val user_preferences_edit ="UI-Einstellungen"
    override val system_preferences_accountDataPresentationLanguage = "Standardsprache für die Datenpräsentation"
    override val system_preferences_accountUiLanguage ="Standardsprache der Benutzeroberfläche"

    override val user_preferences_dataPresentationLanguage = "Datenpräsentationssprache"
    override val user_preferences_uiLanguage ="Sprache der Benutzeroberfläche"

    override val system_preferences_edit = "Systemeinstellungen"

    override val informationshare_management = "Informationsaustausch"
    override val informationshare_short_description = """Information sharing referrs to sharing of cybersecurity information with cybersecurity professionals outside your organization. 
Examples of such professionals may be computer security incident response teams (CSIRTs) your organization is collaborating with.

The purpose of sharing this information with cybersecurity professionals is for them to be better able to assess and analyze cybersecurity issues within organizations and to be able to set it in context with information gathered from other sources in order to be able to devise better mitigation and prevention mechanisms, which in turn may help your organization to better protect against attacks in the future.

If you decide to share, the information will be posted on a protected repository. Only people and organizations that have been authorized by your organization will be able to access the information."""
    override val informationshare_edit = "Informationsaustausch bearbeiten"
    override val informationshare_id = "Id"
    override val informationshare_description = "Beschreibung"
    override val informationshare_state = "Zustand"
    override val informationshare_create = "Created"
    override val informationshare_modified = "Zuletzt geändert"
    override val informationshare_initiator = "Wer"
    override val informationshare_history = "Verlauf"
    override val informationshare_summary = "Summary"

    override val ShareState_AwaitDecision = "Austausch akzeptieren"
    override val ShareState_Accept = "akzeptiert"
    override val ShareState_Decline = "abgelehnt"
    override val ShareState_Unknown = "unbekannt"

    override val system_dependencies = "System"

    override val system_depend_id = "Id"
    override val system_depend_name  = "Name"
    override val system_depend_description= "Beschreibung"
    override val system_depend_connectedFrom= "Verbunden von"
    override val system_depend_connectedTo= "Verbunden zu"
    override val system_depend_infoflow= "Informationsfluss"
    override val system_depend_category= "Kategorie"
    override val system_depend_fields= "Felder"
    override val system_depend_fields_valueset = "Feldwerte"
    override val system_depend_fields_valueset_values = "Werte"
    override val system_depend_node_types = "Node Types"
    override val system_depend_layout = "Anordnung"
    override val system_depend_layoutDirection = "Anordnungsrichtung"
    override val system_depend_layout_horizontal = "Horizontal"
    override val system_depend_layout_vertical = "Vertikal"
    override val system_depend_layout_roots = "Startknoten"
    override val system_depend_layout_user_root = "Anordnungsstart"
    override val system_depend_layout_default_root = "Standard Anordnung"
    override val system_depend_spacing = "Abstand"

    override val system_depend_action_search = "Systemressource durchsuchen"
    override val system_depend_action_new = "Neu erstellen"
    override val system_depend_action_edit = "Bearbeiten"
    override val system_depend_action_delete = "Löschen"
    override val system_depend_action_delete_confirm_title = "Systemressource Löschen"
    override val system_depend_action_config = "Konfiguration"
    override val system_depend_action_import = "Importieren"
    override val system_depend_action_export = "Exportieren"
    override val system_depend_action_config_import = "Konfiguration Importieren"
    override val system_depend_action_config_export = "Konfiguration Exportieren"
    override val system_depend_action_print = "Drucken"

    override val system_depend_action_backup_or_restore = "Sichern oder Wiederherstellen"
    override val system_depend_backup_title = "Systemressourcen Sicherung"
    override val system_depend_backup_create_new = "Neue Sicherung"
    override val system_depend_backup_created = "Erstellt"
    override val system_depend_backup_comment = "Kommentar"
    override val system_depend_backup_nodes_not_in_current = "Nicht im aktuellen"
    override val system_depend_backup_nodes_in_current_not_in_this = "Nicht in diesem"
    override val system_depend_backup_nodes_changed = "Geändert"
    override val system_depend_restore_state = "Ändern"
    override val system_depend_restore_operation = "Operation"
    override val system_depend_restore_operation_remove = "Entfernen"
    override val system_depend_restore_operation_restore = "Wiederherstellen"
    override val system_depend_restore_revert_all_nodes = "Alle Systemknoten wiederherstellen"
    override val system_depend_restore_revert_configuration = "Systemabhängigkeiten Konfiguration wiederherstellen"

    override val system_depend_label_all = "Alle"

    override val system_depend_field_id = "ID"
    override val system_depend_field_label = "Name"
    override val system_depend_field_type = "Typ"
    override val system_depend_field_cardinality = "Kardinalität "
    override val system_depend_field_add = "Feld einbeziehen"
    override val system_depend_fields_config = "Felderkonfiguration.."
    override val system_depend_field_validation_error_txt = "'%value%' ist nicht gültig für '%type%'"

    override val sign_out = "Abmelden"
    override val sign_out_disclaimer = """
        |Die CS-AWARE Sitzung wurde beendet.  
        |Um automatisches login zu vermeiden, müssen Sie sich
        |zusätzlich beim externen Authorierungsanbieter abmelden. 
        |""".trimMargin()
    override val sign_out_link="Abmeldung beim Authorisierungsanbieter."

    override val mail_dialog_title = "E-mail senden"
    override val mail_to = "An"
    override val mail_from = "Von"
    override val mail_subject = "Betreff"
    override val mail_body = "Nachricht"
    override val mail_send = "Senden"

    override val socialmedia_reader : String = "Soziale Medien"
    override val socialmedia_reader_filter_readstate_both = "Sowohl gelesen als auch ungelesen"
    override val socialmedia_reader_filter_readstate_not_read = "Nur ungelesen"
    override val socialmedia_reader_filter_readstate_read = "Nur gelesen"

    override val socialmedia_reader_read_on_source = "Quelle lesen"
    override val socialmedia_createdAt : String = "Erstellt am"
    override val socialmedia_keywords : String = "Schlüsselwörter"
    override val socialmedia_text : String = "Text"
    override val socialmedia_account : String = "Konto"
    override val socialmedia_id:String = "Id"

    override val socialmedia_system_where = "Bezieht sich auf"

    override val socialmedia_account_included = "Enthalten"
    override val socialmedia_management_title = "Soziale Medien Management"
    override val socialmedia_account_edit = "Bearbeiten"
    override val socialmedia_account_new = "Neu"
    override val socialmedia_account_mediaType = "Typ"
    override val socialmedia_account_collectorState = "Sammler"
    override val socialmedia_account_id = "Id"
    override val socialmedia_account_name = "Name"
    override val socialmedia_account_displayName = "Anzeigename"
    override val socialmedia_account_description= "Beschreibung"
    override val socialmedia_account_lastCollected = "Zuletzt gesammelt"

    override val socialmedia_account_twitter_new = "Neue X-Kontosammlung"
    override val socialmedia_account_include = "Enthalten kontosammlung"
    override val socialmedia_account_exclude = "Ausschließen kontosammlung"

    override val socialmedia_account_action_export = "Exportieren"
    override val socialmedia_account_action_import = "Importieren"
    override val socialmedia_account_action_include_page = "Include collection for accounts on the page"
    override val socialmedia_account_action_exclude_page = "Exclude collection for accounts on the page"
    override val socialmedia_create_threatobservation = "Bedrohungsbericht erstellen"

    override val policy = "Politik"
    override val policy_edit = "Politik bearbeiten"
    override val policy_title: String = "Titel"
    override val policy_purpose = "Zweck"
    override val policy_elements = "Elemente"
    override val policy_need = "Bedarf"
    override val policy_roles_responsibilities = "Role and responsibilities"
    override val policy_references = "Referenzen"
    override val policy_tags = "Tags"
    override val policy_systemNodeReferences: String = "Systemressourcen-Referenzen"
    override val policy_new  = "Politik erstellen"
    override val policy_createdAt = "Erstellt am"
    override val policy_createdBy = "Erstellt von"
    override val policy_updatedAt = "Zuletzt geändert"
    override val policy_updatedBy = "Geändert von"

    override val policy_state  = "Zustand"
    override val policy_state_draft = "Entwurf"
    override val policy_state_approved = "Genehmigt"
    override val policy_state_draft_with_approved = "Entwurf mit genehmigter version"
    override val policy_state_obsolete  = "Veraltet"
    override val policy_state_unknown = "Unbekannt"

    override val policy_type_organisation = "Organisation"
    override val policy_type_template = "Vorlage"

    override val policy_comment_comment = "Kommentar"
    override val policy_comment_add = "Kommentar hinzufügen"
    override val policy_comment_edit = "Kommentar bearbeiten"
    override val policy_comment_respond = "Antworten"
    override val policy_comment_delete = "Kommentar löschen"

    override val policy_approve_comment_add = "Genehmigten kommentar hinzufügen"
    override val policy_history = "Geschichte"

    override val policy_action_edit = "Bearbeiten"
    override val policy_action_clone = "Klonen"
    override val policy_action_approve = "Genehmigen"
    override val policy_action_export = "Exportieren"

    override val policy_action_ai_import = "AI import Politik.."
    override val policy_import_extract_text = "Udtræk tekst fra dokument (fil)"

    override val policy_config  = "Konfiguration von Tags und Beziehungstypen"
    override val policy_relation_type = "Beziehungstyp"
    override val policy_relation_id = "Id"
    override val policy_relation_name = "Name"
    override val policy_relation_reversed = "Umgekehrte Beziehung"

    override val policy_template_action_edit: String = "Neu aus der Vorlage"

    override val anomalies_ignores_saved = "Anomalien ignoriert"
    override val anomalies_incident_saved = "Vorfall gespeichert"
    override val anomaly_details_module = "Modul"
    override val anomaly_to_be_ignored= "Ignoriert werden"
    override val anomaly_incident= "Vorfälle"
    override val anomaly_new_incident= "Neu Vorfall"
    override val anomaly_unsaved_changes_titel="Es gibt nicht gespeicherte Änderungen"
    override val anomaly_unsaved_changes_message="Es gibt nicht gespeicherte Änderungen. Möchten Sie fortfahren, ohne sie zu speichern?"

    override val kpi_dimension_title_Dynamic_Cybersecurity_Strategy="Dynamische Cybersicherheitsstrategie"
    override val kpi_dimension_title_Collaborative_Mindset ="Kollaborative Denkweise"
    override val kpi_dimension_title_Organizational_Learning ="Organisatorisches Lernen"
    override val kpi_dimension_title_Regulatory_Compliance="Einhaltung gesetzlicher Vorschriften"
    override val kpi_dimension_title_Standards_and_Technologies= "Standards und Technologien"

    override val kpi_incident_total = "Gesamtzahl der Vorfälle"
    // override val kpi_incident = "Vorfall"
    override val kpi_incident_sharing_ecosystem = "Ökosystem zum Teilen von Vorfällen"
    //   override val kpi_sharing_count_eco = "teilen count eco"
    override val kpi_incident_sharing_global = "Weltweites Teilen von Vorfällen"
    override val kpi_sharing_count_public = "Teilen Anzahl öffentlich"
    override val kpi_incident_sharing_changes = "Änderungen beim Teilen von Vorfällen"
    //   override val kpi_access_level_changes = "Änderungen der Zugriffsebene"
    override val kpi_statistic_description_incident_total= "Die Gesamtzahl der vom System bearbeiteten Vorfälle (vom System automatisch oder manuell geöffnet).\n" +
            "Gibt einen Hinweis darauf, wie gut eine Organisation darauf vorbereitet ist, Cybersicherheit zu erkennen und zu verwalten\n" +
            "Probleme – entweder automatisch durch ordnungsgemäß konfigurierte Tools zur Cybersicherheitsüberwachung oder manuell durch Sensibilisierung und Zusammenarbeit."
    override val kpi_statistic_description_incident_sharing_ecosystem = "Die Anzahl der Vorfälle mit der Freigabeebene „Ökosystem“.\n" +
            "Gibt einen Hinweis darauf, wie gut Ihre Organisation mit anderen verbundenen Organisationen (einschließlich Lieferanten und Kunden) " +
            "zusammenarbeitet, um Cybersicherheitsprobleme in Ihrer Organisation zu bewältigen und kooperierenden Organisationen bei der Bewältigung " +
            "von Problemen mit Diensten zu helfen, auf die sie angewiesen sind."

    override val kpi_statistic_description_incident_sharing_global_d2 = "Die Anzahl der Vorfälle mit der Freigabeebene „global“.\n" +
            "Gibt einen Hinweis darauf, wie gut Ihre Organisation an der kollaborativen Cybersicherheit beteiligt ist, z. B. " +
            "Dies ist in der europäischen Cybersicherheitsstrategie dargelegt. Ist auch ein Hinweis darauf, wie gut Ihre Organisation die Einhaltung " +
            "gesetzlicher und regulatorischer Vorschriften (z. B. NIS, DSGVO) beim Informationsaustausch befolgt."

    override val kpi_statistic_description_incident_sharing_changes = "Die Anzahl der Anpassungen der Freigabeebenen (wöchentlich/monatlich).\n" +
            "Gibt einen Hinweis darauf, wie aktiv sich Ihre Organisation am Informationsaustausch beteiligt, um ein gemeinsames Bewusstsein und eine Zusammenarbeit " +
            "bei der Lösung von Cybersicherheitsproblemen zu erreichen."
    override val kpi_statistic_description_frontend_logins ="Die Anzahl der Anmeldungen am System.\n" +
            "Gibt einen Hinweis darauf, wie aktiv die Organisation die Cybersicherheitslage in der Organisation überwacht und verwaltet."
    override val kpi_statistic_description_distinct_users ="Die Anzahl der unterschiedlichen Benutzer im System.\n" +
            "Gibt einen Hinweis darauf, wie viele Ressourcen Ihre Organisation für die Überwachung und Verwaltung der " +
            "Cybersicherheitslage in der Organisation bereitstellt."
    override val kpi_statistic_description_anomaly_count ="Die Anzahl der von der Plattform erkannten Anomalien.\n" +
            "Gibt einen Hinweis darauf, wie gut die automatische Erkennung von verdächtigem Cybersicherheitsverhalten\n" +
            "in Ihrer Organisation konfiguriert ist."
    override val kpi_statistic_description_anomaly_timeline ="Die Anzahl der vom System erkannten neuen Anomalien (wöchentlich/monatlich).\n" +
            "Gibt einen Hinweis darauf, wie schnell verdächtiges Cybersicherheitsverhalten innerhalb\n" +
            "Ihrer Organisation bewertet und behandelt wird."
    override val kpi_statistic_description_anomaly_dismissed ="Die Anzahl der Anomalien, die vom Human-in-the-Loop als irrelevant eingestuft werden.\n" +
            "Gibt einen Hinweis darauf, wie gut die automatische Überwachung auf den spezifischen Kontext Ihrer\n" +
            "Organisation konfiguriert ist und wie gut die Organisation ihren eigenen Kontext versteht. Je niedriger, desto besser."
    override val kpi_statistic_description_anomaly_incidents ="Die Anzahl der Anomalien, die als Vorfälle betrachtet werden (wöchentlich/monatlich).\n" +
            "Gibt einen Hinweis darauf, wie gut die automatische Überwachung des organisationsspezifischen Kontexts konfiguriert ist\n" +
            "und wie gut der Human-in-the-Loop abnormales Verhalten versteht, das auf ein Cybersicherheitsproblem hinweist, das behandelt werden muss.\n"
    override val kpi_statistic_description_system_graph_assets_d4 ="Die Anzahl der Assets im Systemdiagramm.\n" +
            "Gibt einen Hinweis darauf, wie gut Ihre Organisation Unternehmens-Assets identifiziert hat, die im Cyberspace geschützt werden müssen. " +
            "Im Kontext der Einhaltung gesetzlicher Vorschriften zeigt dies an, wie gut Ihr Unternehmen seine " +
            "Verpflichtungen zum Schutz von Unternehmens-Assets vor Cyberangriffen versteht."
    override val kpi_statistic_description_system_graph_dependencies_d4 ="Die Anzahl der Abhängigkeiten im Systemdiagramm.\n" +
            "Gibt einen Hinweis darauf, wie gut Ihre Organisation versteht, wie die Assets Ihres Unternehmens in Bezug auf die Cybersicherheit voneinander abhängen. " +
            "Im Kontext der Einhaltung gesetzlicher Vorschriften zeigt dies an, wie gut Ihre Organisation versteht, wie wichtig es ist, Abhängigkeiten beim Schutz vor Cyberangriffen zu berücksichtigen."
    override val kpi_statistic_description_system_graph_values_d4 =""
    override val kpi_statistic_description_system_graph_changes_d4 ="Die Anzahl der am Systemdiagramm vorgenommenen Anpassungen (wöchentlich/monatlich).\n" +
            "Gibt einen Hinweis darauf, wie gut Ihre Organisation das Wissen über Cybersicherheit in einer Welt aufrechterhält, in der sich Ihre Organisation und die Cybersicherheitslandschaft ständig ändern. " +
            "Im Kontext der Einhaltung gesetzlicher Vorschriften gibt dies einen Hinweis darauf, wie gut Ihr Unternehmen dynamische Verfahren zum Cybersicherheitsmanagement anspricht."
    override val kpi_statistic_description_incident_sharing_global_d4 ="Die Anzahl der Vorfälle mit der Freigabeebene „global“\n" +
            "Gibt einen Hinweis darauf, wie gut sich Ihre Organisation an der kollaborativen Cybersicherheit beteiligt, wie sie beispielsweise in der europäischen Cybersicherheitsstrategie dargelegt ist. " +
            "Im Zusammenhang mit der Einhaltung gesetzlicher Vorschriften ist dies ein Hinweis darauf, " +
            "wie gut Ihre Organisation die gesetzlichen und behördlichen Vorschriften zum Informationsaustausch einhält (z. B. NIS, DSGVO)."
    override val kpi_statistic_description_system_graph_security_appliances ="Die Anzahl der im Systemdiagramm identifizierten Sicherheitsausrüstung.\n" +
            "Gibt einen Hinweis darauf, wie gut die Organisation Standardsicherheitspraktiken befolgt, indem sie\n" +
            "technologische Sicherheitskontrollen anwendet, um Cybersicherheitsprobleme zu verhindern und zu bewältigen."
    override val kpi_statistic_description_policy_standards ="Die Anzahl der Cybersicherheitsrichtlinien, die aus Sicherheitsstandards abgeleitet wurden."


    override val kpi_time_of_count= "Zeitpunkt der Zählung"

    override val kpi_number_of_incidents= "Anzahl der Vorfälle"

    override val kpi_number_of_policies= "Anzahl der Richtlinien"

    override val kpi_number_of_changes= "Anzahl der Änderungen"

    override val kpi_number_of_assets= "Anzahl der Vermögenswerte"

    override val kpi_number_of_dependencies= "Anzahl der Abhängigkeiten"

    override val kpi_number_of_values= "Anzahl der Werte"
    override val kpi_number_of_logins ="Anzahl der Anmeldungen"
    override val kpi_number_of_distinct_users ="Anzahl der unterschiedlichen Benutzer"
    override val kpi_number_of_anomalies ="Anzahl der Anomalien"
    override val kpi_number_of_new_anomalies ="Anzahl neuer Anomalien"
    override val kpi_number_of_dismissed_anomalies ="Anzahl abgewiesener Anomalien"
    override val kpi_number_of_new_incidents ="Anzahl neuer Vorfälle"
    override val kpi_number_of_dot_dot_dot ="Anzahl von ..."
    override val kpi_number_of_security_appliances ="Anzahl der Sicherheitsgeräte"
    override val kpi_number_of_policy_standards ="Anzahl der Richtlinienstandards"

    override val kpi_time= "Zeit"

    override val kpi_logins ="Anmeldungen "
    override val kpi_distinct_users ="Unterschiedliche Benutzer"
    override val kpi_anomaly_count ="Anzahl der Anomalien"
    override val kpi_anomaly_timeline ="Anomalie-Zeitleiste"
    override val kpi_anomaly_dismissed ="Abgelehnte Anomalie"
    override val kpi_anomaly_incident ="Anomalie-Vorfall"
    override val kpi_system_graph_security_appliances ="Sicherheitsausrüstung für Systemdiagramm"
    override val kpi_security_appliances ="Sicherheitsausrüstung"
    override val kpi_policy_standards ="Richtlinienstandards"
    override val kpi_policy_count= "Anzahl der Richtlinien"

    override val kpi_policy_changes= "Richtlinienänderungen"

    override val kpi_statistic_description_policy_count= "Die Anzahl der Richtlinien auf der Plattform. \n" +
            "Gibt einen Hinweis darauf, wie gut Ihr Unternehmen auf den Umgang mit den verschiedenen Cybersicherheitsproblemen vorbereitet ist."
    override val kpi_statistic_description_policy_changes= "Die Anzahl der an den Richtlinien vorgenommenen Anpassungen (wöchentlich/monatlich). \n" +
            "Gibt einen Hinweis darauf, wie gut Ihr Unternehmen bereit ist, sich an die sich ständig verändernde Cybersicherheitslandschaft anzupassen."
    override val kpi_statistic_description_system_graph_assets_d1= "Die Anzahl der Assets im Systemdiagramm. \n" +
            "Gibt einen Hinweis darauf, wie gut Ihr Unternehmen Unternehmenswerte identifiziert hat, die im Cyberspace geschützt werden müssen."
    override val kpi_statistic_description_system_graph_dependencies_d1= "Die Anzahl der Abhängigkeiten im Systemdiagramm. \n" +
            "Gibt einen Hinweis darauf, wie gut Ihre Organisation versteht, wie die Vermögenswerte Ihres Unternehmens im Hinblick auf die Cybersicherheit voneinander abhängen."
    override val kpi_statistic_description_system_graph_values_d1= "Die Anzahl der für Vermögenswerte im Systemdiagramm angegebenen Werte.\n" +
            "Gibt einen Hinweis auf das durchgeführte Cybersicherheits-Wissensmanagement, um das Wissen aufrechtzuerhalten \n" +
            "langfristig verfügbar"
    override val kpi_statistic_description_system_graph_changes_d1= "Die Anzahl der am Systemdiagramm vorgenommenen Anpassungen (wöchentlich/monatlich). \n" +
            "Gibt einen Hinweis darauf, wie gut Ihr Unternehmen das Wissen über Cybersicherheit weltweit pflegt\n" +
            "Das sieht ständige Veränderungen in Ihrem Unternehmen und in der Cybersicherheitslandschaft."

    override val kpi_system_graph_assets= "Systemdiagramm-Assets"

    override val kpi_system_graph_dependencies= "Abhängigkeiten von Systemdiagrammen"
    override val kpi_system_graph_values= "Werte des Systemdiagramms"
    override val kpi_system_graph_changes= "Änderungen des Systemdiagramms"
    override val kpi_questionnaire= "Fragebogen"
    override val kpi_out_of_100= "von 100"
    override val kpi_d1_section_view_description= "Untersucht die Fähigkeit, eine Cybersicherheitsstrategie zu entwickeln und umzusetzen und die Cybersicherheit des Unternehmens zu verbessern\n" +
            "Resilienz durch Verbesserung der Reaktion auf Vorfälle, der Cyberabwehr und der Schutzkapazitäten für kritische Infrastrukturen (CI)."
    override val kpi_d2_section_view_description = "Behandelt wichtige Elemente einer verantwortungsvollen Cybersicherheitskultur wie das Verständnis von Cyberrisiken,\n" +
            "das Ausmaß des Vertrauens in die Dienste, das Verständnis für Datenschutzprobleme, die Zusammenarbeit innerhalb der Organisation zur " +
            "Förderung des Cybersicherheitsbewusstseins und die Kontakte mit relevanten Interessengruppen"
    override val kpi_d3_section_view_description ="Überprüft die Verfügbarkeit, Nutzung und Qualität relevanter Programme zur Sensibilisierung für Cybersicherheit,\n" +
            "formaler Bildungsprogramme zur Cybersicherheit und professioneller Schulungsprogramme"
    override val kpi_d4_section_view_description ="Untersucht die Fähigkeit der Organisation, nationale Gesetze zu entwerfen und zu erlassen, " +
            "die direkt und indirekt mit der Cybersicherheit in Zusammenhang stehen"
    override val kpi_d5_section_view_description ="Untersucht die Implementierung von Cybersicherheitsstandards und bewährten Praktiken, die Implementierung von Prozessen und\n" +
            "Kontrollen sowie die Entwicklung von Technologien und Produkten zur Reduzierung von Cybersicherheitsrisiken"


    override val markdown_bold = "Fett"
    override val markdown_italic = "Kursiv"
    override val markdown_strikethrough = "Durchgestrichen"
    override val markdown_heading = "Überschrift"
    override val markdown_heading_smaller = "Überschrift kleiner"
    override val markdown_heading_bigger = "Überschrift größer"
    override val markdown_heading_1x = "Überschrift 1"
    override val markdown_heading_2x = "Überschrift 2"
    override val markdown_heading_3x = "Überschrift 3"
    override val markdown_code = "Code"
    override val markdown_quote = "Zitat"
    override val markdown_unordered_list = "Ungeordnete Liste"
    override val markdown_ordered_list = "Geordnete Liste"
    override val markdown_clean_block = "Sauberer _Block"
    override val markdown_link = "Link einfügen"
    override val markdown_image = "Bild einfügen"
    override val markdown_mde_table = "Tabelle einfügen"
    override val markdown_horizontal_rule = "Horizontale Regel"
    override val markdown_preview = "Vorschau einschalten"
    override val markdown_side_by_side = "Seitenweise Ansicht"
    override val markdown_fullscreen = "Vollbildansicht"
    override val markdown_guide = "Leitfaden"
    override val markdown_undo = "Rückgängig"
    override val markdown_redo = "Wiederherstellen"

    override val validation_error: String = "Überprüfungsfehler"
    override val save_anyway: String = "Trotzdem speichern"

    override val required_field_not_filled_error: String= "Füllen Sie dieses Feld aus"
}

