package csaware.comm

import dk.rheasoft.csaware.api.*
import dk.rheasoft.csaware.api.systemdependencies.*

open class SystemDependenciesBackend(exceptionHandler: (errorCode: Int, errorText: String) -> Unit) :
    CSAwareBackend(exceptionHandler), GraphBackend {

    //    fun getSystemDependencies(receiver: (SystemDependency) -> Unit) {
    override fun getSystemDependencies(receiver: (List<SystemDependencyResource>) -> Unit) {
        getTxt("/sysdep/all") {
            receiver(SystemDependencyResource.fromListJson(it))
        }
    }

    override fun getSystemDependency(id: String, receiver: (SystemDependencyResource?) -> Unit) {
        getTxt("/sysdep/read/$id") {
            receiver(SystemDependencyResource.fromJson(it))
        }
    }

    override fun storeSystemDependency(resource: SystemDependencyResource, receiver: (SystemDependencyResource?) -> Unit) {
        sendJsonTxt("/sysdep/update", "POST", resource.toJsonString()) {
            receiver(SystemDependencyResource.fromJson(it))
        }
    }

    override fun storeSystemDependencyWithSourceInOther(
        resource: SystemDependencyResource,
        sourceInOtherResourceIds: Set<String>,
        receiver: (SystemDependencyResource?) -> Unit
    ) {
        val resourceWithOther = SystemDependencyResourceWithSourceInOthers(resource, sourceInOtherResourceIds)
        sendJsonTxt("/sysdep/updateWithSourceInOther", "POST", resourceWithOther.toJsonString()) {
            receiver(SystemDependencyResource.fromJson(it))
        }
    }

    override fun deleteSystemDependency(resource: SystemDependencyResource, receiver: (SystemDependencyResource?) -> Unit) {
        sendJsonTxt("/sysdep/delete/${resource.id}", "DELETE", resource.toJsonString()) {
            receiver(resource)
        }
    }

    override fun importSystemDependencies(jsonString: String, replaceCurrent: Boolean, receiver: () -> Unit) {
        sendJsonTxt("/sysdep/import?replace=$replaceCurrent", "POST", jsonString) {
            receiver()
        }
    }

    override fun getSystemDependencyConfig(receiver: (SystemDependencyConfig) -> Unit) {
        getTxt("/sysdep/config") {
            receiver(SystemDependencyConfig.fromJson(it))
        }
    }

    override fun storeSystemDependencyConfig(config: SystemDependencyConfig, receiver: (SystemDependencyConfig) -> Unit) {
        sendJsonTxt("/sysdep/config", "POST", config.toJsonString()) {
            receiver(SystemDependencyConfig.fromJson(it))
        }
    }

    override fun importSystemDependencyConfig(
        configJson: String,
        receiver: (String) -> Unit
    ) {
        sendJsonTxt("/sysdep/config/import", "POST", configJson) {
            val x = it
            receiver(x)
        }
    }

    override fun getBackups(offset: Int, pagesize: Int, receiver: (QueryResult<SystemDependencyBackup>) -> Unit) {
        getTxt("/sysdep/backups?offset=$offset&limit=$pagesize") {
            val x: QueryResult<SystemDependencyBackup> = SystemDependencyBackup.fromQueryResultJson(it)
            receiver(x)
        }
    }

    override fun backup(comment: String, receiver: (SystemDependencyBackup) -> Unit) {
        sendJsonTxt("/sysdep/backup", "POST", comment) {
            val x: SystemDependencyBackup = SystemDependencyBackup.fromJson(it)
            receiver(x)
        }
    }

    override fun restore(request: SystemDependencyRestoreRequest, receiver: (SystemDependencyBackup) -> Unit) {
        sendJsonTxt("/sysdep/restore", "POST", request.toJsonString()) {
            val x: SystemDependencyBackup = SystemDependencyBackup.fromJson(it)
            receiver(x)
        }
    }
}

