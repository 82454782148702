package dk.rheasoft.csaware.json.adapter.serialization

import dk.rheasoft.csaware.json.adapter.MapAnySerializer
import dk.rheasoft.csaware.json.adapter.base.JsonArrayAdapter
import dk.rheasoft.csaware.json.adapter.base.JsonElementType
import dk.rheasoft.csaware.json.adapter.base.JsonObjectAdapter
import dk.rheasoft.csaware.utils.JsonAccessUtils.toMutableKotlin
import dk.rheasoft.csaware.utils.JsonUtilSerialization
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.JsonElement

class JsObjectAdapter(val jsonAsMap: MutableMap<String, Any> = mutableMapOf()) : JsonObjectAdapter {

    override fun propertyNames(): Iterable<String> {
        return jsonAsMap.keys
    }

    override fun getType(property: String): JsonElementType {
        return when (jsonAsMap[property]) {
            is Number -> JsonElementType.NUMBER
            is Boolean -> JsonElementType.BOOLEAN
            is String -> JsonElementType.STRING
            is Collection<*> -> JsonElementType.ARRAY
            is Map<*, *> -> JsonElementType.OBJECT
            else -> JsonElementType.NULL
        }
    }


    @Suppress("UNCHECKED_CAST")
    override fun getObject(property: String): JsonObjectAdapter =
        JsObjectAdapter(
            if (getType(property) == JsonElementType.OBJECT)
                jsonAsMap[property] as MutableMap<String, Any>
            else
                mutableMapOf()
        )

    override fun setObject(property: String, value: JsonObjectAdapter) {
        jsonAsMap[property] = (value as JsObjectAdapter).jsonAsMap
    }

    @Suppress("UNCHECKED_CAST")
    override fun getArray(property: String): JsonArrayAdapter =
        JsArrayAdapter((jsonAsMap[property] ?: mutableListOf<Any?>()) as MutableList<Any?>)

    override fun setArray(property: String, value: JsonArrayAdapter) {
        jsonAsMap[property] = (value as JsArrayAdapter).jsonList
    }

    override fun getString(property: String): String? = jsonAsMap[property] as String?

    override fun setString(property: String, value: String) {
        jsonAsMap[property] = value
    }

    override fun getNumber(property: String): Number? = jsonAsMap[property] as Number?

    override fun setNumber(property: String, value: Number) {
        jsonAsMap[property] = value
    }

    override fun getBoolean(property: String): Boolean? = jsonAsMap[property] as Boolean?

    override fun setBoolean(property: String, value: Boolean) {
        jsonAsMap[property] = value
    }

    override fun createArray(): JsonArrayAdapter = JsArrayAdapter()
    override fun createArray(json: String): JsonArrayAdapter =
        JsArrayAdapter.fromString(json)

    override fun createObject(): JsonObjectAdapter = JsObjectAdapter()
    override fun createObject(json: String): JsonObjectAdapter =
        fromString(json)

    override fun toJsonString() = JsonUtilSerialization.json.encodeToString(MapAnySerializer, jsonAsMap)

    companion object {

        @Suppress("UNCHECKED_CAST")
        fun fromString(jsonStr: String): JsObjectAdapter {
            val parseToJsonElement: JsonElement = JsonUtilSerialization.json.parseToJsonElement(jsonStr)
            val jsonObject: MutableMap<String, Any> = parseToJsonElement.toMutableKotlin() as MutableMap<String, Any>
            return JsObjectAdapter(jsonObject)
        }

        fun fromMap(map: Map<String, Any?>): JsObjectAdapter {
            val mutableMap = mutableMap(map)
            return JsObjectAdapter(mutableMap)
        }

        private fun mutableMap(map: Map<String, Any?>): MutableMap<String, Any> {
            val mutableMap = mutableMapOf<String, Any>()
            for ((key, value) in map) {
                if (value != null) {
                    @Suppress("UNCHECKED_CAST")
                    when (value) {
                        is Number -> mutableMap[key] = value as Number
                        is Boolean -> mutableMap[key] = value as Boolean
                        is String -> mutableMap[key] = value as String
                        is Collection<*> -> mutableMap[key] = listOf(value)
                        is Map<*, *> -> mutableMap[key] = mutableMap(value as Map<String, Any>)
                    }
                }
            }
            return mutableMap
        }
    }
}