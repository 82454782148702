package dk.rheasoft.csaware.api.systemdependencies

import dk.rheasoft.csaware.utils.JsonUtilSerialization
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.encodeToString

/**
 * Request to restore/revert system dependencies data to a backup. The revert can be partial.
 */
@Serializable
data class SystemDependencyRestoreRequest(
    /**
     * Identification of the backup that is source of the data to revert to.
     */
    var backupId: Instant? = null,
    /**
     * Revert all nodes, this will ignore #deleteNodeIds and @see #revertNodeIds
     */
    var revertAllNodes: Boolean = false,
    /**
     * Revert configuration, usually the configuration should probably not be reverted. In case not reverting or doing a partial
     * revert, some nodes may end up in an invalid state (when checked against configured fields)
     */
    var revertConfiguration: Boolean = false,
    /**
     * Nodes ids from current which should be removed (they will  not in the backup).
     * It is possible to leave out IDs not in the backup, which will keep to node(s) from the current system dependencies graph.
     */
    var deleteNodeIds: MutableSet<String> = mutableSetOf(),
    /**
     * Node ids from the backup to revert data for, these may not be in the current or may be have changed data.
     */
    var revertNodeIds: MutableSet<String> = mutableSetOf(),
)  {
    fun toJsonString(): String =
        JsonUtilSerialization.json.encodeToString(this)

    companion object {
        fun fromJson(json: String): SystemDependencyRestoreRequest =
            JsonUtilSerialization.json.decodeFromString(json)

    }
}
