package dk.rheasoft.csaware.utils

import kotlinx.datetime.*

fun Instant.weekNumber(): Int {
    val localDate = this.toLocalDateTime(TimeZone.UTC).date
    return localDate.weekNumber()
}

fun LocalDate.weekNumber(): Int {
    val firstDayOfYear: LocalDate = LocalDate(year, 1, 1)
    val daysFromYearStart: Int = this.dayOfYear - 1
    val firstThursday: LocalDate = firstDayOfYear.plus(DatePeriod(days = (4 - firstDayOfYear.dayOfWeek.ordinal + 7) % 7))
    val weekNumber = (((daysFromYearStart + firstThursday.dayOfYear) - 1) / 7) + 1
    return if (weekNumber == 0) 52 else weekNumber
}

