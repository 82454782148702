package dk.rheasoft.csaware.api

import dk.rheasoft.csaware.api.access.MainFeature
import dk.rheasoft.csaware.api.access.Permission
import dk.rheasoft.csaware.api.access.UserRole
import dk.rheasoft.csaware.utils.JsonUtilSerialization
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.decodeFromJsonElement

@Serializable
data class User(
    val subject: String,
    var email: String,
    val firstName: String,
    val lastName: String,
    val name: String,
        //allow change to allow assignment of new groups by a User Admin
    var roles: Set<UserRole>,
    var preferences: UserPreferences = UserPreferences(),
) {
    val fullname: String get() = name.ifEmpty {"$firstName $lastName"}

    operator fun get(userRole: UserRole): Boolean = roles.contains(userRole)
    operator fun set(userRole: UserRole, include: Boolean) {
        if (include) {
            roles += userRole
        } else {
            roles -= userRole
        }
    }

    fun hasAccess(feature: MainFeature, permission: Permission) = roles.any{it.hasAccess(feature, permission)}

    fun hasRole(role: UserRole) = roles.contains(role)

    fun hasAnyRole(vararg roles: UserRole) = roles.any { hasRole(it) }
    fun hasAnyRole(roles: Iterable<UserRole>) = roles.any { hasRole(it) }

    val iso2LanguageData: String get() = preferences.dataPresentationLanguage.shortName
    val iso2LanguageUi: String get() = preferences.uiLanguage.shortName

    fun toJsonString(): String =
        JsonUtilSerialization.json.encodeToString(this)


    companion object {
        fun fromJson(json: JsonObject) : User =
            JsonUtilSerialization.json.decodeFromJsonElement(json)

        fun fromJson(json: String) : User =
            JsonUtilSerialization.json.decodeFromString(json)

        fun QueryResult<User>.toJsonString() : String =
            JsonUtilSerialization.json.encodeToString(QueryResult.serializer(serializer()),this)

        fun fromQueryResultJson(json: String) : QueryResult<User> =
            JsonUtilSerialization.json.decodeFromString(QueryResult.serializer(serializer()),json)

        val simulated = User("Simulated", "developer@simulated.email", "Simulated", "User", "", UserRole.entries.toSet(), UserPreferences())
        val none = User("noone", "-", "-", "-", "-", setOf(),UserPreferences())
        val system = User("CS-Aware", "----@cs-aware.eu", "System", "System", "System", UserRole.entries.toSet(), UserPreferences())
    }
}
@Serializable
data class UserPreferences(
    var uiLanguage: Language = Language.ENGLISH,
    var dataPresentationLanguage: Language = Language.ENGLISH,
    var modified: Instant = Clock.System.now(),
) {

    fun toJsonString(): String =
        JsonUtilSerialization.json.encodeToString(this)

    companion object {
        fun QueryResult<UserPreferences>.toJson(): String =
            JsonUtilSerialization.json.encodeToString(QueryResult.serializer(serializer()), this)

        fun fromQueryResultJson(json: String): QueryResult<UserPreferences> =
            JsonUtilSerialization.json.decodeFromString(QueryResult.serializer(serializer()), json)


        fun fromJson(json: JsonObject): UserPreferences =
            JsonUtilSerialization.json.decodeFromJsonElement(json)

        fun fromJson(json: String): UserPreferences =
            JsonUtilSerialization.json.decodeFromString(json)

    }
}

enum class Language {

    DANSK("da","Dansk"),
    ENGLISH("en","English"),
    GREEK("el", "Greek"),
    FRANCAISE("fr", "Francaise"),
    ITALIANO("it","Italiano"),
    DEUTSCH("de","Deutsch"),
    UNKNOWN("n/a","Unkonwn")
    ;

    constructor(shortName: String, displayName:String){
        this.shortName = shortName
        this.displayName = displayName
    }
    var shortName:String
    var displayName:String

    companion object {
        fun parse(value: String): Language = try {
            Language.valueOf(value)
        } catch (any: Throwable) {
            Language.UNKNOWN
        }
    }
}