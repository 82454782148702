package csaware.anomalies

import csaware.main.CsawareServices
import csaware.messages.CsawareMessages
import csaware.messages.i18nText
import dk.rheasoft.csaware.api.incident.AnomalyWrapper
import kafffe.bootstrap.Modal
import kafffe.core.*
import kafffe.core.modifiers.ReplaceContentInterceptor

/**
 * View for showing anomalies and group them into Incidents
 */
class Anomalies : KafffeComponent(), ReplaceContentInterceptor {
    private val unhandledAnomaliesModel: Model<List<AnomalyWrapper>> = Model.of(emptyList())
    private val targetsModel: Model<List<Target>> = Model.ofGet {
        listOf(
            listOf(
                Target("Unhandled", "fa-solid fa-burst", unhandledAnomaliesModel),
                Target("ToBe Ignored", "fa-solid fa-eye-slash", toBeIgnoredCollector.anomaliesModel),
            ),
            incidentsCan.targets()
        ).flatten()
    }
    private val dragHolder: AnomalyDragHolder = AnomalyDragHolder()
    private val table = addChild(AnomaliesTable(unhandledAnomaliesModel, dragHolder, targetsModel))

    init {
        // setup dummy data
        loadUnhandledAnomalies()
    }

    private fun loadUnhandledAnomalies() {
        // No paging for now
        CsawareServices.anomalyBackend.getByState(AnomalyWrapper.State.Todo, 0, 200) {
            unhandledAnomaliesModel.data = it.result
        }

    }

    private val toBeIgnoredCollector = addChild(ToBeIgnoredCollector(dragHolder, targetsModel))

    private val incidentsCan = addChild(IncidentsCollector(dragHolder, targetsModel))

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut =
        div {
            addClass("row mt-2")
            div {
                addClass("col")
                add(table.html)
            }
            div {
                addClass("col vgap-3")

                add(toBeIgnoredCollector.html)
                add(incidentsCan.html)
            }
        }

    override   fun interceptReplaceContent(replace: () -> Unit) {
        if (hasUnsavedChanges()) {
            Modal.Companion.confirm(Model.of(i18nText(CsawareMessages::anomaly_unsaved_changes_titel).data), Model.of(i18nText(CsawareMessages::anomaly_unsaved_changes_message).data), replace)
        } else {
            replace()
        }
    }

    private fun hasUnsavedChanges(): Boolean =
        toBeIgnoredCollector.hasUnsavedChanges() || incidentsCan.hasUnsavedChanges()

}