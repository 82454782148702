package csaware.systemdepend

import csaware.comm.GraphBackend
import csaware.main.CsawareServices
import csaware.main.UserInformation
import csaware.systemdepend.graph.shapes.NodeShapeRegistry
import dk.rheasoft.csaware.api.systemdependencies.SystemDependencyConfig
import dk.rheasoft.csaware.api.systemdependencies.SystemDependencyResource
import dk.rheasoft.csaware.api.access.MainFeature
import dk.rheasoft.csaware.api.access.Permission
import kafffe.core.Model
import kafffe.core.RefreshingCacheModel
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlin.time.Duration.Companion.days

class SystemDependencyService(
    /**
     * EcoSystemId == null, this is the org graph
     */
    val ecoSystemId: String? = null
) {

    fun isOrganisationGraph() : Boolean = ecoSystemId == null
    fun backend(): GraphBackend = if (isOrganisationGraph()) {
        CsawareServices.systemDependenciesBackend
    }  else {
        CsawareServices.ecoSystemGraphBackend(ecoSystemId!!)
    }

    val model: Model<List<SystemDependencyResource>> =
        RefreshingCacheModel(::refreshModel, listOf(), timeToLiveSeconds = 60 * 60)
    val config: Model<SystemDependencyConfig> =
        RefreshingCacheModel(::refreshConfig, SystemDependencyConfig(), timeToLiveSeconds = 61 * 60)
    val keywordCount: Model<Map<String, Long>> = Model.of(emptyMap())

    @Suppress("unused")
    val shapesRegistry = NodeShapeRegistry

    fun expireModels() {
        (model as RefreshingCacheModel).expire()
        (config as RefreshingCacheModel).expire()
    }

    private var refreshing = false
    private fun refreshModel(model: Model<List<SystemDependencyResource>>) {
        if (!refreshing) {
            if (UserInformation.hasAccess(MainFeature.SystemDependencies, Permission.Read)) {
                refreshing = true
                backend().getSystemDependencies {
                    refreshing = false
                    model.data = it
                    reloadKeywordCounts()
                }
            } else {
                model.data = listOf()
            }
        }
    }

    private fun reloadKeywordCounts() {
        try {
            val after: Instant = Clock.System.now() - 30.days
            val language = UserInformation.current.preferences.dataPresentationLanguage.shortName
            CsawareServices.socialMediaBackend.countKeywords(usedKeyWords(), language, after) { counts ->
                val newCounts: Map<String, Long> = model.data.associate { resource ->
                    val count: Long = resource.keywords.sumOf { counts[it] }
                    Pair(resource.id, count)
                }
                keywordCount.data = newCounts
            }
        } catch (e: Throwable) {
            println(e)
        }
    }

    private fun refreshConfig(model: Model<SystemDependencyConfig>) {
        if (UserInformation.hasAccess(MainFeature.SystemDependencyConfig, Permission.Read)) {
            backend().getSystemDependencyConfig { model.data = it }
        } else {
            model.data = SystemDependencyConfig()
        }
    }

    fun refreshAll() {
        refreshModel(model)
        refreshConfig(config)
    }

    fun refresh() {
        refreshModel(model)
    }

    fun refreshConfig() {
        refreshConfig(config)
    }

    fun byId(id: String): SystemDependencyResource? = model.data.find { it.id == id }
    fun byCriticalAssetId(id: String): SystemDependencyResource? = model.data.find { id in it.criticalAssetIds }

    fun byName(name: String): SystemDependencyResource? = model.data.find { it.name == name }

    /** reverse connectsTo returns list of ids*/
    fun connectedFrom(systemResource: SystemDependencyResource): List<String> =
            connectedFromById(systemResource.id)

    /** reverse connectsTo returns list of ids*/
    private fun connectedFromById(resourceId: String) : List<String> =
            model.data.filter { it.source.contains(resourceId) }.map { it.id }

    fun namesFromIds(ids: Iterable<String>): List<String> = ids.mapNotNull(::byId).map { it.name }

    fun store(systemResource: SystemDependencyResource, selectedModel: Model<SystemDependencyResource>) {
        config.data.removeUndefinedFields(systemResource)
        backend().storeSystemDependency(systemResource) {
            refreshWithSelected(
                systemResource.id,
                selectedModel
            )
        }
    }

    fun store(
        systemResource: SystemDependencyResource,
        selectedModel: Model<SystemDependencyResource>,
        connectedToIds: Set<String>
    ) {
        config.data.removeUndefinedFields(systemResource)
        backend().storeSystemDependencyWithSourceInOther(systemResource, connectedToIds) {
            refreshWithSelected(
                systemResource.id,
                selectedModel
            )
        }
    }

    fun delete(systemResource: SystemDependencyResource, selectedModel: Model<SystemDependencyResource>) {
        backend().deleteSystemDependency(systemResource) {
            refreshWithSelected(
                systemResource.id,
                selectedModel
            )
        }
    }

    fun import(jsonString: String, replaceCurrent: Boolean = true) {
        backend().importSystemDependencies(jsonString, replaceCurrent) { refresh(); refreshConfig() }
    }

    fun configImport(jsonString: String) {
        backend().importSystemDependencyConfig(jsonString) { refresh(); refreshConfig() }
    }

    @Suppress("UNUSED_PARAMETER")
    private fun refreshWithSelected(id: String, selectedModel: Model<SystemDependencyResource>) {
        backend().getSystemDependencies { systemDependencyResources ->
            model.data = systemDependencyResources
            reloadKeywordCounts()
            // selectedModel.data = byId(id) ?: SystemDependencyResource.NULL
        }

    }

    fun storeConfig(data: SystemDependencyConfig, callback: () -> Unit = {}) {
        backend().storeSystemDependencyConfig(data){
            config.data = it
            removeUndefinedFieldsAndTags()
            callback()
        }
    }

    private fun removeUndefinedFieldsAndTags() {
        val definedFieldIds = config.data.fields.map{ it.id}.toSet()
        val definedTags = config.data.getInfoflowValues().toSet()
        for (resource in model.data) {
            val undefinedFields = resource.data.keys.filter{it !in definedFieldIds}
            val undefinedTags = resource.x_infoflow.filter { it !in definedTags}
            if (undefinedFields.isNotEmpty() || undefinedTags.isNotEmpty()) {
                undefinedFields.forEach { resource.data.remove(it) }
                undefinedTags.forEach { resource.x_infoflow.remove(it) }
                backend().storeSystemDependency(resource) {}
            }
        }
        (model as RefreshingCacheModel).expire()
    }

    /**
     * Finds the set of currently used keywords in the whole system dependency graph
     */
    fun usedKeyWords(): Set<String> =
        model.data.flatMap { it.keywords }.toSet()

    /**
     * Finds system resources by keywords
     */
    fun findAllHavingByKeywords(keywords: Set<String>): List<SystemDependencyResource> =
        model.data.filter { it.keywords.intersect(keywords).isNotEmpty() }.toList()

}